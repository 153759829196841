(function() {
	'use strict';

	angular.module('adminApp').service('CountryService', ['$http','responseExceptionCatcher','$q' , CountryService]);

	function CountryService($http, responseExceptionCatcher, $q) {
		this.init = init;
		this.getAllCountries = getAllCountries;
		this.getCountryByCode = getCountryByCode;
		this.getCountryNameByCode = getCountryNameByCode;

		var countries = {};

		function init() {

			getCountries();
		}

		function getCountries() {
			return countries.length > 0 ? asPromise(countries) : getCountriesFromFile();
		}

		function getCountriesFromFile() {
			return $http.get('/static/Countries/countries.min.json').then(function(result) {
				countries = result.data.countries;
				decoarateAllCountries();
				return getCopy(countries);
			})
			.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function decoarateAllCountries() {
			return _.each(countries, function(country, iso) {
				country.id = iso;
				country.flagClass = 'flag-' + iso.toLowerCase();
				return country;
			});
		}


		function getAllCountries() {
			return countries;
		}

		function getCountryByCode(countryCode) {
			return countries[countryCode];
		}

		function getCountryNameByCode(countryCode) {
			var country = countries[countryCode.toUpperCase()];
			return country ? country.name : 'Unknown';
		}


		function getCopy(list) {
			return angular.copy(list);
		}

		function asPromise(data) {
			var deferred = $q.defer();
			deferred.resolve(data);
			return deferred.promise;
		}
	}
})();
