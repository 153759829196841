(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminDatabaseService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AdminDatabaseService]);

    function AdminDatabaseService($http, $q, CONFIG, responseExceptionCatcher) {
        this.reindexVisits = reindexVisits;
        this.reIndexVisitsForReseller = reIndexVisitsForReseller;
        this.oneTimeUpdateAccountIdAndUrlCacheKey = oneTimeUpdateAccountIdAndUrlCacheKey;
        this.removePagesForInactiveAccounts = removePagesForInactiveAccounts;
        this.reindexOrganisations = reindexOrganisations;
        this.exportOrganisations = exportOrganisations;
        this.exportAllOrganisationsForDomainMatching = exportAllOrganisationsForDomainMatching;
        this.exportAllISPOrganisations = exportAllISPOrganisations;
        this.exportAllOrganisations = exportAllOrganisations;
        this.pollReindexStatus = pollReindexStatus;
        this.deleteAllReindexStatus = deleteAllReindexStatus;
        this.getStatistics = getStatistics;
        this.removeForAllInactiveAccount = removeForAllInactiveAccount;
        this.countNumberOf = countNumberOf;
        this.moveVisitsForwardForDevelopment = moveVisitsForwardForDevelopment;
        this.cleanupNetworksForDevelpment = cleanupNetworksForDevelpment;
        this.obfuscateOrganisationsFTEAsync = obfuscateOrganisationsFTEAsync;
        this.deleteIndexAndRecreateOrganisations = deleteIndexAndRecreateOrganisations;
        this.deleteIndexAndRecreateVisits = deleteIndexAndRecreateVisits;
        this.enhanceFromCBITUsingDomain = enhanceFromCBITUsingDomain;
        this.enhanceFromCBITUsingCountry = enhanceFromCBITUsingCountry;
        this.enhanceFromNBKUsingCountry = enhanceFromNBKUsingCountry;
        this.updateEnhancementScoreOnAllOrganisations = updateEnhancementScoreOnAllOrganisations;
        this.createDomainAttribute = createDomainAttribute;
        this.lowerCaseallSicNames = lowerCaseallSicNames;
        this.readAllIndustryCodesAndCreateTags = readAllIndustryCodesAndCreateTags;
        this.createDIndustryTags = createDIndustryTags;
        this.updateSwedishOrganisationTypesBasedOnOrganisationNumber = updateSwedishOrganisationTypesBasedOnOrganisationNumber;
        this.updateNorwayOrganisationTypesBasedOnOrganisationNumber = updateNorwayOrganisationTypesBasedOnOrganisationNumber;


        function reindexVisits(from, account) {
            var url = CONFIG.API_PREFIX + '/admin/visits/reindex';
            if( from ){
                url = CONFIG.API_PREFIX + '/admin/visits/reindex?startDate=' + from;
            }
            if( account && account.id){
                url = CONFIG.API_PREFIX + '/admin/visits/reindex?accountId=' + account.id;
            }
            if( account && account.id && from){
                url = CONFIG.API_PREFIX + '/admin/visits/reindex?startDate='  + from + '&accountId=' + account.id;
            }
            return $http.post(url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when reindexing visits '));
        }

        function reIndexVisitsForReseller(resellerId) {
            var url = CONFIG.API_PREFIX + '/admin/visits/reseller/reindex?resellerId=' + resellerId;

            return $http.post(url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when reindexing visits for Reseller'));
        }


        function oneTimeUpdateAccountIdAndUrlCacheKey() {
            return $http.post(CONFIG.API_PREFIX + '/admin/pages/updateaccountandcachechekey')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when reindexing visits '));
        }

       function removePagesForInactiveAccounts() {
            return $http.post(CONFIG.API_PREFIX + '/admin/pages/deleteforinactiveaccounts')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when reindexing visits '));
        }

        function reindexOrganisations(countryISO) {
            var url = CONFIG.API_PREFIX + '/admin/organisations/reindex';
            if (countryISO) {
                url = url + '?countryISO=' + countryISO;
            }
            return $http.post(url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when reindexing organisations'));
        }

        function exportOrganisations(countryISO) {
            return $http.get(CONFIG.API_PREFIX + '/admin/organisations/analysis/' + countryISO + '/export/country',
                {responseType: 'arraybuffer'})
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function exportAllISPOrganisations() {
            return $http.get(CONFIG.API_PREFIX + '/admin/organisations/analysis/aggregated/export/isps',
                {responseType: 'arraybuffer'})
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function exportAllOrganisations(countryISO) {
            return $http.get(CONFIG.API_PREFIX + '/admin/organisations/analysis/aggregated/export/country?countryISO=' + countryISO,
                {responseType: 'arraybuffer'})
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

         function exportAllOrganisationsForDomainMatching(countryISO) {
             return $http.get(CONFIG.API_PREFIX + '/admin/organisations/analysis/' + countryISO + '/export/country/domain',
                {responseType: 'arraybuffer'})
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function pollReindexStatus() {
            return $http.get(CONFIG.API_PREFIX + '/admin/organisations/reindex/status')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteAllReindexStatus() {
            return $http.delete(CONFIG.API_PREFIX + '/admin/organisations/reindex/status/deleteall')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getStatistics() {

            var config = {
                hideLoadingIndicator: true,
                dontFailOnError: true
            };

            return $http.get(CONFIG.API_PREFIX + '/admin/database/statistics', config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function removeForAllInactiveAccount() {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/delete/firstvisits')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function countNumberOf() {
            return $http.get(CONFIG.API_PREFIX + '/admin/database/count/firstvisits')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function moveVisitsForwardForDevelopment() {
            return $http.post(CONFIG.API_PREFIX + '/admin/development/visitsmoveforward')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function cleanupNetworksForDevelpment() {
            return $http.post(CONFIG.API_PREFIX + '/admin/development/networks/cleanup')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function obfuscateOrganisationsFTEAsync() {
            return $http.post(CONFIG.API_PREFIX + '/admin/development/organisations/obfuscate')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteIndexAndRecreateOrganisations() {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/deleteIndexAndRecreate')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteIndexAndRecreateVisits() {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/visits/deleteIndexAndRecreate')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function enhanceFromCBITUsingDomain(domain) {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/enhancefromcbit/domain?domain=' + domain)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function enhanceFromCBITUsingCountry(countryISO) {
            if(!countryISO){
                return;
            }
            return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/enhancefromcbit/domain?countryISO=' + countryISO)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function enhanceFromNBKUsingCountry(countryISO) {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/enhancefromnbk/country?countryISO=' + countryISO)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updateEnhancementScoreOnAllOrganisations() {
            return $http.post(CONFIG.API_PREFIX + '/admin/organisations/updateEnhancementScoreOnAllOrganisations' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createDomainAttribute() {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/createDomainAttribute' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function lowerCaseallSicNames() {
            return $http.post(CONFIG.API_PREFIX + '/admin/database/lowerCaseallSicNames' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

		function readAllIndustryCodesAndCreateTags() {
			return $http.post(CONFIG.API_PREFIX + '/admin/database/readAllIndustryCodesAndCreateTags')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function createDIndustryTags() {
			return $http.post(CONFIG.API_PREFIX + '/admin/database/organisations/createDIndustryTags')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function updateSwedishOrganisationTypesBasedOnOrganisationNumber() {
			return $http.post(CONFIG.API_PREFIX + '/admin/organisations/updateSwedishOrganisationTypesBasedOnOrganisationNumber')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function updateNorwayOrganisationTypesBasedOnOrganisationNumber() {
			return $http.post(CONFIG.API_PREFIX + '/admin/organisations/updateNorwayOrganisationTypesBasedOnOrganisationNumber')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}
    }
})();