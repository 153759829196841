(function() {
	'use strict';

	angular
		.module('adminApp')
		.controller('TokenStatisticsController', [
			'tokenId',
			'TokenService',
			'TokenStatisticsChart',
			'account',
			'user',
			'logger',
			'$q',
			'FileSaver',
			'Blob',
			'CONFIG',
			'TableSort',
			TokenStatisticsController
		]);
	function TokenStatisticsController(
		tokenId,
		TokenService,
		TokenStatisticsChart,
		account,
		user,
		logger,
		$q,
		FileSaver,
		Blob,
		CONFIG,
		TableSort
	) {
		var vm = this;

		vm.tokenStatistics = [];
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.queryFor = null;
		vm.account = account;
		vm.tokenId = tokenId;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});

		// charts
		vm.tokenStatisticsChart = null;
		//moment(vm.start).format('YYYY-MM-DD')
		vm.start = moment()
			.subtract(120, 'days')
			.format('YYYY-MM-DD');
		vm.end = moment().format('YYYY-MM-DD');

		//methods
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.getType = getType;

		activate();

		function activate() {
			getInitialData().then(showData);
		}

		function getInitialData() {
			return $q.all([getTokenStatistics()]);
		}

		function showData(result) {
			vm.tokenStatistics = result[0];
			vm.tokenStatisticsChart = new TokenStatisticsChart(vm.tokenStatistics);
		}

		/// End init

		function getTokenStatistics() {
			return TokenService.getTokenStatistics(vm.account.id, vm.tokenId, vm.start, vm.end);
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename:
					'enhancedOrganisations_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.enhancedOrganisationStatistics.length;
		}

		function getType() {
			if (vm.type === 'user') {
				return 'statistics for user ' + vm.user.userName;
			}
		}
	}
})();
