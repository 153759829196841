(function () {
	'use strict';

	var filters = {
		NAME: 'Name',
		ISP: 'ISP',
		CITY: 'City',
		ZIP_CODE: 'Postal code',
		REGION: 'Region',
		COUNTRY: 'Country',
		DUNS: 'DUNS',
		ORGANISATION_ID: 'Organisation ID',
		LEADENHANCER_ID: 'Leadenhancer ID',
		SIC: 'SIC Code',
		SNI_NACE: 'SNI or NACE Code',
		NAICS: 'NAICS Code',
		GICS: 'GICS Code',
		DOMAIN_NAME: 'Domain name',
		SALES: 'Sales',
		NUMBER_OF_EMPLOYEES: 'Number of employees',
		TURNOVER: 'Sales',
		INDUSTRY_TAGS: 'Industry tags',
		ENHANCEMENT_SCORE : 'Enhancement score',
		ORGANISATION_TYPE : 'Organisation types',
		NETWORKS_SIZE : 'Networks size',

		PAGE_KEYWORDS : 'Page Keywords',
		PAGES : 'Pages',
		SCORE : 'Score',
		REFERER : 'Referer',
		FIRST : 'First'

	};

	var filterGroups = {
		ORGANISATION_DATA: 'Organization data',
		GEOGRAPHY: 'Geography',
		INDUSTRY: 'Industry'
	};

	var filterAccordion = [
		{
			name: filterGroups.ORGANISATION_DATA,
			items: [
				{
					name: filters.NAME
				},

				{
					name: filters.NUMBER_OF_EMPLOYEES
				},
				{
					name: filters.SALES
				},
				{
					name: filters.DUNS
				},
				{
					name: filters.ORGANISATION_ID
				},
				{
					name: filters.LEADENHANCER_ID
				},
				{
					name: filters.DOMAIN_NAME
				},

				{
					name: filters.ENHANCEMENT_SCORE
				},
				{
					name: filters.ORGANISATION_TYPE
				},
				{
					name: filters.NETWORKS_SIZE
				}
			]
		},
		{
			name: filterGroups.GEOGRAPHY,
			items: [

				{
					name: filters.CITY
				},
				{
					name: filters.COUNTRY
				},
				{
					name: filters.REGION
				},
				{
					name: filters.ZIP_CODE
				}
			]
		},
		{
			name: filterGroups.INDUSTRY,
			items: [
				{
					name: filters.ISP
				},
				{
					name: filters.SIC
				},
				{
					name: filters.SNI_NACE
				},
				{
					name: filters.NAICS
				},
				{
					name: filters.GICS
				},
				{
					name: filters.INDUSTRY_TAGS
				}
			]
		}
	];

	// used in directives
	var operands = {
		TEXT_MATCH: 'Text match',
		EQUAL: 'Equal to',
		NOT_EQUAL: 'Not equal to',
		IN: 'In list',
		NOT_IN: 'Not in list',
		BEGIN_WITH: 'Begins with',
		DOES_NOT_BEGIN_WITH: 'Does not begin with',
		CONTAINS: 'Contains',
		DOES_NOT_CONTAINS: 'Does not contain',
		BETWEEN: 'Between',
		LESS_THEN: 'Less than',
		MORE_THEN: 'More than'
	};



	var filterGroupVisits = {
		VISITS: 'Visits data',
		ORGANISATION_DATA: 'Organization data',
		GEOGRAPHY: 'Geography',
		INDUSTRY: 'Industry'
	};

	var filterAccordionVisits = [
		{
			name: filterGroupVisits.VISITS,
			items: [
				{
					name: filters.PAGE_KEYWORDS
				},
				{
					name: filters.PAGES
				},
				{
					name: filters.SCORE
				},
				{
					name: filters.REFERER
				},
				{
					name: filters.FIRST
				}
			]
		},
		{
			name: filterGroupVisits.ORGANISATION_DATA,
			items: [
				{
					name: filters.NAME
				},

				{
					name: filters.NUMBER_OF_EMPLOYEES
				},
				{
					name: filters.SALES
				},
				{
					name: filters.DUNS
				},
				{
					name: filters.ORGANISATION_ID
				},
				{
					name: filters.LEADENHANCER_ID
				},
				{
					name: filters.DOMAIN_NAME
				},
				{
					name: filters.ENHANCEMENT_SCORE
				},
				{
					name: filters.ORGANISATION_TYPE
				},
				{
					name: filters.NETWORKS_SIZE
				}
			]
		},

		{
			name: filterGroupVisits.GEOGRAPHY,
			items: [
				{
					name: filters.CITY
				},
				{
					name: filters.COUNTRY
				},
				{
					name: filters.REGION
				},
				{
					name: filters.ZIP_CODE
				}
			]
		},
		{
			name: filterGroupVisits.INDUSTRY,
			items: [
				{
					name: filters.ISP
				},
				{
					name: filters.SIC
				},
				{
					name: filters.SNI_NACE
				},
				{
					name: filters.NAICS
				},
				{
					name: filters.GICS
				},
				{
					name: filters.INDUSTRY_TAGS
				}
			]
		}
	];

	var maxValues = {};
	maxValues[filters.NUMBER_OF_EMPLOYEES] = 1000 * 1000 * 1000;
	maxValues[filters.SALES] = 1000 * 1000 * 1000;
	maxValues[filters.NETWORKS_SIZE] = 100;

	var constants = {
		operands: operands,
		filterGroups: filterGroups,
		filterGroupVisits: filterGroupVisits,
		filters: filters,
		filterAccordion: filterAccordion,
		filterAccordionVisits: filterAccordionVisits,
		maxValues: maxValues
	};

	angular
		.module('adminApp')
		.constant('filterConstants', constants);
})();
