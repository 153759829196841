(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminLogsService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AdminLogsService]);

    function AdminLogsService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getLatestLogs = getLatestLogs;


        function getLatestLogs(limit) {
            var url = CONFIG.API_PREFIX + '/admin/executionlogs';
            if( limit !== undefined &&  !limit ){
                url = CONFIG.API_PREFIX + '/admin/executionlogs?limit=' + limit;
            }
            return $http.get( url )
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

    }
})();