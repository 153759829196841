(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ReportExampleController', ['$uibModalInstance', 'modalConfig', ReportExampleController]);

    function ReportExampleController($uibModalInstance, modalConfig) {
        var vm = this;
        vm.title = modalConfig.title;
        vm.body = modalConfig.body;
        vm.positive = modalConfig.positive;
        vm.report = modalConfig.report;
        vm.ok = ok;

        function ok() {
            $uibModalInstance.close();
        }
    }

})();