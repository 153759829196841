(function() {
    'use strict';

    angular
        .module('adminApp')
        .directive('catalogSearch', function() {
            return {
                restrict: 'E',
                templateUrl: 'partials/targeting/campaigns/common/catalog-search.html',
                scope: {
                    items: '=',
                    title: '=',
                    isMainCatalog: '=',
                    onItemAdd: '&?'
                },
                controller: ['$element', '$scope', catalogSearchController]
            };
        });

        function catalogSearchController($element, $scope) {
            $scope.removeItem = removeItem;

            function removeItem(index) {
                $scope.items.splice(index, 1);
            }

            $scope.addItem = function(item) {
                $scope.onItemAdd()(item);
            };
        }
})();