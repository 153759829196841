(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PageQueryController', ['account', 'pageQueryId', '$q', '$state', 'logger', 'PageQueryService', 'PageKeywordService', 'PageService',PageQueryController]);

    function PageQueryController(account, pageQueryId, $q, $state, logger, PageQueryService, PageKeywordService, PageService) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.onPageQuerySave = onPageQuerySave;
        vm.autoCompletePageKeywords = autoCompletePageKeywords;
        vm.isPageFilterFormValid = isPageFilterFormValid;
        vm.isPageApplyFormValid = isPageApplyFormValid;
        vm.countPagesMatchingPageFilter = countPagesMatchingPageFilter;


        // this is resolved from ui-router if any
        vm.pageQueryId = pageQueryId;
        vm.account = account;
        vm.refPageKeywords = [];
        vm.pageFilterAndApply = {
            accountId: account.id,
            siteId: account.defaultSiteId,
            pageFilter: {
                pageName: null,
                pageUrl: null,
                pageKeywords: []
            },
            pageApply: {
                applyPageKeywords: [],
                appendKeywords: false,
                applyPageValue: null,
                applyPageName: null
            }
        };


        ////////////
        //  Init
        activate();

        function activate() {
            getPageQuery();
            getAllPageKeywords();
        }

        /// End init
        ///////////////

        function onPageQuerySave() {
            // todo
            PageQueryService.updatePageQuery(vm.account.id, vm.pageFilterAndApply)
                .then(function () {
                    //vm.pages = pages;
                    logger.success('Page Query saved!!!');

                });
        }

        function getPageQuery() {
            if (vm.pageQueryId) {
                PageQueryService.getPageQuery(vm.account.id, vm.pageQueryId)
                    .then(function (pageQuery) {
                        vm.pageFilterAndApply = pageQuery;
                        if (vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageName) {
                            vm.pageQueryFilterOn = 'pageName';
                        } else if (vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageUrl) {
                            vm.pageQueryFilterOn = 'pageUrl';
                        } else if (vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageKeywords) {
                            vm.pageQueryFilterOn = 'pageKeywords';
                        } else {
                            vm.pageQueryFilterOn = 'pageName';
                        }
                    });
            }
        }


        function getAllPageKeywords() {
            PageKeywordService.getAllPageKeywords(vm.account.id)
                .then(function (pageKeywords) {
                    vm.refPageKeywords = pageKeywords;
                });
        }

        function autoCompletePageKeywords($query) {
            return _.filter(vm.refPageKeywords, function (item) {
                return item.keyWord.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
        }

        function isPageFilterFormValid() {
            var valid  = false;
            if (vm.pageFilterAndApply.pageFilter.pageName !== undefined && !_.isNull(vm.pageFilterAndApply.pageFilter.pageName) && !_.isEmpty(vm.pageFilterAndApply.pageFilter.pageName)) {
                valid = true;
            }
            if (vm.pageFilterAndApply.pageFilter.pageUrl !== undefined && !_.isNull(vm.pageFilterAndApply.pageFilter.pageUrl) && !_.isEmpty(vm.pageFilterAndApply.pageFilter.pageUrl)) {
                valid = true;
            }
            if (vm.pageFilterAndApply.pageFilter.pageKeywords !== undefined && vm.pageFilterAndApply.pageFilter.pageKeywords.length > 0) {
                valid = true;
            }
            return valid;
        }

        function isPageApplyFormValid() {
            if (!isPageFilterFormValid()) {
                return false;
            }

            if (vm.pages && vm.pages.length === 0) {
                return false;
            }

            if (vm.pageFilterAndApply.pageApply.pageName !== undefined && !_.isNull(vm.pageFilterAndApply.pageApply.pageName) && !_.isEmpty(vm.pageFilterAndApply.pageApply.pageName)) {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageKeywords !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageKeywords.length > 0) {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageValue !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageValue !== null &&
                vm.pageFilterAndApply.pageApply.applyPageValue !== '') {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageName !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageName !== null &&
                vm.pageFilterAndApply.pageApply.applyPageName !== '') {
                return true;
            }


            return false;
        }


     /*   function onFilterAndApplyPages() {
            PageService.onFilterAndApplyPages(vm.account.id, vm.pageFilterAndApply)
                .then(function (result) {
                    vm.pages = result.data;
                    if( vm.pages && vm.pages.length >0){
                        logger.info('Applied settings on ' + result.header.totalCount + ' of Pages matching Filter criteria!!');
                    } else {
                        logger.error('No Pages changed, try altering Filter criterias for query!!');
                    }
                });
        }
        */

        function countPagesMatchingPageFilter() {
            PageService.onFilterPages(vm.account.id, vm.pageFilterAndApply)
                .then(function (pages) {
                    vm.pages = pages;
                    if( vm.pages && vm.pages.length >0){
                        logger.info('Found ' + vm.pages.length + ' of Pages matching Filter criteria!!');
                    } else {
                        logger.error('No Pages found, try altering Filter criterias for query!!');
                    }

                });
        }



    }
})();