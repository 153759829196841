(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('titleCase', titleCaseFilter);

    function titleCaseFilter() {
        return function (input) {
            input = input || '';
            var titleCase = input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            return _.replace(titleCase, '_', ' ');
            //s.replaceAll(titleCase, '_', ' ');
        };
    }

})();