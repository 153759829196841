(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('DailyTrafficForSiteDuringPeriodController', [ 'siteId', 'TrackingStatisticsService', 'logger', '$filter', '$state',
            '$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort', DailyTrafficForSiteDuringPeriodController]);
    function DailyTrafficForSiteDuringPeriodController(siteId,TrackingStatisticsService, logger, $filter, $state,
                                        $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        vm.trafficAccountTrackingStatisticsDaily = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        vm.startDate = moment().subtract(6, 'months').toDate();
        vm.endDate = moment().add(1, 'days').toDate();


        ////////////
        //  Init
        activate();

        function activate() {
            getDailyTrafficForSiteDuringPeriod();
        }

        function getDailyTrafficForSiteDuringPeriod() {
            TrackingStatisticsService.getDailyTrafficForSiteDuringPeriod(siteId, vm.startDate, vm.endDate).then(showData);
        }

        function showData(trafficAccountTrackingStatisticsDaily) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.trafficAccountTrackingStatisticsDaily = trafficAccountTrackingStatisticsDaily;
        }
        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'TrafficAccountTrackingStatisticsDaily_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.trafficAccountTrackingStatisticsDaily.length;
        }

    }
})();