(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('bareButton', function () {
            return {
                restrict: 'E',
                scope: {
                    label: '=',
                    click: '&',
                    classes: '@'
                },
                transclude: true,
                template: '<span class="{{classes}} bare-button" ng-click="click()"><span ng-transclude></span>{{label}}</span>'
            };
        })

        .directive('boldButton', function () {
            return {
                restrict: 'E',
                scope: {
                    label: '=',
                    click: '&',
                    classes: '@'
                },
                template: '<span class="{{classes}} button" ng-click="click()">{{label}}</span>'
            };
        })

        .directive('spacer', function () {
            return {
                restrict: 'E',
                template: '<span class="spacer"></span>'
            };
        });
})();
