(function() {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignBetaSettingsController', ['$scope', '$window', '$element', '$location', '$timeout', 'CampaignBetaConstants', 'CampaignBetaService', CampaignSettingsController]);

    function CampaignSettingsController($scope, $window, $element, $location, $timeout, CampaignConstants, CampaignService) {
        var vm = this;
        vm.parent = $scope.vm;
        vm.scope = $scope;

        vm.anchormenuItems = CampaignConstants.settingsAnchormenuItems;
        vm.anchormenuItems[0].active = true;

        vm.constants = CampaignConstants;

        vm.inputMoneyType = {type: 'string', subtype: 'numerical'};
        vm.inputNumberType = {type: 'string', subtype: 'numerical'};
        vm.inputURLType = {type: 'string'};
        vm.inputDateType = {type: 'date', subtype: 'date'};

        vm.currentCampaingEndType = 'never';

        vm.timezones = moment.tz.names();

        vm.campaignEndAttributes = CampaignConstants.campaignEndAttributes;
        vm.defaultEndAttribute = _.first(vm.campaignEndAttributes).value;

        vm.campaignCurrencies = CampaignConstants.campaignCurrencies;
        vm.defaultCurrency = _.first(vm.campaignCurrencies).value;

        vm.currentCampaingDeliveryType = 'even';
        vm.isVisitorCapActive = false;
        vm.isCampaignCapActive = false;

        if (vm.parent.campaign) {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }
        $scope.$on('campaignDataLoaded', function() {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }.bind(this));

        var day = Array.apply(null, Array(24 * 4)).map(function(){ return 0; });
        vm.slots = Array.apply(null, Array(7)).map(function(){ return day.slice(); });

        var paymentResult = {};

        if ($location.search().status) {
            handlePaymentResult();
        }

        function handlePaymentResult() {
            paymentResult = {
                error_name: $location.search().error_name,
                hash: $location.search().hash,
                locale: $location.search().locale,
                payment_ref: $location.search().payment_ref,
                status: $location.search().status,
                transaction_id: $location.search().transaction_id
            };

            $location.search('error_name', null);
            $location.search('hash', null);
            $location.search('locale', null);
            $location.search('payment_ref', null);
            $location.search('status', null);
            $location.search('transaction_id', null);

            if (paymentResult.error_name !== '') {
                paymentResult.error_code = $location.search().error_code;
                $location.search('error_code', null);
            }

            CampaignService.updatePayment({
                payment_ref: paymentResult.payment_ref,
                status: paymentResult.status
            }).then(showResult);
        }

        function showResult(payment) {
            var message = '';
            if (payment.hash !== paymentResult.hash) {
                message = 'Hashes are inconsistent';
            } else if (paymentResult.error_code) {
                switch (paymentResult.error_code) {
                    case '129':
                        message = 'Payment was declined';
                        break;
                    case '125':
                        message = 'Card CVV code is invalid';
                        break;
                    case '130':
                        message = 'Card has been expired';
                        break;
                    default:
                        message = 'Payment finished with error. Error code is ' + paymentResult.error_code;
                        break;
                }
            } else {
                message = 'You succesfully paid ' + payment.amount + ' ' + payment.currency;
                //Update balance
            }
            vm.popupMessage = message;
            vm.isPopupVisible = true;
        }
    }
})();