(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('DomainEnhanceCBITController', ['DomainEnhanceCBITService', 'CommonService',  'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$q',
			DomainEnhanceCBITController]);
    function DomainEnhanceCBITController(DomainEnhanceCBITService, CommonService, FileSaver, Blob, CONFIG,  TableSort, logger, $q) {
        var vm = this;
        vm.domainEnhancements = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });


		vm.refImportStatus = [
			{id: 'pending', name: 'Pending'},
			{id: 'matched', name: 'Matched'},
			{id: 'failed', name: 'NFailed'},
			{id: 'enhanced', name: 'Enhanced'}
		];

		vm.selectedStatusId = 'pending';

		//methods
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.updateLEOrganisationFromDomainEnhancedData = updateLEOrganisationFromDomainEnhancedData;
        vm.queryAndStoreDomainDataFromExternalSource = queryAndStoreDomainDataFromExternalSource;
        vm.search = search;
        vm.getCountriesForSelectBox = getCountriesForSelectBox;
        vm.clearQ = clearQ;
        vm.limit = 1000;
        vm.filterToShowOnlyEnhanced = null;
        vm.total = null;

		vm.checkAll = checkAll;
		vm.deCheckAll = deCheckAll;

		activate();

        function activate() {
			getInitialData().then(showData);
        }

		function getInitialData() {
			return $q.all([ getCountries(), countAll()]);
		}

		function showData(result) {
			vm.refCountries = result[0];
		}

		function getCountries() {
			return CommonService.getCountries();
		}


        function onFinished(domainEnhancements){
        	console.log('domainEnhancements' ,domainEnhancements);
			vm.domainEnhancements = domainEnhancements.result;

			vm.total = domainEnhancements.total;
			vm.totalPending = domainEnhancements.totalPending;
			vm.totalMatched = domainEnhancements.totalMatched;
			vm.totalEnhanced = domainEnhancements.totalEnhanced;
			vm.totalFailed = domainEnhancements.totalFailed;

			logger.info('Found ' + vm.domainEnhancements.length + ' items');
		}

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'domainEnhancements_sic_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.domainEnhancements.length;
        }


		function checkAll() {
			vm.domainEnhancements.selected = angular.copy(vm.domainEnhancements);
		}

		function deCheckAll() {
			vm.domainEnhancements.selected = null;
		}

		function updateLEOrganisationFromDomainEnhancedData() {
			var ids = getSelected();
			DomainEnhanceCBITService.updateLEOrganisationFromDomainEnhancedData(ids).then(function() {
				logger.info('Started enhancing LE ORgs from queue.... wait for it');
				getInitialData().then(showData);
			});
		}

		function queryAndStoreDomainDataFromExternalSource() {
			var ids = getSelected();
			DomainEnhanceCBITService.queryAndStoreDomainDataFromExternalSource(ids).then(function() {
				logger.info('Started enhancing querying from external source.... wait for it');
				getInitialData().then(showData);
			});
		}

		function clearQ() {
			DomainEnhanceCBITService.clearQ().then(function() {
				logger.info('Deleting Q items');
				countAll();
			});
		}

		function search() {
			DomainEnhanceCBITService.search(vm.country, vm.limit, vm.selectedStatusId).then( onFinished ) ;
		}

		function getSelected() {
			if (vm.domainEnhancements.selected || vm.domainEnhancements.selected.length > 0) {
				return _.map(vm.domainEnhancements.selected, 'id');
			}
			return null;
		}

		// typeahead callback
		function getCountriesForSelectBox(filterByCountryName) {
			return _.filter(vm.refCountries, function(country) {
				if (country.name && filterByCountryName) {
					return (
						country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1
					);
				}
			});
		}

		// on select in UI
		function onSetCountryForSelectBox(countrySelected) {
			vm.country = countrySelected;
		}

		function countAll() {
			return DomainEnhanceCBITService.countAll().then(function (data) {
				console.log('xcxxcdfsfd',data)
				vm.total = data;
			});
		}
    }
})();