(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AuthService', ['$http', '$q', 'CommonService', '$state', '$window','$timeout', 'responseExceptionCatcher', 'CONFIG', AuthService]);

    function AuthService($http, $q, CommonService, $state, $window, $timeout, responseExceptionCatcher, CONFIG)  {
        var vm = this;
        vm.isAllowed = isAllowed;
        vm.isAuthenticated = isAuthenticated;
        this.logout = logout;
        this.login = login;
        this.userForgotPassword = userForgotPassword;
        this.resetPassword = resetPassword;
        this.isUserPowerUser = isUserPowerUser;
        this.isUserAdmin = isUserAdmin;
        this.isUserTracking = isUserTracking;
        this.isUserTargeting = isUserTargeting;

        function isAllowed(roles) {
            var user = CommonService.getUser();
            if (!user) {
                // $state.go('login');
                return false;
            }
            var userRoles = roles.split(',');
            var intersection = _.intersection(user.roles, userRoles);
            return intersection.length > 0;
        }

        function isUserAdmin() {
            var user = CommonService.getUser();
            if (!user) {
                return false;
            }
			return _.includes(user.roles,'ROLE_ADMIN');

        }

        function isUserPowerUser() {
            var user = CommonService.getUser();
            if (!user) {
                return false;
            }
			return _.includes(user.roles,'ROLE_POWER_USER');
        }

         function isUserTracking() {
            var user = CommonService.getUser();
            if (!user) {
                return false;
            }
			return _.includes(user.roles,'ROLE_LEAD_TRACKING');
        }

         function isUserTargeting() {
            var user = CommonService.getUser();
            if (!user) {
                return false;
            }
			return _.includes(user.roles,'ROLE_TARGETING');
        }

        function isAuthenticated() {
            return $http.get(CONFIG.API_PREFIX + '/authenticate/isLoggedIn')
                .then(function (response) {

                    if( response.header && response.header.code === 'GEN_503' ){
                        var errorObject = { code: 'NOT_AUTHENTICATED' };
                        return $q.reject(errorObject);
                    }
                })
                .catch(function (){
                    //console.log('Not logged in!!!');
                    var errorObject = { code: 'NOT_AUTHENTICATED' };
                    return $q.reject(errorObject);
                });
        }

        function login(credentials) {
            return $http.post( CONFIG.API_PREFIX + '/authenticate/login', credentials)
                .then(function () {
                    $state.reload();
                    $state.go('admin.dashboard');
                }, function errorCallback() {
                    $state.go('login', {message: '1'}, {notify: true});
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function logout() {
            return $http.get(CONFIG.API_PREFIX + '/authenticate/logout')
                .then(function () {
                    $state.go('login');
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function userForgotPassword(user) {
            return $http.post(CONFIG.API_PREFIX + '/authenticate/forgotPassword', user)
                .then(function (response) {
                    return response.data.data;

                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function resetPassword(passwordUpdate) {
            return $http.post(CONFIG.API_PREFIX + '/authenticate/resetPassword', passwordUpdate)
                .then(function (response) {
                    return response.data.data;

                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();


