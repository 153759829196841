(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('dateSpinner', ['moment', dateSpinnerDirective]);

    function dateSpinnerDirective(moment) {
        return {
            restrict: 'EA',
            require: 'ngModel',
            templateUrl: 'partials/date-spinner.html',
            link: link(moment),
            replace: true,
            scope: {
                date: '=ngModel',
                compareDate: '=compareDate',
                id: '=inputId',
                name: '=inputName'
            }
        };
    }

    function link(moment) {
        return function (scope, element, attrs, ngModel) {
            scope.clickUp = clickUp;
            scope.clickDown = clickDown;

            function clickUp() {
                var newDate = moment(ngModel.$modelValue).add(1, 'days');
                ngModel.$setViewValue(newDate.valueOf());
            }

            function clickDown() {
                var newDate = moment(ngModel.$modelValue).subtract(1, 'days');
                ngModel.$setViewValue(newDate.valueOf());
            }

            scope.$watch(function () {
                return ngModel.$modelValue;
            }, updateDiffOnDateChange);

            function updateDiffOnDateChange(modelValue) {
                if (modelValue) {
                    var date = moment(modelValue);
                    var compareDate = moment(scope.compareDate);
                    scope.diff = date.diff(compareDate, 'days') + ' days';
                }
            }

            scope.$watch(function () {
                return scope.compareDate;
            }, updateDateOnCompareDateChange);

            function updateDateOnCompareDateChange(newCompareDate) {
                if (newCompareDate instanceof Date) {
                    var diffValue = parseInt(scope.diff.substring(0, scope.diff.indexOf(' ')));
                    var newDate = moment(newCompareDate);
                    newDate.add(diffValue, 'days');
                    ngModel.$setViewValue(newDate.valueOf());
                }
            }
        };
    }

})();