(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('OrganisationStatisticsController', ['OrganisationStatisticsService', 'logger', '$filter', '$state',
            '$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort', '$q',
            OrganisationStatisticsController]);
    function OrganisationStatisticsController(OrganisationStatisticsService, logger, $filter, $state, $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort, $q) {
        var vm = this;
        vm.organisationsGroupedByCountry = [];
        vm.refDistinctDates = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });

        //methods
        vm.runStatistics = runStatistics;
        vm.onFilterStatisticsOnDate = onFilterStatisticsOnDate;
        vm.loadMore = loadMore;
        vm.findAll = findAll;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([findAll(), findAllDistinctDate()]);
        }

        function initView(result) {
            vm.organisationsGroupedByCountry = result[0];
            vm.refDistinctDates = result[1];
            logger.success('Loaded statistics');

        }
        /// End init

        function findAll() {
            return OrganisationStatisticsService.findAll();
        }

        function onFilterStatisticsOnDate() {
            var date = moment(vm.selectedDate).format('YYYY-MM-DD');
            OrganisationStatisticsService.findAllByDate(date).then( reloadStats );
        }

        function reloadStats(stats){
            vm.organisationsGroupedByCountry = stats;

        }

        function findAllDistinctDate() {
            return OrganisationStatisticsService.findAllDistinctDate();
        }

        function runStatistics() {
            OrganisationStatisticsService.runStatistics().then(enableRunButton);
            logger.info('Running in background.');
        }

        function enableRunButton(){
            logger.info('Statistics created.');
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'orgstats_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.organisationsGroupedByCountry.length;
        }

    }
})();