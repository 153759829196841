(function () {
	'use strict';

	angular
		.module('adminApp')
		.config(['$stateProvider', '$urlRouterProvider', 'STATE', routeConfig]);

	function routeConfig($stateProvider, $urlRouterProvider, STATE) {

		$stateProvider
		//root state
			.state(STATE.ADMIN, admin())
			.state(STATE.LOGIN, login())
			.state(STATE.RESET_PWD, resetPassword())

			.state(STATE.DASHBOARD, dashboard())

			.state(STATE.VISITS, visits())
			.state(STATE.VISIT, visit())
			.state(STATE.VISIT_FILTERS, visitFilters())
			.state(STATE.VISIT_FILTER, visitFilter())

			.state(STATE.VISIT_ADVANCED_FILTERS, trackingVisitsAdvancedFilters())
			.state(STATE.VISIT_ADVANCED_FILTER, trackingVisitsAdvancedFilter())
			.state(STATE.VISIT_ADVANCED_FILTER_SEARCH, trackingVisitsAdvancedFilterSearch())
			.state(STATE.VISIT_ADVANCED_FILTER_LIST, trackingVisitAdvancedFilterList())
			.state(STATE.VISIT_ADVANCED_FILTER_SETTINGS, trackingVisitAdvancedFilterSettings())


			.state(STATE.VISIT_CAMPAIGNS, visitCampaigns())
			.state(STATE.VISIT_CAMPAIGN, visitCampaign())
			.state(STATE.VISIT_REPORTS, visitReports())
			.state(STATE.VISIT_REPORT, visitReport())
			.state(STATE.VISIT_REPORT_HISTORY, visitReportHistory())
			.state(STATE.CLICK_CAMPAIGNS, clickCampaigns())
			.state(STATE.CLICK_CAMPAIGN, clickCampaign())
			.state(STATE.CLICK_CAMPAIGN_TRACKING_LINK, clickCampaignTrackingLink())
			.state(STATE.VISIT_PAGE, visitPage())
			.state(STATE.VISIT_PAGES, visitPages())
			.state(STATE.VISIT_PAGE_KEYWORDS, visitPageKeywords())
			.state(STATE.VISIT_PAGE_KEYWORD, visitPageKeyword())
			.state(STATE.VISIT_PAGE_QUERIES, visitPageQueries())
			.state(STATE.VISIT_PAGE_QUERY, visitPageQuery())
			.state(STATE.VISIT_EXCLUDED_ORGANISATIONS, visitExcludeOrganisations())
			.state(STATE.VISIT_EXCLUDED_ORGANISATION, visitExcludedOrganisation())
			.state(STATE.VISIT_CUSTOM_VARIABLES, visitCustomVariables())
			.state(STATE.VISIT_COOKIES, visitCookies())


			.state(STATE.TARGETING_ADAPTIVE_CONTENTS, targetingAdaptiveContents())
			.state(STATE.TARGETING_ADAPTIVE_CONTENT, targetingAdaptiveContent())
			.state(STATE.TARGETING_ADAPTIVE_CONTENT_STATISTICS, targetingAdaptiveContentStatistics())

			.state(STATE.TARGETING_AUDIENCES, targetingAudiences())
			.state(STATE.TARGETING_AUDIENCE, targetingAudience())



			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTERS, targetingSegmentsAdvancedFilters())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTERS_SHOW_ORGANISATIONS, targetingSegmentsAdvancedFilterShowOrganisations())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER, targetingSegmentsAdvancedFilter())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_SEARCH, targetingSegmentsAdvancedFilterSearch())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_LIST, targetingSegmentsAdvancedFilterList())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_SETTINGS, targetingSegmentsAdvancedFilterSettings())
			.state(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_STATISTICS, targetingSegmentsAdvancedFilterStatistics())


			.state(STATE.CONNECT_TOKENS, connectTokens())
			.state(STATE.CONNECT_TOKEN_STATISTICS, connectTokenStatistics())


			.state(STATE.ADMIN_LOGGING, adminLogging())
			.state(STATE.ADMIN_TOKENS, adminTokens())
			.state(STATE.ADMIN_USERS, adminUsers())
			.state(STATE.ADMIN_USER_AUDIT_LOG, adminUserAuditLog())
			.state(STATE.ADMIN_USER_STATISTICS_LOG, adminUserStatisticsLog())
			.state(STATE.ADMIN_USER, adminUser())
			.state(STATE.ADMIN_MC_LISTS, adminLists())
			.state(STATE.ADMIN_MC_LIST_MEMBERS, adminListMembers())

			.state(STATE.ADMIN_BT_CLIENTS, adminBTClients())
			.state(STATE.ADMIN_BT_ADVERTISERS, adminBTAdvertisers())
			.state(STATE.ADMIN_BT_ADVERTISER_LINEITEMS, adminBTAdvertiserLineItems())
			.state(STATE.ADMIN_BT_CAMPAIGNS, adminBTCampaigns())
			.state(STATE.ADMIN_BT_ADS, adminBTAds())


			.state(STATE.ENHANCE_CLEARBIT_ORGANISATIONS, enhanceClearbitOrganisations())
			.state(STATE.ENHANCE_CLEARBIT_ORGANISATION, enhanceClearbitOrganisation())
			.state(STATE.ENHANCE_NBK_ORGANISATIONS, enhanceNBKORganisations())
			.state(STATE.ENHANCE_DOB_ORGANISATIONS, enhanceDOBOrganisations())
			.state(STATE.ENHANCE_RIPE_ORGANISATIONS, enhanceRIPEORganisations())
			.state(STATE.ENHANCE_LE_ORGANISATION, enhanceLEOrganisation())
			.state(STATE.ENHANCE_LE_ORGANISATIONS, enhanceLEOrganisations())
			.state(STATE.ENHANCE_ENHANCEMENT_QUEUE, enhancementQueue())
			.state(STATE.ENHANCE_ENHANCED_ORGANISATION, enhancementOrganisation())
			.state(STATE.ENHANCE_ENHANCEMENT_QUEUE_ORGANISATION_ISPS, enhancementQueueOrganisationsISPs())
			.state(STATE.ENHANCE_LE_ORGANISATIONS_BATCH_UPLOAD, enhancementLEORganisationsBatchUpload())
			.state(STATE.ENHANCE_ENHANCEMENT_STATS, enhancementStatistics())
			.state(STATE.ENHANCE_ENHANCEMENT_STATS_BY_USER, enhancementStatisticsByUserName())
			.state(STATE.ENHANCE_INDUSTRY_CODES_SIC, enhancementIndustryCodesSIC())
			.state(STATE.ENHANCE_INDUSTRY_CODES_NACE, enhancementIndustryCodesNACE())
			.state(STATE.ENHANCE_INDUSTRY_CODES_NACE_TO_SIC, enhancementIndustryCodesNAceToSIC())
			.state(STATE.ENHANCE_INDUSTRY_CODES_NAICS, enhancementIndustryCodesNAICS())
			.state(STATE.ENHANCE_INDUSTRY_CODES_GICS, enhancementIndustryCodesGICS())
			.state(STATE.ENHANCE_INDUSTRY_CODES_INDUSTRY_TAGS, enhancementIndustryCodesIndustryTags())
			.state(STATE.ENHANCE_QUEUE_DOMAIN, enhancementQueueDomain())
			.state(STATE.ENHANCE_QUEUE_CBIT, enhancementQueueCBIT())
			.state(STATE.ENHANCE_QUEUE_NBK, enhancementQueueNBK())

			.state(STATE.ORGANISATION, organisation());

		$stateProvider
		// Top menu
			.state('admin.userSettings', {
				url: '/usersettings',
				templateUrl: 'partials/settings/userSettings.html',
				controller: 'UserSettingsController as vm'
			})

			.state('admin.userPasswordReset', {
				url: '/userspasswordreset',
				templateUrl: 'partials/settings/userPasswordReset.html',
				controller: 'UserPasswordResetController as vm'
			})

			//////////////////
			///// Campaigns
			.state('admin.campaignsForAccount', {
				url: '/accounts/:accountId/campaigns',
				templateUrl: 'partials/targeting/campaigns/campaigns.html',
				controller: 'CampaignsController as vm'
			})
			.state('admin.campaigns', {
				url: '/campaigns',
				templateUrl: 'partials/targeting/campaigns/campaigns.html',
				controller: 'CampaignsController as vm'
			})
			.state('admin.campaign', {
				url: '/accounts/:accountId/campaigns/:campaignId',
				resolve: {
					campaignId: ['$stateParams', function ($stateParams) {
						return $stateParams.campaignId;
					}]
				},
				templateUrl: 'partials/targeting/campaigns/campaign.html',
				controller: 'CampaignController as vm'
			})
			.state('admin.campaignRevenueModels', {
				url: '/accounts/:accountId/campaignrevenuemodels',
				templateUrl: 'partials/targeting/campaigns/campaign-revenuemodels.html',
				controller: 'CampaignRevenueModelsController as vm'
			})
			.state('admin.campaignRevenueModel', {
				url: '/accounts/:accountId/campaignrevenuemodels/:campaignRevenueModelId',
				resolve: {
					campaignRevenueModelId: ['$stateParams', function ($stateParams) {
						return $stateParams.campaignRevenueModelId;
					}]
				},
				templateUrl: 'partials/targeting/campaigns/campaign-revenuemodel.html',
				controller: 'CampaignRevenueModelController as vm'
			})

			//////////////////
			///// Campaigns beta
			.state('admin.campaignsBetaForAccount', {
				url: '/accounts/:accountId/campaignsbeta',
				templateUrl: 'partials/targeting/beta/campaigns/campaigns.html',
				controller: 'CampaignsBetaController as vm'
			})
			.state('admin.campaignsBeta', {
				url: '/campaignsbeta',
				templateUrl: 'partials/targeting/beta/campaigns/campaigns.html',
				controller: 'CampaignsBetaController as vm'
			})
			.state('admin.campaignBeta', {
				url: '/accounts/:accountId/campaignsbeta/:campaignId',
				resolve: {
					accountId: ['$stateParams', function ($stateParams) {
						return $stateParams.accountId;
					}],
					campaignId: ['$stateParams', function ($stateParams) {
						return $stateParams.campaignId;
					}]
				},
				// templateUrl: 'partials/targeting/campaigns/campaign.html',
				templateUrl: 'partials/targeting/beta/campaigns/campaign_v2.html',
				controller: 'CampaignBetaController as vm',
				redirectTo: 'admin.campaignBeta.settings'
			})
			.state('admin.campaignBeta.adform', {
				url: '/adform',
				templateUrl: 'partials/targeting/beta/campaigns/campaign-adform.html',
				controller: 'CampaignBetaAdformController as vm'
			})
			.state('admin.campaignBeta.settings', {
				url: '/settings',
				templateUrl: 'partials/targeting/beta/campaigns/campaign-settings.html',
				controller: 'CampaignBetaSettingsController as vm'
			})
			.state('admin.campaignBeta.targeting', {
				url: '/targeting',
				templateUrl: 'partials/targeting/beta/campaigns/campaign-targeting.html',
				controller: 'CampaignBetaTargetingController as vm'
			})
			.state('admin.campaignBeta.adCreatives', {
				url: '/adcreatives',
				template: 'adcreatives',
				controller: ''
			})
			.state('admin.campaignBeta.media', {
				url: '/media',
				template: 'media',
				controller: ''
			})
			.state('admin.campaignBeta.properties', {
				url: '/properties',
				templateUrl: 'partials/targeting/beta/campaigns/campaign-properties.html',
				controller: 'CampaignBetaPropertiesController as vm'
			})

			.state('admin.campaignBetaRevenueModels', {
				url: '/accounts/:accountId/campaignrevenuemodels',
				templateUrl: 'partials/targeting/beta/campaigns/campaign-revenuemodels.html',
				controller: 'CampaignRevenueModelsController as vm'
			})

			.state('admin.campaignBetaRevenueModel', {
				url: '/accounts/:accountId/campaignrevenuemodels/:campaignRevenueModelId',
				resolve: {
					campaignRevenueModelId: ['$stateParams', function ($stateParams) {
						return $stateParams.campaignRevenueModelId;
					}]
				},
				templateUrl: 'partials/targeting/beta/campaigns/campaign-revenuemodel.html',
				controller: 'CampaignRevenueModelController as vm'
			})

			// Segments
			.state('admin.segmentFilters', {
				url: '/segmentfilters',
				templateUrl: 'partials/targeting/segments/segmentfilters.html',
				controller: 'SegmentFiltersController as vm'
			})
			.state('admin.segmentFilter', {
				url: '/accounts/:accountId/segmentfilters/:segmentFilterId',
				resolve: {
					segmentFilterId: ['$stateParams', function ($stateParams) {
						return $stateParams.segmentFilterId;
					}],
					accountId: ['$stateParams', function ($stateParams) {
						return $stateParams.accountId;
					}]
				},
				templateUrl: 'partials/targeting/segments/segmentfilter.html',
				controller: 'SegmentFilterController as vm'
			})


			// Segments beta
			// Segments beta
			// Segments beta


			/////////////////////////
			/////////////////////////
			// ADMIN
			// Accounts
			.state('admin.database', {
				url: '/database',
				templateUrl: 'partials/admin/database/database.html',
				controller: 'AdminDatabaseController as vm'
			})
			.state('admin.accounts', {
				url: '/accounts',
				templateUrl: 'partials/admin/accounts/accounts.html',
				controller: 'AccountsController as vm'
			})
			.state('admin.account', {
				// user contains accountid and we are not creating user for loggedinaccount!!!
				url: '/accounts/:accountId',
				resolve: {
					accountId: ['$stateParams', function ($stateParams) {
						return $stateParams.accountId;
					}]
				},
				templateUrl: 'partials/admin/accounts/account.html',
				controller: 'AccountController as vm'
			})
			// ResellerAccounts
			.state('admin.resellerAccounts', {
				url: '/resellers',
				templateUrl: 'partials/admin/reselleraccounts/reselleraccounts.html',
				controller: 'ResellerAccountsController as vm'
			})

			.state('admin.analytics', {
				url: '/analytics',
				templateUrl: 'partials/admin/analytics/analytics-dashboard.html',
				controller: 'AnalyticsController as vm'
			})

			.state('admin.resellerAccount', {
				url: '/resellers/:resellerId',
				resolve: {
					resellerId: ['$stateParams', function ($stateParams) {
						return $stateParams.resellerId;
					}]
				},
				templateUrl: 'partials/admin/reselleraccounts/reselleraccount.html',
				controller: 'ResellerAccountController as vm'
			})
			.state('admin.resellerAccountSwitch', {
				url: '/switchreseller',
				templateUrl: 'partials/admin/reselleraccounts/reselleracountSwitch.html',
				controller: 'ResellerAccountSwitchController as vm'
			})
			.state('admin.sites', {
				url: '/sites',
				templateUrl: 'partials/admin/sites/sites.html',
				controller: 'SitesController as vm'
			})
			.state('admin.site', {
				url: '/accounts/:selectedAccountId/sites/:siteId',
				resolve: {
					siteId: ['$stateParams', function ($stateParams) {
						return $stateParams.siteId;
					}],
					selectedAccountId: ['$stateParams', function ($stateParams) {
						return $stateParams.selectedAccountId;
					}]
				},
				templateUrl: 'partials/admin/sites/site.html',
				controller: 'SiteController as vm'
			})
			// Segments
			.state('admin.segmentGroups', {
				url: '/segmentGroups',
				templateUrl: 'partials/admin/segments/admin-segments.html',
				controller: 'AdminSegmentController as vm'
			})
			// Org stats
			.state('admin.adminOrganisationStatistics', {
				url: '/dmorganisation/statistics',
				templateUrl: 'partials/admin/organisations/statistics/organisationStatistics.html',
				controller: 'OrganisationStatisticsController as vm'
			})


			.state('admin.product', {
				url: '/accounts/:selectedAccountId/products/:productId',
				resolve: {
					productId: ['$stateParams', function ($stateParams) {
						return $stateParams.productId;
					}],
					selectedAccountId: ['$stateParams', function ($stateParams) {
						return $stateParams.selectedAccountId;
					}]
				},
				templateUrl: 'partials/admin/products/product.html',
				controller: 'ProductController as vm'
			})
			.state('admin.adminToken', {
				url: '/accounts/:selectedAccountId/tokens/:tokenId',
				resolve: {
					tokenId: ['$stateParams', function ($stateParams) {
						return $stateParams.tokenId;
					}],
					selectedAccountId: ['$stateParams', function ($stateParams) {
						return $stateParams.selectedAccountId;
					}]
				},
				templateUrl: 'partials/admin/tokens/token.html',
				controller: 'AdminTokenController as vm'
			})



			///// Monitor

			.state('admin.dailyTrafficForSiteDuringPeriod', {
				url: '/dailyTrafficForSiteDuringPeriod/:siteId',
				resolve: {
					siteId: ['$stateParams', function ($stateParams) {
						return $stateParams.siteId;
					}]
				},
				templateUrl: 'partials/monitor/tracking/dailyTrafficForSiteDuringPeriod.html',
				controller: 'DailyTrafficForSiteDuringPeriodController as vm'
			})

			.state('admin.totalTrafficGroupedPerAccount', {
				url: '/totalTrafficGroupedPerAccount',
				templateUrl: 'partials/monitor/tracking/totalTrafficGroupedPerAccount.html',
				controller: 'TotalTrafficGroupedPerAccountController as vm'
			})


			.state('error', {
				templateUrl: 'partials/error.html'
			})

			.state('404', {
				url: '/404',
				templateUrl: 'partials/404.html'
			});

		$urlRouterProvider.otherwise('/404');

	}

	function admin() {
		return {
			abstract: true,
			url: '',
			templateUrl: 'partials/app.html',
			resolve: {
				// all states need to be authenticated
				authenticated: ['AuthService', function (AuthService) {
					return AuthService.isAuthenticated();
				}],
				account: ['authenticated', 'CommonService', function (authenticated, CommonService) {
					return CommonService.getAccount();
				}],
				userAccounts: ['authenticated', 'CommonService', function (authenticated, CommonService) {
					return CommonService.getUserAccounts();
				}],
				// for showing in the drop down
				currentResellerAccount: ['authenticated', 'CommonService', function (authenticated, CommonService) {
					return CommonService.getResellerAccountForCurrentUser();
				}],
				resellerAccounts: ['authenticated', 'CommonService', function (authenticated, CommonService) {
					return CommonService.getActiveAccountsForCurrentReseller();
				}],
				user: ['authenticated', 'CommonService', function (authenticated, CommonService) {
					return CommonService.getUser();
				}]
			}
		};
	}

	function login() {
		return {
			url: '/login?message',
			templateUrl: 'partials/common/login.html',
			resolve: {
				// all states need to be authenticated
				message: ['$stateParams', function ($stateParams) {
					return $stateParams.message;
				}]
			},
			controller: 'LoginController as vm'
		};

	}

	function resetPassword() {
		return {
			url: '/reset?rid&uid&dt',
			templateUrl: 'partials/common/resetpassword.html',
			resolve: {
				rid: ['$stateParams', function ($stateParams) {
					return $stateParams.rid;
				}],
				uid: ['$stateParams', function ($stateParams) {
					return $stateParams.uid;
				}],
				dt: ['$stateParams', function ($stateParams) {
					return $stateParams.dt;
				}]
			},
			controller: 'ResetPwdController as vm'
		};
	}

	function dashboard() {
		return {
			url: '/dashboard?startDate&endDate&filterId&limit',
			templateUrl: 'partials/dashboard/dashboard.html',
			controller: 'DashboardController as vm',
			resolve: {
				startDate: ['$stateParams', function ($stateParams) {
					return $stateParams.startDate;
				}],
				endDate: ['$stateParams', function ($stateParams) {
					return $stateParams.endDate;
				}],
				filterId: ['$stateParams', function ($stateParams) {
					return $stateParams.filterId;
				}],
				limit: ['$stateParams', function ($stateParams) {
					return $stateParams.limit;
				}]
			}
		};
	}

	function visits() {
		return {
			url: '/accounts/:accountId/visits?startDate&endDate&filterId&visitorId&visitCookieId&limit',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.account;
				}],
				startDate: ['$stateParams', function ($stateParams) {
					return $stateParams.startDate;
				}],
				endDate: ['$stateParams', function ($stateParams) {
					return $stateParams.endDate;
				}],
				filterId: ['$stateParams', function ($stateParams) {
					return $stateParams.filterId;
				}],
				visitorId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitorId;
				}],
				visitCookieId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitCookieId;
				}],
				limit: ['$stateParams', function ($stateParams) {
					return $stateParams.limit;
				}]
			},
			templateUrl: 'partials/discover/visits/visits.html',
			controller: 'VisitsController as vm'
		};
	}

	function visit() {
		return {
			url: '/accounts/:accountId/visits/:visitId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				visitId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitId;
				}]
			},
			templateUrl: 'partials/discover/visits/visit.html',
			controller: 'VisitController as vm'
		};
	}

	function organisation() {
		return {
			url: '/organisations/:organisationId',
			resolve: {
				organisationId: ['$stateParams', function ($stateParams) {
					return $stateParams.organisationId;
				}]
			},
			templateUrl: 'partials/common/organisations/organisation.html',
			controller: 'OrganisationController as vm'
		};
	}

	function visitFilters() {
		return {
			url: '/visitfilters',
			templateUrl: 'partials/discover/visitfilters/visitfilters.html',
			controller: 'VisitFiltersController as vm'
		};
	}

	function visitFilter() {
		return {
			url: '/accounts/:accountId/visitfilters/:visitFilterId?showFilterVisits&startDate&endDate&leIds',
			resolve: {
				visitFilterId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitFilterId;
				}],
				startDate: ['$stateParams', function ($stateParams) {
					return $stateParams.startDate;
				}],
				endDate: ['$stateParams', function ($stateParams) {
					return $stateParams.endDate;
				}],
				leIds: ['$stateParams', function ($stateParams) {
					return $stateParams.leIds;
				}]
			},
			templateUrl: 'partials/discover/visitfilters/visitfilter.html',
			controller: 'VisitFilterController as vm'
		};
	}



	// Visits advanced filtering
	function trackingVisitsAdvancedFilters() {
		return {
			url: '/visitadvancedfilters',
			templateUrl: 'partials/discover/visitfilters/advanced/visitfilters.html',
			controller: 'VisitAdvancedFiltersController as vm'
		};
	}

	function trackingVisitsAdvancedFilter() {
		return {
			url: '/accounts/:accountId/visitadvancedfilters/:visitFilterId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				visitFilterId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitFilterId;
				}]
			},
			templateUrl: 'partials/discover/visitfilters/advanced/visitfilter.html',
			controller: 'VisitAdvancedFilterController as vm',
			redirectTo: 'admin.visitAdvancedFilter.search'
		};
	}

	function trackingVisitsAdvancedFilterSearch() {
		return {
			url: '/search',
			templateUrl: 'partials/discover/visitfilters/advanced/visitfilter-search.html',
			controller: 'VisitAdvancedFilterSearchController as vm'
		};
	}

	function trackingVisitAdvancedFilterList() {
		return {
			url: '/list',
			templateUrl: 'partials/discover/visitfilters/advanced/visitfilter-visits-list.html',
			controller: 'VisitAdvancedFilterSegmentListController as vm'
		};
	}

	function trackingVisitAdvancedFilterSettings() {
		return {
			url: '/settings',
			templateUrl: 'partials/discover/visitfilters/advanced/visitfilter-settings.html',
			controller: 'VisitAdvancedFilterSettingsController as vm'
		}
	}



	function visitCampaigns() {
		return {
			url: '/accounts/:accountId/visitors?startDate&endDate&filterId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.account;
				}],
				startDate: ['$stateParams', function ($stateParams) {
					return $stateParams.startDate;
				}],
				endDate: ['$stateParams', function ($stateParams) {
					return $stateParams.endDate;
				}]
			},
			templateUrl: 'partials/discover/visitors/visitors.html',
			controller: 'VisitorsController as vm'
		};
	}

	function visitCampaign() {
		return {
			url: '/accounts/:accountId/visitors/:visitorId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				visitId: ['$stateParams', function ($stateParams) {
					return $stateParams.visitorId;
				}]
			},
			templateUrl: 'partials/discover/visitor/visitor.html',
			controller: 'VisitorController as vm'
		};
	}

	function visitReports() {
		return {
			url: '/reports',
			templateUrl: 'partials/discover/reports/reports.html',
			controller: 'ReportsController as vm'
		};
	}

	function visitReport() {
		return {
			url: '/accounts/:accountId/reports/:reportId',
			resolve: {
				reportId: ['$stateParams', function ($stateParams) {
					return $stateParams.reportId;
				}]
			},
			templateUrl: 'partials/discover/reports/report.html',
			controller: 'ReportController as vm'
		};
	}

	function visitReportHistory() {
		return {
			url: '/accounts/:accountId/report/:reportId/history',
			resolve: {
				reportId: ['$stateParams', function ($stateParams) {
					return $stateParams.reportId;
				}]
			},
			templateUrl: 'partials/discover/reports/reporthistory.html',
			controller: 'ReportHistoryController as vm'
		};
	}

	function clickCampaignTrackingLink() {
		return {
			url: '/accounts/:accountId/clickcampaigns/:clickCampaignId/trackinglinks/:trackingLinkId',
			resolve: {
				clickCampaignId: ['$stateParams', function ($stateParams) {
					return $stateParams.clickCampaignId;
				}],
				trackingLinkId: ['$stateParams', function ($stateParams) {
					return $stateParams.trackingLinkId;
				}]
			},
			templateUrl: 'partials/discover/clicks/trackinglink.html',
			controller: 'TrackingLinkController as vm'
		};
	}

	function clickCampaigns() {
		return {
			url: '/clickcampaigns',
			templateUrl: 'partials/discover/clicks/clickcampaigns.html',
			controller: 'ClickCampaignsController as vm'
		};

	}

	function clickCampaign() {
		return {
			url: '/accounts/:accountId/clickcampaigns/:clickCampaignId',
			resolve: {
				clickCampaignId: ['$stateParams', function ($stateParams) {
					return $stateParams.clickCampaignId;
				}]
			},
			templateUrl: 'partials/discover/clicks/clickcampaign.html',
			controller: 'ClickCampaignController as vm'
		};

	}

	function visitPage() {
		return {
			url: '/pages/:pageId',
			resolve: {
				pageId: ['$stateParams', function ($stateParams) {
					return $stateParams.pageId;
				}]
			},
			templateUrl: 'partials/discover/pages/page.html',
			controller: 'PageController as vm'
		};
	}

	function visitPages() {
		return {
			url: '/pages?:pageQueryId',
			resolve: {
				pageQueryId: ['$stateParams', function ($stateParams) {
					return $stateParams.pageQueryId;
				}]
			},
			templateUrl: 'partials/discover/pages/pages.html',
			controller: 'PagesController as vm'
		};
	}

	function visitPageKeywords() {
		return {
			url: '/pagekeywords',
			templateUrl: 'partials/discover/pages/pagekeywords.html',
			controller: 'PageKeywordsController as vm'
		};
	}

	function visitPageKeyword() {
		return {
			url: '/pagekeyword/:pageKeywordId',
			resolve: {
				pageKeywordId: ['$stateParams', function ($stateParams) {
					return $stateParams.pageKeywordId;
				}]
			},
			templateUrl: 'partials/discover/pages/pagekeyword.html',
			controller: 'PageKeywordController as vm'
		};
	}

	function visitPageQueries() {
		return {
			url: '/pagequeries',
			templateUrl: 'partials/discover/pages/pagequeries.html',
			controller: 'PageQueriesController as vm'
		};
	}

	function visitPageQuery() {
		return {
			url: '/pagequery/:pageQueryId',
			resolve: {
				pageQueryId: ['$stateParams', function ($stateParams) {
					return $stateParams.pageQueryId;
				}]
			},
			templateUrl: 'partials/discover/pages/pagequery.html',
			controller: 'PageQueryController as vm'
		};
	}

	function visitExcludeOrganisations() {
		return {
			url: '/excludedorganisations',
			templateUrl: 'partials/discover/excludedorganisations/excludedorganisations.html',
			controller: 'ExcludedOrganisationsController as vm'
		};
	}

	function visitExcludedOrganisation() {
		return {
			url: '/excludedorganisation/:excludedOrganisationId',
			resolve: {
				excludedOrganisationId: ['$stateParams', function ($stateParams) {
					return $stateParams.excludedOrganisationId;
				}]
			},
			templateUrl: 'partials/discover/excludedorganisations/excludedorganisation.html',
			controller: 'ExcludedOrganisationController as vm'
		};
	}

	function visitCustomVariables() {
		return {
			url: '/customvariables',
			templateUrl: 'partials/discover/customvariables/customvariables.html',
			controller: 'CustomVariablesController as vm'
		};
	}

	function visitCookies() {
		return {
			url: '/visittags',
			templateUrl: 'partials/discover/visitcookietag/visitcookies.html',
			controller: 'VisitCookiesController as vm'
		};
	}

	function connectTokens() {
		return {
			url: '/tokens',
			templateUrl: 'partials/connect/tokens.html',
			controller: 'TokensController as vm'
		};
	}

	function connectTokenStatistics() {
		return {
			url: '/tokens/:tokenId/statistics',
			resolve: {
				tokenId: ['$stateParams', function ($stateParams) {
					return $stateParams.tokenId;
				}]
			},
			templateUrl: 'partials/connect/tokenStatistics.html',
			controller: 'TokenStatisticsController as vm'
		};
	}

	/// Targeting
	///// Adaptive Content
	function targetingAdaptiveContents() {
		return {
			url: '/adaptivecontent',
			templateUrl: 'partials/targeting/adaptivecontent/adaptivecontents.html',
			controller: 'AdaptiveContentsController as vm'
		};
	}

	function targetingAdaptiveContent() {
		return {
			url: '/accounts/:accountId/adaptivecontent/:targetingId',
			resolve: {
				targetingId: ['$stateParams', function ($stateParams) {
					return $stateParams.targetingId;
				}]

			},
			templateUrl: 'partials/targeting/adaptivecontent/adaptivecontent.html',
			controller: 'AdaptiveContentController as vm'
		};
	}

	function targetingAdaptiveContentStatistics() {
		return {
			url: '/adaptivecontent/:accountId/statistics/:targetingId',
			resolve: {
				targetingId: ['$stateParams', function ($stateParams) {
					return $stateParams.targetingId;
				}]
			},
			templateUrl: 'partials/targeting/adaptivecontent/adaptivecontentstatistics.html',
			controller: 'AdaptiveContentStatisticsController as vm'
		};
	}

	// Targeting advnaced
	function targetingAudiences() {
		return {
			url: '/audiences',
			templateUrl: 'partials/targeting/audiences/audiences.html',
			controller: 'AudiencesController as vm'
		};
	}

	function targetingAudience() {
		return {
			url: '/accounts/:accountId/audiences/:audienceId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				audienceId: ['$stateParams', function ($stateParams) {
					return $stateParams.audienceId;
				}]
			},
			templateUrl: 'partials/targeting/audiences/audience.html',
			controller: 'AudienceController  as vm'
		};
	}


	// Targeting advnaced
	function targetingSegmentsAdvancedFilters() {
		return {
			url: '/segmentadvancedfilters',
			templateUrl: 'partials/targeting/segments/advanced/segmentfilters.html',
			controller: 'SegmentAdvancedFiltersController as vm'
		};
	}


	function targetingSegmentsAdvancedFilter() {
		return {
			url: '/accounts/:accountId/segmentadvancedfilters/:segmentFilterId',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				segmentFilterId: ['$stateParams', function ($stateParams) {
					return $stateParams.segmentFilterId;
				}]
			},
			templateUrl: 'partials/targeting/segments/advanced/segmentfilter.html',
			controller: 'SegmentAdvancedFilterController as vm',
			redirectTo: 'admin.segmentAdvancedFilter.search'
			//redirectTo:  STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_SEARCH
		};
	}

	function targetingSegmentsAdvancedFilterShowOrganisations() {
		return {
			url: '/accounts/:accountId/segmentadvancedfilters/:segmentFilterId/organisations',
			resolve: {
				accountId: ['$stateParams', function ($stateParams) {
					return $stateParams.accountId;
				}],
				segmentFilterId: ['$stateParams', function ($stateParams) {
					return $stateParams.segmentFilterId;
				}]
			},
			templateUrl: 'partials/targeting/segments/advanced/segmentfilters-show-organisations.html',
			controller: 'SegmentAdvancedFilterShowOrganisationsController as vm',
			//redirectTo: 'admin.segmentAdvancedFilter.search'
			//redirectTo:  STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_SEARCH
		};
	}

	function targetingSegmentsAdvancedFilterSearch() {
		return {
			url: '/search',
			templateUrl: 'partials/targeting/segments/advanced/segmentfilter-search.html',
			controller: 'SegmentAdvancedFilterSearchController as vm'
		};
	}

	function targetingSegmentsAdvancedFilterList() {
		return {
			url: '/list',
			templateUrl: 'partials/targeting/segments/advanced/segmentfilter-segment-list.html',
			controller: 'SegmentAdvancedFilterSegmentListController as vm'
		};
	}

	function targetingSegmentsAdvancedFilterSettings() {
		return {
			url: '/settings',
			templateUrl: 'partials/targeting/segments/advanced/segmentfilter-settings.html',
			controller: 'SegmentAdvancedFilterSettingsController as vm'
		}
	}


	function targetingSegmentsAdvancedFilterStatistics() {
		return {
			url: '/statistics',
			templateUrl: 'partials/targeting/segments/advanced/segmentfilter-statistics.html',
			controller: 'SegmentAdvancedFilterStatisticsController as vm'
		}
	}


	////

	function adminUsers() {
		return {
			url: '/users',
			templateUrl: 'partials/admin/users/users.html',
			controller: 'UsersController as vm'
		};
	}

	function adminUserAuditLog() {
		return {
			url: '/userauditlog',
			templateUrl: 'partials/admin/users/userauditlog.html',
			controller: 'UserAuditLogController as vm'
		};
	}

	function adminUserStatisticsLog() {
		return {
			url: '/userstatisticslog',
			templateUrl: 'partials/admin/users/userstatisticslog.html',
			controller: 'UserStatisticsLogController as vm'
		};
	}

	function adminLists() {
		return {
			url: '/mc/lists',
			templateUrl: 'partials/admin/mailchimp/mc-lists.html',
			controller: 'AdminMailChimpListsController as vm'
		};
	}

	function adminListMembers() {
		return {
			url: '/mc/list/:listId/members',
			templateUrl: 'partials/admin/mailchimp/mc-list-members.html',
			controller: 'AdminMailChimpListMembersController as vm'
		};
	}

	//
	// BT
	function adminBTClients() {
		return {
			url: '/bt/clients',
			templateUrl: 'partials/admin/bidtheatre/btclients.html',
			controller: 'AdminBTClientsController as vm'
		};
	}

	function adminBTAdvertisers() {
		return {
			url: '/bt/clients/:clientId/advertisers',
			resolve: {
				clientId: ['$stateParams', function ($stateParams) {
					return $stateParams.clientId;
				}]
			},
			templateUrl: 'partials/admin/bidtheatre/btadvertisers.html',
			controller: 'AdminBTAdvertisersController as vm'
		};
	}

	function adminBTAdvertiserLineItems() {
		return {
			url: '/bt/clients/:clientId/advertisers/:advertiserId/lineitems',
			resolve: {
				clientId: ['$stateParams', function ($stateParams) {
					return $stateParams.clientId;
				}],
				advertiserId: ['$stateParams', function ($stateParams) {
					return $stateParams.advertiserId;
				}]
			},
			templateUrl: 'partials/admin/bidtheatre/btadvertiser-lineitems.html',
			controller: 'AdminBTAdvertiserLineItmessController as vm'
		};
	}

	function adminBTAds() {
		return {
			url: '/bt/clients/:clientId/advertisers/:advertiserId/campaigns/:campaignId/ads',
			resolve: {
				clientId: ['$stateParams', function ($stateParams) {
					return $stateParams.clientId;
				}],
				advertiserId: ['$stateParams', function ($stateParams) {
					return $stateParams.advertiserId;
				}],
				campaignId: ['$stateParams', function ($stateParams) {
					return $stateParams.campaignId;
				}]
			},
			templateUrl: 'partials/admin/bidtheatre/bt.ads.html',
			controller: 'AdminBTAdsController as vm'
		};
	}

	function adminBTCampaigns() {
		return {
			url: '/bt/clients/:clientId/advertisers/:advertiserId/campaigns',
			resolve: {
				clientId: ['$stateParams', function ($stateParams) {
					return $stateParams.clientId;
				}],
				advertiserId: ['$stateParams', function ($stateParams) {
					return $stateParams.advertiserId;
				}]
			},
			templateUrl: 'partials/admin/bidtheatre/btcampaigns.html',
			controller: 'AdminBTCampaignsController as vm'
		};
	}

	//


	function adminUser() {
		return {
			url: '/accounts/:selectedAccountId/users/:userId',
			resolve: {
				userId: ['$stateParams', function ($stateParams) {
					return $stateParams.userId;
				}],
				selectedAccountId: ['$stateParams', function ($stateParams) {
					return $stateParams.selectedAccountId;
				}]
			},
			templateUrl: 'partials/admin/users/user.html',
			controller: 'UserController as vm'
		};
	}


	function adminTokens() {
		return {
			url: '/admin/tokens',
			templateUrl: 'partials/admin/tokens/tokens.html',
			controller: 'AdminTokensController as vm'
		};
	}


	function adminLogging() {
		return {
			url: '/admin/logging',
			templateUrl: 'partials/admin/logging/logging.html',
			controller: 'AdminLogsController as vm'
		};
	}


	// enhance
	// Clearbit Organisations
	function enhanceClearbitOrganisations() {
		return {
			url: '/enhance/organisations/clearbit?:query',
			resolve: {
				query: ['$stateParams', function ($stateParams) {
					return $stateParams.query;
				}]
			},
			templateUrl: 'partials/enhance/clearbit/clearbitOrganisations.html',
			controller: 'ClearbitOrganisationsController as vm'
		};
	}

	function enhanceClearbitOrganisation() {
		return {
			url: '/enhance/organisations/clearbit/:cbid',
			resolve: {
				cbid: ['$stateParams', function ($stateParams) {
					return $stateParams.cbid;
				}]
			},
			templateUrl: 'partials/enhance/clearbit/clearbitOrganisation.html',
			controller: 'ClearbitOrganisationController as vm'
		};
	}

	function enhanceDOBOrganisations() {
		return {
			url: '/enhance/organisations/dob',
			templateUrl: 'partials/enhance/dob/dobOrganisations.html',
			controller: 'DOBOrganisationsController as vm'
		};
	}

	/*
    function enhanceDOBOrganisation(){
        return {
            url: '/enhance/organisations/dob/:leid',
            resolve: {
                cbid: ['$stateParams', function ($stateParams) {
                    return $stateParams.leid;
                }]
            },
            templateUrl: 'partials/enhance/clearbit/clearbitOrganisation.html',
            controller: 'ClearbitOrganisationController as vm'
        };
    }*/

	function enhanceNBKORganisations() {
		return {
			url: '/enhance/organisations/nbk',
			templateUrl: 'partials/enhance/nbk/nbkOrganisations.html',
			controller: 'NBKOrganisationController as vm'
		};

	}

	function enhanceRIPEORganisations() {
		return {
			url: '/enhance/organisations/ripe?:query',
			resolve: {
				query: ['$stateParams', function ($stateParams) {
					return $stateParams.query;
				}]
			},
			templateUrl: 'partials/enhance/ripe/ripeOrganisations.html',
			controller: 'RIPEOrganisationController as vm'
		};
	}

	function enhanceLEOrganisations() {
		return {
			url: '/enhance/organisations/le?:query',
			resolve: {
				query: ['$stateParams', function ($stateParams) {
					return $stateParams.query;
				}],
				segmentId: ['$stateParams', function ($stateParams) {
					return $stateParams.segmentId;
				}]

			},
			templateUrl: 'partials/enhance/leadenhancer/leOrganisations.html',
			controller: 'LEOrganisationsController as vm'
		};
	}

	function enhanceLEOrganisation() {
		return {
			url: '/enhance/organisations/le/:organisationId',
			resolve: {
				organisationId: ['$stateParams', function ($stateParams) {
					return $stateParams.organisationId;
				}]
			},
			templateUrl: 'partials/enhance/leadenhancer/leOrganisation.html',
			controller: 'LEOrganisationController as vm'
		};
	}

	function enhancementQueue() {
		return {
			url: '/enhance/queue?:type',
			resolve: {
				type: ['$stateParams', function ($stateParams) {
					return $stateParams.type;
				}]
			},
			templateUrl: 'partials/enhance/enhancementQueue.html',
			controller: 'EnhancementQueueController as vm'
		};
	}

	function enhancementQueueOrganisationsISPs() {
		return {
			url: '/enhance/organisations/isps',
			templateUrl: 'partials/enhance/enhancementOrganisationISPQueue.html',
			controller: 'EnhancementOrganisationsISPsController as vm'
		};
	}

	function enhancementOrganisation() {
		return {
			url: '/enhance/:enhancedOrganisationId',
			resolve: {
				enhancedOrganisationId: ['$stateParams', function ($stateParams) {
					return $stateParams.enhancedOrganisationId;
				}]
			},
			templateUrl: 'partials/enhance/enhancedOrganisation.html',
			controller: 'EnhancedOrganisationController as vm'
		};
	}


	function enhancementLEORganisationsBatchUpload() {
		return {
			url: '/enhance/organisations/batchupload',
			templateUrl: 'partials/enhance/leadenhancer/leOrganisationsBatchUpdate.html',
			controller: 'LEOrganisationsBatchUploadController as vm'
		};
	}

	function enhancementStatistics() {
		return {
			url: '/enhance/statistics/:type',
			resolve: {
				type: ['$stateParams', function ($stateParams) {
					return $stateParams.type;
				}]
			},
			templateUrl: 'partials/enhance/enhancementStatistics.html',
			controller: 'EnhancementStatisticsController as vm'
		};
	}

	function enhancementStatisticsByUserName() {
		return {
			url: '/enhance/statistics/username/:username',
			templateUrl: 'partials/enhance/enhancementStatisticsByUserName.html',
			controller: 'EnhancementStatisticsByUserNameController as vm'
		};
	}


	function enhancementIndustryCodesSIC() {
		return {
			url: '/enhance/industrycodes/sic',
			templateUrl: 'partials/enhance/industrycodes/sicCodes.html',
			controller: 'IndustryCodesSICController as vm'
		};
	}

	function enhancementIndustryCodesNACE() {
		return {
			url: '/enhance/industrycodes/sni',
			templateUrl: 'partials/enhance/industrycodes/sniCodes.html',
			controller: 'IndustryCodesSNIController as vm'
		};
	}

	function enhancementIndustryCodesNAceToSIC() {
		return {
			url: '/enhance/industrycodes/nacetosic',
			templateUrl: 'partials/enhance/industrycodes/naceToSicCodes.html',
			controller: 'IndustryCodesNaceToSICController as vm'
		};
	}

	function enhancementIndustryCodesNAICS() {
		return {
			url: '/enhance/industrycodes/naics',
			templateUrl: 'partials/enhance/industrycodes/naicsCodes.html',
			controller: 'IndustryCodesNAICSController as vm'
		};
	}

	function enhancementIndustryCodesGICS() {
		return {
			url: '/enhance/industrycodes/gics',
			templateUrl: 'partials/enhance/industrycodes/gicsCodes.html',
			controller: 'IndustryCodesGICSController as vm'
		};
	}

	function enhancementIndustryCodesIndustryTags() {
		return {
			url: '/enhance/industrycodes/industrytags',
			templateUrl: 'partials/enhance/industrycodes/industryTags.html',
			controller: 'IndustryTagController as vm'
		};
	}

	function enhancementQueueDomain() {
		return {
			url: '/enhancements/domain',
			templateUrl: 'partials/enhance/domain/domainEnhancement.html',
			controller: 'DomainEnhanceController as vm'
		};
	}

	function enhancementQueueCBIT() {
		return {
			url: '/enhancements/domaincbit',
			templateUrl: 'partials/enhance/clearbit/queue/domainEnhancementCBIT.html',
			controller: 'DomainEnhanceCBITController as vm'
		};
	}


	function enhancementQueueNBK() {
		return {
			url: '/enhancements/queue/nbk',
			templateUrl: 'partials/enhance/nbk/queue/queueNBKEnhancement.html',
			controller: 'QueueNBKEnhanceController as vm'
		};
	}



	angular
		.module('adminApp')
		.run(['$state', '$rootScope', stateChangeErrorHandler]);

	// not shure about this....
	function stateChangeErrorHandler($state, $rootScope) {
		$rootScope.$on('$stateChangeError', console.log.bind(console));
		//$rootScope.$on('$stateChangeError', function (evt, toState, toParams, fromState, fromParams, error) {
		$rootScope.$on('$stateChangeError', function () {
			$state.go('error');

		});

		/**$rootScope.$on('$stateChangeSuccess', function (event, toState) {
			console.log('push data to GA', $location.path())
			window.dataLayer.push({
				event: 'pageView',
				action: $location.path()
			});
		});*/
	}



})();
