(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('NBKOrganisationController', ['NBKOrganisationService', 'EnhancerSettingsService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort','logger',
            NBKOrganisationController]);
    function NBKOrganisationController(NBKOrganisationService, EnhancerSettingsService, FileSaver, Blob, CONFIG,  TableSort, logger) {
        var vm = this;
        vm.nbkOrganisations = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = null;
        vm.queryForOrgId = null;
        vm.queryForCountryISO = {'countryISO':'SE','name': 'Sweden', 'countryISOLowerCase':'se'};
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['name'],
            reverse: false
        });
        vm.refCountries = [
             {'countryISO':'SE','name':'Sweden','countryISOLowerCase':'se'},
             {'countryISO':'FI','name':'Finland','countryISOLowerCase':'fi'},
             {'countryISO':'NO','name':'Norway','countryISOLowerCase':'no'},
             {'countryISO':'DK','name':'Denmark','countryISOLowerCase':'dk'}
        ];
        vm.enhancerSettings = null;

        //methods
        vm.loadMore = loadMore;
        vm.onNBKSearch = onNBKSearch;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.getCountriesForSelectBox = getCountriesForSelectBox;
        vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
        vm.saveEnhancerSettings = saveEnhancerSettings;

        activate();

        function activate() {
            getOrCreateEnhancerSettings();
        }

        function onNBKSearch() {
        	NBKOrganisationService.searchNBK(vm.queryFor, vm.queryForCountryISO.countryISO, vm.queryForOrgId, vm.queryNBKForDUNS).then( onFinished );
        }


        function onFinished(orgs){
            vm.nbkOrganisations = orgs;
            logger.info('Found ' + vm.nbkOrganisations.length + ' Organisations');

        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'orgstats_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.nbkOrganisations.length;
        }


        // typeahead callback
        function getCountriesForSelectBox(filterByCountryName) {
            return _.filter(vm.refCountries, function (country) {
                if (country.name && filterByCountryName) {
                    return country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1;
                }
            });
        }

        // on select in UI
        function onSetCountryForSelectBox(countrySelected) {
            vm.queryForCountryISO = countrySelected;
        }

        function getOrCreateEnhancerSettings(){
            vm.enhancerSettings = EnhancerSettingsService.getOrCreateEnhancerSettings();
        }

        function saveEnhancerSettings() {
            EnhancerSettingsService.saveEnhancerSettings(vm.enhancerSettings);
        }

    }
})();