(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PageQueriesController', ['account', 'PageQueryService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger','$uibModal', '$localStorage', PageQueriesController]);

    function PageQueriesController(account, PageQueryService, FileSaver, Blob, CONFIG, TableSort, logger, $uibModal, $localStorage) {
        var vm = this;
        vm.confirmDelete = confirmDelete;
        vm.getAllPageQueries = getAllPageQueries;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.loadMore = loadMore;
        vm.onTableSort = onTableSort;
        vm.runPageQuery = runPageQuery;

        vm.$storage = $localStorage.$default({showHelp: false});

        vm.account = account;
        vm.pageQueries = null;
        vm.ok = null;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;

        activate();

        function activate() {
            getAllPageQueries();
        }

        function getAllPageQueries() {
            PageQueryService.getAllPageQueries(vm.account.id)
                .then(function (pageQueries) {
                    vm.pageQueries = pageQueries;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'PagesQueries_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function runPageQuery(pageQueryId){
            PageQueryService.publishPageQuery(vm.account.id, pageQueryId)
                .then(function (pageQueries) {
                    vm.pageQueries = pageQueries;
                    logger.info('Page Query has been posted for processing');

                });
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }



        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.pageQueries) {
                return vm.pageQueries.length;
            }
        }

        function deletePageQuery( pageQueryId) {
            PageQueryService.deletePageQuery(vm.account.id, pageQueryId)
                .then(function (pageQueries) {
                    vm.pageQueries = pageQueries;
                    logger.info('Page Query was deleted!!');
                });
        }

        function confirmDelete(item){
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + item.name + ' Page Query?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deletePageQuery(item.id);
            });
        }



    }
})();