(function () {
    'use strict';

    angular
        .module('adminApp')
        .factory('TableSort', [TableSortFactory]);

    function TableSortFactory() {
        return function Sort(tableSortId, tableSortConfig) {
            return tableSortConfig;
        };
    }

})();