(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('scheduleTable', [ScheduleTable]);

    function ScheduleTable() {
        return {
            restrict: 'E',
            templateUrl: 'partials/targeting/campaigns/common/schedule-table.html',
            scope: {
                slots: '='
            },
            link: function (scope) {

                var _days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                var _selection = {
                    state: {
                        isActive: false,
                        value: 0,
                        startHour: 0,
                        startDay: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    },
                    week: 0,
                    holidays: 0,
                    weekends: 0,
                    workdays: 0,
                    day: [0, 0, 0, 0, 0, 0, 0],
                    hour: Array.apply(null, Array(24 * 4)).map(function(){ return 0; })
                };
                scope.currentPreset = '';
                scope.setPreset = setPreset;

                function setPreset(preset) {
                    var i, j;
                    switch (preset) {
                        case 'weekends':
                            _selection.weekends = !_selection.weekends;
                            for (i = 0; i < 7; i++) {
                                for (j = 0; j < 96; j++) {
                                    if (i >= 5 && i < 7 && j >= 32 && j < 72) {
                                        scope.slots[i][j] = true;
                                    } else {
                                        scope.slots[i][j] = false;
                                    }

                                }
                            }
                            scope.currentPreset = 'weekends';
                        break;
                        case 'workdays':
                            _selection.workdays = !_selection.workdays;
                            for (i = 0; i < 7; i++) {
                                for (j = 0; j < 96; j++) {
                                    if (i >= 0 && i < 5 && j >= 32 && j < 72) {
                                        scope.slots[i][j] = true;
                                    } else {
                                        scope.slots[i][j] = false;
                                    }
                                }
                            }
                            scope.currentPreset = 'workdays';
                        break;
                        case 'week':
                            _selection.week = !_selection.week;
                            for (i = 0; i < 7; i++) {
                                for (j = 0; j < 96; j++) {
                                    scope.slots[i][j] = _selection.week;
                                }
                            }
                            scope.currentPreset = '';
                        break;
                        case 'reset':
                            for (i = 0; i < 7; i++) {
                                for (j = 0; j < 96; j++) {
                                    scope.slots[i][j] = 0;
                                }
                            }
                            scope.currentPreset = '';
                        break;
                    }
                }

                function _loop(begin, end, step) {
                    var array = [];

                    for (var i = begin; i <= end; i += step) {
                        array.push(i);
                    }

                    return array;
                }

                function _toggle(what, day, hour, isHover) {
                    var i = 0, j = 0;
                    if (!isHover) {
                        scope.currentPreset = '';
                    }

                    switch (what) {
                        case 'day':
                            _selection.day[day] = !_selection.day[day];

                            for (i = 0; i < 96; i++) {
                                scope.slots[day][i] = _selection.day[day];
                            }
                        break;

                        case 'hour':
                            _selection.hour[hour] = !_selection.hour[hour];

                            for (i = 0; i < 7; i++) {
                                for (j = 0; j < 4; j++) {
                                    scope.slots[i][hour * 4 + j] = _selection.hour[hour];
                                }
                            }
                        break;

                        case 'slot':
                            if (_selection.state.isActive) {
                                _paintSlots(day, hour);
                            }
                        break;
                    }
                }

                function _paintSlots(day, hour) {
                    if (hour < _selection.state.left) {_selection.state.left = hour;}
                    if (hour > _selection.state.right) {_selection.state.right = hour;}
                    if (day < _selection.state.bottom) {_selection.state.bottom = day;}
                    if (day > _selection.state.top) {_selection.state.top = day;}

                    var i = 0, j = 0;
                    for (i = _selection.state.left; i <= _selection.state.right; i++) {
                        for (j = _selection.state.bottom; j <= _selection.state.top; j++) {
                            if (_isValueInInterval(i, _selection.state.startHour, hour) && _isValueInInterval(j, _selection.state.startDay, day)) {
                                scope.slots[j][i] = _selection.state.value;
                            } else {
                                if (_selection.state.value) {
                                    scope.slots[j][i] = !_selection.state.value;
                                } else {
                                    scope.slots[j][i] = _selection.state.value;
                                }
                            }
                        }
                    }

                    if (day < _selection.state.startDay) {
                        _selection.state.bottom = day;
                        _selection.state.top = _selection.state.startDay;
                    } else {
                        _selection.state.bottom = _selection.state.startDay;
                        _selection.state.top = day;
                    }

                    if (hour < _selection.state.startHour) {
                        _selection.state.left = hour;
                        _selection.state.right = _selection.state.startHour;
                    } else {
                        _selection.state.left = _selection.state.startHour;
                        _selection.state.right = hour;
                    }
                }

                function _isValueInInterval(value, a, b) {
                    if (a > b) {
                        if (value <= a && value >= b) {
                            {return true;}
                        } else {return false;}
                    } else {
                        if (value <= b && value >= a) {
                            return true;
                        } else {return false;}
                    }
                }

                function _select(state, day, hour) {
                    _selection.state.isActive = state;

                    if (_selection.state.isActive) {
                        _selection.state.value = !scope.slots[day][hour];
                        _selection.state.startDay = day;
                        _selection.state.startHour = hour;
                        _selection.state.top = day;
                        _selection.state.bottom = day;
                        _selection.state.left = hour;
                        _selection.state.right = hour;
                        _toggle('slot', day, hour);
                    }
                }

                function _init() {
                    scope.loop = _loop;
                    scope.toggle = _toggle;
                    scope.select = _select;

                    scope.days = _days;
                }

                _init();



            }
        };
    }
})();


