(function() {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignBetaPropertiesController', ['$scope', '$window', '$element', '$location', '$timeout', 'CampaignBetaConstants', CampaignPropertiesController]);

    function CampaignPropertiesController($scope, $window, $element, $location, $timeout, CampaignConstants) {
        var vm = this;
        vm.constants = CampaignConstants;
        vm.parent = $scope.vm;

        if (vm.parent.campaign) {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }
        $scope.$on('campaignDataLoaded', function() {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }.bind(this));

        vm.inputLinkType = {
            type: 'string'
        };

        vm.getReceivers = function() {

        };
    }
})();