(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('ClearbitOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', ClearbitOrganisationService]);

    function ClearbitOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getCBITOrganisation = getCBITOrganisation;
        this.search = search;
        this.searchExternal = searchExternal;
        this.findAllWithLimit = findAllWithLimit;
        this.updateFromSelected = updateFromSelected;

        function getCBITOrganisation(cbid, config) {

            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/clearbit/' + cbid, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function search(queryForDomain, countryIso, limit, config) {
        	console.log('' , queryForDomain)
            var queryUrl = null;
            if( queryForDomain ){
                queryUrl = 'q=' + queryForDomain + '&limit=' + limit;
            }
            if( countryIso ){
                queryUrl = 'countryIso=' + countryIso + '&limit=' + limit;
            }
            if(!queryUrl){
                return;
            }
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/clearbit?' + queryUrl, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function searchExternal(queryForDomain, config) {
            var queryUrl = null;
            if( queryForDomain ){
                queryUrl = 'q=' + queryForDomain + '&queryExternalAndStore=true' ;
            }
            if(!queryUrl){
                return;
            }
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/clearbit?' + queryUrl, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllWithLimit(limit, config) {

            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/clearbit?limit=' + limit, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

		function updateFromSelected( selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			console.log('orgs', orgs)

			return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/clearbit/updateFromSelected', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}


    }
})();