(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('UserSettingsService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', UserSettingsService]);

    function UserSettingsService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.updateUserSettings = updateUserSettings;
        vm.updateUserPassword = updateUserPassword;


        function updateUserSettings(accountId, user) {
            var promise;
            if (!(_.isNull(user.id) || user.id === undefined || user.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/users', user)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page '));
            }
            return promise;
        }

        function updateUserPassword(accountId, userPasswordUpdate) {
            var promise;
            if (!(_.isNull(userPasswordUpdate.id) || userPasswordUpdate.id === undefined || userPasswordUpdate.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/users/password', userPasswordUpdate)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page '));
            }
            return promise;
        }


    }
})();