(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('SegmentFilterService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', 'logger', SegmentFilterService]);

    function SegmentFilterService($http, $q, CONFIG, responseExceptionCatcher, logger) {
        var vm = this;
        vm.getSegment = getSegment;
        vm.getSegments = getSegments;
        vm.createOrUpdateSegment = createOrUpdateSegment;
        vm.deleteSegment = deleteSegment;
        vm.inactivate = inactivate;
        vm.findOrganisations = findOrganisations;
        vm.countOrganisations = countOrganisations;
        vm.createSegmentFileWithIPs = createSegmentFileWithIPs;
        vm.createSegmentFileWithOrganisationss = createSegmentFileWithOrganisationss;
        vm.addLeIdsToSegmentFilter = addLeIdsToSegmentFilter;
        vm.createSegmentFileWithIPsUploadToS3AndSendEmail = createSegmentFileWithIPsUploadToS3AndSendEmail;
        vm.getNoOfOrganisationsUsingTransientWithSegmentFilter = getNoOfOrganisationsUsingTransientWithSegmentFilter;
        vm.getOrganisationsUsingTransientWithSegmentFilter = getOrganisationsUsingTransientWithSegmentFilter;
        vm.clone = clone;

        //var cache = $cacheFactory('cacheIdSegmentFilterOrganisations');


        function getSegment(accountId, segmentId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + segmentId, config)
                .then(onGetVisitFilterSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // find segments to add to campaign
        function getSegments(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function createOrUpdateSegment(accountId, segment) {
            var promise;
            var segmentFilterToSave = transformSegmentFilterToJson(segment);
            if (!(_.isNull(segmentFilterToSave.id) || segmentFilterToSave.id === undefined || segmentFilterToSave.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/', segmentFilterToSave)
                    .then(onGetVisitFilterSuccess)
                    .catch(responseExceptionCatcher.catch('Error when saving segment '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/', segmentFilterToSave)
                    .then(onGetVisitFilterSuccess)
                    .catch(responseExceptionCatcher.catch('Error when saving segment '));
            }
            return promise;
        }

        function deleteSegment(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function inactivate(accountId, id) {
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + id + '/inactivate')
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function clone(accountId, id) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + id + '/clone')
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findOrganisations(accountId, segmentId, limit) {

         /*   console.log('cache ' , cache.info());
            if (!angular.isUndefined(cache.get(accountId + segmentId))) {
                console.log('cache hit');
                return cache.get(accountId + segmentId);
            }
*/

            if( limit){
                return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '?limit' + limit)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            } else {
                return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId )
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            }

        }

        function countOrganisations(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '/count')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        // Used when doing searches from SegmentFilter page, can be used with non persistent filter
        function getOrganisationsUsingTransientWithSegmentFilter(accountId, segmentFilterQuery) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters', segmentFilterQuery)
                .then(function (response) {
                    return response.data.data.organisations;
                })
                .catch(responseExceptionCatcher.catch('Error when searching Visits'));
        }

        // Used when filter settings is changed, can be used with non persistent filter
        function getNoOfOrganisationsUsingTransientWithSegmentFilter(accountId, segmentFilterQuery) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/count', segmentFilterQuery)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when searching Visits'));
        }

        function onGetVisitFilterSuccess(response) {
            return transformSegmentFilterFromJson(response.data.data);
        }

        function transformSegmentFilterFromJson(segmentFilter) {
            if (segmentFilter.organisationQueryV2.filterOnCountryISOHolder) {
                segmentFilter.organisationQueryV2.filterOnCountryISO = _.map(
                    segmentFilter.organisationQueryV2.filterOnCountryISOHolder.keyValues, function (keyValue) {
                        keyValue.flagClass = 'flag-' + keyValue.id.toLowerCase();
                        return keyValue;
                    });
            }
            return segmentFilter;
        }

        function transformSegmentFilterToJson(segmentFilterToSave) {
            // Create a deep clone
            return $.extend(true, {}, segmentFilterToSave);
        }

        function createSegmentFileWithIPs(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '/export',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createSegmentFileWithOrganisationss(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '/export/organisations' ,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createSegmentFileWithIPsUploadToS3AndSendEmail(accountId, segmentId, mailTo) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '/export/email?mailTo=' + mailTo ,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        // used from visits controller
        function addLeIdsToSegmentFilter(accountId, segmentId, leIds){
            var ids = { leIds : leIds };
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + segmentId +  '/add', ids)
                .catch(responseExceptionCatcher.catch('Error when saving segment '));
        }

    }
})();