(function () {
    'use strict';

	angular
		.module('adminApp')
		.controller('EnhancementOrganisationsISPsController', [
			'EnhancementOrganisationsISPService',
			'EnhancerSettingsService',
			'CountryService',
			'logger',
			'$state',
			'FileSaver',
			'Blob',
			'CONFIG',
			'TableSort',
			EnhancementOrganisationsISPsController
		]);
	function EnhancementOrganisationsISPsController(
		EnhancementOrganisationsISPService,
		EnhancerSettingsService,
		CountryService,
		logger,
		$state,
		FileSaver,
		Blob,
		CONFIG,
		TableSort
	) {
		var vm = this;

		vm.organisationISPs = [];
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.selectedDate = null;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});
		vm.selectedCountries = null;
		vm.refCountries = [];
		vm.enhancerSettings = null;

		//methods
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.saveEnhancerSettings = saveEnhancerSettings;
		vm.organisationsISPsUpdateLEOrgananisations = organisationsISPsUpdateLEOrgananisations;
		vm.extractISPsFromLEOrganisationsAsync = extractISPsFromLEOrganisationsAsync;
		vm.organisationsISPsUpdateLEOrgananisationsPartial = organisationsISPsUpdateLEOrgananisationsPartial;
		vm.markAsISP = markAsISP;
		vm.deleteItem = deleteItem;

		activate();

		function activate() {
			getOrCreateEnhancerSettings();
			loadOrganisationISPs();
		}

		/// End init

		function loadOrganisationISPs() {
			EnhancementOrganisationsISPService.getOrganisationISPs(
				vm.queryFor,
				vm.enhancerSettings.limit
			).then(function(isps) {
				vm.organisationISPs = isps;
			});
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename: 'organisations_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.organisationISPs.length;
		}

		function getOrCreateEnhancerSettings() {
			vm.enhancerSettings = EnhancerSettingsService.getOrCreateEnhancerSettings();
		}

		function saveEnhancerSettings() {
			EnhancerSettingsService.saveEnhancerSettings(vm.enhancerSettings);
		}

		function organisationsISPsUpdateLEOrgananisations() {
			EnhancementOrganisationsISPService.organisationsISPsUpdateLEOrgananisations();
			logger.info('Request for updating organisations matchin ISPs!!!');
		}

		function extractISPsFromLEOrganisationsAsync() {
			EnhancementOrganisationsISPService.extractISPsFromLEOrganisationsAsync();
			logger.info('Request for extracting ISPs from LE Organisations - time consuming!!!');
		}

		function organisationsISPsUpdateLEOrgananisationsPartial() {
			EnhancementOrganisationsISPService.organisationsISPsUpdateLEOrgananisationsPartial();
			logger.info('Request for processing ISPs in execute state - time consuming!!!');
		}

		function markAsISP(enhancementorganisationsISP) {
			EnhancementOrganisationsISPService.markAsISP(enhancementorganisationsISP);
			logger.info(
				'Request for processing ISPs in execute state as a background job - time consuming!!!'
			);
		}

		function deleteItem(enhancementorganisationsISPId) {
			EnhancementOrganisationsISPService.deleteItem(enhancementorganisationsISPId).then(
				function() {
					logger.success('Deleted ISP from Queue');

					loadOrganisationISPs();
				}
			);
		}
	}
})();