(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdminTokensController', ['AdminTokenService', 'logger', '$state',
            'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort', AdminTokensController]);
    function AdminTokensController(AdminTokenService, logger, $state, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.onSToggleActive = onSToggleActive;

        vm.showOnlyActive = true;
        vm.tokens = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        ////////////
        //  Init
        activate();

        function activate() {
            findAllTokens();
        }

        function findAllTokens() {
            AdminTokenService.findAllTokens(vm.showOnlyActive).then(showTokens);
        }


        function onSToggleActive() {
            vm.showOnlyActive = !vm.showOnlyActive;
            AdminTokenService.findAllTokens(vm.showOnlyActive).then(showTokens);
        }

        function showTokens(tokens) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.tokens = tokens;
        }
        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'tokens_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.tokens.length;
        }

    }
})();