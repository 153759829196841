(function() {
	'use strict';

	angular.module('adminApp').directive('draggable', [draggableDirective]);

	function draggableDirective() {
		var addDragAndDrop = function(scope) {
			var oldList, newList;

			// ace throws out al ot of erros when not defined
			var aceIsHere = false;
			if (typeof ace !== 'undefined'){
				aceIsHere = ace.vars.touch;
			}

			// Use jQuery-UI for sorting of widgets
			$('.widget-container-col')
				.sortable({
					connectWith: '.widget-container-col',
					items: '> .widget-box',
					//handle: ace.vars.touch ? '.widget-header' : false,
					handle: aceIsHere ? '.widget-header' : false,
					cancel: '.fullscreen',
					opacity: 0.5,
					revert: true,
					forceHelperSize: true,
					placeholder: 'widget-placeholder',
					forcePlaceholderSize: true,
					tolerance: 'pointer',
					start: function(event, ui) {
						newList = oldList = ui.item.parent();
						//when an element is moved, it's parent becomes empty with almost zero height.
						//we set a min-height for it to be large enough so that later we can easily drop elements back onto it
						ui.item.parent().css({ 'min-height': ui.item.height() });
					},
					stop: function() {
						// Move element in target container to source container
						var elementToSwap = $('#' + newList.attr('id') + ' > :nth-child(2)');
						if (elementToSwap) {
							$('#' + oldList.attr('id')).append(elementToSwap);
						}

						//save a list of widget containers and widget boxes
						var widgetOrder = {};
						$('.widget-container-col').each(function() {
							var containerId = $(this).attr('id');
							widgetOrder[containerId] = [];

							$(this)
								.find('> .widget-box')
								.each(function() {
									var widgetId = $(this).attr('id');
									widgetOrder[containerId].push(widgetId);
									//now we know each container contains which widgets
								});
						});

						if (scope.onOrderChange) {
							scope.onOrderChange(widgetOrder);
						}
					},
					change: function(event, ui) {
						if (ui.sender) {
							newList = ui.placeholder.parent();
						}
					},
					update: function(event, ui) {
						ui.item.parent({ 'min-height': '' });
					}
				})
				.disableSelection();

			// If order is set, rearrange widgets to match that order
			if (scope.order) {
				var containerList = scope.order;
				if (containerList) {
					for (var containerId in containerList) {
						if (containerList.hasOwnProperty(containerId)) {
							var widgetsInsideContainer = containerList[containerId];
							if (widgetsInsideContainer.length === 0) {
								continue;
							}

							for (var i = 0; i < widgetsInsideContainer.length; i++) {
								var widget = widgetsInsideContainer[i];
								$('#' + widget).appendTo('#' + containerId);
							}
						}
					}
				}
			}
		};

		return {
			restrict: 'A',
			scope: {
				order: '=',
				onOrderChange: '='
			},
			link: function(scope) {
				addDragAndDrop(scope);
			}
		};
	}
})();
