(function() {
	'use strict';

	angular
		.module('adminApp')
		.service('campaignsTableModel', ['CommonService', 'cellRenderers', CampaignsTableModel]);

	function CampaignsTableModel(CommonService, cellRenderers) {
		var filterValue = '';

		function setFilterValue(value) {
			filterValue = value;
		}

		var columnDefs = [
			{
				hide: true,
				headerName: 'Id',
				field: 'id'
			},
			{
				hide: true,
				headerName: 'Account Id',
				field: 'accountId'
			},
			{
				minWidth: 168,
				field: 'name',
				headerName: 'Name',
				cellClass: 'searchable-cell',
				cellRenderer: cellRenderers.PopupCellRenderer,
				headerCheckboxSelectionFilteredOnly: true,
				cellRendererParams: {
					popupGetter: function(params) {
						return [params.data.name];
					},
					filterValue: function() {
						return filterValue;
					},
					valueGetter: function(params) {
						return params.data.name;
					},
					hrefGetter: function(params) {
						return (
							'/#/accounts/' +
							params.data.accountId +
							'/campaignsbeta/' +
							params.data.id
						);
					}
				}
			},
			{
				hide: true,
				headerName: 'type',
				field: 'type'
			},
			{
				minWidth: 108,
				headerName: 'Status',
				valueGetter: function(params) {
					return params.data.status;
				}
			},
			{
				minWidth: 138,
				headerName: 'Budget',
				valueGetter: function(params) {
					return params.data.budget + ' ' + params.data.currencyCode;
				}
			},
			{
				hide: true,
				headerName: 'destinationURL',
				field: 'destinationURL'
			},
			{
				hide: true,
				headerName: 'timezone',
				field: 'timezone'
			},
			{
				minWidth: 138,
				headerName: 'Start',
				valueGetter: function(params) {
					return params.data.start;
				}
			},
			{
				minWidth: 138,
				headerName: 'End',
				valueGetter: function(params) {
					switch (params.data.endType) {
						case 'never':
							return 'never';
						case 'date':
							return params.data.end;
						case 'afterreaching':
							if (params.data.endReaching.attribute === 'budget') {
								return (
									'After reaching ' +
									params.data.endReaching.money +
									' ' +
									params.data.endReaching.currency
								);
							} else {
								return (
									'After reaching ' +
									params.data.endReaching.count +
									' ' +
									params.data.endReaching.attribute
								);
							}
						/* falls through */
						default:
							return '';
					}
				}
			},
			{
				minWidth: 138,
				headerName: 'Delivery Type',
				field: 'deliveryType'
			},
			{
				minWidth: 138,
				headerName: 'Visitor cap',
				valueGetter: function(params) {
					return params.data.visitorCap.status
						? params.data.visitorCap.impressions +
								' impressions per ' +
								params.data.visitorCap.impressionsPer +
								' ' +
								params.data.visitorCap.period
						: 'None';
				}
			},
			{
				minWidth: 138,
				headerName: 'Campaign cap',
				valueGetter: function(params) {
					return params.data.campaignCap.status
						? params.data.campaignCap.impressions +
								' impressions per ' +
								params.data.campaignCap.impressionsPer +
								' ' +
								params.data.campaignCap.period
						: 'None';
				}
			},
			{
				minWidth: 138,
				headerName: 'Segments',
				field: 'accountSegments'
			},
			{
				minWidth: 138,
				headerName: 'Visit filters',
				field: 'visitFilters'
			},
			{
				minWidth: 138,
				headerName: 'Blacklists',
				field: 'blacklists'
			},
			{
				minWidth: 138,
				headerName: 'Places',
				valueGetter: function(params) {
					return _.map(params.data.placesData, 'nameFromSearch');
				}
			},
			{
				minWidth: 138,
				headerName: 'Visits',
				valueGetter: function(params) {
					return (
						params.data.audienceProfile.visits +
						' per ' +
						params.data.audienceProfile.visitsPeriod
					);
				}
			},
			{
				minWidth: 138,
				headerName: 'Devices',
				valueGetter: function(params) {
					return _.filter(_.keys(params.data.audienceProfile.deviceTypes), function(
						value
					) {
						return params.data.audienceProfile.deviceTypes[value];
					});
				}
			},
			{
				minWidth: 168,
				headerName: 'Operating Systems',
				valueGetter: function(params) {
					return _.map(params.data.audienceProfile.operatingSystems, 'name');
				}
			},
			{
				minWidth: 138,
				headerName: 'Browsers',
				valueGetter: function(params) {
					return _.map(params.data.audienceProfile.browsers, 'name');
				}
			},
			{
				minWidth: 138,
				headerName: 'Interests',
				valueGetter: function(params) {
					return _.map(params.data.audienceProfile.interests, 'name');
				}
			}
		];

		return {
			setFilterValue: setFilterValue,
			columnDefs: columnDefs
		};
	}
})();
