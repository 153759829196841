(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('topMenu', TopMenu);

    function TopMenu() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/topmenu.html',
            transclude: {
                'right': '?topMenuRight'
            },
            scope: {
                title: '=',
                backHrefTitle: '=',
                backHref: '='
            }
        };
    }
})();
