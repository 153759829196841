(function() {
	'use strict';

	angular
		.module('adminApp')
		.controller('EnhancementStatisticsByUserNameController', [
			'EnhancementQueueService',
			'EnhancerSettingsService',
			'TopEnhancersBarChart',
			'logger',
			'$q',
			'FileSaver',
			'Blob',
			'CONFIG',
			'TableSort',
			'UserService',
			EnhancementStatisticsByUserNameController
		]);
	function EnhancementStatisticsByUserNameController(
		EnhancementQueueService,
		EnhancerSettingsService,
		TopEnhancersBarChart,
		logger,
		$q,
		FileSaver,
		Blob,
		CONFIG,
		TableSort,
		UserService
	) {
		var vm = this;

		vm.enhancedOrganisationStatistics = [];
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.queryFor = null;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});
		vm.user = null;
		vm.checkOrgUpdates = true;
		vm.monthsBack = 7;

		// charts

		//methods
		vm.loadMore = loadMore;
		vm.getStatisticsForUserName = getStatisticsForUserName;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.searchUsers = searchUsers;

		activate();

		function activate() {

		}

		/// End init

		function getStatisticsForUserName() {

			return EnhancementQueueService.getStatisticsForUserName(vm.user.userName,  vm.checkOrgUpdates, vm.monthsBack).then(function(data){
				vm.enhancedOrganisationStatistics = data;
			})
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename:
					'enhancedOrganisations_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.enhancedOrganisationStatistics.length;
		}

		function searchUsers(user) {
			return UserService.searchAllUsers(user);
		}
	}
})();
