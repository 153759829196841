(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('OrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', OrganisationService]);

    function OrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getOrganisation = getOrganisation;
        vm.getOrganisations = getOrganisations;
        vm.getOrganisationsCount = getOrganisationsCount;

        function getOrganisation(leadenhancerId) {
            return $http.get(CONFIG.API_PREFIX + '/organisations/'  + leadenhancerId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getOrganisations(leadenhancerIds, config) {
            var query = { leIds : leadenhancerIds};
            if( !(_.isNull(leadenhancerIds) || leadenhancerIds  === undefined) ){
                return $http.post(CONFIG.API_PREFIX + '/organisations/query', query, config)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            } else {
                return $q.resolve([]);
            }
        }

        function getOrganisationsCount(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/organisations/filters/' + segmentId + '/count')
                .then(function(response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();