(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('VisitAdvancedFilterController',
			['$scope', '$q', '$state', '$location', '$timeout', 'logger', 'account', 'user', 'accountId',
				'visitFilterId', 'VisitAdvancedFilterService', 'OrganisationService',
				'filterModel', 'VisitAdvancedFilterConstants', 'VisitAdvancedFilterConverter',
				'STATE', '$uibModal', 'FileSaver', VisitAdvancedFilterController]);

	function VisitAdvancedFilterController($scope, $q, $state, $location, $timeout, logger, account, user, accountId,
										   visitFilterId, VisitAdvancedFilterService, OrganisationService,
										   FilterModel, VisitAdvancedFilterConstants, VisitAdvancedFilterConverter,
										   STATE, $uibModal, FileSaver) {

		var vm = this;
		vm.getVisitFilter = getVisitFilter;
		vm.confirmExportIPs = confirmExportIPs;
		vm.confirmExportOrganisations = confirmExportOrganisations;
		vm.importFromLeidsList = importFromLeidsList;
		vm.saveVisitFilter = saveVisitFilter;
		// from modal window
		vm.onImportOrganisationsFromList = onImportOrganisationsFromList;
		vm.onImportOrganisationsFromList = onImportOrganisationsFromList;

		vm.tabItems = VisitAdvancedFilterConstants.tabItems;

		vm.account = account;
		vm.user = user;
		vm.totalVisitSize = '';
		vm.$state = $state;
		var FilterItem = FilterModel.FilterItem;
		var $titleElement;

		this.initialRuleGroupQuery = {
			limit: 10000,
			page: null,
			pageSize: null,
			scoreThreshold: 0,
			ruleGroup: {
				condition: "and",
				rules: [
					{id: "1", field: "visitsOrganisationsISP", operator: "equal", type: "booleanType", value: false}
				],
				ruleGroups: []
			}
		};
		vm.initialNewVisitFilter =  {
			id:  undefined,
			ruleGroupQuery : vm.initialRuleGroupQuery,
			accountId: vm.account.id,
			name: 'New Filter ',
			active : true,
			created:  (new Date()).valueOf(),
			modified: (new Date()).valueOf(),
			visitsSizeFromQuery: 0,
			totalVisitsSize: 0,
			manuallyAddedLEOrganisationIds: [],
			userId: vm.user.id,
			userName: vm.user.userName,
			version: '2',
			type: 'STATIC',
			global: false
		};

		activate();

		function activate() {
			getVisitFilter();
		}

		function getVisitFilter() {
			if (visitFilterId) {
				return VisitAdvancedFilterService.getVisitFilter(accountId, visitFilterId)
					.then(function (visitFilter) {
						vm.visitFilter = visitFilter;
						vm.totalVisitsSize = visitFilter.totalVisitsSize;
						vm.visitsSizeFromQuery = visitFilter.visitsSizeFromQuery;
						vm.filters = visitFilter.filters;
					}).then(function () {
						broadCastNoOSearchHits(vm.visitFilter.visitsSizeFromQuery);
					})
			} else {
				//vm.filters = new FilterItem(null);
				vm.visitFilter = VisitAdvancedFilterConverter.mapFromBackend(vm.initialNewVisitFilter);
				vm.filters = vm.visitFilter.filters;
			}
		}


		$('body').scrollTop(0);

		// does not do anything
		//$scope.$on('$stateChangeSuccess', onStateChangeSucceed);
		$scope.$on('$stateChangeStart', onStateChangeStart);


		$scope.$on(  VisitAdvancedFilterConstants.eventNewVisitAddedToVisitList, onVisitListAddedVisits);
		$scope.$on(  VisitAdvancedFilterConstants.eventDeletedVisitFromVisitList, onDeletedVisitsFromVisittList);
		$scope.$watch('vm.visitFilter.name', onVisitNameChanged);

		/*function onStateChangeSucceed(event, toState, toParams, fromState, fromParams) {
			if (fromState.name === STATE.VISIT_ADVANCED_FILTER_SEARCH &&
				fromParams.accountId === '' && fromParams.visitFilterId === '') {
			}
		}*/

		function onStateChangeStart(event, toState, toParams) {
			if (toState.name.indexOf(STATE.VISIT_ADVANCED_FILTER + '.') === -1 &&
				toState.name !== STATE.VISIT_ADVANCED_FILTER) {
				vm.stateToGo = {
					name: toState.name,
					params: toParams
				};
			}
		}

		function onVisitNameChanged(newVal, oldVal) {
			if (!$titleElement || $titleElement.length === 0) {
				$titleElement = $('.top-menu__title:first');
			}
			if ($titleElement && $titleElement.children().length > 0) {
				vm.visitFilter.name = $titleElement.text();
				$titleElement.html($titleElement.text());
			}
			if (newVal && $titleElement && $titleElement.text().length > 255) {
				logger.warning('You have exceeded the maximum number of 255 characters in this field.');
				vm.visitFilter.name = oldVal;
			}
		}

		function saveVisitFilter() {
			//vm.visitFilter.name = $('.top-menu__title:first').text();
			if (vm.visitFilter.name.trim().length === 0) {
				logger.error('Please set the name before saving.');
				return;
			}
			saveRequest();
		}

		function onImportOrganisationsFromList() {
			return VisitAdvancedFilterService.importOrganisations(vm.account.id, vm.visitFilter.id, vm.importOrganisationLEIds)
				.then(function (visitFilter) {
					$state.reload();
				})
		}

		function saveRequest() {
			vm.visitFilter.userId = user.id;
			if (vm.visitFilter.id) {
				return VisitAdvancedFilterService.createOrUpdateVisitFilter(account.id, vm.visitFilter, vm.filters.serialise())
					.then(function (response) {
						visitFilterSavedSuccessfully(response)
					})

			} else {
				return VisitAdvancedFilterService.createOrUpdateVisitFilter(account.id, vm.visitFilter, vm.filters.serialise())
					.then(function (response) {
						$location.path('/accounts/' + account.id + '/visitadvancedfilters/' + response.id).replace();
						visitFilterSavedSuccessfully(response)
					});
			}
		}

		function broadCastNoOSearchHits(searchHits) {
			// make sure this is the last thing done, so child controller gets it
			$timeout(function () {
				$scope.$broadcast(VisitAdvancedFilterConstants.eventVisitSizeUpdatedFromQuery, searchHits);
			}, 0);
		}

		function visitFilterSavedSuccessfully(visitFilter) {
			//vm.lastSavedSegment = _.cloneDeep(vm.visitFilter);

			vm.totalVisitsSize = visitFilter.totalVisitsSize;

			vm.visitsSizeFromQuery = visitFilter.visitsSizeFromQuery;
			broadCastNoOSearchHits(vm.visitsSizeFromQuery);
			logger.success('The visitFilter has been saved successfully.');
		}


		function exportSegmentIPs() {
			VisitAdvancedFilterService.exportSegmentIPs(vm.account.id, vm.visitFilter.id)
				.then(function (response) {
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					var data = new Blob([response.data], {type: 'application/text;charset=utf-8'});
					var config = {
						data: data,
						filename: 'Segments_IPS_' + vm.visitFilter.id + '_' + new Date().toISOString().substring(0, 10) + '.txt'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function exportSegmentOrganisations() {
			VisitAdvancedFilterService.exportSegmentOrganisations(vm.account.id, vm.visitFilter.id)
				.then(function (response) {
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
					var config = {
						data: data,
						filename: 'Segment_Organisations_' + vm.visitFilter.id + '_' + new Date().toISOString().substring(0, 10) + '.xls'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function confirmExportIPs() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Export IPs!',
							body: 'Are you absolutely sure you want to export the selected visitFilter? The exported ' +
								'file can be used when uploading to a DMP (a DMP not supported by Leadenhancer)',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				exportSegmentIPs();
			});
		}

		function confirmExportOrganisations() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Export Organisations!',
							body: 'Are you absolutely sure you want to export the selected visitFilter? The exported ' +
								'file can be used when when asking a customer to cherry pick Organisations from the visitFilter',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				exportSegmentOrganisations();
			});
		}

		function importFromLeidsList() {
			$uibModal.open({
				templateUrl: 'partials/targeting/segments/advanced/segmentfilter-import-organisations-modal.html',
				//controller: 'ConfirmController as vm',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
			});
		}

		function onVisitListAddedVisits() {
			console.log('Event fired, Segment list changed (new), saving changes...');
			saveRequest();
		}

		function onDeletedVisitsFromVisittList() {
			console.log('Event fired, Segment list changed (delete), saving changes...');
			saveRequest();
		}
	}
})();
