(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminSegmentService', ['$http', 'CONFIG', 'responseExceptionCatcher', AdminSegmentService]);

    function AdminSegmentService($http, CONFIG, responseExceptionCatcher) {
        this.findAllSegmentsGroupedByAccount = findAllSegmentsGroupedByAccount;

        function findAllSegmentsGroupedByAccount() {
            return $http.get(CONFIG.API_PREFIX + '/admin/segments/statistics')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function onSuccess(response) {
            return response.data.data;
        }
    }
})();