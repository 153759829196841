(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('UserSettingsController', ['account' , 'user', '$q','$state'  , 'logger' , 'UserSettingsService', 'CommonService' , 'AuthService',   UserSettingsController]);

    function UserSettingsController(account, user, $q, $state, logger,UserSettingsService, CommonService, AuthService) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.saveUserSettings = saveUserSettings;

        // this is resolved from ui-router if any
        vm.user = user;
        vm.account = account;
        vm.start = moment().subtract(30, 'days').toDate();
        vm.end = moment().toDate();
        vm.refTimeZones = null;
        vm.userRoles = null;

        // reference data
        // initial if creating new
        vm.userUpdateSettings = {
            id: vm.user.id,
            email: vm.user.email,
            lastName: vm.user.lastName,
            timeZoneId: vm.user.timeZoneId,
            firstName: vm.user.firstName

        };

        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getTimeZones()]);
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.refTimeZones = result[0];

            if( AuthService.isUserTracking() ){
				vm.userRoles = 'Tracking';
			}
            if( AuthService.isUserTargeting() ){
				vm.userRoles = vm.userRoles + ' Targeting';
			}
            if( AuthService.isUserPowerUser() ){
				vm.userRoles = vm.userRoles + ' Power user';
			}
        }

        /// End init
        ///////////////

        function saveUserSettings(){
            return $q.all([onSaved()]);
        }

        function onSaved() {
            return UserSettingsService.updateUserSettings(vm.account.id, vm.userUpdateSettings)
                .then(function(){
                    showSaveSuccess();
                });
        }



        function getTimeZones() {
            return CommonService.getTimeZones();
        }

        function showSaveSuccess() {
            logger.success('Settings successfully saved ');
        }
    }
})();