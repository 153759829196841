(function() {
    'use strict';

    angular
        .module('adminApp')
        .constant('SegmentAdvancedFilterConstants', new SegmentAdvancedFilterConstants());

    function SegmentAdvancedFilterConstants() {

		var eventDeletedOrganisationsFromSegmentList = 'eventDeletedOrganisationsFromSegmentList';

		var NAME = 'Name';

		// on save we get a new count on segment
    	var eventSegmentSizeUpdatedFromQuery = 'eventSegmentSizeUpdatedFromQuery';

    	// when fired from search controller we should save the filter
    	var eventNewOrganisationsAddedToSegmentList = 'eventNewOrganisationsAddedToSegmentList';

        var filterTypes = function(filterOperator, filterField) {
        	var equalTypeDependingOnField = 'stringType';

        	if( filterField && filterField === 'organisationsEmployees'){
				equalTypeDependingOnField = 'integerType';
			}
        	if( filterField && filterField === 'organisationsNoOfNetworks'){
				equalTypeDependingOnField = 'integerType';
			}
            if( filterField && filterField === 'organisationsISP'){
				equalTypeDependingOnField = 'booleanType';
			}
			if( filterField && filterField === 'organisationsSic'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'organisationsEnhancementScore'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'organisationsSniNace'  ){
				return 'integerType';
			}
            if( filterField && filterField === 'organisationsNaics'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'organisationsGicsSectorCode1'  ){
				return 'integerType';
			}
			// given operand what type
            var types =  {
                'Text match': 'stringType',
                'Contains': 'stringType',
                'Does not contain': 'stringType',
                'Equal to': equalTypeDependingOnField,
                'Not equal to': (filterField && filterField === 'organisationsEmployees')? 'integerType': 'stringType',
                'Begins with': 'stringType',
                'Does not begin with': 'stringType',
                'More than': 'integerType',
				'Less than': 'integerType',
				'Between': 'integerType',
				'In list': 'stringType',
				'Not in list': 'stringType'
            };

            return types[filterOperator];
        };

		// maps name to field
        var filterFields = {
            'Name': 'organisationsName',
            'Organisation ID': 'organisationsOrganisationId',
            'Leadenhancer ID': 'organisationsLEID',
            'DUNS': 'organisationsDUNS',
            'Sales': 'organisationsSales',
            'Postal code': 'organisationsAddressPostalCode',
            'Region': 'organisationsAddressRegion',
            'Country': 'organisationsAddressCountry',
            'City': 'organisationsAddressCity',
            'ISP': 'organisationsISP',
            'SNI or NACE Code': 'organisationsSniNace',
            'NAICS Code': 'organisationsNaics',
            'GICS Code': 'organisationsGicsSectorCode1',
            'SIC Code': 'organisationsSic',
            'Domain name': 'organisationsAddressDomain',
            'Industry tags': 'organisationsIndustryTags',
            'Number of employees': 'organisationsEmployees',
			'Enhancement score' : 'organisationsEnhancementScore',
			'Organisation types' : 'organisationsType',
			'Networks size' : 'organisationsNoOfNetworks'


        };

        // need to map name
        var filterOperators = {
            'Text match': 'textMatch',
            'Contains': 'contains',
            'Does not contain': 'doesNotContain',
            'Equal to': 'equal',
            'Not equal to': 'notEqual',
            'Begins with': 'beginsWith',
            'Does not begin with': 'doesNotBeginsWith',
          //  'Ends with': '',
            'More than': 'moreThan',
            'Less than': 'lessThan',
            'Between': 'between',
            'In list': 'in',
            'Not in list': 'notIn'
        };

        // textMatch

        var menuItems = [
            {
                name: 'search',
                href: 'admin.segmentAdvancedFilter.search'
            }, {
                name: 'segment list',
                href: 'admin.segmentAdvancedFilter.list'
            },
            {
                name: 'settings',
                href: 'admin.segmentAdvancedFilter.settings'
            }
        ];



        var statuses = {
            'new': 'New',
            'draft': 'Draft',
            'active': 'Active'
        };

        var periodOptions = [
            {
                label: 'Hour',
                value: 'hour'
            },
            {
                label: 'Day',
                value: 'day'
            },
            {
                label: 'Week',
                value: 'week'
            },
            {
                label: 'Month',
                value: 'month'
            },
            {
                label: 'Quarter',
                value: 'quarter'
            }
        ];

        return {
            menuItems: menuItems,
            statuses: statuses,
            periodOptions: periodOptions,
            //emptyWeek: emptyWeek,
            filterTypes: filterTypes,
            filterOperators: filterOperators,
            filterFields: filterFields,

			eventNewOrganisationsAddedToSegmentList : eventNewOrganisationsAddedToSegmentList,
			eventSegmentSizeUpdatedFromQuery : eventSegmentSizeUpdatedFromQuery,
			eventDeletedOrganisationsFromSegmentList : eventDeletedOrganisationsFromSegmentList
        };
    }
})();