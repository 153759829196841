(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('filterValidation', [function () {

            this.numberOfCharsWithoutSpaces = function(numberOfChars, str) {
                var withoutWhitespaces = str.split(' ').join('');
                return withoutWhitespaces.length <= numberOfChars;
            };
            
            this.numericChars = function(str) {
                return /^\d+$/.test(str);
            };


        }]);

})();