(function () {
	'use strict';

	angular
		.module('adminApp')
		.factory('SegmentFilterSearchResultsTableModel', ['CommonService', 'AuthService' , '$state', 'cellRenderers', '$window', SegmentFilterSearchResultsTableModel]);

	function SegmentFilterSearchResultsTableModel(CommonService, AuthService, $state, CellRenderers, $window) {
		var filterValue = '';

		function setFilterValue(value) {
			filterValue = value;
		}

		function leIdCellRendererFunc(params) {
			if( params.scope && AuthService.isUserAdmin() ){
				//params.$scope.goToStateOrganisation = goToStateOrganisation;
				return '<a ui-sref="admin.enhanceLEOrganisation(  {organisationId : data.id } )" target="_blank" class="pointer" ng-bind="data.id"></a>';
			}
			return '<span >' + params.data.id +  '</span>';
		}

		var columnDefs = [
			{
				minWidth: 188,
				headerName: 'Name',
				field: 'name',
				valueGetter: function (params) {
					return params.data.name;
				},
				editable : true
				//cellRenderer: CellRenderers.OrgWithLogo
				//cellRenderer: CellRenderers.ImgCellRenderer,
				/*valueGetter: function (params) {
					return {name : params.name, logo : params.address.logoUrl};
				},*/
				//suppressFilter: true
				/*
				filter: function () {
                	var params;
					this.init = function (params) {
						//init logic
						this.params = params;
					}

					this.getGui = function () {
						return this.params.name;
					}
					this.isFilterActive = function () {
						return true;
					}

					this.doesFilterPass = function (params) {
						//filter logic
						return true;
					}
					this.getModel = function () {
						return { name: "" }
					}

					this.setModel = function (model) {
					}
				}*/
			},



			{
				minWidth: 100,
				headerName: 'Country',
				valueGetter: function (params) {
					if (params.data.address && params.data.address.countryISO) {
						return params.data.address.countryISO.toUpperCase();
					}
					return '';
				},
				cellRenderer: CellRenderers.CountryCellRenderer
			},

			{
				minWidth: 138,
				headerName: 'Domain',
				field: 'www',
				valueGetter: function (params) {
					if (params.data.domain) {
						return params.data.domain ;
					}
					if (params.data.address && params.data.address.www) {
						return params.data.address.www;
					}
					return '';

				}
			},
			{
				minWidth: 138,
				headerName: 'Region',
				hide: true,
				valueGetter: function (params) {
					if (params.data.address && params.data.address.region) {
						return params.data.address.region;
					}
					return '';
				},
				editable : true
			},
			{
				minWidth: 138,
				headerName: 'City',
				valueGetter: function (params) {
					if (params.data.address && params.data.address.city) {
						return params.data.address.city;
					}
					return '';
				},
				editable : true
			},
			{
				minWidth: 140,
				headerName: 'Postal code',
				valueGetter: function (params) {
					if (params.data.address && params.data.address.postalCode) {
						return params.data.address.postalCode;
					}
					return '';
				},
				editable : true
			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'Address',
				valueGetter: function (params) {
					if (params.address && params.address.address) {
						return params.data.address.address;
					}
					return '';
				}
			},
			{
				minWidth: 140,
				headerName: 'Org Nr',
				valueGetter: function (params) {
					return params.data.organisationId;
				},
				editable: true
			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'DUNS',
				valueGetter: function (params) {
					return params.data.duns;
				},
				editable: true
			},
			{
				minWidth: 188,
				headerName: 'Industry Tags ',
				field: 'industryTags',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.industryTags !== null ){
						result = params.data.industryTags;
					}
					return result;
				}
			},
			{
				minWidth: 138,
				headerName: 'Type',
				valueGetter: function (params) {
					if (params.data.organisationType === 'nonProfit') {
						return 'Non profit';
					}
					if (params.data.organisationType === 'soletrader') {
						return 'Solo trader';
					}
					if (params.data.organisationType === 'limitedPartnerShip') {
						return 'Limited partnership';
					}
					if (params.data.organisationType === 'limitedCompany') {
						return 'Limited company';
					}
					if (params.data.organisationType === 'economicAssociation') {
						return 'Economic association';
					}
					if (params.data.organisationType === 'municipalCorporation') {
						return 'Municipal corporation';
					}
					if (params.data.organisationType === 'nonProfit') {
						return 'Non profit';
					}
					if (params.data.organisationType === 'notActive') {
						return 'Not active';
					}


					return params.data.organisationType;
				},
				editable: true
			},
			{
				minWidth: 200,
				headerName: 'SIC',
				field: 'sicPrimaryCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sicPrimaryCode !== null ){
						result = params.data.sicPrimaryCode + ' - ' +  params.data.sicPrimaryCodeDescription;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'SIC 2',
				field: 'sicCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sicCode2 !== null ){
						result = params.data.sicCode2 + ' - ' +  params.data.sicText2;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'SIC 3',
				field: 'sicCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sicCode3 !== null ){
						result = params.data.sicCode3 + ' - ' +  params.data.sicText3;
					}

					return result.toLocaleLowerCase();
				}
			},
			{
				hide: false,
				minWidth: 200,
				headerName: 'NACE',
				field: 'sniNaceCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sniNaceCode !== null ){
						result = params.data.sniNaceCode + ' - ' +  params.data.sniNaceCodeDescription;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				minWidth: 200,
				headerName: 'NACE 2',
				field: 'sniNaceCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sniNaceCode2 !== null ){
						result = params.data.sniNaceCode2 + ' - ' +  params.data.sniNaceCodeText2;
					}
					return result.toLocaleLowerCase();
				}


			},{
				minWidth: 200,
				headerName: 'NACE 3',
				field: 'sniNaceCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.sniNaceCode3 !== null ){
						result = params.data.sniNaceCode3 + ' - ' +  params.data.sniNaceCodeText3;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'NAICS',
				field: 'naicsCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.naicsCode !== null ){
						result = params.data.naicsCode + ' - ' +  params.data.naicsCodeText;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'NAICS 2',
				field: 'naicsCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.naicsCode2 !== null ){
						result = params.data.naicsCode2 + ' - ' +  params.data.naicsText2;
					}
					return result.toLocaleLowerCase();
				}
			},{
				hide: true,
				minWidth: 200,
				headerName: 'NAICS 3',
				field: 'naicsCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.naicsCode3 !== null ){
						result = params.data.naicsCode3 + ' - ' +  params.data.naicsText3;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Sector',
				field: 'gicsSectorCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.gicsSectorCode1 !== null ){
						result = params.data.gicsSectorCode1 + ' - ' +  params.data.gicsSectorTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Industry Group',
				field: 'gicsIndustryGroupCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.gicsIndustryGroupCode1 !== null ){
						result = params.data.gicsIndustryGroupCode1 + ' - ' +  params.data.gicsIndustryGroupTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Industry',
				field: 'gicsIndustryCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.gicsIndustryCode1 !== null ){
						result = params.data.gicsIndustryCode1 + ' - ' +  params.data.gicsIndustryTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Sub Industry',
				field: 'gicsIndustryCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.gicsSubIndustryCode1 !== null ){
						result = params.data.gicsSubIndustryCode1 + ' - ' +  params.data.gicsSubIndustryTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				minWidth: 138,
				headerName: 'No Employees',
				field: 'noOfEmployees',
				editable : true
			},
			{
				minWidth: 140,
				headerName: 'Sales Euro',
				valueGetter: function (params) {
					return params.data.sales;
				},
				editable : true
			},
			{
				hide: true,
				minWidth: 140,
				headerName: 'ISP',
				field: 'isIsp',
				valueGetter: function (params) {
					if( params.data.isIsp ){
						return 'yes';
					}
					return 'no';
				}
			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'Networks',
				field: 'noOfNetworks'
			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'Score',
				field: 'enhancementScore'
			},
			{
				hide: false,
				minWidth: 138,
				headerName: 'LEid',
				field: 'id',
				editable : true,
				cellRenderer: leIdCellRendererFunc
			}
			/* {
                 hide: true,
                 minWidth: 138,
                 headerName: 'Organisation Description',
                 valueGetter: function () {
                     return '';
                 }
             }*/

		];


		return {
			setFilterValue: setFilterValue,
			columnDefs: columnDefs
		};
	}

})();