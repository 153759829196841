(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TokenService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', TokenService]);

    function TokenService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAccountTokens = findAccountTokens;
        this.getTokenStatistics = getTokenStatistics;
        this.getToken = getToken;


        function findAccountTokens(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/tokens')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getTokenStatistics(accountId, tokenId, startDate, endDate) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/tokens/' + tokenId + '/statistics?startDate=' + startDate + '&endDate=' + endDate)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getToken(accountId, tokenId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/tokens/' + tokenId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


    }
})();