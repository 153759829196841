(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('VisitAdvancedFilterSearchController',
			['$state', '$rootScope', '$scope', '$q', '$timeout', '$localStorage', 'CommonService',
				'VisitAdvancedFilterService', 'VisitAdvancedFilterConverter', 'OrganisationService',
				'account', 'draggingService', 'filterConstants', 'VisitFilterSearchResultsTableModel',
				'VisitAdvancedFilterConstants','logger', 'EnhancedOrganisationService',
				VisitAdvancedFilterSearchController]);


	function VisitAdvancedFilterSearchController($state, $rootScope, $scope, $q, $timeout, ngStorage, CommonService,
												 VisitAdvancedFilterService, VisitAdvancedFilterConverter,
												 OrganisationService, account, DraggingService, filterConstants,
												 VisitFilterSearchResultsTableModel, VisitAdvancedFilterConstants,
												 logger, EnhancedOrganisationService) {
		var vm = this;
		vm.addSelectedOrganistionsToSegmentList = addSelectedOrganistionsToSegmentList;
		vm.addToEnhancmentdSelectedOrganistions = addToEnhancmentdSelectedOrganistions;
		vm.showResults = showResults;
		vm.hideResults = hideResults;

		vm.resultsVisible = false;
		// holds organisations selected to added to segment list
		vm.selectedVisits = {list: []};


		vm.parent = $scope.vm;
		if (vm.parent.visitFilter) {
			vm.visitFilter = vm.parent.visitFilter;
			vm.visitsSizeFromQuery = vm.parent.visitFilter.visitsSizeFromQuery;

		}
		if (vm.parent.filters) {
			vm.filters = vm.parent.filters;
			$scope.numberOfFilters = vm.filters.numberOfFilters();
		}

		activate();

		function activate() {
		}

		var $container = $('.segmentfilter-search__filters-container');
		vm.hasBeenScrolled = false;
		$scope.filterDefs = filterConstants.filterAccordionVisits;

		vm.hamburgerFiltersOpenState = true;

		$scope.panelHeights = 171 + 36;
		if ($(window).height() <= 600) {
			$scope.panelHeights -= 25;
		}

		vm.visitFilterSearchResultTable = {
			angularCompileRows: true,
			getFn: function () {
				// fired when jumping into table pressing search
				return VisitAdvancedFilterService
					.searchWithRuleGroup(account.id,
						VisitAdvancedFilterConverter.convertFiltersToSave(JSON.parse(vm.filters.serialise())))
					.then(function (visits) {
						vm.visitsSizeFromQuery = visits.length;
						return visits;
					});
			}.bind(this),
			columnDefs: VisitFilterSearchResultsTableModel.columnDefs,
			pagination: true
		};
		$scope.avatarFn = function (el) {
			return $(el.el).parents('.filter-block');
		};

		$scope.$on(VisitAdvancedFilterConstants.eventVisitSizeUpdatedFromQuery, function (event, sizeFromQueryUpdated) {
			vm.visitsSizeFromQuery = sizeFromQueryUpdated;
		});



		var unsubscribeDragStop = $rootScope.$on('dragStop', onDragStop);
		var unsubscribeDragMove = $rootScope.$on('dragMove', onDragMove);

		// Called when adding new filter/variable
		var unsubscribeFilterAdded = $rootScope.$on('filterAdded', function (eventName, data) {
			vm.filters.addNewItem(data.name);
			$scope.numberOfFilters = vm.filters.numberOfFilters();
		});

		var unsubscribeFilterRemoved = $rootScope.$on('filterRemoved', function () {
			$scope.numberOfFilters = vm.filters.numberOfFilters();
		});

		var usubscribeNumberOfFiltersWatch = $scope.$watch('numberOfFilters', function () {
			$timeout(function () {
				filtersContainerHeight = $('.segmentfilter-search__filters-container')[0].scrollHeight;
			});
		});

		$scope.$on('$destroy', onDestroy);


		var filtersContainerHeight = 0;

		$scope.$on('numberOfTagsChanged', function () {
			$timeout(function () {
				filtersContainerHeight = $('.segmentfilter-search__filters-container')[0].scrollHeight;
			});
		});

		$timeout(function () {
			var leftPanelHieght;
			if (($(window).height() - $scope.panelHeights) < 167) {
				leftPanelHieght = 167;
			} else {
				leftPanelHieght = $(window).height() - $scope.panelHeights;
			}

			$('.segmentfilter-search__left-panel').css('height', leftPanelHieght);
			filtersContainerHeight = $('.segmentfilter-search__filters-container')[0].scrollHeight;

			$scope.$on('dropdownOpened', function (event, elem) {
				scrollIfContentInvisible({currentTarget: elem});
			});

			$scope.$on('dropdownClosed', function () {
				if (vm.hasBeenScrolled) {
					$timeout(function () {
						$container.scrollTop($container.scrollTop() - 1);
						vm.hasBeenScrolled = false;
					});
				}
			});

			$scope.$on('autocompleteExpanded', function (event, elem) {
				$timeout(function () {
					scrollIfContentInvisible({currentTarget: elem});
				});
			});
		});

		function showResults() {
			vm.resultsVisible = true;
			var topPanelHeight = 67;
			if ($(window).height() <= 600) {
				topPanelHeight -= 13;
			}
			$('.segmentfilter-search__results').height(
				$('.segmentfilter-search__left-panel').height() + topPanelHeight
			);

			$scope.query = vm.filters.toString();
		}

		function hideResults() {
			vm.resultsVisible = false;
			$('.segmentfilter-search__results').height(0);
		}

		function scrollIfContentInvisible(event) {
			var $dropdown = $(event.currentTarget).find('.dropdown-select__dropdown');
			$dropdown = $dropdown.offset() ? $dropdown : $(event.currentTarget).find('.autocomplete');
			var scrollDown = $dropdown.offset() ? (($dropdown.offset().top + $dropdown.height()) - ($container.offset().top + $container.height())) : 0;
			var scrollUp = $dropdown.height() + 46 - $container.height();
			scrollDown = scrollDown > 0 ? scrollDown : 0;
			scrollUp = scrollUp > 0 ? scrollUp : 0;
			if (scrollDown !== scrollUp) {
				vm.hasBeenScrolled = true;
			}
			scrollTo($container[0], $container.scrollTop() + scrollDown - scrollUp, 350);
		}

		function scrollTo(element, to, duration) {
			if (duration <= 0) {
				return;
			}
			var difference = to - element.scrollTop;
			var perTick = difference / duration * 10;

			setTimeout(function () {
				element.scrollTop = element.scrollTop + perTick;
				if (element.scrollTop === to) {
					return;
				}
				scrollTo(element, to, duration - 10);
			}, 10);
		}

		function addSelectedOrganistionsToSegmentList() {
			// get ids of organisations in table
			var selectedIdsInTable = _.map(vm.selectedVisits.list, 'data.id');

			vm.visitFilter.manuallyAddedLEOrganisationIds = _(vm.visitFilter.manuallyAddedLEOrganisationIds).concat(selectedIdsInTable).uniq().value();
			//reset selection
			vm.selectedVisits.list = [];
			$scope.$broadcast('updateTable', vm.visitFilter.manuallyAddedLEOrganisationIds);

			$scope.$emit(VisitAdvancedFilterConstants.eventNewVisitAddedToVisitList ,vm.visitFilter.manuallyAddedLEOrganisationIds);

			logger.info('Added selected organisations to Segment List')
		}

		function addToEnhancmentdSelectedOrganistions() {
			// get ids of organisations in table
			var selectedIdsInTable = _.map(vm.selectedVisits.list, 'data.id');

			EnhancedOrganisationService.createHighPrioEnhancementRequests(selectedIdsInTable);

			//reset selection
			vm.selectedVisits.list = [];
			logger.info('Request for Enhancement has been created');
			$scope.$broadcast('updateTable', []);

		}

		function onDragMove(event, data) {
			var isInContainer =
				$(data.target).parents('.segmentfilter-search__filters-container').length ||
				$(data.target).hasClass('segmentfilter-search__filters-container');

			if (!isInContainer) {
				return;
			}
			var fb;
			if ($(data.target).parents('.filter-block').length) {
				fb = $(data.target).parents('.filter-block');
			} else if ($(data.target).hasClass('segmenfilter-search__between')) {
				fb = $(data.target).parent().find('.filter-block');
			} else if (
				$(data.target).parents('.segmenfilter-search__between').length
			) {
				fb = $(data.target).parent().parent().find('.filter-block');
			}

			if (fb) {
				var fId = fb.attr('id');
				var item = vm.filters.findById(fId);
				if (data.dragData.data.id === fId) {
					return;
				}
				$scope.$apply(function () {
					if ($scope.lastCadidate !== item) {
						if ($scope.lastCadidate) {
							$scope.lastCadidate.candidate = false;
						}
						$scope.lastCadidate = item;
					}
					if (item) {
						item.candidate = true;
					}
				}.bind(this));
			}
		}

		function onDragStop(eventName, data) {
			var isInContainer =
				$(data.target).parents('.segmentfilter-search__filters-container').length ||
				$(data.target).hasClass('segmentfilter-search__filters-container');

			if (!isInContainer) {
				return;
			}

			if ($(data.target).hasClass('segmentfilter-search__filters-container')) {
				$scope.$apply(function () {
					if (data.dragData.type === 'moveFilter') {
						if ($scope.lastCadidate) {
							$scope.lastCadidate.candidate = false;
							$scope.lastCadidate = null;
						}
						vm.filters.appendItem(data.dragData.data.prepareForMove());
					} else {
						vm.filters.addNewItem(data.dragData.data.name);
					}
					$scope.numberOfFilters = vm.filters.numberOfFilters();
				});

				return;
			}

			var fb;
			if ($(data.target).parents('.filter-block').length) {
				fb = $(data.target).parents('.filter-block');
			} else if ($(data.target).hasClass('segmenfilter-search__between')) {
				fb = $(data.target).parent().find('.filter-block');
			} else if (
				$(data.target).parents('.segmenfilter-search__between').length
			) {
				fb = $(data.target).parent().parent().find('.filter-block');
			}

			var fId = fb ? fb.attr('id') : null;

			var item = vm.filters.findById(fId);

			$scope.$apply(function () {
				if (data.dragData.type === 'moveFilter') {
					if (data.dragData.data.id === fId) {
						return;
					}
					if ($scope.lastCadidate) {
						$scope.lastCadidate.candidate = false;
						$scope.lastCadidate = null;
					}
					if (data.target.offsetHeight / 2 > data.posTip.top && item) {
						item.prependItemBefore(data.dragData.data.prepareForMove());
					} else if (item) {
						item.appendItemAfter(data.dragData.data.prepareForMove());
					}

				} else {
					if (data.target.offsetHeight / 2 > data.posTip.top && item) {
						item.prependNewItemBefore(data.dragData.data.name);
					} else if (item) {
						item.appendNewItemAfter(data.dragData.data.name);
					}
				}
				$scope.numberOfFilters = vm.filters.numberOfFilters();
			});
		}

		function onDestroy() {
			unsubscribeDragStop();
			unsubscribeDragMove();
			unsubscribeFilterAdded();
			unsubscribeFilterRemoved();
			usubscribeNumberOfFiltersWatch();
		}
	}

})();