(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('Visits', Visits);

    function Visits() {
        return function (list, accountId, siteId, start, end, filterId, limit) {
            var that = this;

            this.list = list;
            this.accountId = accountId;
            this.siteId = siteId;
            this.start = start;
            this.end = end;
            this.filterId = filterId;
            this.limit = limit;
            this.isFor = isFor;

            function isFor(accountId, siteId, start, end, filterId, limit) {
                return that.accountId === accountId && that.siteId === siteId && that.start === start && that.end === end && that.filterId === filterId && that.limit === limit;
            }
        };
    }

})();