(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('reportSchedule', yesNoFilter);

    function yesNoFilter() {
        return function (value) {
            if( value === 'DAILY_ALL'){
                return 'Daily';
            }

            if( value === 'WEEKLY_MONDAY'){
                return 'Weekly on Monday';
            }

            if( value === 'WEEKLY_TUESDAY'){
                return 'Weekly on Tuesday';
            }

            if( value === 'WEEKLY_WEDNESDAY'){
                return 'Weekly on Wednesday';
            }

            if( value === 'WEEKLY_THURSDAY'){
                return 'Weekly on Thursday';
            }

            if( value === 'WEEKLY_FRIDAY'){
                return 'Weekly on Friday';
            }

            if( value === 'WEEKLY_SATURDAY'){
                return 'Weekly on Saturday';
            }

            if( value === 'WEEKLY_SUNDAY'){
                return 'Weekly on Sunday';
            }

            if( value === 'MONTHLY'){
                return 'Monthly';
            }

            return '';
        };
    }

})();