(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('EnhancedOrganisationController', ['enhancedOrganisationId', 'EnhancedOrganisationService', 'EnhancementQueueService', 'EnhancerSettingsService', 'LEOrganisationService', 'RIPEOrganisationService', 'NBKOrganisationService', 'CommonService', 'logger', '$q', '$state', EnhancedOrganisationController]);

	function EnhancedOrganisationController(enhancedOrganisationId, EnhancedOrganisationService, EnhancementQueueService, EnhancerSettingsService, LEOrganisationService, RIPEOrganisationService, NBKOrganisationService, CommonService, logger, $q, $state) {
		var vm = this;
		vm.getEnhancedOrganisation = getEnhancedOrganisation;
		vm.onUpdateEnhancedOrganisation = onUpdateEnhancedOrganisation;
		vm.enhancingFailed = enhancingFailed;
		vm.tableSize = tableSize;
		vm.tableSizeOrganisations = tableSizeOrganisations;
		vm.tableSizeNBKOrganisations = tableSizeNBKOrganisations;
		vm.takeOverLEORganisation = takeOverLEORganisation;
		vm.takeOverNBKORganisation = takeOverNBKORganisation;
		vm.getSNICodesForSelectBox = getSNICodesForSelectBox;
		vm.getNAICSCodesForSelectBox = getNAICSCodesForSelectBox;


		vm.getGICSSubIndustryCodesForSelectBox = getGICSSubIndustryCodesForSelectBox;


		vm.getCountriesForSelectBox = getCountriesForSelectBox;
		vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
		vm.getSICCodesForSelectBox = getSICCodesForSelectBox;
		vm.onSetSICCodesForSelectBox = onSetSICCodesForSelectBox;
		vm.onSetSIC2CodesForSelectBox = onSetSIC2CodesForSelectBox;
		vm.onSetSIC3CodesForSelectBox = onSetSIC3CodesForSelectBox;
		vm.onSetSNICodesForSelectBox = onSetSNICodesForSelectBox;
		vm.onSetSNI2CodesForSelectBox = onSetSNI2CodesForSelectBox;
		vm.onSetSNI3CodesForSelectBox = onSetSNI3CodesForSelectBox;
		vm.onSetNAICSCodesForSelectBox = onSetNAICSCodesForSelectBox;
		vm.onSetNAICS2CodesForSelectBox = onSetNAICS2CodesForSelectBox;
		vm.onSetNAICS3CodesForSelectBox = onSetNAICS3CodesForSelectBox;

		vm.onSetGICSSubIndustryCodesForSelectBox = onSetGICSSubIndustryCodesForSelectBox;
		vm.updateToIsp = updateToIsp;
		vm.updateToNonIsp = updateToNonIsp;


		vm.selectedCountry = null;
		vm.searchFilter = '';
		vm.searchOrganisationFilter = '';
		vm.enhancedOrganisationId = enhancedOrganisationId;
		vm.enhancedOrganisation = null;
		vm.ripeOrganisation = null;
		vm.nbkOrganisations = null;
		vm.organisations = null;
		vm.useSourceData = 'basic';
		vm.showNBKBuyButton = true;

		// ref
		vm.refCountries = [];
		vm.refSNICodes = [];
		vm.refSICCodes = [];
		vm.refNAICSCodes = [];
		vm.refGICSCodes = [];  // hiearchical
		vm.refGICSSubIndustryCodes = [];  // hiearchical
		vm.refGICSTableRows = null;
		vm.refOrganisationTypes = [
			{id : 'limitedCompany' , name : 'Limited company'},
			{id : 'soletrader' , name : 'Solo Trader'},
			{id : 'limitedPartnerShip' , name : 'Limited partnership'},
			{id : 'economicAssociation' , name : 'Economic Association'},
			{id : 'municipalCorporation' , name : 'Municipal corporation'},
			{id : 'nonProfit' , name : 'Non profit'},
			{id : 'notActive' , name : 'Not Active'}
		];

		////////////
		//  Init
		activate();

		function activate() {
			getOrCreateEnhancerSettings();
			getInitialData().then(showData);
		}

		function getOrCreateEnhancerSettings() {
			vm.enhancerSettings = EnhancerSettingsService.getOrCreateEnhancerSettings();
		}


		function getInitialData() {
			return $q.all([getEnhancedOrganisation(), getCountries(), getSNICodes(), getSICCodes(), getNAICSCodes(), getGICSCodes()]);
		}

		function getEnhancedOrganisation() {
			return EnhancedOrganisationService.getEnhancedOrganisation(vm.enhancedOrganisationId);
		}

		function getCountries() {
			return CommonService.getCountries();
		}

		function getSNICodes() {
			return CommonService.getNACECodes();
		}

		function getSICCodes() {
			return CommonService.getSICCodes();
		}

		function getNAICSCodes() {
			return CommonService.getNAICSCodes();
		}

		function getGICSCodes() {
			return CommonService.getGICSCodes();
		}

		function getLEOrganisations() {
			if (vm.enhancedOrganisation.organisationName && vm.enhancedOrganisation.countryIso) {
				var config = {
					hideLoadingIndicator: true,
					dontFailOnError: true
				};
				var countries = [vm.enhancedOrganisation.countryIso];
				LEOrganisationService.searchQuick(vm.enhancedOrganisation.organisationName, countries, vm.enhancerSettings.limit, config).then(function (result) {
					vm.organisations = result;
				});
			}
		}

		function getRIPEData() {
			if (vm.enhancedOrganisation.ip) {
				var config = {
					hideLoadingIndicator: true,
					dontFailOnError: true
				};
				RIPEOrganisationService.searchRIPE(vm.enhancedOrganisation.ip, config).then(function (result) {
					vm.ripeOrganisation = result;
				});
			}
		}

		function getNBKData() {
			if (vm.enhancedOrganisation.organisationName) {
				var config = {
					hideLoadingIndicator: true,
					dontFailOnError: true
				};
				NBKOrganisationService.searchNBK(vm.enhancedOrganisation.organisationName, 'SE', config).then(function (result) {
					vm.nbkOrganisations = result;
				});
			}
		}

		// display the stuff when promises are finished
		function showData(result) {
			vm.enhancedOrganisation = result[0];
			vm.refCountries = result[1];
			vm.refSNICodes = result[2];
			vm.refSICCodes = result[3];
			vm.refNAICSCodes = result[4];
			vm.refGICSCodes = result[5];

			console.log('result[5]', result[5])


			vm.refGICSSubIndustryCodes = vm.refGICSCodes.gicsSubIndustryCode;
			vm.refGICSTableRows = vm.refGICSCodes.tableRows;


			// take over basic direct
			vm.enhancedOrganisation.organisationName = vm.enhancedOrganisation.organisation.name;
			vm.enhancedOrganisation.countryIso = vm.enhancedOrganisation.organisation.address.countryISO;


			onInitSetCountryFromEnhancedOrganisation(vm.enhancedOrganisation.countryIso);

			getLEOrganisations();
			getRIPEData();
			getNBKData();

		}

		// initialize on activate
		function onInitSetCountryFromEnhancedOrganisation(countryIso) {
			if (countryIso) {
				vm.selectedCountry = CommonService.getCountryFromIsoCode(countryIso);
			}
		}

		function onInitSetSICSelectFromEnhancedOrganisation(sicCodeFromEnhancedOrg) {
			// select uses afull json object
			vm.selectedSICCode = _.find(vm.refSNICodes, function (code) {
				if (sicCodeFromEnhancedOrg === code.code) {
					return code;
				}
			});
		}

		function onInitSetSNISelectFromEnhancedOrganisation(sniCodeFromEnhancedOrg) {
			// select uses afull json object
			vm.selectedSNICode = _.find(vm.refSNICodes, function (code) {
				if (sniCodeFromEnhancedOrg === code.code) {
					return code;
				}
			});
		}

		/// End init


		function tableSizeOrganisations() {
			return vm.organisations.length;
		}

		function tableSizeNBKOrganisations() {
			return vm.nbkOrganisations.length;
		}

		function tableSize() {
			if (vm.ripeOrganisation) {
				return vm.ripeOrganisation.all.length;
			}
			return null;
		}


		function onUpdateEnhancedOrganisation() {
			//vm.enhancedOrganisation.status = 'ENHANCED';
			return EnhancedOrganisationService.updateEnhancedOrganisation(vm.enhancedOrganisation)
				.then(function (data) {
					vm.enhancedOrganisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function enhancingFailed() {
			//vm.enhancedOrganisation.status = 'ENHANCING_FAILED';
			return EnhancedOrganisationService.updateEnhancedOrganisationToFailed(vm.enhancedOrganisation)
				.then(function (data) {
					vm.enhancedOrganisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function showSaveSuccess() {
			logger.success('Enhancement saved');
			//$state.go('admin.enhance', {accountId: vm.account.id, campaignId: vm.campaign.id}, {notify: false});
			$state.reload();
		}

		function takeOverLEORganisation(selectedLeId) {
			var useMe = _.find(vm.organisations, function (org) {
				return org.id === selectedLeId;
			});

			// take over to
			if (useMe) {
				vm.enhancedOrganisation.organisationName = useMe.name;
				vm.enhancedOrganisation.organisationId = useMe.organisationId;
				vm.enhancedOrganisation.duns = useMe.duns;
				vm.enhancedOrganisation.sicPrimary = useMe.sicPrimaryCode;
				vm.enhancedOrganisation.sicDescription = useMe.sicPrimaryCodeDescription;
				vm.enhancedOrganisation.sni = useMe.sniNaceCode;
				vm.enhancedOrganisation.sniDescription = useMe.sniNaceCodeDescription;
				vm.enhancedOrganisation.ceo = useMe.positionCEO;
				vm.enhancedOrganisation.cmo = useMe.positionCMO;
				vm.enhancedOrganisation.cso = useMe.positionCSO;
				vm.enhancedOrganisation.resourceManager = useMe.positionHR;
				vm.enhancedOrganisation.noOfEmployees = useMe.noOfEmployees;
				vm.enhancedOrganisation.financialYearEnd = useMe.financialYearEnd;
				vm.enhancedOrganisation.legalStatus = useMe.legalStatus;
				vm.enhancedOrganisation.salesEuro = useMe.sales;
				vm.enhancedOrganisation.currency = useMe.currency;
				vm.enhancedOrganisation.source = useMe.source;
				vm.enhancedOrganisation.isIsp = useMe.isIsp;
				vm.enhancedOrganisation.active = true;
				vm.enhancedOrganisation.comment = 'manually enhanced';
				vm.enhancedOrganisation.countryIso = useMe.address.countryISO;
				vm.enhancedOrganisation.phone = useMe.address.phone;
				vm.enhancedOrganisation.email = useMe.address.email;
				vm.enhancedOrganisation.address = useMe.address.address;
				vm.enhancedOrganisation.city = useMe.address.city;
				vm.enhancedOrganisation.postalCode = useMe.address.postalCode;

				onInitSetCountryFromEnhancedOrganisation(vm.enhancedOrganisation.countryIso);

				onInitSetSICSelectFromEnhancedOrganisation(vm.enhancedOrganisation.sni);

				onInitSetSNISelectFromEnhancedOrganisation();

			}
			logger.info('Took over data!!');
			//vm.useSourceData = 'Leadenhancer';
		}

		function takeOverNBKORganisation(selectedNbkOrganisation) {
			return NBKOrganisationService.takeOverNBKORganisation(selectedNbkOrganisation).then(takeOverFromNBK);

		}

		function takeOverFromNBK(useMe) {
			if (useMe) {
				vm.enhancedOrganisation.organisationName = useMe.name;
				vm.enhancedOrganisation.organisationId = useMe.organisationId;
				vm.enhancedOrganisation.duns = useMe.duns;
				vm.enhancedOrganisation.sicPrimary = useMe.sicPrimaryCode;
				vm.enhancedOrganisation.sicDescription = useMe.sicPrimaryCodeDescription;
				vm.enhancedOrganisation.sni = useMe.sniNaceCode;
				vm.enhancedOrganisation.sniDescription = useMe.sniNaceCodeDescription;
				vm.enhancedOrganisation.ceo = useMe.positionCEO;
				vm.enhancedOrganisation.cmo = useMe.positionCMO;
				vm.enhancedOrganisation.cso = useMe.positionCSO;
				vm.enhancedOrganisation.resourceManager = useMe.positionHR;
				vm.enhancedOrganisation.noOfEmployees = useMe.noOfEmployees;
				vm.enhancedOrganisation.financialYearEnd = useMe.financialYearEnd;
				vm.enhancedOrganisation.legalStatus = useMe.legalStatus;
				vm.enhancedOrganisation.sales = useMe.sales;
				vm.enhancedOrganisation.currency = useMe.currency;
				vm.enhancedOrganisation.source = useMe.source;
				vm.enhancedOrganisation.isIsp = useMe.isIsp;
				vm.enhancedOrganisation.active = true;
				vm.enhancedOrganisation.comment = 'manually enhanced';
				vm.enhancedOrganisation.countryIso = useMe.address.countryISO;
				vm.enhancedOrganisation.phone = useMe.address.phone;
				vm.enhancedOrganisation.email = useMe.address.email;
				vm.enhancedOrganisation.address = useMe.address.address;
				vm.enhancedOrganisation.city = useMe.address.city;
				vm.enhancedOrganisation.postalCode = useMe.address.postalCode;

				onInitSetCountryFromEnhancedOrganisation(vm.enhancedOrganisation.countryIso);

				onInitSetSICSelectFromEnhancedOrganisation(vm.enhancedOrganisation.sicPrimary);

				onInitSetSNISelectFromEnhancedOrganisation(vm.enhancedOrganisation.sniNaceCode);

			}
			logger.info('Took over data!!');
			//vm.useSourceData = 'Leadenhancer';


		}


		// typeahead callback
		function getSNICodesForSelectBox(filterBy) {
			return _.filter(vm.refSNICodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.name && filterBy && isString) {
					return code.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}

		// typeahead callback
		function getNAICSCodesForSelectBox(filterBy) {
			return _.filter(vm.refNAICSCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.description && filterBy && isString) {
					return code.description.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}




		// typeahead callback
		function getGICSSubIndustryCodesForSelectBox(filterBy) {
			return _.filter(vm.refGICSSubIndustryCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.description && filterBy && isString) {
					return code.description.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}


		function onSetSNICodesForSelectBox(item) {
			vm.enhancedOrganisation.sniNaceCode = item.code;
			vm.enhancedOrganisation.sniNaceCodeText = item.name;
		}

		function onSetSNI2CodesForSelectBox(item) {
			vm.enhancedOrganisation.sniNaceCode2 = item.code;
			vm.enhancedOrganisation.sniNaceCodeText2 = item.name;
		}

		function onSetSNI3CodesForSelectBox(item) {
			vm.enhancedOrganisation.sniNaceCode3 = item.code;
			vm.enhancedOrganisation.sniNaceCodeText3 = item.name;
		}

		function onSetNAICSCodesForSelectBox(item) {
			vm.enhancedOrganisation.naicsCode = item.code;
			vm.enhancedOrganisation.naicsCodeText = item.description;
		}

		function onSetNAICS2CodesForSelectBox(item) {
			vm.enhancedOrganisation.naicsCode2 = item.code;
			vm.enhancedOrganisation.naicsText2 = item.description;
		}

		function onSetNAICS3CodesForSelectBox(item) {
			vm.enhancedOrganisation.naicsCode3 = item.code;
			vm.enhancedOrganisation.naicsText3 = item.description;
		}



		function onSetGICSSubIndustryCodesForSelectBox(item) {
			vm.enhancedOrganisation.gicsSubIndustryCode1 = item.code;
			vm.enhancedOrganisation.gicsSubIndustryTxt1 = item.description;
			var industryGroupCode =_.find(vm.refGICSTableRows, function(row){
				return row.gicsSubIndustryGroupCode.code === item.code;
			});

			vm.enhancedOrganisation.gicsSectorCode1 = industryGroupCode.gicsSectorCode.code;
			vm.enhancedOrganisation.gicsSectorTxt1 = industryGroupCode.gicsSectorCode.description;
			vm.enhancedOrganisation.gicsIndustryGroupCode1 = industryGroupCode.gicsIndustryGroupCode.code;
			vm.enhancedOrganisation.gicsIndustryGroupTxt1 = industryGroupCode.gicsIndustryGroupCode.description;
			vm.enhancedOrganisation.gicsIndustryCode1 = industryGroupCode.gicsIndustryCode.code;
			vm.enhancedOrganisation.gicsIndustryTxt1 = industryGroupCode.gicsIndustryCode.description;
			vm.enhancedOrganisation.gicsSubIndustryCode1 = industryGroupCode.gicsSubIndustryGroupCode.code;
			vm.enhancedOrganisation.gicsSubIndustryTxt1 = industryGroupCode.gicsSubIndustryGroupCode.description;


		}


		// typeahead callback
		function getSICCodesForSelectBox(filterBy) {
			return _.filter(vm.refSICCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.name && filterBy && isString) {
					return code.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}

		function onSetSICCodesForSelectBox(item) {
			vm.enhancedOrganisation.sicPrimary = item.code;
			vm.enhancedOrganisation.sicDescription = item.name;
		}

		function onSetSIC2CodesForSelectBox(item) {
			vm.enhancedOrganisation.sicCode2 = item.code;
			vm.enhancedOrganisation.sicText2 = item.name;
		}

		function onSetSIC3CodesForSelectBox(item) {
			vm.enhancedOrganisation.sicCode3 = item.code;
			vm.enhancedOrganisation.sicText3 = item.name;
		}

		// typeahead callback
		function getCountriesForSelectBox(filterByCountryName) {
			return _.filter(vm.refCountries, function (country) {
				if (country.name && filterByCountryName) {
					return country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1;
				}
			});
		}

		// on select in UI
		function onSetCountryForSelectBox(item) {
			vm.enhancedOrganisation.countryIso = item.countryISO;
			vm.enhancedOrganisation.countryDescription = item.name;
		}


		function updateToIsp() {
			var ids = [vm.enhancedOrganisation.id];
			EnhancementQueueService.updateToIsps(ids).then(function () {
				logger.info('Organisation updated to ISP');
				getEnhancedOrganisation().then( function(data){
					vm.enhancedOrganisation = data;
				} )

			});
		}

		function updateToNonIsp() {
			var ids = [vm.enhancedOrganisation.id];
			EnhancementQueueService.updateToNonIsps(ids).then(function () {
				logger.info('Organisation/s updated to NON ISP');
				getEnhancedOrganisation().then( function(data){
					vm.enhancedOrganisation = data;
				} )
			});
		}

	}
})();