(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ClickCampaignController', ['account', 'clickCampaignId', '$q', '$state', 'logger', 'ClickCampaignService', 'TrackingLinkService', 'SiteService', '$uibModal', 'STATE', ClickCampaignController]);

    function ClickCampaignController(account, clickCampaignId, $q, $state, logger, ClickCampaignService, TrackingLinkService, SiteService, $uibModal, STATE) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.saveClickGroup = saveClickGroup;
        vm.addClick = addClick;

        vm.confirmDeleteTrackingLink = confirmDeleteTrackingLink;
        vm.tableSize = tableSize;  // for trackinglinks

        // this is resolved from ui-router if any
        vm.clickCampaignId = clickCampaignId;
        vm.account = account;
        vm.start = moment().subtract(30, 'days').toDate();
        vm.end = moment().toDate();

        // reference data
        vm.refSitesForAccount = [];
        vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};

        // initial if creating new
        vm.clickCampaign = {
            accountId: account.id,
            active: true
        };

        vm.trackingLinks = [];

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(showInitialView);
        }

        function getInitialData() {
            return $q.all([getClickCampaign(), getTrackingLinks(), getSitesForAccount()]);
        }

        function getClickCampaign() {
            if (_.isNull(vm.clickCampaignId) || _.isEmpty(vm.clickCampaignId)) {
                // on create
                return vm.clickCampaign;
            } else {
                return ClickCampaignService.getClickCampaign(vm.account.id, vm.clickCampaignId);
            }
        }

        function getTrackingLinks() {
            if (!(_.isNull(vm.clickCampaignId) || _.isEmpty(vm.clickCampaignId))) {
                return TrackingLinkService.getClicks(vm.account.id, vm.clickCampaignId);
            }
        }

        function getSitesForAccount() {
            return SiteService.getSitesForAccount(vm.account.id);
        }

        function showInitialView(result) {
            vm.clickCampaign = result[0];
            vm.trackingLinks = result[1];
            vm.refSitesForAccount = result[2];
        }

        /// End init
        ///////////////
        function saveClickGroup() {
            return ClickCampaignService.createOrUpdateClickCampaign(vm.account.id, vm.clickCampaign)
                .then(function (data) {
                    vm.clickCampaign = data;
                    showSaveSuccess();
                    // refresh clickl;
                    //refreshOnSaveClicks();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Click Campaign successfully saved ');
            $state.go(STATE.CLICK_CAMPAIGN, {
                accountId: vm.account.id,
                clickCampaignId: vm.clickCampaign.id
            }, {notify: true});
        }

        function addClick() {
            $state.go(STATE.CLICK_CAMPAIGN_TRACKING_LINK, {
                accountId: vm.account.id,
                clickCampaignId: vm.clickCampaign.id
            }, {notify: true});
        }

        function tableSize() {
            if (vm.trackingLinks) {
                return vm.trackingLinks.length;
            }
        }


        function deleteTrackingLink(trackingLink) {
            return TrackingLinkService.deleteTrackingLink(vm.account.id, trackingLink.clickCampaignId, trackingLink.id)
                .then(function () {
                    logger.success('Tracking Link successfully deleted ');
                    getTrackingLinks().then( function( links ){
                        vm.trackingLinks = links;
                    } );
                });
        }

        function confirmDeleteTrackingLink(item){
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + item.name + ' Tracking Link?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteTrackingLink(item);
            });
        }
    }
})();