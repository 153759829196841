(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('firstFilter', ['$http', 'filterConstants', 'filterValidation',  FirstFilter]);

    function FirstFilter($http, filterConstants, filterValidation) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/filters/visit/firstFilter.html',
            link: function (scope) {
                scope.opers = filterConstants.operands;
                scope.isp = filterConstants.filters.FIRST;

                scope.operands = [
					scope.opers.EQUAL
                ];

				scope.booleanValue = false;

                if(scope.filter.data.value === undefined) {
                    scope.filter.data.value = false;
                }

                if(scope.filter.data.operand === undefined) {
                    scope.filter.data.operand = scope.opers.EQUAL;
                }

				scope.$watch('booleanValue', function (newValue, oldValue) {
					scope.filter.data.value = newValue;
				});
            }
        };
    }
})();