(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('AudiencesTableModel', ['$state', 'CommonService', 'cellRenderers', AudiencesTableModel]);

	function AudiencesTableModel($state, CommonService, cellRenderers) {
		var filterValue = '';

		function goToState(audienceId, accountId) {
			$state.go('admin.audience', {
				audienceId: audienceId,
				accountId: accountId
			})
		}

		function nameCellRendererFunc(params) {
			params.$scope.goToState = goToState;
			return '<a ng-click="goToState(data.id, data.accountId)" class="pointer"  ng-bind="data.name"></a>';
		}

		function setFilterValue(value) {
			filterValue = value;
		}

		var columnDefs = [
			{
				hide: true,
				headerName: 'LE Audience Id',
				field: 'id'
			},


			{
				minWidth: 168,
				field: 'name',
				headerName: 'Name',
				cellClass: 'searchable-cell',
				//cellRenderer: cellRenderers.PopupCellRenderer,
				// in v 18 filter: 'agTextColumnFilter',

				headerCheckboxSelectionFilteredOnly: true,
				cellRenderer: nameCellRendererFunc
				/*cellRendererParams: {
					popupGetter: function(params) {
						return [params.data.name];
					},
					filterValue: function() {
						return filterValue;
					},
					valueGetter: function(params) {
						return params.data.name;
					},
					hrefGetter: function (params) {
						cellRenderer: nameCellRendererFunc

								return '/#/accounts/' +
									params.data.accountId +
									'/audiences/' +
									params.data.id;

					}
				}*/
			},
			{
				minWidth: 108,
				headerName: 'Status',
				filter: 'agTextColumnFilter',
				valueGetter: function (params) {
					if (params.data.status === 'ACTIVE') {
						return 'Active';
					}
					if (params.data.status === 'NOT_ACTIVE') {
						return 'Not Active';
					}
				}

			},
			{
				hide: false,
				headerName: 'Segment size',
				field: 'totalSegmentSize',
				filter: 'agTextColumnFilter'
			},
			{
				hide: false,
				minWidth: 165,
				headerName: 'DMP Audience Id',
				field: 'externalAudienceId'
			},
			{
				hide: true,
				headerName: 'Account Id',
				field: 'accountId',
				filter: 'agTextColumnFilter'
			},
			{
				hide: false,
				headerName: 'CPM PRice',
				field: 'cpmPrice'
			},
			{
				hide: false,
				headerName: 'TTL',
				field: 'ttl'
			},
			{
				hide: false,
				headerName: 'Frequency',
				field: 'frequency'
			},
			{
				hide: false,
				headerName: 'Lookalike',
				field: 'lookALike'
			}

		];

		return {
			setFilterValue: setFilterValue,
			columnDefs: columnDefs
		};
	}
})();
