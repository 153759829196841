(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ClickCampaignsController', ['account', 'ClickCampaignService', 'TrackingLinkService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$q','TokenCommonService', '$uibModal', ClickCampaignsController]);

    function ClickCampaignsController(account, ClickCampaignService, TrackingLinkService, FileSaver, Blob, CONFIG, TableSort, logger, $q, TokenCommonService, $uibModal) {
        var vm = this;
        vm.confirmDelete = confirmDelete;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;

        vm.account = account;
        vm.token = null;
        vm.clickCampaigns = null;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;

        activate();

        function activate() {
            getInitialData().then(showInitialView);
        }

        function getInitialData() {
            return $q.all([getClickCampaigns(), countNumberOfClicksForAccount(), getTokenForAccountAndClickProductType(), countNumberOfTotalClickHitsForAccount()]);
        }

        function getClickCampaigns() {
            return ClickCampaignService.getClickCampaigns(vm.account.id);
        }

        function countNumberOfClicksForAccount() {
            return TrackingLinkService.countNumberOfClicksForAccount(vm.account.id);
        }

         function countNumberOfTotalClickHitsForAccount() {
            return TrackingLinkService.countNumberOfTotalClickHitsForAccount(vm.account.id);
        }

        function showInitialView(result) {
            vm.clickCampaigns = result[0];
            vm.noOfClicksForAccount = result[1];
            vm.token = result[2];
            if( !vm.token ){
                logger.info('Contact Leadenhancer to enable this product feature!!! ');
                return;
            }

            if (!vm.clickCampaigns.length) {
                logger.info('No Click Campaigns found!');
            }

            vm.noOfTotalClickHitsForAccount = result[3];
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'ClickCampaigns_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem(clickCampaignId) {
            if( !clickCampaignId  ){
                logger.error('Can not delete empty Click Campaign!!');
            }
            ClickCampaignService.deleteClickCampaign(vm.account.id, clickCampaignId)
                .then(function (clickCampaigns) {
                    vm.clickCampaigns = clickCampaigns;
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.clickCampaigns) {
                return vm.clickCampaigns.length;
            }
        }

        function getTokenForAccountAndClickProductType(){
            return TokenCommonService.getTokenForAccountAndProduct(vm.account.id, 'CLICK_TRACKING_OPEN_API');
        }


        function confirmDelete(itemId, name) {
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + name + ' Click Campaign and any Tracking Links with it ?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(itemId);
            });
        }
    }
})();