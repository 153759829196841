(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitsPieChart', [VisitsPieChart]);

    function VisitsPieChart() {
        return function (visits) {
            var that = this;

            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'pie',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 20,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 400
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.point.name + '</b>: ' + this.y + ' visits';
                        }
                    }
                },
                title: {
                    text: ''
                },
                series: [{
                    data: [],
                    size: '100%',
                    innerSize: '50%',
                    showInLegend: true,
                    dataLabels: {
                        formatter: function () {
                            return this.point.name + ' ' + this.y;
                        },
                        color: '#ffffff',
                        distance: -30
                    }
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                }
            };

            initChart(visits);

            function initChart(visits) {
                var numberOfVisitsPerCountry = _.chain(visits)
                    .groupBy(knownVisit)
                    .map(toChartItem)
                    .value();

                that.config.setData(numberOfVisitsPerCountry);
            }

            function knownVisit(visit) {
                return visit.organisation.duns ? 'Known' : 'Unknown';
            }

            function toChartItem(visits, knownOrUnknown) {
                return [knownOrUnknown, visits.length];
            }
        };
    }
})();
