(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SegmentFiltersController', ['account', 'SegmentFilterService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', SegmentFiltersController]);

    function SegmentFiltersController(account, SegmentFilterService, FileSaver, Blob, CONFIG, TableSort, logger) {
        var vm = this;
        vm.account = account;
        vm.segments = null;
        vm.ok = null;
        vm.searchFilter = '';
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });

        vm.inActivate = inActivate;
        vm.tableSize = tableSize;
        vm.getSegments = getSegments;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;
        vm.clone = clone;

        activate();

        function activate() {
            getSegments(vm.account.id);
        }

        function getSegments(accountId) {
            SegmentFilterService.getSegments(accountId)
                .then(function (segments) {
                    vm.segments = segments;
                    if (!vm.segments.length) {
                        logger.info('No segments found!');
                    }

                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Segments_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function inActivate( segmentId) {
            SegmentFilterService.inactivate(vm.account.id, segmentId)
                .then(function (segments) {
                    if( segments ){
                        vm.segments = segments;
                    }
                });
        }

         function clone( segmentId) {
            SegmentFilterService.clone(vm.account.id, segmentId)
                .then(function (segments) {
                    if( segments ){
                        vm.segments = segments;
                    }
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.segments) {
                return vm.segments.length;
            }
        }

    }
})();