(function () {
	'use strict';

	angular
		.module('adminApp')
		.directive('nameFilter', ['$http', '$timeout', '$animate', 'filterValidation', 'filterConstants', NameFilter]);


	function NameFilter($http, $timeout, $animate, filterValidation, filterConstants) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/organisation/nameFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.operands = [
					scope.opers.EQUAL,
					scope.opers.NOT_EQUAL,
					scope.opers.CONTAINS,
					scope.opers.DOES_NOT_CONTAINS,
					scope.opers.BEGIN_WITH,
					scope.opers.DOES_NOT_BEGIN_WITH
				];
				//$animate.enabled(true);


				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = '';
				}

				if (scope.filter.data.operand === undefined) {
					scope.filter.data.operand = scope.opers.CONTAINS;
				}

				scope.$watch('filter.data.value', function (newValue, oldValue) {
					if (newValue !== oldValue) {
						scope.filter.data.value = newValue;
					}
				});


/*				function isOperandListType(operand) {
					if (operand === undefined) {
						return false;
					}
					return operand === scope.opers.CONTAINS_ANY_IN_LIST ||
						operand === scope.opers.BEGINS_WITH_ANY_IN_LIST;
				}

				scope.$watch('filter.data.operand', function (newOperandValue, oldOperandValue) {
					var operandWasChanged = newOperandValue !== oldOperandValue;
					var valueIsArray = scope.filter.data.value !== undefined && Array.isArray(scope.filter.data.value);
					console.log('isOperandListType', isOperandListType(newOperandValue))
					if (operandWasChanged && isOperandListType(newOperandValue) && !isOperandListType(oldOperandValue)) {
						// when switching operand
						console.log('reset to  array')
						scope.filter.data.value = [];
					}
					if (operandWasChanged && !isOperandListType(newOperandValue) && isOperandListType(oldOperandValue)) {
						// when switching operand
						console.log('reset to  empty string')
						scope.filter.data.value = '';
					}
				});

 */

				scope.$on('$destroy', function () {
					$animate.enabled(true);
				});
			}
		};
	}

})();