(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminMailChimpService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AdminMailChimpService]);

    function AdminMailChimpService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getLists = getLists;
        this.getMembersInList = getMembersInList;

        function getLists() {
            return $http.get( CONFIG.API_PREFIX + '/admin/mc/lists' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getMembersInList(listId) {
            return $http.get( CONFIG.API_PREFIX + '/admin//mc/list/'+ listId + '/memebers' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();