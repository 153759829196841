(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('industryTagsFilter', [ '$animate', 'filterValidation', 'filterConstants', 'CommonService', IndustryTagsFilter]);


    function IndustryTagsFilter($animate, filterValidation, filterConstants, CommonService) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/industry/industryTagFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.INDUSTRY_TAGS;
				scope.operands = [
					scope.opers.IN,
					scope.opers.NOT_IN

				];


				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = [];
				}

				if(scope.filter.data.operand === undefined) {
					scope.filter.data.operand = scope.opers.IN;
				}


				scope.autoCompleteIndustryTags = function (query) {
					return _.filter(CommonService.getIndustryTagsNoIndicator(), function (tag) {
						if( tag.name.toLowerCase().indexOf(query.toLowerCase()) !== -1){
							return tag;
						}
					});
				};

				scope.tagAdded = function(tag){
					//scope.filter.data.value.push(tag.id);
				}
			}
		};
    }

})();