(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('positiveNegative', positiveNegativeFilter);

    function positiveNegativeFilter() {
        return function (value) {
            return parseFloat(value) > 0 ? '+' + value : value;
        };
    }

})();