var Highcharts = require('highcharts/highstock');

(function() {
	'use strict';

	angular.module('adminApp').service('VisitsMapChart', [VisitsMapChart]);

	function VisitsMapChart() {
		return function(visits) {
			var that = this;

			this.getNumberOfItems = getNumberOfItems;
			this.config = {
				options: {
					chart: {
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 0,
						spacingTop: 0,
						spacingLeft: 0,
						spacingRight: 20,
					},
					exporting: {
						enabled: false,
					},
					legend: {
						enabled: true,
					},
					colorAxis: {
						min: 0,
					},
					mapNavigation: {
						enabled: true,
						enableDoubleClickZoomTo: true,
					},
					plotOptions: {
						series: {
							animation: {
								duration: 400,
							},
						},
					},
				},
				chartType: 'map',
				title: {
					text: '',
				},
				series: [
					{
						data: [],
						nullColor: 'white',
						mapData: Highcharts.maps['custom/world'],
						joinBy: 'hc-key',
						name: 'Number of visits',
						states: {
							hover: {
								color: '#ffb752',
							},
						},
					},
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
				getData: function() {
					return that.config.series[0].data;
				},
			};

			initChart(visits);

			function initChart(visits) {
				var numberOfVisitsPerCountry = _.chain(visits)
					.groupBy(countryISO)
					.map(toChartItem)
					.value();

				that.config.setData(numberOfVisitsPerCountry);
			}

			function countryISO(visit) {
				return visit.countryIso;
			}

			function toChartItem(visits, countryIso) {
				return {
					'hc-key': countryIso,
					value: visits.length,
				};
			}

			function getNumberOfItems() {
				return that.config.getData().length;
			}
		};
	}
})();
