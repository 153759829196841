(function() {
	'use strict';

	angular.module('adminApp').service('OrganisationSizeBarChart', [OrganisationSizeBarChart]);

	function OrganisationSizeBarChart() {
		return function(visits) {
			var that = this;

			this.config = {
				options: {
					chart: {
						renderTo: 'container',
						type: 'column',
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 20,
						spacingTop: 20,
						spacingLeft: 20,
						spacingRight: 20,
					},
					exporting: {
						enabled: false,
					},
					plotOptions: {
						series: {
							animation: {
								duration: 400,
							},
						},
					},
					tooltip: {
						formatter: function() {
							return '<b>' + this.point.name + '</b>: ' + this.y + ' visits';
						},
					},
				},
				title: {
					text: '',
				},
				xAxis: {
					categories: [],
				},
				yAxis: {
					title: {
						text: '',
					},
					gridLineWidth: 0,
					minorGridLineWidth: 0,
				},
				series: [
					{
						data: [],
						size: '100%',
						showInLegend: false,
						dataLabels: false,
					},
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
				setXAxis: function(xAxis) {
					that.config.xAxis.categories = xAxis;
				},
			};

			initChart(visits);

			function initChart(visits) {
				var numberOfVisitsPerOrganisationSize = _.chain(visits)
					.sortBy(organisationSizeOrder)
					.groupBy(organisationSize)
					.map(toChartItem)
					.value();
				that.config.setData(numberOfVisitsPerOrganisationSize);

				var xAxis = _.chain(visits)
					.sortBy(organisationSizeOrder)
					.groupBy(organisationSize)
					.map(toXAxis)
					.value();
				that.config.setXAxis(xAxis);
			}

			function organisationSize(visit) {
				var noOfEmployees = visit.organisation.noOfEmployees;
				if (numberOfEmployeesUnknown(visit)) {
					return 'Unknown';
				} else if (noOfEmployees <= 9) {
					return '0-9 (Micro)';
				} else if (noOfEmployees <= 49) {
					return '10-49 (Small)';
				} else if (noOfEmployees <= 249) {
					return '50-249 (Medium)';
				} else if (noOfEmployees <= 499) {
					return '250-499 (Big)';
				} else if (noOfEmployees >= 500) {
					return '450- (Huge)';
				}
			}

			function organisationSizeOrder(visit) {
				// Return a very high number if noof employees is unknown to cause it to be sorted last
				return numberOfEmployeesUnknown(visit)
					? 99999999
					: visit.organisation.noOfEmployees;
			}

			function numberOfEmployeesUnknown(visit) {
				var noOfEmployees = visit.organisation.noOfEmployees;
				return noOfEmployees !== 0 && !noOfEmployees;
			}

			function toChartItem(visits, knownOrUnknown) {
				return [knownOrUnknown, visits.length];
			}

			function toXAxis(numberOfVisits, organisationSize) {
				return organisationSize;
			}
		};
	}
})();
