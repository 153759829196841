(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('hmenu', HMenu);

    function HMenu() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/hmenu.html',
            scope: {
                items: '='
            },
            link: function () {
            }
        };
    }
})();
