(function () {
	'use strict';

	angular
		.module('adminApp')
		.constant('CONFIG', {
			API_PREFIX: '/v3',
			API_PREFIX_V4: '/v4',
			SCROLL_STEP: 2,
			INFINITE_SCROLL_STEP: 20,

			// pertains to exporting an excel file from table data using FileSaver
			EXPORT_FILE_ENCODING: 'utf-8',
			EXPORT_FILE_TYPE: 'application/xml;charset=utf-8',
			MAX_VISITS_WHEN_EXPORTING_FROM_UI: 40000,

			// Enhancer settings
			ENHANCER_DEFAULT_COUNTRY: 'SE',
			ENHANCER_DEFAULT_QUEUE_LIMIT: 100,
		});
})();
