(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('textInput', ['$timeout', TextInput]);


    function getRestrictFn(type) {
        if(type.subtype === 'alpha') {
            return function (value) {
                return !/^[a-zA-Z]+$/.test(value);
            };
        } else if(type.type === 'money') {
            return function(value) {
                return !/^[0-9]+\.{0,1}[0-9]{0,2}$/.test(value);
            };
        } else if(type.type === 'number') {
            return function (value, min, max) {
                if (min !== undefined && max !== undefined) {
                    if (/^[0-9]*$/.test(value)) {
                        return !(Number(value) >= min && Number(value) <= max);
                    } else {
                        return true;
                    }
                } else {
                    return !/^[0-9]*$/.test(value);
                }
            };
        } else if(type.subtype === 'numerical' && type.type === 'string') {
            return function (value) {
                return !/^[0-9]*$/.test(value);
            };
        } else if(type.type === 'string' && type.subtype === 'alphaNumerical') {
            return function (value) {
                return !/^[a-zA-Z0-9 ]+$/.test(value);
            };
        } else {
            return function () {
                return false;
            };
        }
    }


    function TextInput() {
        return {
            controllerAs: 'textCtrl',
            controller: angular.noop,
            bindToController:true,
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/inputs/text-input.html',
            scope: {
                type: '=',
                value: '=?',
                placeholder: '=?',
                readonly: '=?',
                min: '=?',
                max: '=?'
            },
            link: function (scope, elem, attrs, textCtrl) {
                var vm = textCtrl;
                scope.vm = vm;
                if (vm.type.subtype === 'date') {
                } else {
                    var restrictFn = getRestrictFn(vm.type);
                    scope.$watch('vm.value', function(value, old) {
                        if(value !== undefined && restrictFn(value, vm.min, vm.max)) {
                            if (vm.value === null && vm.type.type === 'number' && vm.min !== undefined) {
                                vm.value = vm.min;
                            } else {
                                vm.value = old;
                            }
                        }
                    });
                }
            }
        };
    }

})();