(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TokenCommonService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', TokenCommonService]);

    function TokenCommonService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getTokenForAccountAndProduct = getTokenForAccountAndProduct;

        function getTokenForAccountAndProduct(accountId, productType) {
            return $http.get( CONFIG.API_PREFIX + '/accounts/' + accountId + '/tokens/products/type/' + productType)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();