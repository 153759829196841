(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('back', ['$window', backDirective]);

    function backDirective($window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                element.bind('click', function () {
                    $window.history.back();
                });
            }
        };
    }

})();