(function () {
	'use strict';

	angular
		.module('adminApp')
		.config(['$httpProvider', httpInterceptorConfig]);

	function httpInterceptorConfig($httpProvider) {
		$httpProvider.interceptors.push('httpRequestInterceptor');
	}

	angular
		.module('adminApp')
		.service('httpRequestInterceptor', ['responseExceptionCatcher', '$q', '$injector', '$templateCache',  HttpRequestInterceptor]);

	function HttpRequestInterceptor(responseExceptionCatcher, $q, $injector, $templateCache) {
		this.request = request;
		this.response = response;
		this.responseError = responseError;

		var ignoredErrorCodes = [400];
		var notAuthorizedCodes = [500, 401, -1];
		// METHOD_NOT_ALLOWED(405, "Method Not Allowed"),
		var accountmismatchCodes = 405;

		function request(config) {

			if ( config.url !== undefined &&  config.url.endsWith('html')  &&
				config.method === 'GET'
				&& $templateCache.get(config.url) === undefined) {
				config.url += '?ver=2' ;
				//console.log( 'html req', config.url )
			}


			config.url = encodeURI(config.url);
			config.requestTimestamp = new Date().getTime();
			config.timeout = 120 * 60 * 1000;



			return config;
		}

		function response(res) {
			res.config.responseTimestamp = new Date().getTime();
			// log response times...
			// console.debug('Getting ' + response.config.url + ' took ' + ((response.config.responseTimestamp - response.config.requestTimestamp) / 1000) + ' seconds.');
			return res;
		}

		function responseError(resError) {
			console.log('resp error ' + resError.status)
			if (statusInIgnoreList(resError)) {
				console.log('status ignored', resError.status);
				if (statusInNotAuthorizedList(resError)) {
					console.log('not authorzed' , resError.status);
					//  var logger = $injector.get('logger');
					//  logger.warning('Session has timed out or failed to login');
					var $state = $injector.get('$state');
					$state.go('login');
				}
				responseExceptionCatcher.catch('Unexpected Exception')(resError);
			} else if (accountMismatchExcpetion(resError)) {
				console.log('Account switched, reloading app', resError.status);
				var $location = $injector.get('$location');
				var $window = $injector.get('$window');
				$location.path('/dashboard');
				$window.location.reload();
			} else {
				if (!resError.config.dontFailOnError) {
					//console.log( 'HttpRequestInterceptor::responseError ' )
					var logger = $injector.get('logger');
					if (resError.data && resError.data.errors) {
						logger.error(resError.data.errors[0].description);
					}
				}
				return $q.reject(resError);
			}
		}

		// if true we have major problems and should show the error page
		function statusInIgnoreList(resError) {
			//console.log('statusInIgnoreList status ' + resError.status + ' return ' + ignore)
			return !_.includes(ignoredErrorCodes, resError.status)
				&& resError.status !== accountmismatchCodes;
		}

		function accountMismatchExcpetion(resError) {
			return resError.status === accountmismatchCodes;
		}

		function statusInNotAuthorizedList(resError) {
			//console.log(' loggedIn status ' + resError.status )
			return _.includes(notAuthorizedCodes, resError.status);
		}
	}

})();