(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('RuleGroup', [ RuleGroup]);

    function RuleGroup( ) {
        return function (condition, isRoot) {
            var that = this;
            this.condition = condition;  //  and, or, not
            this.rules = [];
            this.ruleGroups = [];
            this.parent = null;
            this.isRoot = !!isRoot;

            this.addRule = function addRule(rule) {
                that.rules.push(rule);
            };

            this.removeRule = function removeRule(currentRule) {
                this.rules = _.filter(this.rules, function(item){
                    return item !== currentRule;
                });
            };

            this.addRuleGroup = function addRuleGroup(currentRuleGroup, ruleGroup) {
                that.ruleGroups.push(ruleGroup);
            };

            this.removeRuleGroup = function removeRuleGroup(ruleGroup) {
                that.ruleGroups.push(ruleGroup);
            };

            this.getRuleGroups = function getRuleGroups() {
                return this.ruleGroups;
            };

            this.getRules = function getRules() {
                return this.rules;
            };

            this.changeConditionToAnd = function changeConditionToAnd() {
                that.condition = 'and';
            };

            this.changeConditionToOr = function changeConditionToOr() {
                that.condition = 'or';
            };

            this.changeConditionToNot = function changeConditionToNot() {
                that.condition = 'not';
            };

            this.isValid = function isValid() {
                return this.rules && this.rules.length > 0;
            };

        };
    }
})
();
