(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('DomainEnhanceCBITService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', DomainEnhanceCBITService]);

    function DomainEnhanceCBITService($http, $q, CONFIG, responseExceptionCatcher) {
        this.search = search;
        this.updateLEOrganisationFromDomainEnhancedData = updateLEOrganisationFromDomainEnhancedData;
        this.queryAndStoreDomainDataFromExternalSource = queryAndStoreDomainDataFromExternalSource;
        this.clearQ = clearQ;
        this.countAll = countAll;

        function search( country, limit, status, config) {
			var request = null;
        	if(country && country.countryISO){
        		request = 'countryISO=' + country.countryISO;
			}
        	if(limit){
        		request = request + '&limit=' + limit;
			}
        	if(status){
        		request = request + '&importStatus=' + status;
			}
			if(!request){
				return $http.get( CONFIG.API_PREFIX + '/enhance/domaincbit/search', config )
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			} else {
				return $http.get( CONFIG.API_PREFIX + '/enhance/domaincbit/search?' + request, config )
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			}
        }

        function updateLEOrganisationFromDomainEnhancedData( selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domaincbit/updateLEOrganisationFromDomainEnhancedData', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function queryAndStoreDomainDataFromExternalSource( selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domaincbit/queryAndStoreDomainDataFromExternalSource', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function clearQ( ) {
			return $http.post(CONFIG.API_PREFIX + '/enhance/domaincbit/clearQ')
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

		function countAll() {
			return $http.get(CONFIG.API_PREFIX + '/enhance/domaincbit/countAll')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}
    }
})();