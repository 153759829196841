// Polyfill
require('babel-polyfill');

// External libs
const angular = require('angular');
require('jquery');
require('jquery-ui');
require('angular-ui-router');
require('angular-messages');
require('angular-ui-bootstrap');
require('angulartics');
//require('angulartics-google-analytics');
require('angulartics-google-tag-manager');
require('angular-animate');
require('angular-file-saver');
const Highcharts = require('highcharts/highstock');
require('highcharts/modules/map')(Highcharts);
require('highcharts/modules/funnel')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('../lib/highcharts/mapdata/custom/world')(Highcharts);
//require('../lib/highcharts/mapdata/custom/world-highres')(Highcharts);
require('highcharts-ng');
require('angular-scroll');
require('ng-infinite-scroll');
require('angular-translate');
require('angular-translate-loader-static-files');
require('angular-file-upload');
require('angular-sanitize');
require('ng-tags-input');
require('angular-confirm');
require('angular-hamburger-toggle');
require('angular-ui-sortable');
require('angular-ui-validate');
require('angular-toastr');
require('angular-simple-logger');
require('angular-google-maps');
require('ngstorage');
require('angular-ui-switch');
require('angular-chosen-localytics');
require('checklist-model');
require('angular-click-outside');
require('angularjs-slider');
require('angularjs-datetime-picker');
require('angular-timezone-selector');
require('angular-jquery-timepicker');
require('ui-select');
//require('angular-contenteditable');
require('moment');
require('moment-timezone');
require('mark.js/dist/jquery.mark.js');
const agGrid = require('ag-grid');
agGrid.initialiseAgGridWithAngular1(angular);

require('bootstrap');
require('angular-ui-switch/angular-ui-switch.css');
require('ui-select/dist/select.css');
require('!style-loader!css-loader!ag-grid/dist/styles/ag-grid.css');

const app = angular.module('adminApp', [
	'ngMessages',
	'ui.router',
	'ui.bootstrap',
	'ngAnimate',
	'ngFileSaver',
	'highcharts-ng',
	'duScroll',
	'infinite-scroll',
	'pascalprecht.translate',
	'angularFileUpload',
	'ngTagsInput',
	'angular-confirm',
	'ngHamburger',
	'ui.sortable',
	'ui.validate',
	'toastr',
	'nemLogging',
	'uiGmapgoogle-maps',
	'ngStorage',
	'uiSwitch',
	'localytics.directives',
	'checklist-model',
	'tw.directives.clickOutside',
	'rzModule',
	'angularjs-datetime-picker',
	'angular-timezone-selector',
	'agGrid',
	'ui.timepicker',
	'ui.select',
	//'contenteditable',
	'ngSanitize',
	'angulartics',

	//'angulartics.google.analytics'
	'angulartics.google.tagmanager'


]);

// LE AngularJS
require('routes.config');
require('common/states');
require('common/common.service');
require('common/confirm.controller');
require('common/config.constant');
//require('common/locale.util');
require('common/http-request-interceptor.config');
require('common/exception-handler.config');
require('common/response-exception-catcher.factory');
require('common/loading.directive');
require('common/form/date-spinner.directive');
require('common/back.directive');
require('common/positive-negative.filter');
require('common/positive-negative.directive');
require('common/yes-no.filter');
require('common/page-state.filter');
require('common/user-status-template.filter');
require('common/title-case.filter');
require('common/report-schedule.filter');
require('common/report-template.filter');
require('common/logging/toastr.config');
require('common/logging/logger.factory');
require('common/form/form-input.directive');
require('common/moment.factory');
require('common/slide-toggle.animation');
require('common/tablesort/sort.model');
require('common/tablesort/table-sort.directive');
require('common/dateutils.service');
require('common/join-by.filter');
require('common/auth/auth.directive');
require('common/auth/auth.service');
require('common/draggable.directive');
require('common/form/form-on-change');
const NoDataComponent = require('common/no-data.component');
require('common/number-decimals.filter');
require('common/producttype.filter');
//require('common/modal-window.directive');
//require('common/modal-window.service');

require('common/guid-util.service');
require('common/organisations/organisation.controller');
require('common/organisations/organisation.service');
require('common/navigation.controller');
require('common/inline-filter.directive');
//require('common/table-component/list-filter.directive');
//require('common/table-component/sticky-header.directive');
//require('common/table-component/column-settings.directive');
require('common/table-component/checkbox.directive');
//require('common/table-component/le-table.directive');
require('common/table-component/table-component.directive');
require('common/table-component/table-component-header.directive');
require('common/table-component/table-component-settings.directive');
require('common/table-component/table-component-filter.directive');
require('common/table-component/cell-renderers.service');
require('common/hmenu.directive');
require('common/topmenu.directive');
require('common/buttons.directive');
require('common/page-content.directive');
require('common/anchormenu.directive');

require('common/sticky.directive');

require('common/dropdown-select-input.directive');

//require('translations/locale-finder.service');
//	require('translations/translate.config');

require('dashboard/dashboard.controller');
require('dashboard/dashboard.service');
require('common/country.service');
require('dashboard/visits-mapchart.model');
require('dashboard/visits-piechart.model');
require('dashboard/visits-areachart.model');
require('dashboard/industries-piechart.model');
require('dashboard/organisation-size-barchart.model');
require('dashboard/top-score-organisation-barchart.model');
require('dashboard/unique-visits-funnelchart.model');
require('dashboard/top-countries-barchart.model');
require('dashboard/top-referrer-piechart.model');
require('dashboard/widget-order-local-storage.service');

require('discover/visits/visits.controller');
require('discover/visits/visit.controller');
require('discover/visits/visit.service');
require('discover/visits/visits.model');
require('discover/visits/visit-lat-long-mapchart.model');

require('discover/visitcookietags/visitcookie.service');
require('discover/visitcookietags/visitcookies.controller');

require('targeting/campaigns/campaign-revenuemodels.controller');
require('targeting/campaigns/campaign-revenuemodel.controller');
require('targeting/campaigns/campaign.controller');
require('targeting/campaigns/campaign.service');
require('targeting/campaigns/campaign-revenuemodel.controller');
require('targeting/campaigns/campaigns.controller');
require('targeting/campaigns/campaignAdform.controller');
require('targeting/segment/segmentfilters.controller');
require('targeting/segment/segmentfilter.service');
require('targeting/segment/segmentfilter.controller');
require('targeting/campaigns/campaign-revenuemodels.controller');
require('targeting/targetingdashboard.service');


require('targeting/adaptivecontent/adaptivecontent.service');
require('targeting/adaptivecontent/adaptivecontents.controller');
require('targeting/adaptivecontent/adaptivecontent.controller');
require('targeting/adaptivecontent/adaptivecontentstatistics.controller');
require('targeting/adaptivecontent/adaptivecontentstatistics.chart');


require('targeting/campaigns/advanced/campaigns-advanced.controller');
require('targeting/campaigns/advanced/campaign-advanced.service');
require('targeting/campaigns/advanced/campaigns-advanced-table-model.service.js');


require('targeting/audiences/audiences.controller');
require('targeting/audiences/audience.service');
require('targeting/audiences/audience.controller');
require('targeting/audiences/audiences-table-model.service');
require('targeting/audiences/confirm-new-category.controller');
require('targeting/audiences/confirm-publish-audience-modal.controller');


require('targeting/beta/campaigns/campaigns.controller');
require('targeting/beta/campaigns/campaignsTableModel.service');
require('targeting/beta/campaigns/campaign.controller');

require('targeting/beta/campaigns/campaignSettings.controller');
require('targeting/beta/campaigns/campaignTargeting.controller');
require('targeting/beta/campaigns/campaignProperties.controller');
require('targeting/beta/campaigns/campaign.service');
require('targeting/beta/campaigns/campaign.converter');
require('targeting/beta/campaigns/campaign.constants');
require('targeting/beta/campaigns/common/schedule-table.directive');
require('targeting/beta/campaigns/common/payment-popup.directive');
require('targeting/beta/campaigns/common/targeting-map.directive');
require('targeting/beta/campaigns/common/catalog-search.directive');


require('targeting/segment/advanced/segmentfilter.service');

require('targeting/segment/advanced/segments/segmentfilters.controller');
require('targeting/segment/advanced/segments/segmentfilters-table-model');
require('targeting/segment/advanced/segments/standardsegmentfilters-table-model');

require('targeting/segment/advanced/segmentfilter.constants');
require('targeting/segment/advanced/segmentfilter.converter');
require('targeting/segment/advanced/segmentfilter.controller');
require('targeting/segment/advanced/segmentfilter-list.controller');
require('targeting/segment/advanced/segmentfilter-search.controller');
require('targeting/segment/advanced/segmentfilter-settings.controller');
require('targeting/segment/advanced/segmentfilter-search-results-table-model');
require('targeting/segment/advanced/segmentfilter-importorganisations.controller');
require('targeting/segment/advanced/segmentfilter-show-organisations.controller');


// Common filter directives for segments and visits
require('common/advancedfilters/filter-accordion.directive');
require('common/advancedfilters/filter.constants');
require('common/advancedfilters/filter-block-builder.directive');
require('common/advancedfilters/inputs/dropdownSelect.directive');
require('common/advancedfilters/inputs/rangeInput.directive');
require('common/advancedfilters/inputs/textInput.directive');
require('common/advancedfilters/inputs/autocompleteInput.directive');
require('common/advancedfilters/inputs/tagsAutocompleteInput.directive');
require('common/advancedfilters/inputs/toggleInput.directive');
require('common/advancedfilters/inputs/switchInput.directive');
require('common/advancedfilters/inputs/filterValidation.service');
require('common/advancedfilters/dragging.directive');
require('common/advancedfilters/dragging.service');
require('common/advancedfilters/filterModel.service');
require('common/advancedfilters/logicJoint.directive');
require('common/advancedfilters/filters/organisation/nameFilter.directive');
require('common/advancedfilters/filters/organisation/domainNameFilter.directive');
require('common/advancedfilters/filters/organisation/organisationIdFilter.directive');
require('common/advancedfilters/filters/organisation/leadenhancerIdFilter.directive');
require('common/advancedfilters/filters/organisation/DUNSFilter.directive');
require('common/advancedfilters/filters/organisation/organisationTypesFilter.directive');
require('common/advancedfilters/filters/organisation/numberOfEmployeesFilter.directive');
require('common/advancedfilters/filters/organisation/enhancementScoreRangeFilter.directive');
require('common/advancedfilters/filters/organisation/salesFilter.directive');
require('common/advancedfilters/filters/geography/cityFilter.directive');
require('common/advancedfilters/filters/geography/countryFilter.directive');
require('common/advancedfilters/filters/geography/regionFilter.directive');
require('common/advancedfilters/filters/geography/zipCodeFilter.directive');
require('common/advancedfilters/filters/geography/postalCodeFilter.directive');
require('common/advancedfilters/filters/industry/ispFilter.directive');
require('common/advancedfilters/filters/industry/industryTagsFilter.directive');
require('common/advancedfilters/filters/industry/sicCodeRangeFilter.directive');
require('common/advancedfilters/filters/industry/networksSizeFilter.directive');
require('common/advancedfilters/filters/industry/naicsCodeRangeFilter.directive');
require('common/advancedfilters/filters/industry/gicsCodeRangeFilter.directive');
require('common/advancedfilters/filters/industry/sniNaceCodeRangeFilter.directive.js');
require('common/advancedfilters/filters/visit/pageKeywordsFilter.directive');
require('common/advancedfilters/filters/visit/firstFilter.directive');
require('common/advancedfilters/filters/visit/scoreRangeFilter.directive');
require('common/advancedfilters/filters/visit/refererFilter.directive');
require('common/advancedfilters/filters/visit/pagesFilter.directive');





require('discover/visitfilters/advanced/visitfilters.controller');
require('discover/visitfilters/advanced/visitfilter.service');
require('discover/visitfilters/advanced/visitfilters-table-model');
require('discover/visitfilters/advanced/visitfilter-search-results-table-model');
require('discover/visitfilters/advanced/visitfilter.constants');
require('discover/visitfilters/advanced/visitfilter.converter');
require('discover/visitfilters/advanced/visitfilter.controller');
require('discover/visitfilters/advanced/visitfilter-list.controller');
require('discover/visitfilters/advanced/visitfilter-search.controller');
require('discover/visitfilters/advanced/visitfilter-settings.controller');
//require('discover/visitfilters/advanced/visitfilters/visitfilter.accordion.constants');
require('discover/visitfilters/advanced/visitfilter.importorganisations.controller');


require('discover/reports/report-example.controller');
require('discover/reports/report.service');
require('discover/reports/reports.controller');
require('discover/reports/report.controller');
require('discover/reports/reports.history.controller');
require('discover/pages/page.service');
require('discover/pages/pages.controller');
require('discover/pages/page.controller');
require('discover/pages/pagekeywords.controller');
require('discover/pages/pagekeyword.service');
require('discover/pages/pagekeyword.controller');
require('discover/pages/pagequeries.controller');
require('discover/pages/pagequery.controller');
require('discover/pages/pagequery.service');
require('discover/visitors/visitor.service');
require('discover/visitors/visitors.controller');
require('discover/visitors/visitor-visits.controller');

require('admin/logging/admin.logs.controller');
require('admin/logging/admin.logs.service');

require('admin/reselleraccounts/reselleraccount.service');
require('admin/reselleraccounts/reselleraccounts.controller');
require('admin/reselleraccounts/reselleraccount.controller');
require('admin/reselleraccounts/reselleraccountswitch.controller');

require('admin/analytics/analytics.controller');
require('admin/analytics/analytics.service');

require('admin/sites/site.service');
require('admin/sites/sites.controller');
require('admin/sites/site.controller');

require('admin/segments/admin.segment.service');
require('admin/segments/admin.segments.controller');

require('admin/mailchimp/admin.mc.service');
require('admin/mailchimp/admin.mclistmembers.controller');
require('admin/mailchimp/admin.mclists.controller');

require('admin/bidtheatre/admin.bt.clients.controller');
require('admin/bidtheatre/admin.bt.advertisers.controller');
require('admin/bidtheatre/admin.bt.campaigns.controller');
require('admin/bidtheatre/admin.bt.advertiser.lineitems.controller');
require('admin/bidtheatre/admin.bt.service');

require('admin/accounts/account.controller');
require('admin/accounts/accounts.controller');
require('admin/accounts/account.service');

require('admin/organisations/statistics/organisationstatistics.controller');
require('admin/organisations/statistics/organisationstatistics.service');
require('admin/database/admin.database.controller');
require('admin/database/admin.database.service');

require('admin/products/product.controller');
require('admin/products/product.service');

require('admin/tokens/admin.token.controller');
require('admin/tokens/admin.token.service');
require('admin/tokens/admin.tokens.controller');
require('common/tokens/token.common.service');

require('common/login.controller');
require('common/resetpwd.controller');

require('common/account.selector.controller');

require('admin/users/user.controller');
require('admin/users/users.controller');
require('admin/users/user.service');
require('admin/users/userauditlog.controller');
require('admin/users/userauditlog.service');
require('admin/users/userloginstatisticslog.controller');
require('admin/users/userloginstatisticslog.service');

require('discover/visitfilters/visitfilters.controller');
require('discover/visitfilters/visitfilter.controller');
require('discover/visitfilters/visitfilter.service');
require('discover/visitfilters/countries.filter');

require('discover/customvariables/customavariable.service');
require('discover/customvariables/customvariables.controller');

require('discover/clicks/clickcampaign.controller');
require('discover/clicks/clickcampaign.service');
require('discover/clicks/clickcampaigns.controller');
require('discover/clicks/trackinglink.controller');
require('discover/clicks/trackinglink.service');

require('discover/excludedorganisations/excludedorganisations.controller');
require('discover/excludedorganisations/excludedorganisation.service');

require('settings/user/usersetting.controller');
require('settings/user/userpasswordreset.controller');
require('settings/user/usersettings.service');

require('connect/token.service');
require('connect/tokens.controller');
require('connect/tokenstatistics.controller');
require('connect/tokenstatistics.model');

require('monitor/tracking/dailyTrafficForSiteDuringPeriod-controller');
require('monitor/tracking/totalTrafficGroupedPerAccount-controller');
require('monitor/tracking/trackingstatistics.service');

require('enhance/clearbit/clbitorganisation.controller');
require('enhance/clearbit/clbitorganisations.controller');
require('enhance/clearbit/clbitorganisations.service');
require('enhance/nbk/nbkorganisations.controller');
require('enhance/nbk/nbkorganisations.service');
require('enhance/ripe/ripeorganisations.controller');
require('enhance/ripe/ripeorganisations.service');
require('enhance/enhancementqueue.controller');
require('enhance/enhancementqueue.service');
require('enhance/enhancedorganisation.controller');
require('enhance/enhancedorganisation.service');
require('enhance/enhancementstatistics.controller');
require('enhance/enhancementstatisticsbyusername.controller');
require('enhance/enhancersettings.service');
require('enhance/top-enhancers-barchart.model');
require('enhance/leadenhancer/leorganisation.controller');
require('enhance/leadenhancer/leorganisations.controller');
require('enhance/leadenhancer/leorganisations.service');
require('enhance/leadenhancer/leorganisations.batchupload.controller');
require('enhance/enhancementOrganisationISPs.controller');
require('enhance/enhancementOrganisationISP.service');
require('enhance/industrycodes/industrycodes.service');
require('enhance/industrycodes/industrycodesSIC.controller');
require('enhance/industrycodes/industrycodesNaceToSic.controller');
require('enhance/industrycodes/industrycodesSNI.controller');
require('enhance/industrycodes/industrycodesNAICS.controller');
require('enhance/industrycodes/industrycodesGICS.controller');
require('enhance/industrycodes/industryTags.controller');
require('enhance/dob/doborganisation.controller');
require('enhance/dob/doborganisations.controller');
require('enhance/dob/doborganisations.service');
require('enhance/domain/domainenhance.controller');
require('enhance/domain/domainenhance.service');
require('enhance/clearbit/queue/domainenhancecbit.controller');
require('enhance/clearbit/queue/domainenhancecbit.service');
require('enhance/nbk/queue/queue-nbk-enhance.controller');
require('enhance/nbk/queue/queue-nbk-enhance.service');

require('common/querybuilder/Rule');
require('common/querybuilder/RuleGroup');
require('common/querybuilder/QueryBuilder');
require('common/querybuilder/RULE_FIELDS');
require('common/querybuilder/RULE_OPERATORS');
require('common/querybuilder/RULE_TYPES');

function requireAll(r) {
	r.keys().forEach(r);
}
requireAll(require.context('../css/', true, /\.css$/));

app.run([
	'$rootScope',
	'$state',
	'CommonService',
	'CountryService',
	function($rootScope, $state, CommonService, CountryService) {
		$rootScope.$on('$stateChangeStart', function(evt, to, params) {
			if (to.redirectTo) {
				evt.preventDefault();
				$state.go(to.redirectTo, params, { location: 'replace' });
			}
		});

		CommonService.init();

		CountryService.init();
	}
]);

app.component('noData', NoDataComponent);

console.log('LE Admin has bootstrapped');
