(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('SegmentAdvancedFiltersController', ['$element', '$timeout',
			'$q', '$scope', '$state', '$window', 'logger', 'account', 'SegmentAdvancedFilterService',
			'SegmentFiltersTableModel',
			'StandardSegmentFiltersTableModel',
			'$uibModal',
			SegmentAdvancedFiltersController]);

	function SegmentAdvancedFiltersController($element,
											  $timeout,
											  $q,
											  $scope,
											  $state,
											  $window,
											  logger,
											  account,
											  SegmentAdvancedFilterService,
											  SegmentFiltersTableModel,
											  StandardSegmentFiltersTableModel,
											  $uibModal) {

		var vm = this;

		vm.doExportToExcel = doExportToExcel;
		vm.confirmDelete = confirmDelete;
		vm.confirmCopyStandardSegments = confirmCopyStandardSegments;
		vm.confirmEnhanceDomain = confirmEnhanceDomain;
		vm.confirmEnhanceDomainEnhancementCBIT = confirmEnhanceDomainEnhancementCBIT;
		vm.segmentsAdvancedCreateRequestForNBKEnhancement = segmentsAdvancedCreateRequestForNBKEnhancement;
		vm.confirmClone = confirmClone;
		vm.convertOldToNew = convertOldToNew;

		vm.checkedIds = [];
		vm.checkedIdsNumber = 0;
		vm.selectedNodes = [];
		vm.selectedStandardSegmentNodes = [];
		vm.account = account;

		// filter search
		vm.accountSegmentsSearch = '';
		vm.standardSegmentSearch = '';

		vm.segmentsTable = {
			defaultColDef: {
				maxWidth: 400,
				minWidth: 40
			},
			columnDefs: SegmentFiltersTableModel.columnDefs,
			enableFilter: true,
			animateRows : true,
			angularCompileRows: true,
			isExternalFilterPresent: isExternalFilterPresent,
			doesExternalFilterPass: doesExternalFilterPass,
			onRowsNumberChanged: function (rowsNumber) {
				vm.numberOfRowsSegments = rowsNumber;
				accountSegmentFilterChanged();
			},
			onPostInit: function (rowsNumber) {
				vm.numberOfRowsSegments = rowsNumber;
			},
			getFn: function () {
				return SegmentAdvancedFilterService.getSegments(account.id);
			},
			onDisplayedColumnsChanged: function () {
				resizeColumns();
			}
		};

		vm.standardSegmentsTable = {
			defaultColDef: {
				maxWidth: 400,
				minWidth: 40
			},
			columnDefs: StandardSegmentFiltersTableModel.columnDefs,
			enableFilter: true,
			animateRows : true,
			angularCompileRows: true,
			isExternalFilterPresent: isStandardExternalFilterPresent,
			doesExternalFilterPass: doesStandardExternalFilterPass,
			onRowsNumberChanged: function (rowsNumber) {
				vm.numberOfRowsStandardSegments = rowsNumber;
				standardSegmentFilterChanged();
			},
			onPostInit: function (rowsNumber) {
				vm.numberOfRowsStandardSegments = rowsNumber;
			},
			getFn: function () {
				return SegmentAdvancedFilterService.getStandardSegments(account.id);
			},
			onDisplayedColumnsChanged: function () {
				resizeStandardSegmentsColumns();
			}
		};


		vm.panelHeights = 120;
		var $segmentTable = $('.segments-table');

		$timeout(function () {
			resizeHeight();
		});


		vm.segmentsSearchChanged = function () {
			SegmentFiltersTableModel.setFilterValue(vm.accountSegmentsSearch);
			vm.segmentsTable.grid.api.onFilterChanged();
		};

		vm.standardSegmentSearchChanged = function () {
			SegmentFiltersTableModel.setFilterValue(vm.standardSegmentSearch);
			vm.standardSegmentsTable.grid.api.onFilterChanged();
		};

		$scope.$on('$destroy', onDestroy);
		angular.element($window).bind('resize', _.debounce(onResize, 250));

		function confirmDelete() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to delete the selected Segment(s)? Any of the Segments' +
							' part of a Camppaign can not be deleted.',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				deleteSelected();
			});
		}

		function confirmClone() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to clone the selected Segment(s)?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				cloneSelected();
			});
		}

		function confirmCopyStandardSegments() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to clone the selected Standard Segment(s)?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				copyStandardSegmentsToAccount();
			});
		}

		function confirmEnhanceDomain() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for domain enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainSelected();
			});
		}

		function confirmEnhanceDomainEnhancementCBIT() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for domain enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainCBITSelected();
			});
		}

		function segmentsAdvancedCreateRequestForNBKEnhancement() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for NBK enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainNBKSelected();
			});
		}

		function onDestroy() {
			angular.element($window).off('resize');
		}

		function onResize() {
			resizeColumns();
			resizeHeight();
		}

		function resizeColumns() {
			vm.segmentsTable.grid.api.sizeColumnsToFit();
		}

		function resizeStandardSegmentsColumns() {
			vm.standardSegmentsTable.grid.api.sizeColumnsToFit();
		}

		function resizeHeight() {
			if ($(window).height() > 600) {
				$segmentTable.css('height', $(window).height() - vm.panelHeights);
			} else {
				$segmentTable.css('height', 800 - vm.panelHeights);
			}
		}

		function isExternalFilterPresent() {
			return vm.accountSegmentsSearch.length > 0;
		}

		function doesExternalFilterPass(node) {
			var query = vm.accountSegmentsSearch.toLowerCase();
			return node.data.name.toLowerCase().indexOf(query) >= 0 ||
				node.data.userName.toLowerCase().indexOf(query) >= 0 ||
				_.map(node.data.campaigns, 'name').join(', ').toLowerCase().indexOf(query) >= 0 ||
				node.data.id.toLowerCase().indexOf(query) >= 0;
		}

		function isStandardExternalFilterPresent() {
			return vm.standardSegmentSearch.length > 0;
		}

		function doesStandardExternalFilterPass(node) {
			var query = vm.standardSegmentSearch.toLowerCase();
			return node.data.name.toLowerCase().indexOf(query) >= 0 ||
				node.data.userName.toLowerCase().indexOf(query) >= 0 ||
				_.map(node.data.campaigns, 'name').join(', ').toLowerCase().indexOf(query) >= 0 ||
				node.data.id.toLowerCase().indexOf(query) >= 0;
		}

		function accountSegmentFilterChanged() {
			var sel = $element.find('.searchable-cell');
			if (vm.accountSegmentsSearch) {
				sel.unmark();
				sel.mark(vm.accountSegmentsSearch);
			} else {
				sel.unmark();
			}
		}

		function standardSegmentFilterChanged() {
			var sel = $element.find('.searchable-cell');
			if (vm.standardSegmentSearch) {
				sel.unmark();
				sel.mark(vm.standardSegmentSearch);
			} else {
				sel.unmark();
			}
		}

		function deleteSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.deleteSegments(account.id, segmentsIds)
				.then(function () {
					$scope.$broadcast('updateTable');
				});
		}

		function enhanceDomainSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.enhanceDomain(account.id, segmentsIds)
				.then(function (response) {
					if( response === 0 ){
						logger.error('Nothing was added. ISPS and already enhanced domains are not added to Q ' );
					} else {
						logger.success('Segment added to domain Q - items created ' + response);
					}

				});
		}

		function enhanceDomainCBITSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.enhanceDomainEnhancementCBITQueue(account.id, segmentsIds)
				.then(function (response) {
					if(response === 0){
						logger.error('Nothinh added - ISPs and already enhanced are filtered out');
					} else {
						logger.success('Segment added to enhancment Q - items created ' + response);
					}
				});
		}

		function enhanceDomainNBKSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.segmentsAdvancedCreateRequestForNBKEnhancement(account.id, segmentsIds)
				.then(function (response) {
					if(response === 0){
						logger.error('Nothinh added - ISPs and already enhanced are filtered out');
					} else {
						logger.success('Segment added to enhancment NBK Q - items created ' + response);
					}
				});
		}

		function cloneSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.cloneSegments(account.id, segmentsIds)
				.then(function (result) {
					logger.success('You have created clone(s).');
					$scope.$broadcast('updateTable', result);
				});
		}

		function copyStandardSegmentsToAccount() {
			var segmentsIds = _.map(vm.selectedStandardSegmentNodes, function (node) {
				return node.data.id;
			});
			SegmentAdvancedFilterService.copyStandardSegmentsToAccount(account.id, segmentsIds)
				.then(function (result) {
					logger.success('You have copied Standard Segment(s) to your Accounts');
					$scope.$broadcast('updateTable', result);
				});
		}

		function doExportToExcel() {
			var columnKeys = _.map(vm.segmentsTable.grid.columnApi.getAllDisplayedColumns(), 'colId').slice(0, -1);
			vm.segmentsTable.grid.api.exportDataAsCsv({
				columnKeys: columnKeys,
				allColumns: false
			});
		}


		function convertOldToNew() {
			return SegmentAdvancedFilterService.convertOldToNew(vm.account.id)
				.then(function (data) {
					logger.info('Converted all old Segment to new ones')
				});
		}


	}
})();