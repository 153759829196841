(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive(	'tableComponent', ['$interpolate', '$compile',
            function TableComponent($interpolate, $compile) {
            return {
                restrict: 'E',
                templateUrl: 'partials/common/table-component/table-component.html',
                scope: {
                    options: '=',
                    quickFilter: '=?',
                    selectedNodes: '=?'
                },
                controller: function ($scope) {
                    $scope.$watch('quickFilter', function(newVal) {
                        if (newVal !== '') {
                            $scope.grid.setQuickFilter(newVal);
                        }
                    });

                    $scope.$on('exportDataAsExcel', function() {
                        $scope.grid.exportDataAsExcel();
                    });

                    $scope.$on('updateTable', function(something, data) {
                        $scope.grid.onGridReady(data);
                        $scope.selectedNodes = [];
                    });

                    function HeaderComponent() {
                    }

                    HeaderComponent.prototype.init = function (params) {
                        this.scope = $scope.$new();
                        this.scope.params = params;
                        this.el = $compile('<table-component-header></table-component-header>')(this.scope);
                    };

                    HeaderComponent.prototype.getGui = function () {
                        return this.el.get(0);
                    };

                    HeaderComponent.prototype.destroy = function () {
                    };

                    function SettingsComponent() {
                    }

                    SettingsComponent.prototype.init = function (params) {
                        this.scope = $scope.$new();
                        this.scope.params = params;
                        this.el = $compile('<table-component-settings></table-component-settings>')(this.scope);
                    };
                    SettingsComponent.prototype.getGui = function () {
                        return this.el.get(0);
                    };

                    SettingsComponent.prototype.isFilterActive = function () {
                    };

                    SettingsComponent.prototype.doesFilterPass = function () {
                        return true;
                    };

                    SettingsComponent.prototype.destroy = function () {};
                    SettingsComponent.prototype.getModel = function () {};
                    SettingsComponent.prototype.setModel = function() {};


                    function FilterComponent() {
                    }

                    FilterComponent.prototype.init = function(params) {
                        this.scope = $scope.$new();
                        this.grid = $scope.grid;
                        this.scope.params = params;
                        this.el = $compile('<table-component-filter></table-component-filter>')(this.scope);
                    };

                    FilterComponent.prototype.getGui = function () {
                        return this.el.get(0);
                    };

                    FilterComponent.prototype.isFilterActive = function () {
                        return this.scope.filterValue.length > 0;
                    };

                    FilterComponent.prototype.doesFilterPass = function(params) {
                        var value = this.scope.params.valueGetter(params);
                        if(value) {
                            return value.toString().toLowerCase() === this.scope.filterValue.toLowerCase();
                        } else {
                            return false;
                        }
                    };

                    FilterComponent.prototype.getModel = function () {};
                    FilterComponent.prototype.setModel = function() {};

                    function TableComponent(options) {
                        this.getFn = options.getFn;
                        _.defaultsDeep(this, options, this.defaults);

                        // unshift -> put it in the first column, push -> last
                        this.columnDefs.unshift({
                            maxWidth: 70,
							minWidth: 70,
                            suppressSorting: true,
                            headerComponentParams: {
                                'settingsColumn': true
                            },
                            filter: SettingsComponent
                        });


                    }

                    TableComponent.prototype.defaults = {
                        debug: false,
                        headerHeight: 40,
                        rowHeight: 50,
                        enableFilter: true,
                        enableSorting: true,
                        enableColResize: true,
                        suppressMovableColumns: true,
                        rowSelection: 'multiple',
                        suppressRowClickSelection: true,
                        rowData: [],
                        columnDefs: [],
                        defaultColDef: {
                            headerComponent: HeaderComponent,
                            checkboxSelection: function (params) {
                                var displayedColumns = params.columnApi.getAllDisplayedColumns();
                                return displayedColumns[0] === params.column;
                            },
                            headerCheckboxSelection: function(params) {
                                var displayedColumns = params.columnApi.getAllDisplayedColumns();
                                return displayedColumns[0] === params.column;
                            },
                            filter: FilterComponent
                        },
                        icons: {
                            checkboxChecked: '<span class="ag-checkbox ag-checkbox-checked"></span>',
                            checkboxUnchecked: '<span class="ag-checkbox ag-checkbox-unchecked"></span>',
                            checkboxIndeterminate: '<span class="ag-checkbox ag-checkbox-unchecked"></span>'
                        }
                    };

                    TableComponent.prototype.getNumberOfRows = function () {
                        var count = 0;
                        this.api.forEachNodeAfterFilter(function () {
                            count+=1;
                        });
                        return count;
                    };

                    TableComponent.prototype.onGridReady = function (data) {
                        this.api.sizeColumnsToFit();
                        if (data && data.data) {
                            this.api.setRowData(data.data);
                            this.postInit();
                        } else {
                            this.getFn().then(function (results) {
                                this.api.setRowData(results);
                                this.postInit();
                            }.bind(this));
                        }
                    };

                    TableComponent.prototype.postInit = function () {
                        this.api.addEventListener('afterSortChanged', this.rowsNumberChanged.bind(this));
                        this.api.addEventListener('afterFilterChanged', this.rowsNumberChanged.bind(this));
                        if(this.onPostInit) {
                            this.onPostInit(this.getNumberOfRows());
                        }
                        if(this.onSelectionChanged) {
                            this.api.addEventListener('selectionChanged', function() {
                                this.onSelectionChanged(this.api.getSelectedRows());
                            }.bind(this));
                        }
                    };

                    TableComponent.prototype.rowsNumberChanged = function () {
                        if(this.onRowsNumberChanged) {
                            this.onRowsNumberChanged(this.getNumberOfRows());
                        }
                    };

                    TableComponent.prototype.getUnpinnedColumns = function () {
                        return _.filter(this.columnApi.getAllColumns(), function (column) {
                            return !column.isPinned();
                        });
                    };

                    $scope.options.grid = $scope.grid;

                    TableComponent.prototype.setQuickFilter = function(query) {
                        this.api.setQuickFilter(query);
                    };

                    TableComponent.prototype.getSelectedNodes = function() {
                        this.api.getSelectedNodes();
                    };

                    TableComponent.prototype.deselectAll = function() {
                        this.api.deselectAll();
                    };

                    TableComponent.prototype.onSelectionChanged = function() {
                        $scope.selectedNodes = this.api.getSelectedNodes();
                        $scope.$apply();
                    };

                    $scope.grid = new TableComponent($scope.options);
                    $scope.options.grid = $scope.grid;
                },
                link: function () {

                }
            };
        }]);
})();