(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitFilterService', ['$http', '$q', 'CONFIG', '$cacheFactory', 'CountryService', 'responseExceptionCatcher', VisitFilterService]);

    function VisitFilterService($http, $q, CONFIG, $cacheFactory, CountryService, responseExceptionCatcher) {
        this.getVisitFilter = getVisitFilter;
        this.getVisitFilters = getVisitFilters;
        this.getVisitFiltersBasic = getVisitFiltersBasic;
        this.createOrUpdateInstance = createOrUpdateInstance;
        this.deleteItem = deleteItem;

        //var cache = $cacheFactory('cacheIdFilters');

        function getVisitFilter(accountId, visitFilterId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters/' + visitFilterId)
                .then(onGetVisitFilterSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteItem(accountId, visitFilterId) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters/' + visitFilterId)
                .then(function (response) {
                    if( response.data.errors ){
                        return response.data.errors[0].description;
                    } else {
                        return null;
                    }

                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // used from dashboard and filters views
        function getVisitFilters(accountId, config) {

            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters', config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getVisitFiltersBasic(accountId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters/basic', config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function createOrUpdateInstance(accountId, visitFilter) {
            var promise;
            var visitFilterToSave = transformVisitFilterToJson(visitFilter);
            if (!(_.isNull(visitFilterToSave.id) || visitFilterToSave.id === undefined || visitFilterToSave.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters', visitFilterToSave)
                    .then(onSaveVisitFilterSuccess)
                   // .then(clearCache(accountId))
                    .catch(responseExceptionCatcher.catch('Error when saving VisitFilter '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/filters', visitFilterToSave)
                    .then(onSaveVisitFilterSuccess)
                    //.then(clearCache(accountId))
                    .catch(responseExceptionCatcher.catch('Error when saving VisitFilter '));
            }
            return promise;
        }

        function onGetVisitFilterSuccess(response) {
            return transformVisitFilterFromJson(response.data.data);
        }

        function onSaveVisitFilterSuccess(response) {
            return transformVisitFilterFromJson(response.data.data);
        }

       /* function clearCache(accountId) {
            cache.remove(accountId);
            //console.log('removed cache filters for ' + accountId + ' ' , cache.info())

        }*/

        function transformVisitFilterFromJson(visitFilter) {
            if (visitFilter.visitFilterQuery.filterOnCountryISOHolder) {
                visitFilter.visitFilterQuery.filterOnCountryISO = _.map(
                    visitFilter.visitFilterQuery.filterOnCountryISOHolder.keyValues, function (keyValue) {
                        keyValue.flagClass = 'flag-' + keyValue.id.toLowerCase();
                        return keyValue;
                });
            }
            return visitFilter;
        }

        // TODO: Should be possible to remove this when both country id and country name is included in visitFilterQuery
        function transformVisitFilterToJson(visitFilterToSave) {
            // Create a deep clone
            var visitFilter = $.extend(true, {}, visitFilterToSave);

            if ( visitFilter.visitFilterQuery.filterOnPageKeywords) {
                visitFilter.visitFilterQuery.filterOnPageKeywords = _.map(visitFilter.visitFilterQuery.filterOnPageKeywords, function (keyWord) {
                    return keyWord.keyWord;
                });
            }

            return visitFilter;
        }


    }
})();