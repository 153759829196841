(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ReportHistoryController', ['account', 'reportId', 'ReportService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', ReportHistoryController]);

    function ReportHistoryController(account, reportId, ReportService, FileSaver, Blob, CONFIG, TableSort) {
        var vm = this;
        vm.deleteHistoryForReport = deleteHistoryForReport;
        vm.tableSize = tableSize;
        vm.getReportHistory = getReportHistory;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;

        vm.account = account;
        vm.reportId = reportId;
        vm.reportHistory = null;
        vm.ok = null;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;


        activate();

        function activate() {
            getReportHistory(vm.reportId);
        }

        function getReportHistory(reportId) {
            ReportService.getReportHistory(vm.account.id,  reportId)
                .then(function (reportHistory) {
                    vm.reportHistory = reportHistory;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'ReportHistory_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);

        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteHistoryForReport(reportId) {
            ReportService.deleteReport(account.id, reportId)
                .then(function (reports) {
                    vm.reports = reports;
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.reportHistory) {
                return vm.reportHistory.length;
            }
        }
    }
})();