(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ResellerAccountSwitchController', ['currentResellerAccount', 'ResellerAccountService', 'CommonService', 'logger', '$q', '$state', '$rootScope', ResellerAccountSwitchController]);

    function ResellerAccountSwitchController(currentResellerAccount, ResellerAccountService, CommonService, logger, $q,$state,$rootScope ) {
        var vm = this;

        vm.switchResellerAccount = switchResellerAccount;

        vm.reselleAccount = currentResellerAccount;
        vm.resellerAccounts = [];
        vm.currenResellerAccount = currentResellerAccount;
        vm.selectedResellerAccount = null;

        activate();

        ////////////
        //  Init
        function activate() {
            getInitialData()
                .then(showInitialView);
        }

        function getInitialData() {
            return $q.all([getAllResellerAccounts()]);
        }

        function getAllResellerAccounts() {
            return ResellerAccountService.getAllResellerAccounts();
        }

        function showInitialView(result) {
            vm.resellerAccounts = result[0];
        }

        /// End init


        function switchResellerAccount() {
            if (vm.selectedResellerAccount) {
                ResellerAccountService.switchResellerTo(vm.selectedResellerAccount)
                    .then(function (data) {


						vm.currenResellerAccount = data;
                        logger.success('Switched Reseller to ' + vm.currenResellerAccount.name);
						console.log('Broadcast new or updated account');
						$rootScope.$broadcast('reselleraccounts-updated');

//						$state.reload();
                    });
            }
        }
    }
})();