(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('DateUtils', ['moment', DateUtils]);

    function DateUtils(moment) {
        this.getToday = getToday;
        this.getYesterday = getYesterday;
        this.normalizeEpoch = normalizeEpoch;
        this.normalizeDate = normalizeDate;

        function getToday() {
            return normalizeDate(moment().toDate());
        }

        function getYesterday() {
            return normalizeDate(moment().add(-1, 'days').toDate());
        }

        function normalizeEpoch(epoch) {
            return moment(normalizeDate(moment(epoch).toDate())).valueOf();
        }

        function normalizeDate(date) {
            var newDate = null;
            if (date) {
                newDate = new Date(date.getTime());
                newDate.setHours(12, 0, 0, 0);
            }
            return newDate;
        }
    }
})();