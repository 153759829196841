(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('userStatus', activeFilter);

    function activeFilter() {
        return function (value) {
            if( value === 'ACTIVE'){
                return 'Active';
            }
            if( value === 'NOT_ACTIVE'){
                return 'Not active';
            }
            return '';
        };
    }

})();