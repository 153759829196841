(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdminTokenController', ['selectedAccountId', 'tokenId', 'AdminTokenService', 'CommonService', 'logger', '$q',
            '$state', AdminTokenController]);
    function AdminTokenController(selectedAccountId, tokenId, AdminTokenService, CommonService, logger, $q, $state) {
        var vm = this;
        vm.saveToken = saveToken;
        vm.regenerateToken = regenerateToken;

        // initial if creating new
        vm.token = {
            id: tokenId

        };

        vm.selectedAccountId = selectedAccountId;

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getToken()]);
        }

        function getToken() {
            return AdminTokenService.getToken(vm.selectedAccountId, vm.token.id);
        }

        // display the stuff when promises are finished
        function initView(result) {
            vm.token = result[0];
        }

        /// End init

        function saveToken() {
            return $q.all([onSave()]);
        }

        function onSave() {
            return AdminTokenService.updateToken(vm.selectedAccountId, vm.token)
                .then(function (data) {
                    vm.token = data;
                    showSaveSuccess();
                    return data;
                });
        }

         function regenerateToken() {
            return AdminTokenService.regenerateToken(vm.selectedAccountId, vm.token.id)
                .then(function (data) {
                    vm.token = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Token saved');
            $state.go('admin.token', {selectedAccountId: vm.selectedAccountId,tokenId: vm.token.id}, {notify: false});
        }
    }
})();