(function() {
    'use strict';

    angular
        .module('adminApp')
        .constant('CampaignBetaConstants', new CampaignConstants());

    function CampaignConstants() {
        var settingsAnchormenuItems = [
            {
                name: 'Main setting',
                anchor: 'mainsettings'
            }, {
                name: 'Budget',
                anchor: 'budget'
            },
            {
                name: 'URL',
                anchor: 'url'
            },
            {
                name: 'Campaign Schedule',
                anchor: 'schedule'
            },
            {
                name: 'Delivery',
                anchor: 'delivery'
            }
        ];

        var targetingAnchormenuItems = [
            {
                name: 'Audience',
                anchor: 'audience'
            },
            {
                name: 'Geo-targeting',
                anchor: 'geo-targeting'
            },
            {
                name: 'Audience Profile',
                anchor: 'audience-profile'
            }
        ];

        var menuItems = [
            {
                name: 'settings',
                href: 'admin.campaignBeta.settings'
            }, {
                name: 'targeting',
                href: 'admin.campaignBeta.targeting'
            },
            {
                name: 'ad creatives',
                href: 'admin.campaignBeta.adCreatives'
            },
            {
                name: 'media',
                href: 'admin.campaignBeta.media'
            },
            {
                name: 'properties',
                href: 'admin.campaignBeta.properties'
            }
        ];

        var campaignStatuses = [
            {
                value: 'Draft',
                label: 'Draft'
            },
            {
                value: 'Active',
                label: 'Active'
            },
            {
                value: 'NotActive',
                label: 'Scheduled'
            },

            {
                value: 'Paused',
                label: 'On Hold'
            },
            {
                value: 'Completed',
                label: 'Completed'
            }
        ];

        var campaignEndAttributes = [
            {
                value: 'budget',
                label: 'Budget'
            },
            {
                value: 'impression',
                label: 'Impressions'
            },
            {
                value: 'click',
                label: 'Clicks'
            }
        ];

        var campaignCurrencies = [
            {
                value: 'SEK',
                label: 'SEK'
            },
            {
                value: 'USD',
                label: 'USD'
            },
            {
                value: 'EUR',
                label: 'EUR'
            }
        ];

        var periods = [
            {
                value: 'hours',
                label: 'hours'
            },
            {
                value: 'days',
                label: 'days'
            },
            {
                value: 'weeks',
                label: 'weeks'
            },
            {
                value: 'months',
                label: 'months'
            }
        ];

        var period = [
            {
                value: 'day',
                label: 'daily'
            },
            {
                value: 'week',
                label: 'weekly'
            }
        ];

        var days = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday'
        ];

        return {
            settingsAnchormenuItems: settingsAnchormenuItems,
            targetingAnchormenuItems: targetingAnchormenuItems,
            menuItems: menuItems,
            campaignStatuses: campaignStatuses,
            campaignEndAttributes: campaignEndAttributes,
            campaignCurrencies: campaignCurrencies,
            periods: periods,
            period: period,
            days: days
        };
    }
})();