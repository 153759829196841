(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdminSegmentController', ['AdminSegmentService', 'logger', '$filter', '$state',
            '$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort',
            AdminSegmentController]);
    function AdminSegmentController(AdminSegmentService, logger, $filter, $state,
                                $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        vm.segmentGroups = [];
        vm.segmentGroupsTop4Accounts = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['accountName', 'active'],
            reverse: false
        });

        vm.status = {
            isopen: false
        };


        ////////////
        //  Init
        activate();

        function activate() {
            findAllSegmentsGroupedByAccount();
        }

        function findAllSegmentsGroupedByAccount() {
            AdminSegmentService.findAllSegmentsGroupedByAccount().then(showSegments);
        }

        function showSegments(segmentGroups) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.segmentGroups = segmentGroups;
            vm.segmentGroupsTop4Accounts = _.sortBy(vm.segmentGroups, function(o) { return -o.noOfSegments; });
        }

        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'admin_segmentGroups_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.segmentGroups.length;
        }

    }
})();