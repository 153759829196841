(function () {
	'use strict';

	angular
		.module('adminApp')
		.directive('salesFilter', ['filterConstants', SalesFilter]);


	function SalesFilter(filterConstants) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/organisation/salesFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.SALES;
				scope.operands = [
					scope.opers.BETWEEN
				];

				var initialValue = {
					from: 0,
					to: 100000
				};

				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = initialValue;
					scope.filter.data.operand = scope.opers.BETWEEN;
				}

				scope.$watch('filter.data.operand', function (newOperandValue, oldOperandValue) {
					var operandWasChanged = newOperandValue !== oldOperandValue;
					if (operandWasChanged) {
						// when switching operand
						if (newOperandValue === scope.opers.BETWEEN) {
							scope.filter.data.value = initialValue;
						}
						if (newOperandValue === scope.opers.BEGIN_WITH) {
							scope.filter.data.value = 9;
						}
					} else {
						// when switching to settings/list we end up in here
						if (newOperandValue === scope.opers.BETWEEN) {
							// we always receive an array from backend, convert it to {to :  X, from : Y}
							var valueIsArray = scope.filter.data.value !== undefined && Array.isArray(scope.filter.data.value);
							if (valueIsArray) {
								scope.filter.data.value = {
									from: scope.filter.data.value[0],
									to: scope.filter.data.value[1]
								};
							}
						}
					}
				});
			}
		};
	}
})();