(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('tagsAutocompleteInput', ['$timeout', TagsAutocompleteInput]);

    function TagsAutocompleteInput($timeout) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/inputs/tags-autocomplete-input.html',
            require: 'ngModel',
            scope: {
                sourceFn: '&',
                placeholder: '=',
                allowFreeText: '=',
                mode: '=?'
            },
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$render = function () {
                    scope.value = ngModel.$modelValue;
                };

                scope.$watchCollection('tags', function (newVal, oldVal) {
                    if(newVal !== oldVal) {
                        if(!newVal) {
                            newVal = [];
                        }
                        ngModel.$setViewValue(newVal.map(function (v) {
                           return v.text;
                        }));
                        scope.$emit('numberOfTagsChanged');
                    }
                });

                $timeout(function () {
                    scope.tags = ngModel.$modelValue;
                });

                if(scope.mode === 'country') {
                    scope.source = function (query) {
                        return scope.sourceFn({query: query}).then(function (res) {
                            scope.$emit('autocompleteExpanded', elem);
                            return _.map(res.data, function (option) {
                                return {
                                    text: option.name,
                                    flagClass: option.flagClass
                                };
                            });
                        });
                    };
                } else {
                    scope.source = function (query) {
                        return scope.sourceFn({query: query}).then(function (res) {
                            scope.$emit('autocompleteExpanded', elem);
                            return res.data.map(function (option) {
                                return {
                                    text: option
                                };
                            });
                        });
                    };
                }


            }
        };
    }


})();