(function () {
    'use strict';

    angular
        .module('adminApp')
        .constant('RULE_TYPES', {
            'stringType': 'String',
            'integerType': 'Integer',
            'floatType': 'Float',
            'dateTimeType': 'DateTime',
            'booleanType': 'Boolean'
        });
})();