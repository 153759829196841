(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('VisitCookiesController', ['account', '$document', '$q', 'CONFIG', 'FileSaver', 'TableSort', '$state', 'logger', '$localStorage', 'VisitCookieService', 'STATE', VisitCookiesController]);

	function VisitCookiesController(account, $document, $q, CONFIG, FileSaver, TableSort, $state, logger, $localStorage, VisitCookieService, STATE) {
		var vm = this;

		vm.onTableSort = onTableSort;
		vm.doExportToExcel = doExportToExcel;
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.deleteSelected = deleteSelected;

		//
		vm.checkAll = checkAll;
		vm.deCheckAll = deCheckAll;


		vm.account = account;
		vm.visitCookies = [];
		vm.sort = new TableSort('adminTable', {
			by: ['startDate'],
			reverse: true
		});
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.$storage = $localStorage.$default({showHelp: false});

		activate();

		function activate() {
			getInitialData().then(showInitialView);
		}

		function getInitialData() {
			return $q.all([getVisitCookies()]);
		}

		function getVisitCookies() {
			return VisitCookieService.findAll(vm.account.id);
		}


		// use the stuff when promises are finished
		function showInitialView(result) {
			vm.visitCookies = result[0];
		}

		function onTableSort(by) {
			//jshint unused:false
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
			var config = {
				data: data,
				filename: 'VisitTags_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			if (vm.visitCookies) {
				return vm.visitCookies.length;
			}
		}

		function deleteSelected() {
			var ids = getSelectedItems();
			VisitCookieService.deleteSelected(ids,vm.account.id).then(function() {
				logger.info('Deleted selected ');
				getInitialData().then(showInitialView);
			});
		}

		function getSelectedItems() {
			if (vm.visitCookies.selected || vm.visitCookies.selected.length > 0) {
				return _.map(vm.visitCookies.selected, 'id');
			}
			return null;
		}

		function checkAll() {
			vm.visitCookies.selected = angular.copy(vm.visitCookies);

		}

		function deCheckAll() {
			vm.visitCookies.selected = null;
		}


	}
})();