(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('pageKeywordsFilter', [ '$animate', 'filterValidation', 'filterConstants', 'CommonService', PageKeywordsFilter]);


    function PageKeywordsFilter($animate, filterValidation, filterConstants, CommonService) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/visit/pageKeywordFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.PAGE_KEYWORDS;
				scope.operands = [
					scope.opers.IN,
					scope.opers.NOT_IN
				];

				var pageKeywords = [];
					CommonService.getAllPageKeywords().then(function(keywords){
						pageKeywords = keywords;
				});



				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = [];
				} else {
					// converts to js types
					console.log('scope.filter.data.value', scope.filter.data.value)
					/*for (var i = 0; i < scope.filter.data.value.length; i++) {
						var type = scope.filter.data.value[i];
						if( type.id !== undefined ){
							// make sure we always use ISO and not json if already set
							type = type.id;
						}
						scope.filter.data.value[i] = CommonService.getOrganisationType(type);
					}*/
				}

				if(scope.filter.data.operand === undefined) {
					scope.filter.data.operand = scope.opers.IN;
				}

				// in converter we filter out the by keyword type, we store the pagekeyword in json for the autocomplete
				scope.autoCompleteOrganisationTypes = function (query) {
					return _.filter(pageKeywords, function (keywordItem) {
						if (keywordItem.keyWord.toLowerCase().indexOf(query.toLowerCase()) !== -1){
							return keywordItem;
						}
					});
				};

				scope.tagAdded = function(keyWord){
					//console.log( 'keyWord', keyWord )
					//scope.filter.data.value.push(tag.id);
				}
			}
		};
    }

})();