(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('filterBlock', ['$compile', FilterBlockBuilder]);

    function FilterBlockBuilder($compile) {
        return {
            restrict: 'E',
            scope: {
                filter: '='
            },
            templateUrl: 'partials/common/advancedfilters/filter-block.html',

            link: function (scope, el) {
                scope.avatarFn = function (el) {
                    var av = $(el).parent().clone();
                    av.css('width', $(el).parent().outerWidth());
                    return av;
                };

                scope.dragData = function (s) {
                    return {
                        type: 'moveFilter',
                        data: s.filter
                    };
                };

                var innerBlock = el.find('.filter-block__input-block');
                var directiveName = scope.filter.data.name.toLowerCase().split(' ').join('-') + '-filter';
				//var directiveName = 'general-filter';
                var innerFilter = $compile('<' + directiveName + '></ ' + directiveName+ ' >')(scope);
                innerBlock.append(innerFilter);
            }
        };
    }
})();