(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('positiveNegative', positiveNegativeDirective);

    function positiveNegativeDirective() {
        return {
            restrict: 'E',
            scope: {
                value: '='
            },
            template: '<span ng-bind="value" ng-class="getActiveClass()"></span>',
            link: function (scope) {
                scope.getActiveClass = function () {
                    if (isNegative()) {
                        return 'negative';
                    } else if (isPositive()) {
                        return 'positive';
                    }
                };
                function isNegative() {
                    var value = scope.value;
                    if (value) {
                        return value && value < 0 || (isNaN(value) && value.indexOf('-') > -1);
                    }
                }

                function isPositive() {
                    var value = scope.value;
                    if (value) {
                        return value && value > 0 || (isNaN(value) && value.indexOf('-') === -1);
                    }
                }
            }
        };
    }

})();