(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('allowed', ['$rootScope', 'AuthService', authDirective]);

    // Use like: <button class='btn' allowed='ROLE_ADMIN'>ADMIN </button>
    function authDirective($rootScope, AuthService) {
        return {
            restrict: 'A',
            link: function (scope, elem, attr) {
                if (!AuthService.isAllowed(attr.allowed)) {
                    elem.hide();
                }

                /*
                var wrapper = angular.element('<i class='fa fa-lock'/> ');
                elem.after(wrapper);
                wrapper.prepend(elem);
                scope.$on('$destroy', function () {
                    wrapper.after(elem);
                    wrapper.remove();
                });*/
            }
        };
    }
})();
