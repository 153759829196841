(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('countries', [countriesFilter]);

    function countriesFilter() {
        return function (countries) {
            return _.map(countries, function (country) {
                return country.name;
            });
        };
    }

})();