(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AccountService', ['$http', 'CONFIG', 'responseExceptionCatcher', AccountService]);

    function AccountService($http, CONFIG, responseExceptionCatcher) {
        this.getAccountsBasic = getAccountsBasic;
        this.getAccountBasicByAccountId = getAccountBasicByAccountId;
        this.getAccount = getAccount;
        this.getAccounts = getAccounts;
        this.getAllResellerAccounts = getAllResellerAccounts;
        this.createOrUpdateAccount = createOrUpdateAccount;
        this.setUpDSP = setUpDSP;

        function getAccountsBasic(active) {
            var url = CONFIG.API_PREFIX + '/admin/accounts/basic';
            if( active !== undefined &&  !active ){
                url = CONFIG.API_PREFIX + '/admin/accounts/basic?active=false';
            }
            return $http.get(url)
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAccountBasicByAccountId(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/basic/' + accountId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAccounts() {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function getAllResellerAccounts() {
            return $http.get(CONFIG.API_PREFIX + '/admin/resellerAccounts')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateAccount(account) {
            var promise;
            if (!(_.isNull(account.id) || account.id === undefined || account.id === '')) {
                promise = $http.put( CONFIG.API_PREFIX + '/admin' + '/accounts/', account)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/admin' + '/accounts/', account)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving '));
            }
            return promise;
        }

        function setUpDSP(account){
            return $http.put(CONFIG.API_PREFIX + '/admin/accounts/setupDSP', account)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when seting up DSP for Account'));

        }

        function onSuccess(response) {
            return response.data.data;
        }
    }
})();