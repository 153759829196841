(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('EnhancedOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', EnhancedOrganisationService]);

	function EnhancedOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
		this.getEnhancedOrganisation = getEnhancedOrganisation;
		this.updateEnhancedOrganisation = updateEnhancedOrganisation;
		this.updateEnhancedOrganisationToFailed = updateEnhancedOrganisationToFailed;
		this.createHighPrioEnhancementRequests = createHighPrioEnhancementRequests;
		this.createEnhancementRequestForISP = createEnhancementRequestForISP;
		this.createEnhancementRequestForISPs = createEnhancementRequestForISPs;


		function getEnhancedOrganisation(enhancedOrganisationId) {
			return $http.get(CONFIG.API_PREFIX + '/enhance/' + enhancedOrganisationId)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function updateEnhancedOrganisation(enhancedOrganisation) {
			var promise;
			if (!(_.isNull(enhancedOrganisation.id) || enhancedOrganisation.id === undefined || enhancedOrganisation.id === '')) {
				promise = $http.put(CONFIG.API_PREFIX + '/enhance/' + enhancedOrganisation.id, enhancedOrganisation)
					.then(function (response) {
						return response.data.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving enhanced organisation '));
			}
			return promise;
		}

		function updateEnhancedOrganisationToFailed(enhancedOrganisation) {
			var promise;
			if (!(_.isNull(enhancedOrganisation.id) || enhancedOrganisation.id === undefined || enhancedOrganisation.id === '')) {
				promise = $http.put(CONFIG.API_PREFIX + '/enhance/' + enhancedOrganisation.id + '?enhancingFailed=true', enhancedOrganisation)
					.then(function (response) {
						return response.data.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving enhanced organisation '));
			}
			return promise;
		}

		function createHighPrioEnhancementRequests(leIds) {
			if (!leIds || !Array.isArray(leIds)) {
				console.log('Empty data for enhancements');
				return;
			}

			console.log('Adding to high prio Q', leIds);
			return $http.post(CONFIG.API_PREFIX + '/enhance/queue/highPrio/leorganisations?leIds=' + leIds)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function createEnhancementRequestForISP(visit, user, account) {

			if (!visit || !visit.organisation.id) {
				return;
			}
			var isp = {
				name: visit.organisation.name,
				countryISO: visit.organisation.address.countryISO,
				accountName: account.name,
				accountId: account.id,
				createdByUserName: user.userName,
				createdByUserId: user.id
			};
			var isps = [isp];
			return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/isps', isps)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function createEnhancementRequestForISPs(visits, user, account) {
			var isps = [];
			_.each(visits, function (visit) {
				var isp = {
					name: visit.organisation.name,
					countryISO: visit.organisation.address.countryISO,
					accountName: account.name,
					accountId: account.id,
					createdByUserName: user.userName,
					createdByUserId: user.id
				};
				isps.push(isp);
			});
			return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/isps', isps)
				.catch(responseExceptionCatcher.catch('Error when saving  '));
		}
	}
})();