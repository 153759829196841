(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('reportTemplate', yesNoFilter);

    function yesNoFilter() {
        return function (value) {
            if( value === 'VISITS_SIMPLE'){
                return 'Visits Report (html)';
            }
            if( value === 'VISITS_WITH_MAP_REPORT'){
                return 'Visits with Map Report (html)';
            }
            if( value === 'VISITS_CUSTOMIZED'){
                return 'Visits (excel)';
            }
            if( value === 'VISITS_AND_PAGES_CUSTOMIZED'){
                return 'Visits & Pages (excel)';
            }

            return '';
        };
    }

})();