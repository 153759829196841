(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('NBKOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', NBKOrganisationService]);

    function NBKOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        this.searchNBK = searchNBK;
        this.searchNBKCountry = searchNBKCountry;
        this.searchNBKOrgId = searchNBKOrgId;
        this.takeOverNBKORganisation = takeOverNBKORganisation;

        function searchNBK(queryForOrgName, countryIso, organisationId, duns, config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/nbk?q=' + queryForOrgName + '&countryIso=' + countryIso  +
				'&organisationId=' + organisationId + '&duns=' + duns, config )
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function searchNBKCountry(countryIso, config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/nbk?countryIso=' + countryIso, config )
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function searchNBKOrgId(queryForOrgId, countryIso, config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/nbk?organisationId=' + queryForOrgId + '&countryIso=' + countryIso, config )
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function takeOverNBKORganisation(selectedNbkOrganisation){
            var promise;
            if (!(_.isNull(selectedNbkOrganisation.dunsNumber))) {
                promise = $http.post(CONFIG.API_PREFIX + '/enhance/organisations/nbk/takeover', selectedNbkOrganisation)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            }
            return promise;
        }
    }
})();