(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdaptiveContentStatisticsController', ['account', 'targetingId', 'AdaptiveContentService', 'AdaptiveStatisticsChart','logger', '$q', AdaptiveContentStatisticsController]);

    function AdaptiveContentStatisticsController(account, targetingId, AdaptiveContentService, AdaptiveStatisticsChart, logger, $q) {
        var vm = this;
        vm.account = account;
        vm.targetingId = targetingId;
        vm.adaptiveContentStatisticsChart = [];
        vm.start = moment().add(-30, 'days').format('YYYY-MM-DD');
        vm.end = moment().format('YYYY-MM-DD');
        vm.totalRequestForPeriod = 0;
        vm.totalHitsForPeriod = 0;

        // reference data
        vm.datePickerOptionsEndDate = {formatYear: 'yy', startingDay: 1};
        vm.datePickerOptionsStarDate = {formatYear: 'yy', startingDay: 1};

        vm.activate = activate;
        vm.clearChart = clearChart;
        vm.loadStatistics = loadStatistics;


        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getTargetingStatistics(), getTargeting()]);
        }

        function getTargetingStatistics() {
            var start = moment(vm.start).format('YYYY-MM-DD');
            var end = moment(vm.end).format('YYYY-MM-DD');
            return AdaptiveContentService.getTargetingStatistics(vm.account.id, vm.targetingId, start, end);
        }

        function getTargeting(){
            return AdaptiveContentService.getTargeting(vm.account.id, vm.targetingId);
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.targetingStatistics = result[0];
            vm.targeting = result[1];
            vm.adaptiveContentStatisticsChart = new AdaptiveStatisticsChart(vm.targetingStatistics);
            calcTotalRequestsForPeriod();
            calcTotalHitsForPeriod();
        }


        function calcTotalRequestsForPeriod(){
            vm.totalRequestForPeriod = _.reduce(vm.targetingStatistics, function (memo, val) {
                return memo + val.dailyRequestCounter;
            }, 0);

        }

        function calcTotalHitsForPeriod(){
            vm.totalHitsForPeriod = _.reduce(vm.targetingStatistics, function (memo, val) {
                return memo + val.dailyRequestHitsCounter;
            }, 0);

        }


        /// End init
        ///////////////

        function clearChart() {
            vm.targetingStatistics = [];
        }

        function loadStatistics() {
            var start = moment(vm.start).format('YYYY-MM-DD');
            var end = moment(vm.end).format('YYYY-MM-DD');

            AdaptiveContentService.getTargetingStatistics(vm.accountId, vm.targetingId, start, end)
                .then(function (targetingStatistics) {
                    angular.forEach(targetingStatistics, function (point) {
                        vm.chartDateAndValueSeries.push([point.date, point.dailyRequestCounter]);
                        vm.chartSeriesName = point.name;
                    });
                    vm.targetingStatistics = [{name: vm.chartSeriesName, data: vm.chartDateAndValueSeries}];
                });
        }

    }
})();