(function () {
    'use strict';

    angular
        .module('adminApp')
        .config(['$provide', exceptionConfiguration]);

    function exceptionConfiguration($provide) {
        $provide.decorator('$exceptionHandler', ['$delegate', '$injector', '$log' , customExceptionHandler]);
    }

    function customExceptionHandler($delegate, $injector, $log) {
        return function (exception, cause) {
            $delegate(exception, cause);
            var errorData = {
                message: exception.message || 'Unknown error',
                cause: cause || exception.cause
            };

            // $injector.get('$rootScope').errorData = errorData;
            //var $state = $injector.get('$state');
            //$state.go('error');
            $log.error('Error caught ', errorData);
        };
    }

})();