(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('CommonService', [
			'$http',
			'$location',
			'$q',
			'CONFIG',
			'responseExceptionCatcher',
			'$localStorage',
			'GuidUtil',
			CommonService
		])
		.run();

	function CommonService($http, $location, $q, CONFIG, responseExceptionCatcher, $localStorage, GuidUtil) {
		this.getNACECodes = getNACECodes;
		this.naceCodesSearch = naceCodesSearch;
		this.naicsCodesSearch = naicsCodesSearch;
		this.sicCodesSearch = sicCodesSearch;
		this.getNAICSCodes = getNAICSCodes;
		this.getSICCodes = getSICCodes;
		this.getAllPageKeywords = getAllPageKeywords;
		this.getGICSCodes = getGICSCodes;
		this.getIndustryTagsNoIndicator = getIndustryTagsNoIndicator;
		this.getOrganisationTypesNoIndicator = getOrganisationTypesNoIndicator;
		this.getOrganisationType = getOrganisationType;
		this.getCountries = getCountries;
		this.getCountryFromIsoCode = getCountryFromIsoCode;
		this.getCurrencies = getCurrencies;
		this.getTimeZones = getTimeZones;
		this.getAccountId = getAccountId;
		this.getAccount = getAccount;
		this.getUserAccounts = getUserAccounts;
		this.getAccountSites = getAccountSites;
		this.getActiveAccountsForCurrentReseller = getActiveAccountsForCurrentReseller;
		this.getActiveAccountsForCurrentResellerFromBackend = getActiveAccountsForCurrentResellerFromBackend;
		this.getResellerAccountForCurrentUser = getResellerAccountForCurrentUser;
		this.getUser = getUser;
		this.userIsAdminOrPowerUSer = userIsAdminOrPowerUSer;
		this.getCurrentSite = getCurrentSite;
		this.switchAccount = switchAccount;
		this.switchSite = switchSite;
		this.init = init;
		this.logout = logout;

		var countries = [];
		var currencies = [];
		var gicsCodes = [];
		var naicsCodes = [];
		var sicCodes = [];
		var naceCodes = [];
		//var accountId = null;
		var account = null;
		var accountsForCurrentReseller;
		var currentResellerAccount;
		var userAccounts = null;
		var user = null;

		var organisationTypes =  [
			{id: 'limitedCompany', name: 'Limited company'},
			{id: 'soletrader', name: 'Solo Trader'},
			{id: 'limitedPartnerShip', name: 'Limited partnership'},
			{id: 'economicAssociation', name: 'Economic Association'},
			{id: 'municipalCorporation', name: 'Municipal corporation'},
			{id: 'nonProfit', name: 'Non profit'},
			{id: 'notActive', name: 'Not Active'}
		];


		function init() {
			getUser();
			getCountries();
		}

		function getCountries() {
			return countries.length > 0 ? asPromise(countries) : getCountriesFromBackend();
		}

		function getCurrencies() {
			return currencies.length > 0 ? asPromise(currencies) : getCurrenciesFromBackend();
		}

		function getCountriesFromBackend() {
			if ($localStorage.countries) {
				return $localStorage.countries;
			}
			return $http.get(CONFIG.API_PREFIX + '/countries')
				.then(function (response) {
					countries = response.data.data;
					$localStorage.$default({countries: countries});
					return getCopy(countries);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function getCountryFromIsoCode(countryIso) {
			if (countryIso) {
				return _.find($localStorage.countries, function (country) {
					if (countryIso.toLowerCase() === country.countryISOLowerCase) {
						return country;
					}
				});
			}
		}


		// converts enum to js type
		function getOrganisationType(typeInput) {
			if (typeInput) {
				return _.find(organisationTypes, function (type) {
					if (typeInput === type.id) {
						return type;
					}
				});
			}
		}

		function getNACECodes(){
			return naceCodes.length > 0 ? asPromise(naceCodes) : getNACECodesNoIndicator();

		}

		function getNACECodesNoIndicator() {
			if ($localStorage.naceCodes) {
				return asPromise($localStorage.naceCodes);
			}

			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/naceCodes', config)
				.then(function (response) {
					var codes = response.data;
					$localStorage.$default({naceCodes: codes});
					return getCopy(codes);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function naceCodesSearch(searchBy) {
			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/naceCodes/search?q=' + searchBy, config)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function naicsCodesSearch(searchBy) {
			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/naicsCodes/search?q=' + searchBy, config)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function sicCodesSearch(searchBy) {
			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/sicCodes/search?q=' + searchBy, config)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}

		function getSICCodes(){
			return sicCodes.length > 0 ? asPromise(sicCodes) : getSICCodesNoIndicator();
		}

		function getSICCodesNoIndicator() {
			if ($localStorage.sicCodes) {
				return asPromise($localStorage.sicCodes);
			}

			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/sicCodes', config)
				.then(function (response) {
					var codes = response.data;
					$localStorage.$default({sicCodes: codes});
					return getCopy(codes);

				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}


		function getNAICSCodes(){
			return naicsCodes.length > 0 ? asPromise(naicsCodes) : getNAICSCodesNoIndicator();
		}

		function getNAICSCodesNoIndicator() {
			if ($localStorage.naicsCodes) {
				return asPromise($localStorage.naicsCodes);
			}


			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/naicsCodes', config)
				.then(function (response) {
					var codes = response.data;
					$localStorage.$default({naicsCodes: codes});
					return getCopy(codes);

				})
				.catch(responseExceptionCatcher.catch('Error when retrieving countries'));
		}


		// table of codes
		// use .tableRows for table, .gicsSubIndustryCode for subindustry
		function getGICSCodes(){
			return gicsCodes.length > 0 ? asPromise(gicsCodes) : getGICSCodesNoIndicator();
		}


		function getGICSCodesNoIndicator() {
			if ($localStorage.gicsCodes) {
				return asPromise($localStorage.gicsCodes);
			}
			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/gicscodes', config)
				.then(function (response) {
					gicsCodes = response.data;
					$localStorage.$default({gicsCodes: gicsCodes});
					return getCopy(gicsCodes);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving gics'));
		}




		function getIndustryTagsNoIndicator() {
			//{id: "32323", name : "bank"}
			if ($localStorage.tags) {
				return $localStorage.tags;
			}

			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
			return $http.get(CONFIG.API_PREFIX + '/industryTags', config)
				.then(function (response) {
					var tags = response.data;
					$localStorage.$default({tags: tags});
					return tags;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving tags'));
		}

		function getOrganisationTypesNoIndicator() {
			return organisationTypes;
		}

		// used from ui-router
		function getAccountId() {
			return $http.get(CONFIG.API_PREFIX + '/accounts/current')
				.then(function (response) {
					account = response.data.data;
					return account.id;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving account '));
		}

		function switchAccount(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/switch/' + accountId)
				.then(function (response) {
					account = response.data.data;
					return account;
				})
				.catch(responseExceptionCatcher.catch('Error when switching account'));
		}

		function switchSite(accountId, siteId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/switch/' + siteId)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when switching Site'));
		}

		function getCurrentSite() {
			return $http.get(CONFIG.API_PREFIX + '/sites/current')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when getting Site'));
		}

		function getAccount() {
			if (account !== null) {
				return account;
			}
			return getAccountFromBackend();
		}

		function getAccountFromBackend() {
			return $http.get(CONFIG.API_PREFIX + '/accounts/current')
				.then(function (response) {
					account = response.data.data;
					return account;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving account '));
		}

		function getTimeZones() {
			if ($localStorage.timeZones) {
				return $localStorage.timeZones;
			}
			return $http.get(CONFIG.API_PREFIX + '/timeZones')
				.then(function (response) {
					var timeZones = response.data.data;
					$localStorage.$default({timeZones: timeZones});
					return timeZones;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving account '));
		}

		function getResellerAccountForCurrentUser() {
			return $http.get(CONFIG.API_PREFIX + '/resellerAccounts/current')
				.then(function (response) {
					currentResellerAccount = response.data.data;
					return currentResellerAccount;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving account '));
		}

		function getUserAccounts() {
			if (userAccounts) {
				return userAccounts;
			}
			return $http.get(CONFIG.API_PREFIX + '/accounts/user')
				.then(function (response) {
					userAccounts = response.data.data;
					return userAccounts;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving user accounts'));
		}

		function getAccountSites(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites')
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving user accounts'));
		}

		function getActiveAccountsForCurrentReseller() {
			if (accountsForCurrentReseller) {
				return accountsForCurrentReseller;
			}
			return getActiveAccountsForCurrentResellerFromBackend();
		}

		// used by account selector when refresh event fired
		function getActiveAccountsForCurrentResellerFromBackend() {
			var promise = $http.get(CONFIG.API_PREFIX + '/accounts/reseller')
				.then(function (response) {
					accountsForCurrentReseller = response.data.data;
					return accountsForCurrentReseller;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving reseller accounts'));
			return promise;
		}

		function logout() {
			var promise = $http.get('/j_spring_security_logout')
				.then(function () {
					$location.path('/');
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving reseller accounts'));
			return promise;
		}

		function getUser() {
			if (user) {
				return user;
			}
			return user ? getUserInState() : getUserFromBackend();
		}

		function userIsAdminOrPowerUSer() {
			var theUser = getUser();
			var admin = _.includes(theUser.roles, 'ROLE_ADMIN');
			var power = _.includes(theUser.roles, 'ROLE_POWER_USER');
			return admin || power;
		}

		function getUserInState() {
			var deferred = $q.defer();
			deferred.resolve(user);
			return deferred.promise;
		}

		function getUserFromBackend() {
			return $http.get(CONFIG.API_PREFIX + '/users/current?guid=' + GuidUtil.guid())
				.then(function (response) {
					user = response.data;
					return user;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving account id'));
		}

		function getCurrenciesFromBackend() {
			return $http.get(CONFIG.API_PREFIX + 'currencies')
				.then(function (response) {
					currencies = response.data.data;
					return getCopy(currencies);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving currencies'));
		}

		function getAllPageKeywords() {
			var aid = getAccount().aid;
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + aid + '/pagekeywords')
				.then(function (response) {
					console.log('calli backend',response.data.data) ;
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function getCopy(list) {
			return angular.copy(list);
		}

		function asPromise(data) {
			var deferred = $q.defer();
			deferred.resolve(data);
			return deferred.promise;
		}
	}

})();
