(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('LoginController', ['message', '$q', 'CONFIG', '$scope', '$state', 'logger', 'AuthService', LoginController]);

    function LoginController(message, $q, CONFIG, $scope, $state, logger, AuthService) {
        var vm = this;

        // vm.login = login;
        vm.logout = logout;
        vm.login = login;
        vm.onClickShowForgotPasswordForm = onClickShowForgotPasswordForm;
        vm.onValidateForgotPasswordForm = onValidateForgotPasswordForm;
        vm.onClickSendForgotEmail = onClickSendForgotEmail;
        vm.loginError = message;
        vm.showForgotPasswordForm = false;
        vm.isForgotPasswordFormOk = false;
        vm.forgotPasswordUserName = null;
        vm.forgotPasswordParam1 = Math.floor(Math.random() * 10) + 1;
        vm.forgotPasswordParam2 = Math.floor(Math.random() * 10) + 1;
        vm.forgotPasswordInputSum = null;
        vm.userForgotMailJson = null;

        vm.credentials = {};

        function login() {
            //TODO: improve update strategy
            localStorage.clear();
            AuthService.login(vm.credentials).then(loggedIn);
        }

        function onClickShowForgotPasswordForm() {
            vm.showForgotPasswordForm = !vm.showForgotPasswordForm;
        }

        function onValidateForgotPasswordForm() {
            var sum = vm.forgotPasswordParam1 + vm.forgotPasswordParam2;
            var mathOk = sum === vm.forgotPasswordInputSum;
            if (mathOk && vm.forgotPasswordUserName && vm.forgotPasswordUserName.length > 2) {
                vm.isForgotPasswordFormOk = true;
            } else {
                vm.isForgotPasswordFormOk = false;
            }
        }

        function logout() {
            AuthService.logout().then(loggedOut);
        }

        function loggedOut() {
            $state.reload();
        }

        function loggedIn() {
            AuthService.isAuthenticated();
            //$state.reload();
        }


        function onClickSendForgotEmail() {
            vm.userForgotMailJson = {
                userName: vm.forgotPasswordUserName,
                paramOne: vm.forgotPasswordParam1,
                paramTwo: vm.forgotPasswordParam2,
                captcha: vm.forgotPasswordInputSum

            };
            AuthService.userForgotPassword(vm.userForgotMailJson).then(mailSent);
        }

        function mailSent() {
            logger.info('Request received, be patient!');
            clearForgetPasswordForm();
        }

        function clearForgetPasswordForm() {
            vm.forgotPasswordUserName = null;
            vm.forgotPasswordInputSum = null;

        }
    }
})();