(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitController', ['accountId', 'visitId', '$q', 'VisitService', 'AccountService', '$document', '$window', 'VisitMapChart', VisitController]);

    function VisitController(accountId, visitId, $q, VisitService, AccountService, $document, $window, VisitMapChart) {
        var vm = this;
        vm.newWindowWithDomain = newWindowWithDomain;
        vm.accountId = accountId;
        vm.visitId = visitId;
        vm.VisitService = VisitService;
        vm.AccountService = AccountService;
        vm.account = null;
        vm.visit = null;
        vm.map = null;
        vm.organisationMarker = null;
		vm.visitsMapChart = null;

        activate();

        function activate() {
            $document.scrollTopAnimated(0);

            getInitialData()
                .then(showInitialData);
        }

        function getInitialData() {
            return $q.all([getAccount(), getVisit()]);
        }

        function getAccount() {
            return AccountService.getAccount(vm.accountId);
        }

        function getVisit() {
            return VisitService.getVisit(vm.accountId, vm.visitId);
        }

        function showInitialData(result) {
            showAccount(result[0]);
            showVisit(result[1]);
        }

        function showAccount(account) {
            vm.account = account;
        }

		function newWindowWithDomain(){
			var ulr = 'http://' +  vm.visit.organisation.address.www;
			$window.open ( ulr,  '_blank');
		}

        function showVisit(visit) {
            vm.visit = visit;

			vm.visitsMapChart = new VisitMapChart(vm.visit);

            var visitPosition = {
                latitude: visit.latitude,
                longitude: visit.longitude
            };

            vm.map = {
                center: visitPosition,
                zoom: 7,
                options: {
                    zoomControl: true,
                    maxZoom: 20,
                    mapTypeControl: true,
                    streetViewControl: true,
                    draggable: true,
                    panControl: true
                }
            };
            vm.organisationMarker = {
                id: 'organisation',
                coordinates: visitPosition,
                options: {
                    animation: true
                }
            };
        }

    }


})();