(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('joinBy', [joinByFilter]);

    function joinByFilter() {
        return function (input, delimiter) {
            return (input || []).join(delimiter || ', ');
        };
    }

})();