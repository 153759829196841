/* jshint quotmark: false */
(function() {
    'use strict';

    angular
        .module('adminApp')
        .directive('targetingMap', function() {
            return {
                restrict: 'E',
                templateUrl: 'partials/targeting/beta/campaigns/common/targeting-map.html',
                scope: {
                    placesNames: '=',
                    lastPlaces: '=',
                    placesData: '='
                },
                transclude: true,
                controller: ['$element', '$scope', targetingMapController]
            };
        });

    function targetingMapController($element, $scope) {
        var map;
        var placesService;
        var layer;
        var selectedCountires = [];

        $scope.$watch('placesNames', onPlacesChange);

        initMap();

        function onPlacesChange(newVal, oldVal) {
            if (!newVal) {
                return;
            }

            if (newVal.length < oldVal.length) {
                removeLocation(_.difference(oldVal, newVal)[0]);
            } else if (newVal.length > oldVal.length) {
                addLocation(_.difference(newVal, oldVal)[0]);
            }
        }

        function addLocation(locationName) {
            /* jshint sub: true */
            var placeId = _.find($scope.lastPlaces, {'description': locationName})['place_id'];
            placesService.getDetails({
                placeId: placeId
            }, function(placeWithDetails) {
                console.log(placeWithDetails);

                if (placeWithDetails.types.includes('country')) {
                    selectedCountires.push("'" + placeWithDetails.name + "'");
                    updateLayer();
                    $scope.placesData.push({
                        nameOnMap: placeWithDetails.name,
                        nameFromSearch: locationName,
                        id: placeId
                    });
                } else {
                    var marker = new google.maps.Marker({
                        map: map,
                        position: placeWithDetails.geometry.location
                    });
                    $scope.placesData.push({
                        nameOnMap: placeWithDetails.name,
                        nameFromSearch: locationName,
                        id: placeId,
                        location: marker.position,
                        marker: marker
                    });
                }
            });
        }

        function removeLocation(location) {
            var removedItemIndex = _.findIndex($scope.placesData, {'nameFromSearch': location});
            var removedItem = $scope.placesData.splice(removedItemIndex, 1);
            if (removedItem[0].marker) {
                removedItem[0].marker.setMap(null);
            } else {
                selectedCountires = selectedCountires.filter(function(country) {
                    return country !== "'" + removedItem[0].nameOnMap + "'";
                });
            }
            updateLayer();
        }

        function updateLayer() {
            var query = {
                select: 'geometry',
                from: '1AbZIv2wwK1CTfWkO_qx0Dnm9-Uve9_0oRKrj7dM',
                where: "'Name' IN (" + selectedCountires.join(',') + ")"
            };

            if (layer !== undefined) {
                if (selectedCountires.length > 0) {
                    layer.setOptions({ query: query });
                } else {
                    layer.setMap(null);
                    layer = undefined;
                }
            } else if (selectedCountires.length > 0) {
                layer = new google.maps.FusionTablesLayer({ query: query });
                layer.setMap(map);
            }
        }

        function initMap() {
            var Stockholm = {lat: 59.329444, lng: 18.068611};

            map = new google.maps.Map($('.selected-map')[0], {
                center: Stockholm,
                zoom: 4,
                gestureHandling: 'cooperative'
            });

            var minZoomLevel = 2;
            var maxZoomLevel = 10;
            google.maps.event.addListener(map, 'zoom_changed', function () {
                if (map.getZoom() < minZoomLevel) {
                    map.setZoom(minZoomLevel);
                }
                if (map.getZoom() > maxZoomLevel) {
                    map.setZoom(maxZoomLevel);
                }
            });

            placesService = new google.maps.places.PlacesService(map);

            if ($scope.placesData && $scope.placesData.length > 0) {
                fillMap();
            } else {
                $scope.placesNames = [];
            }

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    map.setCenter(pos);
                }, function() {
                    handleLocationError();
                });
            } else {
                handleLocationError();
            }
        }

        function fillMap() {
            $scope.placesNames = [];
            $scope.placesData.forEach(function(placeData, index) {
                if (placeData.location) {
                    var marker = new google.maps.Marker({
                        map: map,
                        position: placeData.location
                    });
                    $scope.placesData[index].marker = marker;
                } else {
                    selectedCountires.push("'" + placeData.nameOnMap + "'");
                }
                $scope.placesNames.push({text: placeData.nameFromSearch});
            });
            if (selectedCountires.length > 0) {
                updateLayer();
            }
        }

        function handleLocationError() {
            console.error('Something with defining location');
        }
    }

})();