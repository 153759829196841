(function () {
	'use strict';

	angular
		.module('adminApp')
		.directive('rangeInput', ['$timeout', RangeInput]);


	function linkVars(watchers, scope, left, right) {
		var lw = scope.$watch(left, function (value, old) {
			if (old !== value) {
				_.set(scope, right, value);
			}
		});
		var rw = scope.$watch(right, function (value, old) {
			if (old !== value) {
				_.set(scope, left, value);
			}
		});
		watchers.push(lw);
		watchers.push(rw);
	}

	function RangeInput($timeout) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/inputs/range-input.html',
			require: 'ngModel',
			scope: {
				type: '=',
				floor: '=?',
				ceil: '=?'
			},
			link: function (scope, elem, attrs, ngModel) {

				ngModel.$render = function () {
					scope.value = ngModel.$modelValue.from;
					scope.high = ngModel.$modelValue.to;
					/* gepo
					if (scope.type === 'between') {
						scope.value = ngModel.$modelValue.from;
						scope.high = ngModel.$modelValue.to;
					} else {
                      	scope.value = ngModel.$modelValue;
					}*/
				};

				var options = {
					floor: scope.floor,
					ceil: scope.ceil,
					noSwitching: true,
					customValueToPosition: function (val, minVal, maxVal) {
						val = val === 0 ? 0 : Math.log10(val);
						minVal = minVal === 0 ? 0 : Math.log10(minVal);
						maxVal = maxVal === 0 ? 0 : Math.log10(maxVal);
						var range = maxVal - minVal;
						return (val - minVal) / range;
					},
					customPositionToValue: function (percent, minVal, maxVal) {
						minVal = minVal === 0 ? 0 : Math.log10(minVal);
						maxVal = maxVal === 0 ? 0 : Math.log10(maxVal);
						var value = percent * (maxVal - minVal) + minVal;
						return Math.pow(10, value);
					}
				};

				$timeout(function () {

					var watchers = [];


					scope.leftLimit = {
						value: scope.floor,
						disabled: false
					};

					scope.rightLimit = {
						value: scope.ceil,
						disabled: false
					};

					if (scope.type === 'between') {
						options.minRange = 1;
						scope.leftLimit.value = scope.value;  // from
						scope.rightLimit.value = scope.high;  // to

						linkVars(watchers, scope, 'value', 'leftLimit.value');
						linkVars(watchers, scope, 'high', 'rightLimit.value');
						scope.$watch('high', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								if (_.isUndefined(newValue) || _.isNaN(newValue) || newValue < scope.floor || newValue > scope.ceil) {
									scope.high = oldValue;
									scope.rightLimit.value = oldValue;
								} /*else if (_.isNull(newValue)) {
									scope.high = scope.value + 1;
									scope.rightLimit.value = scope.value + 1;
								}*/

								/*if (scope.high <= scope.value) {
									scope.high = scope.value + 1;
									scope.rightLimit.value = scope.high;
								}*/


								ngModel.$setViewValue({
									from: ngModel.$modelValue.from,
									to: scope.high
								});
							}
						});
						scope.$watch('value', function (newValue, oldValue) {
							if (newValue !== oldValue) {

								if (_.isUndefined(newValue) ||_.isNaN(newValue) ||newValue < scope.floor || newValue > scope.ceil) {
									scope.value = oldValue;
									scope.leftLimit.value = oldValue;
								} /*
								this makes a 0 appear always,
								else if (_.isNull(newValue)) {
									scope.value = 0;
									scope.leftLimit.value = 0;
								}*/

								/*if (scope.high <= scope.value) {
									scope.value = scope.high - 1;
									scope.leftLimit.value = scope.value;
								}
*/
								ngModel.$setViewValue({
									from: scope.value,
									to: ngModel.$modelValue.to
								});

							}
						});
					} /*else if (scope.type === 'moreThan') {
						options.showSelectionBarEnd = true;
						options.showSelectionBar = false;
						scope.rightLimit.disabled = true;
						scope.leftLimit.value = scope.value;
						linkVars(watchers, scope, 'value', 'leftLimit.value');
						scope.$watch('value', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								if (_.isUndefined(newValue) ||
									_.isNaN(newValue) ||
									newValue < scope.floor || newValue > scope.ceil
								) {
									scope.value = oldValue;
									scope.leftLimit.value = oldValue;
								} else if (_.isNull(newValue)) {
									scope.value = scope.floor;
									scope.leftLimit.value = scope.floor;
								}

								ngModel.$setViewValue(scope.value);
							}
						});
					} else if (scope.type === 'lessThan') {
						options.showSelectionBar = true;
						options.showSelectionBarEnd = false;
						scope.leftLimit.disabled = true;
						scope.rightLimit.value = scope.value;
						linkVars(watchers, scope, 'value', 'rightLimit.value');
						scope.$watch('value', function (newValue, oldValue) {
							if (newValue !== oldValue) {
								if (_.isUndefined(newValue) ||
									_.isNaN(newValue) ||
									newValue < scope.floor || newValue > scope.ceil
								) {
									scope.value = oldValue;
									scope.rightLimit.value = oldValue;
								} else if (_.isNull(newValue)) {
									scope.value = scope.floor;
									scope.rightLimit.value = scope.floor;
								}

								ngModel.$setViewValue(scope.value);
							}
						});
					}*/

					scope.options = function () {
						return options;
					};

					scope.$on('$destroy', function () {
						//scope.options = null;
						scope.leftLimit = null;
						scope.rightLimit = null;
						_.each(watchers, function (w) {
							w();
						});
						watchers = [];
					});
				});
			}
		};
	}

})();
