(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('draggable', ['$parse', 'draggingService', Draggable])
        .directive('dragTarget', ['$parse', 'draggingService', DragTarget]);


        function Draggable($parse, draggingService) {
            return {
                restrict: 'A',
                scope: false,
                link: function (scope, elem) {
                    var avatarFn = function (el) {
                        return el.clone();
                    };

                    elem.on('mousedown.dragging touchstart.dragging', function (e) {
                        if ($(window).width() <= 1024 && $(e.target).closest('.filter-accordion__group-item').length > 0) {
                            return true;
                        }
                        e.preventDefault();
                        if(scope.avatarFn) {
                            avatarFn = scope.avatarFn;
                        }

                        var ava = avatarFn(elem);
                        draggingService.start(e, elem, ava, scope.dragData(scope));
                        return true;
                    });
                }
            };
        }

        function DragTarget($parse, draggingService) {
            return {
                restrict: 'A',
                scope: false,
                link: function (scope, elem) {
                    elem.on('mouseover touchenter', function () {
                        draggingService.setTarget(elem);
                        return true;
                    });

                    elem.on('mouseleave touchleave', function () {
                        draggingService.resetTarget();
                        return true;
                    });
                }
            };
        }

})();
