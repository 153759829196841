(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TrackingStatisticsService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', TrackingStatisticsService]);

    function TrackingStatisticsService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getDailyTrafficForSiteDuringPeriod = getDailyTrafficForSiteDuringPeriod;
        this.getTotalTrafficGroupedPerAccount = getTotalTrafficGroupedPerAccount;

        // TrafficAccountTrackingStatisticsDailyJson
        function getDailyTrafficForSiteDuringPeriod(siteId, startDate, endDate) {
            return $http.get( CONFIG.API_PREFIX + '/monitor/dailyTrafficForSiteDuringPeriod/' + siteId + '?startDate=' + startDate +
            '&endDate='+ endDate + '&limit=' + 10000)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getTotalTrafficGroupedPerAccount() {
            return $http.get( CONFIG.API_PREFIX + '/monitor/totalTrafficGroupedPerAccount?limit=' + 10000)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


    }
})();