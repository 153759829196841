(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ClearbitOrganisationsController', ['ClearbitOrganisationService', 'CommonService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger',
            ClearbitOrganisationsController]);
    function ClearbitOrganisationsController(ClearbitOrganisationService, CommonService, FileSaver, Blob, CONFIG,  TableSort, logger) {
        var vm = this;
        vm.clearBitOrganisations = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = null;
        vm.queryForCountryISO = {'countryISO':'SE','name': 'Sweden', 'countryISOLowerCase':'se'};
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });
        vm.refCountries = [];
        vm.enhancerSettings = null;
        vm.limit = 1000;

        //methods
        vm.loadMore = loadMore;
        vm.onCBITSearch = onCBITSearch;
        vm.onCBITSearchExternal = onCBITSearchExternal;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.getCountriesForSelectBox = getCountriesForSelectBox;
        vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
        vm.updateFromSelected = updateFromSelected;

		vm.checkAll = checkAll;
		vm.deCheckAll = deCheckAll;

        activate();

        function activate() {
            onLoad();
            getCountries();
        }

        /// End init
        function onLoad() {
            ClearbitOrganisationService.findAllWithLimit( vm.limit).then( onFinished );
        }

        function onCBITSearch() {
            ClearbitOrganisationService.search(vm.queryFor, vm.queryForCountryISO.countryISO, vm.limit).then( onFinished );
        }


        function updateFromSelected() {
			ClearbitOrganisationService.updateFromSelected(getSelected()).then(function (response) {
				logger.info('Updated ' + response.data.data + ' LE Organisations');
			});
        }


        function onCBITSearchExternal() {
            ClearbitOrganisationService.searchExternal(vm.queryFor).then( onFinished );
        }

        function onFinished(orgs){
            vm.clearBitOrganisations = orgs;
            logger.info('Found ' + vm.clearBitOrganisations.length + ' Organisations');
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'clear_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.clearBitOrganisations.length;
        }

        // typeahead callback
        function getCountriesForSelectBox(filterByCountryName) {
            return _.filter(vm.refCountries, function (country) {
                if (country.name && filterByCountryName) {
                    return country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1;
                }
            });
        }

        // on select in UI
        function onSetCountryForSelectBox(countrySelected) {
            vm.queryForCountryISO = countrySelected;
        }

        function getCountries() {
            vm.refCountries = CommonService.getCountries();
        }


		function getSelected() {
			if (vm.clearBitOrganisations.selected || vm.clearBitOrganisations.selected.length > 0) {
				return _.map(vm.clearBitOrganisations.selected, 'idAsString');
			}
			return null;
		}

		function checkAll() {
			vm.clearBitOrganisations.selected = angular.copy(vm.clearBitOrganisations);
		}

		function deCheckAll() {
			vm.clearBitOrganisations.selected = null;
		}
    }
})();