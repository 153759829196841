(function() {
	'use strict';

	angular.module('adminApp').factory('filterModel', ['$rootScope', FilterModel]);

	function FilterModel($rootScope) {
		function generateUUID() {
			// Public Domain/MIT
			var d = new Date().getTime();
			if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
				d += performance.now(); //use high-precision timer if available
			}
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				/* jshint bitwise: false */
				var r = ((d + Math.random() * 16) % 16) | 0;
				d = Math.floor(d / 16);
				return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
			});
		}

		function createFilterData(name) {
			return {
				id: generateUUID(),
				name: name,
				included: true,
				value: undefined,
				operand: undefined,
				detalisation: undefined,
				candidate: false
			};
		}

		function FilterItem(parent, data) {
			this.id = generateUUID();
			this.jointNext = 'and';
			this.data = data;
			this.parent = parent;
			this.items = [];
		}

		FilterItem.prototype.appendJoint = function() {
			if (this.hasNext()) {
				return ' ' + this.jointNext + ' ';
			}
			return '';
		};

		FilterItem.prototype.toString = function() {
			var res = '';
			if (this.isGroup() || this.parent === null) {
				if (this.parent === null) {
					res = this.items
						.map(function(item) {
							return item.toString();
						})
						.join(' ');
				} else {
					res =
						'[' +
						this.items
							.map(function(item) {
								return item.toString();
							})
							.join(' ') +
						']';
				}
			} else {
				res =
					'(' +
					(this.data.included ? '' : 'not') +
					':' +
					this.data.name +
					':' +
					this.data.operand +
					': =' +
					JSON.stringify(this.data.value) +
					')';
			}
			res += this.appendJoint();
			return res;
		};

		FilterItem.prototype.numberOfFilters = function() {
			if (this.items.length === 0 && this.parent === null) {
				return 0;
			}
			if (this.items.length === 0) {
				return 1;
			}

			return this.items.reduce(function(acc, item) {
				acc = acc + item.numberOfFilters();
				return acc;
			}, 0);
		};

		FilterItem.prototype.transformToApi = function() {
			if (this.items.length === 0) {
				return this.data;
			} else if (this.items.length === 1) {
				return this.items[0].transformToApi();
			} else if (this.items.length === 2) {
				return {
					condition: this.items[0].jointNext,
					rules: this.items.map(function(item) {
						return item.transformToApi();
					})
				};
			} else if (this.items.length > 2) {
				var startJoint = this.items[0].jointNext;
				var left = _.drop(this.items);
				return _.reduce(
					left,
					function(acc, item, index) {
						if (index === left.length - 1) {
							acc.rules.push(item.transformToApi());
							return acc;
						} else {
							if (item.jointNext === acc.condition) {
								acc.rules.push(item.transformToApi());
								return acc;
							} else {
								acc.rules.push(item.transformToApi());
								var res = {
									condition: item.jointNext,
									rules: [acc.length === 1 ? acc.rules[0] : acc]
								};
								return res;
							}
						}
					}.bind(this),
					{
						condition: startJoint,
						rules: [this.items[0].transformToApi()]
					}
				);
			}
		};

		FilterItem.prototype.serialise = function() {
			return JSON.stringify(
				this,
				function(key, value) {
					if (key === 'parent') {
						return value ? value.id : value;
					}
					return value;
				},
				'\t'
			);
		};

		FilterItem.prototype.findById = function(id) {
			if (this.id === id) {
				return this;
			}

			var res = null;
			_.some(this.items, function(item) {
				var i = item.findById(id);
				if (i) {
					res = i;
					return true;
				}
				return false;
			});

			return res;
		};

		FilterItem.prototype.isGroup = function() {
			return this.items.length > 1;
		};

		FilterItem.prototype.isRoot = function() {
			return this.parent === null;
		};

		FilterItem.prototype.addNewItem = function(data) {
			this.items.push(new FilterItem(this, createFilterData(data)));
		};

		FilterItem.prototype.prependItem = function(item) {
			item.parent = this;
			if (!this.isGroup()) {
				this.convertIntoGroup();
			}
			this.items.unshift(item);
		};

		FilterItem.prototype.prependItemBefore = function(item) {
			var itemIndex = _.findIndex(this.parent.items, this);
			item.parent = this.parent;
			this.parent.items.splice(itemIndex, 0, item);
		};

		FilterItem.prototype.prependNewItemBefore = function(data) {
			var newItem = new FilterItem(this, createFilterData(data));
			this.prependItemBefore(newItem);
		};

		FilterItem.prototype.appendItem = function(item) {
			item.parent = this;
			this.items.push(item);
		};

		FilterItem.prototype.appendItems = function(items) {
			_.forEach(
				items,
				function(item) {
					this.appendItem(item);
				}.bind(this)
			);
		};

		FilterItem.prototype.appendItemAfter = function(item) {
			var itemIndex = _.findIndex(this.parent.items, this);
			item.parent = this.parent;
			this.parent.items.splice(itemIndex + 1, 0, item);
		};

		FilterItem.prototype.appendNewItemAfter = function(data) {
			var newItem = new FilterItem(this, createFilterData(data));
			this.appendItemAfter(newItem);
		};

		FilterItem.prototype.hasNext = function() {
			if (this.parent === null) {
				return false;
			}
			var itemIndex = _.findIndex(this.parent.items, this);
			return itemIndex + 1 < this.parent.items.length;
		};

		FilterItem.prototype.replaceItemWith = function(item, replacement) {
			var itemIndex = _.findIndex(this.items, item);
			this.items[itemIndex] = replacement;
			replacement.parent = this;
		};

		FilterItem.prototype.replaceItemWithItems = function(item, replacements) {
			var itemIndex = _.findIndex(this.items, item);
			_.forEach(
				replacements,
				function(r, i) {
					r.parent = this;
					this.items.splice(itemIndex + i, 0, r);
				}.bind(this)
			);
		};

		FilterItem.prototype.convertIntoGroup = function() {
			this.items = [new FilterItem(this, this.data)];
			this.data = undefined;
		};

		FilterItem.prototype.groupWithNext = function() {
			if (this.parent) {
				this.parent.groupIntoNextItem(this);
			}
		};

		FilterItem.prototype.ungroupWithNext = function() {
			if (!this.hasNext() || this.parent === null) {
				return;
			}
			this.parent.splitOnItem(this);
		};

		FilterItem.prototype.fromArray = function(arr, parent) {
			if (arr.length === 1) {
				arr[0].parent = parent;
				return arr[0];
			} else {
				var nl = new FilterItem(parent);
				nl.items = arr;
				_.forEach(nl.items, function(item) {
					item.parent = nl;
				});
				return nl;
			}
		};

		FilterItem.prototype.splitOnItem = function(item) {
			var itemIndex = _.findIndex(this.items, item);
			var left = this.items.slice(0, itemIndex + 1);
			var right = this.items.slice(itemIndex + 1, this.items.length);

			left = this.fromArray(left, this.parent);
			right = this.fromArray(right, this.parent);

			var selfIndex = _.findIndex(this.parent.items, this);
			this.parent.items[selfIndex] = left;
			this.parent.items.splice(selfIndex + 1, 0, right);
		};

		FilterItem.prototype.groupIntoNextItem = function(item) {
			var itemIndex = _.findIndex(this.items, item);
			if (itemIndex + 1 < this.items.length) {
				var nextItem = this.items[itemIndex + 1];
				if (!item.isGroup()) {
					nextItem.prependItem(item);
					this.items.splice(itemIndex, 1);
				} else {
					if (nextItem.isGroup()) {
						item.appendItems(nextItem.items);
					} else {
						item.appendItem(nextItem);
					}
					this.items.splice(itemIndex + 1, 1);
				}

				if (this.items.length === 1 && this.parent !== null) {
					this.parent.replaceItemWith(this, nextItem);
				}
			}
		};

		FilterItem.prototype.removeItem = function(item) {
			var index = _.findIndex(this.items, item);
			this.items.splice(index, 1);
			if (this.items.length === 1 && this.parent !== null) {
				this.parent.replaceItemWith(this, this.items[0]);
			}
		};

		FilterItem.prototype.remove = function() {
			if (this.isGroup()) {
				return;
			}
			this.parent.removeItem(this);
			$rootScope.$emit('filterRemoved');
		};

		FilterItem.prototype.prepareForMove = function() {
			this.remove();
			return this;
		};

		FilterItem.prototype.insertAfter = function(item) {
			var index = _.findIndex(this.parent.items, item);
			this.parent.items.splice(index + 1, 0, item);
		};

		var from = function(item, parent) {
			var obj = new FilterItem();
			obj.id = item.id;
			obj.jointNext = item.jointNext;
			obj.data = item.data;
			// For tag autocomplete fields
			// if (obj.data && obj.data.value && _.isArray(obj.data.value)) {
			//     obj.data.value = obj.data.value.map(function(item) {
			//         return {text: item};
			//     });
			// }
			obj.parent = parent;
			obj.items = item.items.map(function(c) {
				return 	from(c, obj);
			});
			return obj;
		};

		var deserialize = function(str) {
			var rawFilters = JSON.parse(str);
			return from(rawFilters, null);
		};

		return {
			FilterItem: FilterItem,
			deserialize: deserialize,
			createFilterData: createFilterData,
			generateUUID: generateUUID
		};
	}
})();
