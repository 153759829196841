(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdminMailChimpListMembersController', ['AdminMailChimpService', 'logger', '$state',
            'FileSaver', 'Blob', 'CONFIG', 'TableSort', AdminMailChimpListMembersController]);
    function AdminMailChimpListMembersController(AdminMailChimpService, logger, $state, FileSaver, Blob, CONFIG, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        vm.mcListMembers = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        ////////////
        //  Init
        activate();

        function activate() {
            getMembersInList();
        }

        function getMembersInList() {
            AdminMailChimpService.getMembersInList().then(showResult);
        }

        function showResult(mcListMembers) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.mcListMembers = mcListMembers;
        }
        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'mailchimp_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.mcListMembers.length;
        }

    }
})();