(function() {
	'use strict';

	angular
		.module('adminApp')
		.directive('loading', ['$http', '$rootScope', 'CONFIG', loadingDirective]);

	function loadingDirective($http, $rootScope, CONFIG) {
		return {
			restrict: 'E',
			templateUrl: 'partials/loading.html',
			link: function(scope, element) {
				scope.isLoading = function() {
					return hasPendingBlockingRequests();
				};

				scope.$watch(scope.isLoading, function(isLoading) {
					if (isLoading) {
						showLoadingOverlay(element);
					} else {
						hideLoadingOverlay(element);
					}
				});

				function hasError() {
					return $rootScope.errorData;
				}

				scope.$watch(hasError, function(hasError) {
					if (hasError) {
						hideLoadingOverlay(element);
					}
				});
			},
		};

		function hasPendingBlockingRequests() {
			return _.some($http.pendingRequests, function(request) {
				return isApiRequest(request) && shouldShowLoadingIndicator(request);
			});
		}

		function isApiRequest(request) {
			return (
				_.startsWith(request.url, CONFIG.API_PREFIX) || _.startsWith(request.url, '/mock')
			);
		}

		function shouldShowLoadingIndicator(request) {
			return request.hideLoadingIndicator === undefined || !request.hideLoadingIndicator;
		}
	}

	function showLoadingOverlay(element) {
		resizeLoadingOverlay();
		element.addClass('fadeInLong');
		element.removeClass('fadeOut');
	}

	function hideLoadingOverlay(element) {
		element.addClass('fadeOut');
		element.removeClass('fadeInLong');
	}

	$(window).on('resize', function() {
		resizeLoadingOverlay();
	});

	$(window).on('scroll', function() {
		resizeLoadingOverlay();
	});

	function resizeLoadingOverlay() {
		$('.loading-overlay').css({
			top: $(window).scrollTop(),
			height: $(window).height(),
		});
	}
})();
