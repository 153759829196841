(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('SegmentAdvancedFilterConverter', ['responseExceptionCatcher', 'SegmentAdvancedFilterConstants', 'filterModel', SegmentAdvancedFilterConverter]);

    function SegmentAdvancedFilterConverter(responseExceptionCatcher, SegmentAdvancedFilterConstants, filterModel) {
        this.responseExceptionCatcher = responseExceptionCatcher;
        this.filterModel = filterModel;
        this.segmentfilterConstants = SegmentAdvancedFilterConstants;


	}

    // when segment filter loads from backend
    SegmentAdvancedFilterConverter.prototype.mapFromBackend = function(segmentFilterBackend) {

        var segmentFilterUI = {};

        try {
            segmentFilterUI = {
                id: segmentFilterBackend.id || undefined,
                accountId: segmentFilterBackend.accountId,
                name: segmentFilterBackend.name || '',
				active : segmentFilterBackend.active ,
                //campaigns: segmentFilterBackend.campaigns || [],
                created: segmentFilterBackend.created || (new Date()).valueOf(),
                modified: segmentFilterBackend.modified || (new Date()).valueOf(),
				segmentSizeFromQuery: segmentFilterBackend.segmentSizeFromQuery || 0,
				totalSegmentSize: segmentFilterBackend.totalSegmentSize || 0,
				manuallyAddedLEOrganisationIds: segmentFilterBackend.manuallyAddedLEOrganisationIds || [],
                //siteId: segmentFilterBackend.siteId || '',
                userId: segmentFilterBackend.userId || undefined,
                userName: segmentFilterBackend.userName || '',
                version: segmentFilterBackend.version || '2',
                type: segmentFilterBackend.type || 'STATIC',
				global: segmentFilterBackend.global || false,
				useForMultiUpdates : segmentFilterBackend.useForMultiUpdates || false
            };
            if( segmentFilterBackend.ruleGroupQuery ){
				segmentFilterUI.filters = this.filterModel.deserialize(JSON.stringify(this.convertRuleGroupFromAPI(segmentFilterBackend.ruleGroupQuery)));
			} else {
            	// no rules
				segmentFilterUI.filters = new this.filterModel.FilterItem(null);
			}
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }
        return segmentFilterUI;
    };

	// when loading existing filter from backend
	SegmentAdvancedFilterConverter.prototype.convertRuleGroupFromAPI = function(ruleGroupBackend) {
		var ruleGroupUI = {};
		try {
			ruleGroupUI = {
				id: this.filterModel.generateUUID(),
				jointNext: ruleGroupBackend.ruleGroup.condition,
				parent: null,
				items: []
			};
			var i = 0;
			for (i = 0; i < ruleGroupBackend.ruleGroup.rules.length; i++) {
				ruleGroupUI.items.push(this.convertRuleFromAPI(ruleGroupBackend.ruleGroup.rules[i], ruleGroupBackend.ruleGroup.condition, ruleGroupUI.id));
			}

			for (i = 0; i < ruleGroupBackend.ruleGroup.ruleGroups.length; i++) {
				var tempRuleGroup = {
					id: this.filterModel.generateUUID(),
					jointNext: ruleGroupUI.condition,
					parent: ruleGroupUI.id,
					items: []
				};
				for (var j = 0; j < ruleGroupBackend.ruleGroup.ruleGroups[i].rules.length; j++) {
					tempRuleGroup.items.push(this.convertRuleFromAPI(ruleGroupBackend.ruleGroup.ruleGroups[i].rules[j], ruleGroupBackend.ruleGroup.ruleGroups[i].condition, tempRuleGroup.id));
				}
				ruleGroupUI.items.push(tempRuleGroup);
			}
		} catch(e) {
			console.error(e);
			this.responseExceptionCatcher.catch('Error when converting Rules for Segment');
		}
		return ruleGroupUI;
	};


	// convert from api
	SegmentAdvancedFilterConverter.prototype.convertRuleFromAPI = function(filter, condition, parentId) {
		return {
			id: filter.id,
			jointNext: condition,
			data: {
				id: this.filterModel.generateUUID(),
				name: _.invert(this.segmentfilterConstants.filterFields)[filter.field],
				included: true,
				value: filter.value,
				operand: _.invert(this.segmentfilterConstants.filterOperators)[filter.operator],
				candidate: false
			},
			parent: parentId,
			items: []
		};
	};


	SegmentAdvancedFilterConverter.prototype.convertSegmentFilterToSave = function(segmentUI, ruleGroupQueryUI) {
        var result = {};
        try {
            result = {
                id: segmentUI.id || undefined,
                accountId: segmentUI.accountId,
                name: segmentUI.name || '',
				active : segmentUI.active,
                campaigns: segmentUI.campaigns || [],
                created: segmentUI.created || (new Date()).valueOf(),
                modified: segmentUI.modified || (new Date()).valueOf(),
				manuallyAddedLEOrganisationIds: segmentUI.manuallyAddedLEOrganisationIds || [],
                siteId: segmentUI.siteId || '',
                userId: segmentUI.userId || undefined,
                userName: segmentUI.userName || '',
                version: segmentUI.version || '2',
                type: segmentUI.type || 'STATIC',
                global: segmentUI.global,
				useForMultiUpdates : segmentUI.useForMultiUpdates

            };
            result.ruleGroupQuery = ruleGroupQueryUI? this.convertFiltersToSave(JSON.parse(ruleGroupQueryUI)): {ruleGroup: {}};
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }

        return result;
    };

    SegmentAdvancedFilterConverter.prototype.convertFiltersToSave = function(filters) {
        var result = {};
        try {
            result = {
                scoreThreshold: null,
                limit: 10000,
                page: null,
                pageSize: null,
                ruleGroup: {
                    condition: filters.jointNext,
                    rules: [],
                    ruleGroups: []
                }
            };

            for (var i = 0; i < filters.items.length; i++) {
                if (filters.items[i].items.length > 0) {
                    var tempRuleGroup = {
                        condition: filters.items[i].items[0].jointNext,
                        rules: []
                    };
                    for (var j = 0; j < filters.items[i].items.length; j++) {
                        if (!filters.items[i].items[j].data.included) {
                            continue;
                        }
                        tempRuleGroup.rules.push(this.convertOneFilterToSave(filters.items[i].items[j]));
                    }
                    result.ruleGroup.ruleGroups.push(tempRuleGroup);
                } else {
                    if (!filters.items[i].data.included) {
                        continue;
                    }
                    result.ruleGroup.rules.push(this.convertOneFilterToSave(filters.items[i]));
                }
            }
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }
        return result;
    };

    SegmentAdvancedFilterConverter.prototype.convertOneFilterToSave = function(filter) {
		var field = this.segmentfilterConstants.filterFields[filter.data.name];
        var type = this.segmentfilterConstants.filterTypes(filter.data.operand, field);
        var operator = this.segmentfilterConstants.filterOperators[filter.data.operand];
		var value = filter.data.value;


        if( (operator === 'in' || operator === 'notIn')  && field === this.segmentfilterConstants.filterFields.Country  ){
        	// convert list of objects to iso code (= id)
			value = _.map(filter.data.value, 'id');
		} else if( (operator === 'in' || operator === 'notIn')  && field === this.segmentfilterConstants.filterFields["Industry tags"] ){
			value = _.map(filter.data.value, 'name');

		} else if(( type === 'integerType'  &&  operator === 'beginsWith') ) {
			// industrycodes
			console.log('filter.data.value', filter.data.value)
			type = 'stringType';
			console.log('type', type)
			if ( filter.data.value.indexOf(',') > -1) {
				value = filter.data.value.split(',');
			}

		} else if(( type !== 'integerType'  &&  (operator === 'contains' || operator === 'beginsWith') )) {
			// when we have a list of strings we will do a OR based on array of items
			// indu codes are number so excluded
			if ( filter.data.value.indexOf(',') > -1) {
				value = filter.data.value.split(',');
			}
		} else if( (operator === 'in' || operator === 'notIn')  && field === this.segmentfilterConstants.filterFields["Organisation types"] ){
        	// convert list of objects to iso code (= id)
			value = _.map(filter.data.value, 'id');
		} else if( field === this.segmentfilterConstants.filterFields.ISP ){
        	// always boolean
			value = filter.data.value;
		} else {
			value = filter.data.value? filter.data.value: type === 'integerType'? 0: '';
		}

        var convertdFilter = {
            id: filter.id,
            field: field,
            type: type,
            operator: operator,
            value: value
        };
        console.log('converted' , convertdFilter)
		return convertdFilter;
	};
})();