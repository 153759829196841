(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('StandardSegmentFiltersTableModel', ['$filter','$state','cellRenderers',StandardSegmentFiltersTableModel
		]);

	function StandardSegmentFiltersTableModel($filter,$state,cellRenderers) {

		function goToState(segmentId, accountId) {
			$state.go('admin.segmentAdvancedFiltersShowOrganisations', {
				accountId : accountId,
				segmentFilterId: segmentId
			})
		}

		function nameCellRendererFunc(params) {
			params.$scope.goToState = goToState;
			return '<a ng-click="goToState(data.id, data.accountId)" class="pointer" ng-bind="data.name"></a>';
		}

		var filterValue = '';
		return {
			setFilterValue: function (value) {
				filterValue = value;
			},
			columnDefs: [
				{
					minWidth: 400,
					headerName: 'Name',
					field: 'name',
					headerCheckboxSelectionFilteredOnly: true,
					cellRenderer: nameCellRendererFunc

				},
				{
					headerName: 'Type',
					field: 'type',
					valueGetter: function (params) {
						return params.data.type === 'STATIC'
							? 'Static'
							: params.data.type === 'DYNAMIC'
								? 'Dynamic ': 'Dynamic';
					}
				},
				{
					minWidth: 160,
					headerName: 'No of Organisations',
					field: 'nr orgs',
					valueGetter: function (params) {
						return params.data.totalSegmentSize
							? params.data.totalSegmentSize
							: 0;
					},
					filter: 'number'
				},
				{
					headerName: 'Created By',
					field: 'userName',
					cellClass: 'searchable-cell',
					valueGetter: function (params) {
						return params.data.userName;
					},
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				},
				{
					headerName: 'Updated',
					minWidth: 250,
					field: 'updated',
					valueGetter: function (params) {
						return $filter('date')(params.data.modified, 'yyyy-MM-dd');
					}
				},
				{
					hide : false,
					minWidth: 250,
					headerName: 'Id',
					field: 'id',
					cellClass: 'searchable-cell',
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				}

			]
		};
	}
})();
