(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SegmentAdvancedFilterSettingsController', ['$scope', 'SegmentAdvancedFilterService', 'SegmentAdvancedFilterConstants', 'CampaignBetaConstants',
			SegmentAdvancedFilterSettingsController]);


    function SegmentAdvancedFilterSettingsController($scope, SegmentAdvancedFilterService, SegmentAdvancedFilterConstants, CampaignConstants) {
        var vm = this;
        vm.parent = $scope.vm;

        if (vm.parent.segment) {
            vm.segment = vm.parent.segment;
            //vm.status = vm.segment.status? vm.segment.status: vm.segment.campaigns.length>0? 'active': 'draft';
        }

        vm.segmentID = $scope.$resolve.segmentFilterId;

      /*  vm.daysOfWeek = CampaignConstants.days;
        vm.periodOptions = SegmentAdvancedFilterConstants.periodOptions;

        $scope.$watch('vm.segment.period', function (newValue, oldValue) {
            if (newValue === undefined || oldValue === undefined || newValue === oldValue) {
                return;
            }
            if (newValue === 'week') {
                vm.segment.periodValue = _.cloneDeep(SegmentfilterConstants.emptyWeek);
            } else if (newValue === 'day') {
                vm.segment.periodValue = new Date(1970, 0, 1, 12, 0, 0);
            } else {
                vm.segment.periodValue = 1;
            }
        });*/

    }

})();
