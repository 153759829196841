(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignsController', ['account', 'CampaignService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', '$state', CampaignsController]);

    function CampaignsController(account, CampaignService, FileSaver, Blob, CONFIG, TableSort, $state) {
        var vm = this;
        vm.account = account;
        vm.campaigns = null;
        vm.deleteItem = deleteItem;
        vm.ok = null;
        vm.tableSize = tableSize;
        vm.getCampaigns = getCampaigns;
        vm.doExportToExcel = doExportToExcel;
        vm.searchFilter = '';
        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.loadMore = loadMore;
        vm.onShowCampaignRevenueModels = onShowCampaignRevenueModels;

        activate();

        function activate() {
            getCampaigns(vm.account.id);
        }

        function getCampaigns(accountId) {
            CampaignService.getCampaigns(accountId)
                .then(function (campaigns) {
                    vm.campaigns = campaigns;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Campaigns_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem( campaignId) {
            CampaignService.deleteCampaign(vm.account.id, campaignId)
                .then(function (campaigns) {
                    vm.campaigns = campaigns;
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.campaigns) {
                return vm.campaigns.length;
            }
        }


        function onShowCampaignRevenueModels(){
            $state.go('admin.campaignRevenueModels', {accountId: vm.account.id}, {notify: true});
        }

    }
})();