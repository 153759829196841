(function() {
	'use strict';

	angular
		.module('adminApp')
		.controller('EnhancementQueueController', [
			'EnhancementQueueService',
			'EnhancerSettingsService',
			'account',
			'type',
			'logger',
			'$q',
			'FileSaver',
			'Blob',
			'CONFIG',
			'TableSort',
			'CommonService',
			EnhancementQueueController
		]);
	function EnhancementQueueController(
		EnhancementQueueService,
		EnhancerSettingsService,
		account,
		type,
		logger,
		$q,
		FileSaver,
		Blob,
		CONFIG,
		TableSort,
		CommonService
	) {
		var vm = this;

		vm.enhancedOrganisations = [];
		vm.type = type;
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.queryFor = null;
		vm.account = account;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});
		vm.enhancerSettings = null;
		vm.refCountries = [];

		//methods
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.updateToIsps = updateToIsps;
		vm.updateToNonIsps = updateToNonIsps;
		vm.capitalizeQueueType = capitalizeQueueType;
		vm.getCountryForQueueTypeCountry = getCountryForQueueTypeCountry;
		vm.getAccountNameForQueueTypeAccount = getAccountNameForQueueTypeAccount;
		vm.getForQueueTypeEnhanced = getForQueueTypeEnhanced;
		vm.getPrioQueueHeaderForQueueTypePrio = getPrioQueueHeaderForQueueTypePrio;
		vm.saveEnhancerSettings = saveEnhancerSettings;
		vm.getCountriesForSelectBox = getCountriesForSelectBox;
		vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
		vm.enhanceFromNBKUsingEnhancementQueue = enhanceFromNBKUsingEnhancementQueue;
		vm.removeDuplicatesInAllAccounts = removeDuplicatesInAllAccounts;
		vm.countPendingForCountry = countPendingForCountry;

		vm.checkAll = checkAll;
		vm.deCheckAll = deCheckAll;

		activate();

		function activate() {
			getOrCreateEnhancerSettings();
			getInitialData().then(showData);
		}

		function getOrCreateEnhancerSettings() {
			vm.enhancerSettings = EnhancerSettingsService.getOrCreateEnhancerSettings();
		}

		function saveEnhancerSettings() {
			EnhancerSettingsService.saveEnhancerSettings(vm.enhancerSettings);
		}

		function getInitialData() {
			if (vm.type === 'prio') {
				return $q.all([findAllPendingForHighPrio(), getCountries()]);
			}
			if (vm.type === 'account') {
				return $q.all([findAllPendingForAccount(), getCountries()]);
			}
			if (vm.type === 'country') {
				return $q.all([
					findAllPendingForCountry(),
					getCountries(),
					countPendingForCountry()
				]);
			}
			if (vm.type === 'enhanced') {
				return $q.all([findEnhancedOrFailed(), getCountries()]);
			}
			return $q.all([findAllPendingForHighPrio(), getCountries()]);
		}

		function showData(result) {
			vm.enhancedOrganisations = result[0];
			vm.refCountries = result[1];
			if (result[2]) {
				vm.noPendingInCountry = result[2];
			}
		}

		function getCountries() {
			return CommonService.getCountries();
		}

		/// End init

		function findAllPendingForHighPrio() {
			return EnhancementQueueService.findAllPendingForHighPrio(vm.enhancerSettings.limit);
		}

		function findAllPendingForAccount() {
			return EnhancementQueueService.findAllPendingForAccount(vm.enhancerSettings.limit);
		}

		function findAllPendingForCountry() {
			return EnhancementQueueService.findAllPendingForCountry(
				vm.enhancerSettings.country.countryISO,
				vm.enhancerSettings.limit
			);
		}

		function countPendingForCountry() {
			return EnhancementQueueService.countPendingForCountry(
				vm.enhancerSettings.country.countryISO
			);
		}

		function findEnhancedOrFailed() {
			return EnhancementQueueService.findEnhancedOrFailed(vm.enhancerSettings.limit);
		}

		function capitalizeQueueType() {
			return _.capitalize(vm.type);
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename:
					'enhancedOrganisations_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.enhancedOrganisations.length;
		}

		function checkAll() {
			vm.enhancedOrganisations.selected = angular.copy(vm.enhancedOrganisations);
		}

		function deCheckAll() {
			vm.enhancedOrganisations.selected = null;
		}

		function updateToIsps() {
			var ids = getSelectedEnhancedOrganisations();
			EnhancementQueueService.updateToIsps(ids).then(function() {
				logger.info('Organisation/s updated to ISP');
				getInitialData().then(showData);
			});
		}

		function updateToNonIsps() {
			var ids = getSelectedEnhancedOrganisations();
			EnhancementQueueService.updateToNonIsps(ids).then(function() {
				logger.info('Organisation/s updated to NON ISP');
				getInitialData().then(showData);
			});
		}

		function enhanceFromNBKUsingEnhancementQueue() {
			EnhancementQueueService.enhanceFromNBKUsingEnhancementQueue(
				vm.enhancerSettings.country.countryISO
			).then(function() {
				logger.info('Auto update finished for ' + vm.enhancerSettings.country.countryISO);
				getInitialData().then(showData);
			});
		}

		function removeDuplicatesInAllAccounts() {
			EnhancementQueueService.removeDuplicatesInAllAccounts().then(function() {
				logger.info('Merged duplicates process finished');
				getInitialData().then(showData);
			});
		}

		function getSelectedEnhancedOrganisations() {
			if (vm.enhancedOrganisations.selected || vm.enhancedOrganisations.selected.length > 0) {
				return _.map(vm.enhancedOrganisations.selected, 'id');
			}
			return null;
		}

		function getCountryForQueueTypeCountry() {
			if (vm.type === 'country' && vm.enhancerSettings.country.countryISO) {
				return CommonService.getCountryFromIsoCode(vm.enhancerSettings.country.countryISO);
			}
		}

		function getAccountNameForQueueTypeAccount() {
			if (vm.type === 'account') {
				return vm.account.name;
			}
		}

		function getForQueueTypeEnhanced() {
			if (vm.type === 'enhanced') {
				return 'enhanced or failed';
			}
		}

		function getPrioQueueHeaderForQueueTypePrio() {
			if (vm.type === 'prio') {
				return 'high prio enhancements';
			}
		}

		// typeahead callback
		function getCountriesForSelectBox(filterByCountryName) {
			return _.filter(vm.refCountries, function(country) {
				if (country.name && filterByCountryName) {
					return (
						country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1
					);
				}
			});
		}

		// on select in UI
		function onSetCountryForSelectBox(countrySelected) {
			vm.enhancerSettings.country = countrySelected;
		}
	}
})();
