(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminTokenService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AdminTokenService]);

    function AdminTokenService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAllTokens = findAllTokens;
        this.getToken = getToken;
        this.updateToken = updateToken;
        this.regenerateToken = regenerateToken;


        function findAllTokens(active) {
            var url = CONFIG.API_PREFIX + '/admin/tokens';
            if( active !== undefined &&  !active ){
                url = CONFIG.API_PREFIX + '/admin/tokens?active=false';
            }
            return $http.get( url )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function getToken(accountId, tokenId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/tokens/' + tokenId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function regenerateToken(accountId, tokenId) {
            return $http.put( CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/tokens/' + tokenId + '/regenerate' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // creation is done when adding a product to an account
        function updateToken(accountid, token) {
            var promise;
            if (!(_.isNull(token.id) || token.id === undefined || token.id === '')) {
                promise = $http.put( CONFIG.API_PREFIX + '/admin/accounts/' + accountid + '/tokens/', token)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            }
            return promise;
        }
    }
})();