(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('AnalyticsService', ['$http', 'CONFIG', 'responseExceptionCatcher', AnalyticsService]);

	function AnalyticsService($http, CONFIG, responseExceptionCatcher) {
		this.getTotalsGroupedByResellerAndAccountAll = getTotalsGroupedByResellerAndAccountAll;
		this.getTotalsGroupedByResellerAndAccountYTD = getTotalsGroupedByResellerAndAccountYTD;
		this.getTotalsGroupedByResellerAndAccountStartOfMonth = getTotalsGroupedByResellerAndAccountStartOfMonth;
		this.getTotalsGroupedByResellerAndAccountStartOfWeek = getTotalsGroupedByResellerAndAccountStartOfWeek;
		this.getTotalsGroupedByAccountAll = getTotalsGroupedByAccountAll;
		this.getTotalsGroupedByAccountYTD = getTotalsGroupedByAccountYTD;
		this.getTotalsGroupedByAccountStartOfMonth = getTotalsGroupedByAccountStartOfMonth;
		this.getTotalsGroupedByAccountStartOfWeek = getTotalsGroupedByAccountStartOfWeek;
		this.getTotalsGroupedByAccountAndOrganisationAll = getTotalsGroupedByAccountAndOrganisationAll;
		this.getTotalsGroupedByAccountAndOrganisationYTD = getTotalsGroupedByAccountAndOrganisationYTD;
		this.getTotalsGroupedByAccountAndOrganisationStartOfMonth = getTotalsGroupedByAccountAndOrganisationStartOfMonth;
		this.getTotalsGroupedByAccountAndOrganisationStartOfWeek = getTotalsGroupedByAccountAndOrganisationStartOfWeek;

		this.getTotalsGroupedByDateAll = getTotalsGroupedByDateAll;
		this.getTotalsGroupedByDateYTD = getTotalsGroupedByDateYTD;
		this.getTotalsGroupedByDateStartOfMonth = getTotalsGroupedByDateStartOfMonth
		;
		this.getTotalsGroupedByDateStartOfWeek = getTotalsGroupedByDateStartOfWeek;

		function getTotalsGroupedByResellerAndAccountAll() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByResellerAndAccountAll';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByResellerAndAccountYTD() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByResellerAndAccountYTD';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByResellerAndAccountStartOfMonth() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByResellerAndAccountStartOfMonth';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByResellerAndAccountStartOfWeek() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByResellerAndAccountStartOfWeek';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountAll() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountAll';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountYTD() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountYTD';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountStartOfMonth() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountStartOfMonth';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountStartOfWeek() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountStartOfWeek';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		//
		function getTotalsGroupedByAccountAndOrganisationAll() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountAndOrganisationAll';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountAndOrganisationYTD() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountAndOrganisationYTD';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountAndOrganisationStartOfMonth() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountAndOrganisationStartOfMonth';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByAccountAndOrganisationStartOfWeek() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByAccountAndOrganisationStartOfWeek';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

	//
		function getTotalsGroupedByDateAll() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByDateAll';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByDateYTD() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByDateYTD';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByDateStartOfMonth() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByDateStartOfMonth';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getTotalsGroupedByDateStartOfWeek() {
			var url = CONFIG.API_PREFIX + '/admin/analytics/getTotalsGroupedByDateStartOfWeek';
			return $http.get(url)
				.then(onSuccess)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function onSuccess(response) {
			return response.data;
		}
	}
})();