(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('dunsFilter', ['filterValidation', 'filterConstants', DUNSFilter]);


    function DUNSFilter(filterValidation, filterConstants) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/filters/organisation/DUNSFilter.html',
            link: function (scope) {

				scope.opers = filterConstants.operands;
				scope.operands = [
					scope.opers.EQUAL,
					scope.opers.NOT_EQUAL,
					scope.opers.CONTAINS,
					scope.opers.DOES_NOT_CONTAINS,
					scope.opers.BEGIN_WITH,
					scope.opers.DOES_NOT_BEGIN_WITH
				];

                if(scope.filter.data.value === undefined) {
                    scope.filter.data.value = '';
                }

                if(scope.filter.data.operand === undefined) {
                    scope.filter.data.operand = scope.opers.EQUAL;
                }


            }
        };
    }

})();