(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('SegmentAdvancedFilterService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', 'logger', 'SegmentAdvancedFilterConverter', SegmentAdvancedFilterService]);

    function SegmentAdvancedFilterService($http, $q, CONFIG, responseExceptionCatcher, logger, SegmentAdvancedFilterConverter) {
        var vm = this;
        vm.getSegment = getSegment;
        vm.getSegments = getSegments;
        vm.getStandardSegments = getStandardSegments;
        vm.findAllForAccountAndUseForMultiUpdates = findAllForAccountAndUseForMultiUpdates;
        vm.cloneSegments = cloneSegments;
        vm.copyStandardSegmentsToAccount = copyStandardSegmentsToAccount;
        vm.deleteSegments = deleteSegments;
        vm.createOrUpdateSegment = createOrUpdateSegment;
        vm.renameSegmentFilterName = renameSegmentFilterName;
        vm.deleteSegment = deleteSegment;
        vm.isNameAvailable = isNameAvailable;
        vm.inActivate = inActivate;
        vm.findOrganisationsByRules = findOrganisationsByRules;
        vm.findOrganisationsBySegmentId = findOrganisationsBySegmentId;
        vm.exportSegmentIPs = exportSegmentIPs;
        vm.exportSegmentOrganisations = exportSegmentOrganisations;
        vm.importOrganisations = importOrganisations;
        vm.createSegmentFileWithOrganisationss = createSegmentFileWithOrganisationss;
        vm.addLeIdsToSegmentFilter = addLeIdsToSegmentFilter;
        vm.convertOldToNew = convertOldToNew;
        vm.enhanceDomain = enhanceDomain;
        vm.enhanceDomainEnhancementCBITQueue = enhanceDomainEnhancementCBITQueue;
        vm.segmentsAdvancedCreateRequestForNBKEnhancement = segmentsAdvancedCreateRequestForNBKEnhancement;
       // vm.createSegmentFileWithIPsUploadToS3AndSendEmail = createSegmentFileWithIPsUploadToS3AndSendEmail;
        vm.clone = clone;

        //var cache = $cacheFactory('cacheIdSegmentFilterOrganisations');


        function getSegment(accountId, segmentId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' + segmentId, config)
                .then(transformFromJson)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // find segments to add to campaign
        function getSegments(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


		// find segments to add to campaign
		function getStandardSegments(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvancedStandard')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function findAllForAccountAndUseForMultiUpdates(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/multiUpdates')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteSegments(accountId, segmentsIds) {
			var deleteThese = {
				leIds: segmentsIds
			};
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/delete', deleteThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function cloneSegments(accountId, segmentIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/clone', segmentIds)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function copyStandardSegmentsToAccount(accountId, segmentIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/copyToAccount', segmentIds)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function convertOldToNew(accountId) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/convert')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateSegment(accountId, segment, filters) {
            var promise;
            var segmentFilterToSave = transformSegmentFilterToJson(segment);
            segmentFilterToSave.accountId = accountId;
            segmentFilterToSave = SegmentAdvancedFilterConverter.convertSegmentFilterToSave(segmentFilterToSave, filters);

            if (!(_.isNull(segmentFilterToSave.id) || segmentFilterToSave.id === undefined || segmentFilterToSave.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/', segmentFilterToSave)
                    .then(transformFromJson)
					.then(function(segment) {
						return segment;
					})
                    .catch(responseExceptionCatcher.catch('Error when saving segment '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/', segmentFilterToSave)
                    .then(transformFromJson)
                    .then(function(segment) {
                        //vm.newSavedSegment = segment;
                        return segment;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving segment '));
            }
            return promise;
        }


        function  renameSegmentFilterName(accountId, segmentFilterId, segmentFilterName ){
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + segmentFilterId + '/rename', segmentFilterName)
                .then(function () {
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function deleteSegment(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' + id)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function enhanceDomain(accountId, segmentIds) {
		   var useThese = {
			   leIds: segmentIds
		   };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/createRequestForDomainEnhancment', useThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


       function enhanceDomainEnhancementCBITQueue(accountId, segmentIds) {
		   var useThese = {
			   leIds: segmentIds
		   };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/createRequestForDomainEnhancementCBIT', useThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


       function segmentsAdvancedCreateRequestForNBKEnhancement(accountId, segmentIds) {
		   var useThese = {
			   leIds: segmentIds
		   };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/createRequestForNBKEnhancment', useThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function isNameAvailable() {
            return $q.resolve(true);
            // return $http.get('/mock/segments?name=' + name).then(function(response) {
            //     return response.data;
            // });
        }

        function inActivate(accountId, segmentIds) {
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/inactivate', segmentIds)
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function clone(accountId, segmentIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/clone' , segmentIds)
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findOrganisationsByRules(accountId, rules) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/search/rules', rules)
                .then(function(response) {
                	console.log('res', response.data)

                    return _.filter(response.data.organisations, function(organisation) {
                        return organisation;
                    });
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


     function findOrganisationsBySegmentId(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' +  segmentId +  '/search')
                .then(function(response) {
                    return _.filter(response.data.organisations, function(organisation) {
                        return organisation;
                    });
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function transformFromJson(response) {
            return SegmentAdvancedFilterConverter.mapFromBackend(response.data);
        }



        function transformSegmentFilterToJson(segmentFilterToSave) {
            // Create a deep clone
            return $.extend(true, {}, segmentFilterToSave);
        }

        function exportSegmentIPs(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' + segmentId + '/export',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function exportSegmentOrganisations(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' + segmentId + '/exportOrganisations',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function importOrganisations(accountId, segmentId, leidsCommaSeperated) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segmentsAdvanced/' + segmentId + '/importOrganisations', leidsCommaSeperated)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createSegmentFileWithOrganisationss(accountId, segmentId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/organisations/filters/' + segmentId + '/export/organisations' ,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }



        // used from visits controller
        function addLeIdsToSegmentFilter(accountId, segmentId, leIds){
            var ids = { leIds : leIds };
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/segments/' + segmentId +  '/add', ids)
                .catch(responseExceptionCatcher.catch('Error when saving segment '));
        }

    }
})();