(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('AudiencesController', ['$state', '$scope', '$uibModal', '$element', '$window', '$timeout', 'logger', 'account', 'AudienceService', 'AudiencesTableModel', AudiencesController]);

	function AudiencesController($state, $scope, $uibModal, $element, $window, $timeout, logger, account, AudienceService, AudiencesTableModel) {
		var vm = this;
		vm.account = account;
		vm.audiences = null;
		vm.ok = null;
		vm.selectedNodes = [];
		vm.initialized = {value: false};
		vm.panelHeights = 120;
		vm.globalSearch = '';
		vm.deleteSelected = deleteSelected;
		vm.cloneSelected = cloneSelected;
		vm.doExportToExcel = doExportToExcel;
		vm.confirmDelete = confirmDelete;
		vm.confirmClone = confirmClone;
		vm.newAudience = newAudience;


		function doExportToExcel() {
			$scope.$broadcast('exportDataAsExcel');
		}

		function deleteSelected() {
			// only Not Active audiences can be deleted
			var activeAudiences = _.find(vm.selectedNodes, function (node) {
				return node.data.status === 'ACTIVE';
			});
			if (activeAudiences !== undefined) {
				logger.error('Selected Audience(s) contained an Active Audience');
				return;
			}
			var audiencesIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			AudienceService.deleteAudiences(vm.account.id, audiencesIds)
				.then(function () {
					logger.success('You have deleted Audience(s).');
					$scope.$broadcast('updateTable');
				});
		}

		function cloneSelected() {
			var audiencesIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			AudienceService.cloneAudiences(vm.account.id, audiencesIds)
				.then(function () {
					logger.success('You have created clone(s).');
					$scope.$broadcast('updateTable');
				});
		}

		vm.audiencesTable = {
			getFn: function () {
				return AudienceService.getAudiences(vm.account.id)
					.then(function (audiences) {
						vm.audiences = audiences;
						return audiences;
					});
			},
			enableFilter: true,
			enableSorting: true,
			animateRows: true,
			isExternalFilterPresent: isExternalFilterPresent,
			doesExternalFilterPass: doesExternalFilterPass,
			onRowsNumberChanged: function () {
				filterChanged();
			},
			columnDefs: AudiencesTableModel.columnDefs,
			angularCompileRows: true
		};

		vm.globalSearchChanged = function () {
			AudiencesTableModel.setFilterValue(vm.globalSearch);
			vm.audiencesTable.grid.api.onFilterChanged();
		};

		$scope.$on('$destroy', onDestroy);
		angular.element($window).bind('resize', resizeContentHeight);

		$timeout(function () {
			resizeContentHeight();
		});

		function filterChanged() {
			var sel = $element.find('.searchable-cell');
			if (vm.globalSearch) {
				sel.unmark();
				sel.mark(vm.globalSearch);
			} else {
				sel.unmark();
			}
		}

		function isExternalFilterPresent() {
			return vm.globalSearch.length > 0;
		}

		function doesExternalFilterPass(node) {
			var query = vm.globalSearch.toLowerCase();
			return node.data.name.toLowerCase().indexOf(query) >= 0;
		}

		function resizeContentHeight() {
			if ($(window).height() > 600) {
				$element.css('height', $(window).height() - vm.panelHeights);
			} else {
				$element.css('height', 800 - vm.panelHeights);
			}
		}

		function onDestroy() {
			angular.element($window).off('resize');
		}


		function confirmDelete() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to delete the selected Audiences?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				deleteSelected();
			});
		}

		function confirmClone() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to clone the selected Audiences?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				cloneSelected();
			});
		}

		function newAudience() {
			$state.go('admin.audience', {accountId: vm.account.id, audienceId: null});
		}
	}
})();