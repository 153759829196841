(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ReportController', ['account', 'user', 'reportId', '$q', '$state', 'logger', 'ReportService', 'VisitFilterService', 'STATE', '$uibModal', ReportController]);

    function ReportController(account, user, reportId, $q, $state, logger, ReportService, VisitFilterService, STATE,$uibModal) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.saveReport = saveReport;
        vm.showReportColumns = showReportColumns;
        vm.validateReceivers = validateReceivers;
        vm.runReport = runReport;
        vm.showReportExample = showReportExample;

        // this is resolved from ui-router if any
        vm.reportId = reportId;
        vm.account = account;
        vm.start = moment().subtract(30, 'days').toDate();
        vm.end = moment().toDate();

        // reference data
        vm.refFilters = [];
        vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};

        vm.refReportColumns = [{id: 'ORGANISATION', name: 'Organisation'},
            {id: 'POSTAL_CODE', name: 'Postal code'},
            {id: 'CITY', name: 'City'},
            {id: 'COUNTRY', name: 'Country'},
            {id: 'PHONE', name: 'Phone'},
            {id: 'STARTDATE', name: 'Start date'},
            {id: 'FAX', name: 'Fax'},
            {id: 'SIC', name: 'SIC'},
            {id: 'NO_OF_EMPLOYEES', name: 'No of employees'},
            {id: 'TIME', name: 'Time'},
            {id: 'PAGE_VIEWS', name: 'Page views'},
            {id: 'SCORE', name: 'Score'},
            {id: 'PAGES', name: 'Pages'},
            {id: 'KEYWORDS', name: 'Keywords'},
            {id: 'QUERY_TERMS', name: 'Query terms'},
            {id: 'ORGANISATION_ID', name: 'Organisation id'},
            {id: 'REFERER', name: 'Referer'}
        ];

        vm.refReportTemplate = [{id: 'VISITS_SIMPLE', name: 'Visits Report (html)'},
            {id: 'VISITS_WITH_MAP_REPORT', name: 'Visits with Map Report (html)'},
            {id: 'VISITS_CUSTOMIZED', name: 'Visits (excel)'},
            {id: 'VISITS_AND_PAGES_CUSTOMIZED', name: 'Visits & Pages (excel)'},
            {id: 'VISITS_AND_VISITORS_SIMPLE', name: 'Visits & Visitors (html)'}

        ];

        vm.refReportTypes = [{id: 'NORMAL', name: 'Normal using Visit Filter '},
            {id: 'NORMAL_GROUP_BY_ORGANSATIONAMEAND_COUNTRY', name: 'Normal Grouped by Organisation Name and Country'},
            {id: 'NORMAL_GROUP_BY_ORGANSATIONS', name: 'Normal Grouped by Organisation Name, Country and City'},
            {id: 'ACCOUNT_MISSED_VISITS', name: 'Missed Visits Excluding Visit Filter'}
        ];

        vm.refReportSchedule = [{id: 'DAILY_ALL', name: 'Daily'},
            {id: 'WEEKLY_MONDAY', name: 'Weekly Monday'},
            {id: 'WEEKLY_TUESDAY', name: 'Weekly Tuesday'},
            {id: 'WEEKLY_WEDNESDAY', name: 'Weekly Wednesday'},
            {id: 'WEEKLY_THURSDAY', name: 'Weekly  Thursday'},
            {id: 'WEEKLY_FRIDAY', name: 'Weekly Friday'},
            {id: 'WEEKLY_SATURDAY', name: 'Weekly  Saturday'},
            {id: 'WEEKLY_SUNDAY', name: 'Weekly Sunday'},
            {id: 'MONTHLY', name: 'Monthly'}
        ];

        vm.refReportScheduleHourOfDay = [
            {id: '1', name: '01:00'},
            {id: '2', name: '02:00'},
            {id: '3', name: '03:00'},
            {id: '4', name: '04:00'},
            {id: '5', name: '05:00'},
            {id: '6', name: '06:00'},
            {id: '7', name: '07:00'},
            {id: '8', name: '08:00'},
            {id: '9', name: '09:00'},
            {id: '10', name: '10:00'},
            {id: '11', name: '11:00'},
            {id: '12', name: '12:00'},
            {id: '13', name: '13:00'},
            {id: '14', name: '14:00'},
            {id: '15', name: '15:00'},
            {id: '16', name: '16:00'},
            {id: '17', name: '17:00'},
            {id: '18', name: '18:00'},
            {id: '19', name: '19:00'},
            {id: '20', name: '20:00'},
            {id: '21', name: '21:00'},
            {id: '22', name: '22:00'},
            {id: '23', name: '23:00'},
            {id: '24', name: '24:00'}

        ];


        // initial available column, used when we create a new report
        vm.leftColumns = [
            {id: 'POSTAL_CODE', icon: './img/icons/gmail.jpg', title: 'POSTAL_CODE'},
            {id: 'PHONE', icon: './img/icons/gmail.jpg', title: 'PHONE'},
            {id: 'FAX', icon: './img/icons/gmail.jpg', title: 'FAX'},
            {id: 'SIC', icon: './img/icons/gmail.jpg', title: 'SIC'},
            {id: 'NO_OF_EMPLOYEES', icon: './img/icons/gmail.jpg', title: 'NO_OF_EMPLOYEES'},
            {id: 'SCORE', icon: './img/icons/gmail.jpg', title: 'SCORE'},
            {id: 'KEYWORDS', icon: './img/icons/gmail.jpg', title: 'KEYWORDS'},
            {id: 'QUERY_TERMS', icon: './img/icons/gmail.jpg', title: 'QUERY_TERMS'},
            {id: 'ORGANISATION_ID', icon: './img/icons/gmail.jpg', title: 'ORGANISATION_ID'},
            {id: 'REFERER', icon: './img/icons/gmail.jpg', title: 'REFERER'}
        ];

        // initial preset column, used when we create a new report
        vm.rightColumns = [
            {id: 'ORGANISATION', icon: './img/icons/gmail.jpg', title: 'ORGANISATION'},
            {id: 'ADDRESS', icon: './img/icons/gmail.jpg', title: 'ADDRESS'},
            {id: 'KEYWORDS', icon: './img/icons/gmail.jpg', title: 'KEYWORDS'},
            {id: 'CITY', icon: './img/icons/gmail.jpg', title: 'CITY'},
            {id: 'COUNTRY', icon: './img/icons/gmail.jpg', title: 'COUNTRY'},
            {id: 'STARTDATE', icon: './img/icons/gmail.jpg', title: 'STARTDATE'},
            {id: 'TIME', icon: './img/icons/gmail.jpg', title: 'TIME'},
            {id: 'PAGE_VIEWS', icon: './img/icons/gmail.jpg', title: 'PAGE_VIEWS'},
            {id: 'PAGES', icon: './img/icons/gmail.jpg', title: 'PAGES'}
        ];


        // initial if creating new
        vm.report = {
            id: vm.reportId,
            accountId: account.id,
            siteId: account.defaultSiteId,
            hourOfDay: 6,
            emailTemplate: 'VISITS_SIMPLE',
            type: 'NORMAL',
            schedule: 'DAILY_ALL',
            active: true,
            userId: user.id,
            reportTemplate: 'VISITS_SIMPLE'
        };


        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData()
                .then(showInitialView);
        }

        function getInitialData() {
            return $q.all([getReport(), getVisitFilteters()]);
        }

        function getVisitFilteters() {
            return VisitFilterService.getVisitFilters(vm.account.id,{hideLoadingIndicator: true});
        }


        function getReport() {
            if (_.isNull(vm.reportId) || _.isEmpty(vm.reportId)) {
                // on create
                return vm.report;
            } else {
                return ReportService.getReport(vm.account.id, vm.reportId);
            }
        }

        function showInitialView(result) {
            vm.report = result[0];
            vm.refVisitFilters = result[1];
            if (vm.refVisitFilters.length > 0) {
                vm.report.visitFilter = vm.refVisitFilters[0].id;
            } else {
                logger.warning( 'No Visit Filters have been created. You need at least one to be able to create a Report'  );
            }
            // when we get a report back update the report columns, if any
            initSelectedColumns();
            initAvailableColumn();
        }


        function initSelectedColumns() {
            if (vm.report.selectedReportColumns) {
                vm.rightColumns = [];
                _.each(vm.report.selectedReportColumns, function (column) {
                    var aCol = {id: column, title: column, icon: './img/icons/gmail.jpg',};
                    vm.rightColumns.push(aCol);
                });
            }
        }


        function validateReceivers(values) {
            var valid = false;
            _.each(values, function(email) {
                if( email.indexOf('@') === -1){
                    valid = false;
                } else {
                    valid = true;
                }
            });
            return valid;
        }

        function initAvailableColumn() {
            if (vm.report.availableReportColumns) {
                vm.leftColumns = [];
                _.each(vm.report.availableReportColumns, function (column) {
                    var aCol = {id: column, title: column, icon: './img/icons/gmail.jpg',};
                    vm.leftColumns.push(aCol);
                });
            }
        }

        /// End init
        ///////////////

		function showReportExample(){
			$uibModal.open({
				templateUrl: 'partials/discover/reports/examples/report-html-example.html',
				controller: 'ReportExampleController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Example Report ' ,
							body: 'An example report with dummy data ',
							positive: 'Ok',
							report: vm.report

						};
					}
				}
			}).result.then(function () {
			});
		}

        function showReportColumns() {
            if (vm.report.reportTemplate === 'VISITS_CUSTOMIZED' || vm.report.reportTemplate === 'VISITS_AND_PAGES_CUSTOMIZED') {
                return true;
            }
            return false;
        }

        function saveReport() {
            // take over columns
            if (showReportColumns()) {
                vm.report.selectedReportColumns = _.map(vm.rightColumns, 'id');
                vm.report.availableReportColumns = _.map(vm.leftColumns, 'id');
            } else {
                // clear columns out for non excel reports
                vm.report.selectedReportColumns = [];
                vm.report.availableColumns = [];
            }
            return $q.all([onSaved()]);
        }

        function onSaved() {
            return ReportService.createOrUpdateReport(vm.account.id, vm.report)
                .then(function (data) {
                    vm.report = data;
                    initSelectedColumns();
                    initAvailableColumn();
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Visit Report successfully saved ');
            $state.go(STATE.VISIT_REPORT, {accountId: vm.account.id, reportId: vm.report.id}, {notify: false});
        }

        function runReport() {
            if( vm.report.id ){
                ReportService.runReport(vm.account.id, vm.report.id)
                    .then(function () {
                        logger.info('Report has been scheduled to run!');
                    });
            }
        }

        ///////////

        function createOptions() {
            var options = {
                placeholder: 'report-column',
                connectWith: '.report-columns-container',
                activate: function () {
                },
                beforeStop: function () {
                },
                change: function () {
                },
                create: function () {
                },
                deactivate: function () {
                },
                out: function () {
                },
                over: function () {
                },
                receive: function () {
                },
                remove: function () {
                },
                sort: function () {
                },
                start: function () {
                },
                stop: function () {
                },
                update: function () {
                }
            };
            return options;
        }

        vm.sortableOptionsList = [createOptions('A'), createOptions('B')];

    }
})();