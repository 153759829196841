(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('switchInput', [SwitchInput]);

    function SwitchInput() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/inputs/switch-input.html',
            scope: {
                options: '=?'
            },
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                ngModel.$render = function () {
                    _.forEach(scope.options, function (option) {
                       if(option.value === ngModel.$modelValue) {
                           option.selected = true;
                       }
                    });
                };

                scope.optionClicked = function (event, option) {
                    _.forEach(scope.options, function (o) {
                       o.selected = false;
                    });
                    option.selected = true;
                    ngModel.$setViewValue(option.value);
                };

            }
        };
    }


})();