(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('UserLoginStatisticsLogService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', UserLoginStatisticsLogService]);

    function UserLoginStatisticsLogService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAll = findAll;

        function findAll(userName, startDate) {
        	console.log('from' , startDate)

        	var url = '/admin/userstatistivcslog';
        	if( userName !== null && userName !== undefined && userName !== '' && userName !== 'undefined' && from ){
				url = url + '?userName=' +userName + '&startDate=' + startDate;
			} else if (startDate){
				url = url + '?startDate=' + startDate;
			}
            return $http.get(CONFIG.API_PREFIX + url)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


    }
})();