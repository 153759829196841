(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignsAdvancedController', ['$scope','$uibModal', '$element', '$window', '$timeout', 'logger', 'account', 'CampaignAdvancedService', 'CampaignsAdvancedTableModel', CampaignsAdvancedController]);

    function CampaignsAdvancedController($scope,$uibModal, $element, $window, $timeout, logger, account, CampaignAdvancedService, CampaignsAdvancedTableModel) {
        var vm = this;
        vm.account = account;
        vm.campaigns = null;
        vm.ok = null;
		vm.selectedNodes = [];
		vm.initialized = {value: false};
		vm.panelHeights = 120;
		vm.globalSearch = '';
		vm.deleteSelected = deleteSelected;
		vm.cloneSelected = cloneSelected;
		vm.doExportToExcel = doExportToExcel;
		vm.confirmDelete = confirmDelete;
		vm.confirmClone = confirmClone;


        function doExportToExcel() {
            $scope.$broadcast('exportDataAsExcel');
        }

        function deleteSelected() {
        	// only Not Active campaigns can be deleted
			var activeCampaigns = _.find(vm.selectedNodes, function(node) {
				return node.data.status === 'ACTIVE';
			});
			if( activeCampaigns !== undefined  ){
				logger.error('Selected Campain(s) contained an Active Campaign');
				return;
			}
			var campaignsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
            CampaignAdvancedService.deleteCampaigns(vm.account.id, campaignsIds)
                .then(function() {
					logger.success('You have deleted Campaign(s).');
                    $scope.$broadcast('updateTable');
                });
        }

        function cloneSelected() {
            var campaignsIds = _.map(vm.selectedNodes, function(node){return node.data.id;});
            CampaignAdvancedService.cloneCampaigns(vm.account.id, campaignsIds)
                .then(function(){
                    logger.success('You have created clone(s).');
                    $scope.$broadcast('updateTable');
                });
        }

        vm.campaignsTable = {
            getFn: function() {
                return CampaignAdvancedService.getCampaigns(vm.account.id)
                    .then(function (campaigns) {
                        vm.campaigns = campaigns;
                        return campaigns;
                    });
            },
            enableFilter: true,
            isExternalFilterPresent: isExternalFilterPresent,
            doesExternalFilterPass: doesExternalFilterPass,
            onRowsNumberChanged: function () {
                filterChanged();
            },
            columnDefs: CampaignsAdvancedTableModel.columnDefs
        };

        vm.globalSearchChanged = function () {
			CampaignsAdvancedTableModel.setFilterValue(vm.globalSearch);
            vm.campaignsTable.grid.api.onFilterChanged();
        };

        $scope.$on('$destroy', onDestroy);
        angular.element($window).bind('resize', resizeContentHeight);

        $timeout(function() {
            resizeContentHeight();
        });

        function filterChanged() {
            var sel = $element.find('.searchable-cell');
            if(vm.globalSearch) {
                sel.unmark();
                sel.mark(vm.globalSearch);
            } else {
                sel.unmark();
            }
        }

        function isExternalFilterPresent() {
            return vm.globalSearch.length > 0;
        }

        function doesExternalFilterPass(node) {
            var query = vm.globalSearch.toLowerCase();
            return node.data.name.toLowerCase().indexOf(query) >= 0;
        }

        function resizeContentHeight() {
            if ($(window).height() > 600) {
                $element.css('height', $(window).height() - vm.panelHeights);
            } else {
                $element.css('height', 800 - vm.panelHeights);
            }
        }

        function onDestroy() {
            angular.element($window).off('resize');
        }


		function confirmDelete(){
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to delete the selected Campaigns?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				deleteSelected();
			});
		}

		function confirmClone(){
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to clone the selected Campaigns?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				cloneSelected();
			});
		}
    }
})();