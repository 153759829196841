(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdaptiveContentController', ['account', 'targetingId', 'AdaptiveContentService', 'logger', '$q',  '$state', 'STATE', 'QueryBuilder', 'Rule', 'RULE_FIELDS', AdaptiveContentController]);

    function AdaptiveContentController(account, targetingId, AdaptiveContentService, logger, $q, $state, STATE, QueryBuilder, Rule, RULE_FIELDS) {
        var vm = this;
        vm.account = account;
        vm.targetingId = targetingId;
        vm.selected = undefined;
        vm.searchFilter = '';

        // method declarations
        vm.onClick = onClick;
        vm.saveTargeting = saveTargeting;
        vm.disableSave = disableSave;
        vm.addTargetingRule = addTargetingRule;
        vm.activate = activate;
        vm.startDateChanged = startDateChanged;
        vm.deleteTargetingRule = deleteTargetingRule;
        vm.searchWithQuery = searchWithQuery;

        var start = moment().toDate();
        var end = moment().add(3, 'months').toDate();

        // initial if creating new
        vm.targeting = {
            id: targetingId,
            accountId: account.id,
            name: null,
            description: null,
            start: start,
            end: end,
            ignoreIsp :true,
            active : true,
            rules: []
        };

        vm.newTargetingRule = {
            name: null,
            type: null,
            value: null,
            valueMax: null,
            response: null
        };

        // reference data
        vm.refSelectIgnoreIsps = [{id: true, name: 'Ignore'}, {id: false, name: 'Do not ignore'}];
        vm.refSelectActive = [{id: true, name: 'Active'}, {id: false, name: 'Not active'}];
        vm.refSelectOperators = [{id: 1, name: 'Less than'}, {id: 2, name: 'Equal'}, {id: 3,name: 'Greater then'}, {id: 4, name: 'Less then'}, {id: 5, name: 'Does not contain'}, {id: 6, name: 'Between'}];
        vm.refSelectTypes = [{id: 1, name: 'Sales'}, {id: 2, name: 'Employees'},{id: 3, name: 'Org. ids'}, {id: 4, name: 'DUNS Ids'},{id: 5, name: 'Country Codes'}, {id: 6, name: 'SIC codes'},{id: 7, name: 'Org. Name'}, {id: 8, name: 'SNI/Nace codes'}];
        vm.datePickerOptionsEndDate = { formatYear: 'yy', startingDay: 1 };
        vm.datePickerOptionsStarDate = { formatYear: 'yy', startingDay: 1};

        //
        vm.queryBuilder = new QueryBuilder(10);
        var organisationNameRule = new Rule( new Date() , RULE_FIELDS.properties[ RULE_FIELDS.organisationsName].name, 'textMatch', 'stringType');
        vm.queryBuilder.addNewRuleToRootGroup( organisationNameRule);
        organisationNameRule.value = 'handelsbanken';

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getTargeting()]);
        }

        function getTargeting() {
            if (_.isNull(vm.targetingId) || _.isEmpty(vm.targetingId)) {
                // initial when no campaign found
                return vm.targeting;
            } else {
                return AdaptiveContentService.getTargeting(vm.account.id, vm.targetingId);
            }
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.targeting = result[0];
        }

        /// End init
        ///////////////


        function disableSave() {

            if (_.isNull(vm.targeting.name) || _.isEmpty(vm.targeting.name)) {
                return true;
            }
            if (_.isNull(vm.targeting.start)) {
                return true;
            }
            if (_.isNull(vm.targeting.end)) {
                return true;
            }

            if (_.isNull(vm.targeting.ignoreIsp)) {
                return true;
            }
            if (_.isNull(vm.targeting.active)) {
                return true;
            }


            return false;
        }

        function saveTargeting() {
            return $q.all([onSaveTargeting()]);
        }

        // update period field
        function startDateChanged() {
            if (_.isNull(vm.period) || _.isEmpty(vm.period)) {
                vm.targeting.period = moment(vm.targeting.start).format('YYYY-MM-DD') + ' - ' + moment(vm.targeting.end).format('YYYY-MM-DD');
            }
        }

        function onSaveTargeting() {
            vm.targeting.start = moment(vm.targeting.start).valueOf();
            vm.targeting.end = moment(vm.targeting.end).valueOf();
            return AdaptiveContentService.createOrUpdateInstance(vm.account.id, vm.targeting)
                .then(function (data) {
                    vm.targeting = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function addTargetingRule() {
            // check if name already present
            var foundRule = _.find(vm.targeting.rules, function(item){
                return item.name === vm.newTargetingRule.name;
            });
            if (foundRule !== undefined) {
               logger.error('You can not have rules with same name!!!' );
                return;
            }
            vm.targeting.rules.push(vm.newTargetingRule);

            vm.newTargetingRule = {
                name: null,
                type: null,
                value: null,
                valueMax: null,
                response: null
            };

            onSaveTargeting().then( function(){
                logger.success('Rule was added and Adaptive Content updated!!' );
            });

        }

        function deleteTargetingRule(ruleId) {
            vm.targeting.rules = _.filter(vm.targeting.rules, function (rule) {
                return ruleId !== rule.id;
            });
        }

        function showSaveSuccess() {
            logger.success('Adaptive Content successfully saved!!');
            $state.go(STATE.TARGETING_ADAPTIVE_CONTENT, {accountId: vm.account.id, targetingId : vm.targeting.id} , {notify: false});
        }

        function onClick() {
        //    $window.alert(' ' + vm.account.id);
        }

        function searchWithQuery(){
            organisationNameRule.value = vm.adaptiveContentSearchName ;
            vm.finalQuery = vm.queryBuilder.toJSON();
            AdaptiveContentService.searchWithQuery(vm.finalQuery).then(onFinishedSearch).then(onFinishedSearchQount);
        }

        function onFinishedSearch(orgs) {
            vm.organisations = orgs;
            logger.info('Fetched ' + vm.organisations.length + ' Organisations');
        }


        function onFinishedSearchQount(hits) {
            logger.info('Total hits ' + hits + ' Organisations matching. Of which we only fetched ' + vm.organisations.length + ' for performance reasons ');
            vm.totalHits = hits;
        }


        ///////////


        vm.sortingLog = [];

        vm.sortableOptions = {
            activate: function () {
            },
            beforeStop: function () {
            },
            change: function () {
            },
            create: function () {
            },
            deactivate: function () {
            },
            out: function () {
            },
            over: function () {
            },
            receive: function () {
            },
            remove: function () {
            },
            sort: function () {
            },
            start: function () {
            },
            update: function () {
            },
            stop: function () {

                // this callback has the changed model

                //$scope.sortingLog.push('Stop: ' + logEntry);
            }
        };


    }
})();