(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TopScoreOrganisationBarChart', [TopScoreOrganisationBarChart]);

    function TopScoreOrganisationBarChart() {
        return function (visits) {
            var that = this;

            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'bar',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 20,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 400
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.point.name + '</b>: ' + this.y + ' total score';
                        }
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                series: [{
                    data: [],
                    size: '100%',
                    showInLegend: false,
                    dataLabels: false
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                },
                setYAxis: function (yAxis) {
                    that.config.xAxis.categories = yAxis;
                }
            };

            initChart(visits);

            function initChart(visits) {
                var numberOfVisitsPerIndustry = _.chain(visits)
                    .groupBy(organisationName)
                    .map(scoreSum)
                    .map(toChartItem)
                    .sortBy(organisationScore)
                    .value()
                    .slice(0, 10);
                that.config.setData(numberOfVisitsPerIndustry);

                var yAxis = _.chain(visits)
                    .groupBy(organisationName)
                    .map(scoreSum)
                    .map(toChartItem)
                    .sortBy(organisationScore)
                    .map(toYAxis)
                    .value()
                    .slice(0, 10);
                that.config.setYAxis(yAxis);
            }

            function organisationName(visit) {
                return visit.organisation.name;
            }

            function scoreSum(value, key) {
                var sum = _.reduce(value, function (memo, val) {
                    return memo + val.visitScore;
                }, 0);
                return {name: key, totalScore: sum};
            }

            function toChartItem(visit) {
                return [visit.name, visit.totalScore];
            }

            function organisationScore(visit) {
                return -visit[1];
            }

            function toYAxis(visit) {
                return visit.name;
            }
        };
    }
})
();
