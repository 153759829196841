(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('checkbox', Checkbox);

    function Checkbox() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/table-component/checkbox.html',
            scope: {
                value: '=',
                changed: '&',
                white: '='
            }
        };
    }

})();