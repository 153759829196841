(function() {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignBetaTargetingController', [
            '$q',
            '$http',
            '$scope',
            '$window',
            '$element',
            '$timeout',
            '$state',
            'SegmentAdvancedFilterService',
            'VisitFilterService',
            'CampaignBetaConstants',
            'CampaignBetaService',
            CampaignTargetingController
        ]);

    function CampaignTargetingController($q, $http, $scope, $window, $element, $timeout, $state, SegmentAdvancedFilterService, VisitFilterService, CampaignConstants, CampaignService) {
        var vm = this;
        vm.parent = $scope.vm;
        vm.placesNames = undefined;
        vm.lastPlacesResponse = [];
        vm.constants = CampaignConstants;
        vm.constants.targetingAnchormenuItems[0].active = true;
        vm.inputNumberType = {type: 'string', subtype: 'numerical'};


        vm.chooseInterest = function(item) {
            if (_.findIndex(vm.campaign.audienceProfile.interests, item) === -1) {
                vm.campaign.audienceProfile.interests.push(item);
            }
        };

        vm.chooseBrowser = function(item) {
            if (_.findIndex(vm.campaign.audienceProfile.browsers, item) === -1) {
                vm.campaign.audienceProfile.browsers.push(item);
            }
        };

        vm.chooseOperatinSystem = function(item) {
            if (_.findIndex(vm.campaign.audienceProfile.operatingSystems, item) === -1) {
                vm.campaign.audienceProfile.operatingSystems.push(item);
            }
        };

        $scope.$watch('vm.places', function(newVal, oldVal) {
            console.log(newVal, oldVal);
        });
        if (vm.parent.campaign) {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }
        $scope.$on('campaignDataLoaded', function() {
            vm.campaign = vm.parent.campaign;
            vm.account = vm.parent.account;
        }.bind(this));

        var autocompleteService = new google.maps.places.AutocompleteService();

        CampaignService.getInterests().then(function(result) {
            vm.interests = result;
        });
        CampaignService.getBrowsers().then(function(result) {
            vm.browsers = result;
        });
        CampaignService.getOperatingSystems().then(function(result) {
            vm.operatingSystems = result;
        });

        vm.getVisitFilters = function(query) {
            return VisitFilterService.getVisitFilters(vm.parent.account.id).then(filterByQueryInName.bind(this, query));
        };

        vm.getSegments = function(query) {
            return SegmentFilterService.getSegments(vm.parent.account.id).then(filterByQueryInName.bind(this, query));
        };

        vm.getBlacklists = function(query) {
            return SegmentFilterService.getSegments(vm.parent.account.id).then(filterByQueryInName.bind(this, query));
        };

        vm.getPlaces = function(query) {
            var deferred = $q.defer();

            autocompleteService.getPlacePredictions({
                input: query,
                type: 'geocode'
            }, function(response) {
                vm.lastPlacesResponse = response;
                var result = _.uniq(_.map(response, 'description'));
                deferred.resolve({data: result});
            });

            return deferred.promise;
        };

        vm.newVisitFilter = function() {
            CampaignService.createOrUpdateCampaign(vm.account.id, vm.campaign).then(function() {
                $state.go('admin.visitFilter', {visitFilterId: null});
            });
        };

        vm.newSegment = function() {
            CampaignService.createOrUpdateCampaign(vm.account.id, vm.campaign).then(function() {
                $state.go('admin.segmentFilter', {segmentFilterId: null});
            });
        };
    }

    function filterByQueryInName(query, items) {
        var result = _.filter(items, function(item) {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });
        if (result.length === 0) {
            result = items.slice(0, 10);
        }
        result = _.map(result, 'name');
        return {data: result};
    }

})();