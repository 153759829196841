(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('IndustryCodesService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', IndustryCodesService]);

    function IndustryCodesService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findSicCodes = findSicCodes;
        this.findSniCodes = findSniCodes;
        this.findNaceToSICCodes = findNaceToSICCodes;
        this.findNAICSCodes = findNAICSCodes;
        this.findGICSCodes = findGICSCodes;
        this.findIndustryTags = findIndustryTags;

        function findSicCodes( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/sic' , config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findSniCodes( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/sni' , config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function findNaceToSICCodes( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/nacetosic' , config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findNAICSCodes( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/naics' , config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findGICSCodes( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/gics' , config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function findIndustryTags( config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/industrycodes/industryTags' , config )
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


    }
})();