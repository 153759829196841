(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('toggleInput', [ToggleInput]);

    function ToggleInput() {
        return {
            restrict: 'E',
            replace: true,
            template: function(n, e) {
                var s = '';
                return s += '<span',
                s += ' class="toggle-input' + (e.class ? ' ' + e.class : '') + '"',
                s += e.ngModel ? ' ng-click="' + e.ngModel + '=!' + e.ngModel + (e.ngChange ? '; ' + e.ngChange + '()"' : '"') : '',
                s += ' ng-class="{ checked:' + e.ngModel + ' }"',
                s += '>',
                s += '<small></small>',
                s += '<input type="checkbox"',
                s += e.id ? ' id="' + e.id + '"' : '',
                s += e.name ? ' name="' + e.name + '"' : '',
                s += e.ngModel ? ' ng-model="' + e.ngModel + '"' : '',
                s += ' style="display:none" />',
                s += '<span class="toggle-input-text">',
                s += e.on ? '<span class="on">' + e.on + '</span>' : '',
                s += e.off ? '<span class="off">' + e.off + '</span>' : ' ',
                s += '</span>';
            }
        };
    }


})();