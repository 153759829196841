(function () {
    'use strict';
    angular
        .module('adminApp')
        .service('TargetingDashboardService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', TargetingDashboardService]);

    function TargetingDashboardService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getTargetingSummary = getTargetingSummary;

        function getTargetingSummary(accountId) {

			var config = {hideLoadingIndicator: true,dontFailOnError: true};

			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetingSummary', config)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();