(function () {
    'use strict';

    angular
        .module('adminApp')
        //.factory('responseExceptionCatcher', '$q', responseExceptionCatcher);
        .service('responseExceptionCatcher', responseExceptionCatcher);
       // .factory('responseExceptionCatcher', ['$window', responseExceptionCatcher]);


    //function responseExceptionCatcher($q) {
    /* jshint validthis: true */
    function responseExceptionCatcher() {
        this.catch = catcher;

        function catcher(message) {
            return function (response) {
                throw {
                    message: message,
                    cause: findProblemInResponse(response) + findErrors(response),
                    status: response.status
                };
            };
        }

        function findProblemInResponse(response) {
            //return response.config.method + ' ' + response.config.url + ' (' + response.statusText + ') ' + response.status;
            return response.statusText + ' ' + response.status;
        }

        function findErrors(response) {
            return response.data && response.data.errors ? ' ~ ' + findCauseInError(response) : '';
        }

        function findCauseInError(response) {
            if (response.data.errors.constructor === Array) {
                return response.data.errors[0].description;
            } else {
                return response.data.errors;
            }
        }
    }

})();