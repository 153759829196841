(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdminBTCampaignsController', ['AdminBTService', 'clientId', 'advertiserId', 'logger', '$state',
            'FileSaver', 'Blob', 'CONFIG', 'TableSort', AdminBTCampaignsController]);
    function AdminBTCampaignsController(AdminBTService, clientId, advertiserId, logger, $state, FileSaver, Blob, CONFIG, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        vm.clientId = clientId;
        vm.advertiserId = advertiserId;
        vm.btCampaigns = [];
        vm.btAdvertiser = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        ////////////
        //  Init
        activate();

        function activate() {
            getData();
        }

        function getData() {

            AdminBTService.getCampaigns(vm.clientId, vm.advertiserId).then(showResult);
            AdminBTService.getAdvertiser(vm.clientId, vm.advertiserId).then(showAdvertiser);

        }

        function showResult(btCampaigns) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.btCampaigns = btCampaigns;
        }

        function showAdvertiser(btAdvertiser) {
            vm.btAdvertiser = btAdvertiser;
        }

        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'bt_clients_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.btCampaigns.length;
        }

    }
})();