(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PageController', ['account' , 'pageId', '$q','$state'  , 'logger' , 'PageService', 'PageKeywordService' ,   PageController]);

    function PageController(account, pageId, $q, $state, logger, PageService, PageKeywordService) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.savePage = savePage;
        vm.autoCompletePageKeywords = autoCompletePageKeywords;

        // this is resolved from ui-router if any
        vm.pageId = pageId;
        vm.account = account;

        vm.refPageKeywords = [];
        vm.refPageStates = [{id: 'ACTIVE', name: 'Active'},
            {id: 'IN_ACTIVE', name: 'In Active'},
            {id: 'DELETED', name: 'Deleted'}
        ];

        activate();

        function activate() {
            getPage();
            getAllPageKeywords();
        }

        function getPage(){
            return PageService.getPage(vm.account.id, vm.pageId).then(function(page){
                vm.page = page;
            });
        }

        /// End init
        ///////////////

        function savePage(){
            return PageService.updatePage(vm.account.id, vm.page)
                .then(function (data) {
                    vm.page = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Page successfully saved ');
        }

        function getAllPageKeywords() {
            PageKeywordService.getAllPageKeywords(vm.account.id)
                .then(function (pageKeywords) {
                    vm.refPageKeywords = pageKeywords;
                });
        }

        function autoCompletePageKeywords($query) {
            return _.filter(vm.refPageKeywords, function (item) {
                return item.keyWord.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
        }



    }
})();