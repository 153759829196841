(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitFilterController', ['account', 'user', 'visitFilterId', 'startDate', 'endDate', 'leIds', '$q', '$state', 'logger', '$document', 'VisitFilterService', 'VisitService', 'CountryService', 'ExcludedOrganisationService', 'PageKeywordService', 'PageService', 'EnhancedOrganisationService', 'CommonService', 'TableSort', 'CONFIG', 'FileSaver', 'STATE', VisitFilterController]);

    function VisitFilterController(account, user, visitFilterId, startDate, endDate, leIds, $q, $state, logger, $document, VisitFilterService, VisitService, CountryService, ExcludedOrganisationService, PageKeywordService, PageService, EnhancedOrganisationService, CommonService, TableSort, CONFIG, FileSaver, STATE) {
        var vm = this;
        // interface
        vm.onFormChange = onFormChange;
        vm.saveVisitFilter = saveVisitFilter;
        vm.onSearchWithVisitFilter = onSearchWithVisitFilter;
        //vm.onShowFilterVisits = onShowFilterVisits;
        vm.onVisitExcludeClick = onVisitExcludeClick;
        vm.clearVisitFilterSettings = clearVisitFilterSettings;
        vm.onVisitFilterChangeGetNoOfVisits = onVisitFilterChangeGetNoOfVisits;
        vm.createVisitReportExcel = createVisitReportExcel;
        vm.createVisitReportHtml = createVisitReportHtml;
        vm.validateReceivers = validateReceivers;
        vm.createVisitReportAndSendEmail = createVisitReportAndSendEmail;
        vm.onVisitClickRequestEnhancementForISP = onVisitClickRequestEnhancementForISP;
        vm.onVisitTagAsISPsClick = onVisitTagAsISPsClick;
        vm.onVisitClickRequestEnhancement = onVisitClickRequestEnhancement;
        vm.onVisitClickRequestEnhancements = onVisitClickRequestEnhancements;
        vm.onVisitExcludeAllClick = onVisitExcludeAllClick;
        vm.checkAll = checkAll;
        vm.deCheckAll = deCheckAll;
        vm.hideResults = hideResults;


        vm.resultsVisible = false;
        vm.leIds = leIds;
        vm.visits = [];
        vm.visits.selected = [];
        vm.sendMailTo = [];
        vm.mailSubject = 'New Visits of interest';
        // this is resolved from ui-router if any
        vm.visitFilterId = visitFilterId;
        vm.account = account;
        if( startDate ){
            vm.start = moment (startDate).toDate();
        } else {
            vm.start = moment().subtract(7, 'days').toDate();
        }
        if( endDate ){
            vm.end = moment (endDate).toDate();
        } else {
            vm.end = moment().toDate();
        }


        // reference data
        vm.refFilters = [];
        vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};
        // initial if creating new
        vm.visitFilter = null;
        vm.pageKeyWords = null;

        // export and search disabled if not less than this
        vm.maxNoOfHitsAllowed = CONFIG.MAX_VISITS_WHEN_EXPORTING_FROM_UI;
        vm.isExportAllowed = false;


        // Toggles for filter queries
        vm.showFilterPages = false;
        vm.showFilterPageKeywords = false;
        vm.showFilterISP = false;
        vm.showFilterFirstVisit = false;
        vm.showFilterScore = false;
        vm.showFilterSales = false;
        vm.showFilterEmployees = false;
        vm.showFilterOrganisationIds = false;
        vm.showFilterOrganisationNames = false;
        vm.showFilterSICIds = false;
        vm.showFilterSNINACE = false;
        vm.showFilterLEIds = false;
        vm.showFilterDUNS = false;
        vm.showFilterPostalCodes = false;
        vm.showFilterRegions = false;
        vm.showFilterCountries = false;
        vm.isNoFilterShowing = isNoFilterShowing;

        var lessThan = {id: 'LESS_THAN', name: 'Less than'};
        var equal = {id: 'EQUAL', name: 'Equal'};
        var biggerThen = {id: 'BIGGER_THAN', name: 'Bigger then'};
        var contains = {id: 'CONTAINS', name: 'Contains'};
        var beginsWith = {id: 'BEGINS_WITH', name: 'Begins with'};
        var regExp = {id: 'REGEXP', name: 'Reg exp'};
        var notEqual = {id: 'NOT_EQUAL', name: 'Not equal'};

        // drop downs operators, different for different fields
        vm.refQueryOperatorsForCountries = [contains, notEqual];
        vm.refQueryOperatorsAll = [lessThan, equal, biggerThen, contains, beginsWith, regExp, notEqual];
        vm.refQueryOperatorsForDunsIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForLeIds = [equal, notEqual];
        vm.refQueryOperatorOrganisationsNames = [contains, notEqual];
        vm.refQueryOperatorsForOrganisationIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForSNIIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForSICIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForRegions = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForPostalCodes = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForPageKeyqords = [contains, notEqual];

        vm.autoCompletePageKeyWords = autoCompletePageKeyWords;
        vm.autoCompleteCountries = autoCompleteCountries;
        vm.autoCompletePages = autoCompletePages;

        // Current filter result
        vm.numberOfVisits = null;

        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('visitsForFilterTable', {
            by: ['startDate'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.onVisitClick = onVisitClick;
        vm.doExportToExcel = doExportToExcel;
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;

        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData()
                .then(showInitialData);
        }

        function getInitialData() {
            var promises = [getVisitFilter(), getPageKeyWords(), getPages()];
            return $q.all(promises);
        }

        function getVisitFilter() {
            if (!_.isNull(vm.visitFilterId) && !_.isEmpty(vm.visitFilterId)) {
                return VisitFilterService.getVisitFilter(vm.account.id, vm.visitFilterId);
            }
        }

        function getPageKeyWords() {
            return PageKeywordService.getAllPageKeywords(vm.account.id);
        }

        function getPages() {
            return PageService.getPages(vm.account.id, 30000);
        }

        // use the stuff when promises are finished
        function showInitialData(result) {
            if (result[0]) {
                vm.visitFilter = result[0];
            } else if(  vm.leIds ){
                var filterOnLeIds = [];
                // when jumping from visits here...
                if( vm.leIds.constructor === Array ){
                    filterOnLeIds = _.uniq(vm.leIds);
                } else {
                    filterOnLeIds.push( vm.leIds );
                }
                vm.visitFilter = {
                    id: vm.visitFilterId,
                    accountId: account.id,
                    siteId: account.defaultSiteId,
                    userId: user.id,
                    isNew: true,
                    visitFilterQuery: {
                        organisationName: '*',
                        aid: account.id,
                        defaultSiteId: account.defaultSiteId,
                        sid: account.siteId,
                        filterOnLeIds : filterOnLeIds
                    }
                };

            } else {
                vm.visitFilter = {
                    id: vm.visitFilterId,
                    accountId: account.id,
                    siteId: account.defaultSiteId,
                    userId: user.id,
                    isNew: true,
                    visitFilterQuery: {
                        organisationName: '*',
                        aid: account.id,
                        defaultSiteId: account.defaultSiteId,
                        sid: account.siteId
                    }
                };
            }
            vm.pageKeyWords = result[1];
            vm.pages = result[2];

            updateViewOnVisitFilterQuery();
            onVisitFilterChangeGetNoOfVisits();

            /*if (result[3]) {
                showVisitsForFilter(result[3]);
            }*/
        }

        function updateViewOnVisitFilterQuery() {
            // vm.visitFilter.visitFilterQuery can be null if user has not entered a fitler param
            if (vm.visitFilter.visitFilterQuery !== null) {
                vm.showFilterPages = vm.visitFilter.visitFilterQuery.filterOnPageIdsHolder;
                vm.showFilterPageKeywords = vm.visitFilter.visitFilterQuery.filterOnPageKeywords;
                vm.showFilterISP = vm.visitFilter.visitFilterQuery.hideIsp;
                vm.showFilterFirstVisit = vm.visitFilter.visitFilterQuery.firstVisit;
                vm.showFilterScore = vm.visitFilter.visitFilterQuery.minScore || vm.visitFilter.visitFilterQuery.maxScore;
                vm.showFilterSales = vm.visitFilter.visitFilterQuery.minSales || vm.visitFilter.visitFilterQuery.maxSales;
                vm.showFilterEmployees = vm.visitFilter.visitFilterQuery.minNoEmployees || vm.visitFilter.visitFilterQuery.maxNoEmployees;
                vm.showFilterOrganisationIds = vm.visitFilter.visitFilterQuery.filterOnOrganisationsIds;
                vm.showFilterOrganisationNames = vm.visitFilter.visitFilterQuery.filterOnOrganisationNames;
                vm.showFilterSICIds = vm.visitFilter.visitFilterQuery.filterOnSicCodeIds;
                vm.showFilterSNINACE = vm.visitFilter.visitFilterQuery.filterOnSNICodeIds;
                vm.showFilterLEIds = vm.visitFilter.visitFilterQuery.filterOnLeIds;
                vm.showFilterDUNS = vm.visitFilter.visitFilterQuery.filterOnDunsIds;
                vm.showFilterPostalCodes = vm.visitFilter.visitFilterQuery.filterOnPostalCodes;
                vm.showFilterRegions = vm.visitFilter.visitFilterQuery.filterOnRegions;
                vm.showFilterCountries = vm.visitFilter.visitFilterQuery.filterOnCountryISOHolder;
            }
        }

        /// End init
        ///////////////

		function hideResults(){
        	vm.resultsVisible = false;
		}

        function onSearchWithVisitFilter() {
            vm.visits = [];
            vm.numberOfVisits = null;
            //$document.scrollToElementAnimated(angular.element(document.getElementById('filter-result')), -1);
            getVisitsUsingTransientWithVisitFilter();
            vm.resultsVisible = true;
        }

        function getVisitsUsingTransientWithVisitFilter(){
            return VisitService.getVisitsUsingTransientWithVisitFilter(vm.account.id, vm.account.defaultSiteId, format(vm.start), format(vm.end), vm.visitFilter.visitFilterQuery)
                .then(function (visits) {
                    // getting it from response getNumberOfVisitsForFilter();
                    vm.numberOfVisits = visits.length;
                    vm.visits = visits;
                });
        }

        function saveVisitFilter() {

            vm.visits = [];
            VisitFilterService.createOrUpdateInstance(vm.account.id, vm.visitFilter)
                .then(function (savedVisitFilter) {
                    showSavedVisitFilter(savedVisitFilter);
                    onVisitFilterChangeGetNoOfVisits();
                    updateViewOnVisitFilterQuery();
                });
        }

        function showSavedVisitFilter(savedVisitFilter) {
            vm.visitFilter = savedVisitFilter;
            logger.success('Visit filter successfully saved!');
            $state.go(STATE.VISIT_FILTER, {accountId: vm.account.id, visitFilterId: vm.visitFilter.id}, {notify: false});
        }


        function format(date) {
            return moment(date).format('YYYY-MM-DD');
        }


        function onVisitClick(visit) {
            $state.go(STATE.VISIT, {accountId: vm.visitFilter.accountId, visitId: visit.id});
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'VisitFilters_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);

        }

        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.visits) {
                return vm.visits.length;
            }
        }

        function autoCompleteCountries(query) {
            return _.filter(CountryService.getAllCountries(), function (country) {
                return country.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }

        function autoCompletePageKeyWords(query) {
            return _.filter(vm.pageKeyWords, function (pageKeyWord) {
                return pageKeyWord.keyWord.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }

        function autoCompletePages(query) {
            return _.filter(vm.pages, function (page) {
                return page.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }

        function onVisitFilterChangeGetNoOfVisits(){
            vm.visits = [];
            vm.numberOfVisits = null;

            var isStartDateValid = moment(format(vm.start), ['YYYY-MM-DD'], true).isValid();
            var isEndDateValid = moment(format(vm.end), ['YYYY-MM-DD'], true).isValid();
            if (isStartDateValid && isStartDateValid !== undefined && isEndDateValid && isEndDateValid !== undefined) {
                return VisitService.getNoOfVisitsUsingTransientWithVisitFilter(vm.account.id, vm.account.defaultSiteId,
                    format(vm.start), format(vm.end), vm.visitFilter.visitFilterQuery)
                    .then(function (noOfVisits) {
                        vm.numberOfVisits = noOfVisits;
                        if( vm.numberOfVisits < vm.maxNoOfHitsAllowed ){
                            vm.isExportAllowed = true;
                        } else {
                            vm.isExportAllowed = false;
                        }
                    });
            }
        }

        function isNoFilterShowing() {
            /*jslint laxbreak: true */
            return !vm.showFilterPages && !vm.showFilterPageKeywords && !vm.showFilterISP && !vm.showFilterFirstVisit
                && !vm.showFilterScore && !vm.showFilterSales && !vm.showFilterEmployees && !vm.showFilterOrganisationIds
                && !vm.showFilterSICIds && !vm.showFilterSNINACE && !vm.showFilterLEIds
                && !vm.showFilterDUNS && !vm.showFilterPostalCodes && !vm.showFilterRegions && !vm.showFilterCountries
                && !vm.showFilterOrganisationNames;
        }

        function onVisitExcludeClick(visit){
            ExcludedOrganisationService.updateExcludedOrganisation( vm.account, visit )
                .then( function (){
                    logger.info('Added Excluded Organisation, Excluding last 2 weeks and future visits from this Organisation.');
                });
            //.then(showVisits);

        }


        function clearVisitFilterSettings() {
            // vm.visitFilter.visitFilterQuery can be null if user has not entered a fitler param
            if (vm.visitFilter.organisationQueryV2 !== null) {
                vm.visitFilter.visitFilterQuery.filterOnPageIdsHolder = null;
                vm.visitFilter.visitFilterQuery.filterOnPageKeywords = null;
                vm.visitFilter.visitFilterQuery.hideIsp = true;
                vm.visitFilter.visitFilterQuery.firstVisit = false;
                vm.visitFilter.visitFilterQuery.minScore = null;
                vm.visitFilter.visitFilterQuery.maxScore = null;
                vm.visitFilter.visitFilterQuery.minSales = null;
                vm.visitFilter.visitFilterQuery.maxSales = null;
                vm.visitFilter.visitFilterQuery.minNoEmployees = null;
                vm.visitFilter.visitFilterQuery.maxNoEmployees = null;
                vm.visitFilter.visitFilterQuery.filterOnOrganisationsIds = null;
                vm.visitFilter.visitFilterQuery.filterOnOrganisationNames = null;
                vm.visitFilter.visitFilterQuery.organisationName = '*';
                vm.visitFilter.visitFilterQuery.filterOnSicCodeIds  = null;
                vm.visitFilter.visitFilterQuery.filterOnSNICodeIds  = null;
                vm.visitFilter.visitFilterQuery.filterOnLeIds  = null;
                vm.visitFilter.visitFilterQuery.filterOnDunsIds  = null;
                vm.visitFilter.visitFilterQuery.filterOnPostalCodes  = null;
                vm.visitFilter.visitFilterQuery.filterOnRegions  = null;
                vm.visitFilter.visitFilterQuery.filterOnCountryISOHolder = null;
                updateViewOnVisitFilterQuery();

                vm.numberOfVisits = 0;

                onVisitFilterChangeGetNoOfVisits();
            }
        }


        // update count when something changes in form
        function onFormChange(){
            vm.visits = [];
            onVisitFilterChangeGetNoOfVisits();
        }


        /////////
        // Methods to handle export of Visits
        function createVisitReportExcel(){
            VisitService.createVisitReportExcel(vm.account.id, account.defaultSiteId, format(vm.start),format(vm.end),  vm.visitFilter.visitFilterQuery)
                .then( function(response){
                    var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                    var config = {
                        data: data,
                        filename: 'Visits_' + new Date().toISOString().substring(0, 10) + '.xls'
                    };
                    FileSaver.saveAs(config.data, config.filename);
                } );
        }

        function createVisitReportHtml(){
            VisitService.createVisitReportHtml(vm.account.id, account.defaultSiteId, format(vm.start),format(vm.end),  vm.visitFilter.visitFilterQuery)
                .then( function(response){
                    var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                    var config = {
                        data: data,
                        filename: 'Visits_' + new Date().toISOString().substring(0, 10) + '.html'
                    };
                    FileSaver.saveAs(config.data, config.filename);
                } );
        }

        function createVisitReportAndSendEmail(){
            VisitService.createVisitReportAndSendEmail(vm.account.id, account.defaultSiteId,
				format(vm.start),format(vm.end),  vm.visitFilter.visitFilterQuery, vm.sendMailTo, vm.mailSubject)
                .then(showExportSuccess);
        }

        function showExportSuccess() {
            logger.success('Report sent ' );
        }

        function validateReceivers(values) {
            var valid = false;
            _.each(values, function(email) {
                if( email.indexOf('@') === -1){
                    valid = false;
                } else {
                    valid = true;
                }
            });
            return valid;
        }

        function checkAll() {
            vm.visits.selected = angular.copy(vm.visits);
        }

        function deCheckAll() {
            vm.visits.selected = null;
        }

        function onVisitClickRequestEnhancement(visit) {
            EnhancedOrganisationService.createHighPrioEnhancementRequests( [visit.organisation.id]);
            logger.info('Request for Enhancement has been created');
        }

        function onVisitClickRequestEnhancements() {
            if (vm.visits.selected && vm.visits.selected.length > 0) {
                var leIds = [];
                _.each( vm.visits.selected, function(visit){
                    leIds.push(visit.organisation.id);
                });
                EnhancedOrganisationService.createHighPrioEnhancementRequests( leIds );
                logger.info('Request for Enhancement has been created');
            }

        }

        function onVisitClickRequestEnhancementForISP(visit) {
            var user = CommonService.getUser();
            EnhancedOrganisationService.createEnhancementRequestForISP( visit, user,  account );
            logger.info('Request for Enhancement of ISP has been created and we will have a look into it');
        }

        function onVisitTagAsISPsClick() {
            if (vm.visits.selected && vm.visits.selected.length > 0) {
                var user = CommonService.getUser();
                EnhancedOrganisationService.createEnhancementRequestForISPs( vm.visits.selected, user,  account );
                logger.info('Request for Enhancement of ISP/s has been created and we will have a look at it');

            }
        }

        function onVisitExcludeAllClick() {
            if (vm.visits.selected && vm.visits.selected.length > 0) {
                ExcludedOrganisationService.updateExcludedOrganisationBatch(vm.account, vm.visits.selected)
                    .then(function () {
                        logger.info('Added Excluded Organisation, Excluding last 2 weeks and future visits from this Organisation.');
                    });
            }
        }
    }
})();