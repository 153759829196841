(function() {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignBetaAdformController', [
            '$scope',
            '$window',
            '$element',
            '$timeout',
            'VisitorService',
            'VisitFilterService',
            CampaignAdformController]);

    function CampaignAdformController($scope, $window, $element, $timeout, VisitorService, VisitFilterService) {
        var vm = this;
        vm.parent = $scope.vm;

        vm.startTime = 0;
        vm.endTime = 0;
        vm.inputDateType = {type: 'date', subtype: 'date'};
        vm.selectedTimezone = moment.tz.guess();

        vm.segments = [];
        vm.filters = [];

        vm.getSegments = getSegments;
        vm.getFilters = getFilters;

        $scope.$on('campaignDataLoaded', function() {
            vm.startTime = moment.tz(new Date(vm.parent.campaign.start), vm.selectedTimezone).format('MM/DD/YY h:mm A');
            vm.endTime = moment.tz(new Date(vm.parent.campaign.end), vm.selectedTimezone).format('MM/DD/YY h:mm A');
            vm.segments = _.map(vm.parent.campaign.segments, function(segment) {
                return segment.name;
            });
        });

        $scope.$on('campaignDataLoaded', function() {
            console.log(vm.parent.campaign.start);
            vm.parent.account.defaultSiteId = '4eca2f51744ea29785001aee';
            VisitorService.findNumberOfVisitors(vm.parent.account.id, vm.parent.account.defaultSiteId, format(vm.parent.campaign.start), format(vm.parent.campaign.end), '4eca2f51744ea29785001aee')
                .then(function(result) {
                    console.log(result);
                });
        });

        function format(date) {
            return moment(date).format('YYYY-MM-DD');
        }

        function getSegments(query) {
            return SegmentFilterService.getSegments(vm.parent.account.id).then(function(segments) {
                var resultSegments = _.filter(segments, function(segment) {
                    return segment.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                });

                if (resultSegments.length === 0) {
                    resultSegments = segments;
                }

                resultSegments = _.map(resultSegments, function(segment) {
                    return segment.name;
                });

                return {data: resultSegments};
            });
        }

        function getFilters(query) {
            return VisitFilterService.getVisitFiltersBasic(vm.parent.account.id).then(function(filters) {
                var resultFilters = _.filter(filters, function(filter) {
                    return filter.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                });

                if (resultFilters.length === 0) {
                    resultFilters = filters;
                }

                resultFilters = _.map(resultFilters, function(filter) {
                    return filter.name;
                });
                return {data: resultFilters};
            });
        }
    }
})();