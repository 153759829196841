(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CustomVariablesController', ['account', 'CustomVariableService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', CustomVariablesController]);

    function CustomVariablesController(account, CustomVariableService, FileSaver, Blob, CONFIG, TableSort) {
        var vm = this;
        vm.deleteItem = deleteItem;
        vm.getAllCustomVariables = getAllCustomVariables;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;

        vm.account = account;
        vm.customVariables = null;
        vm.ok = null;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;

        activate();

        function activate() {
            getAllCustomVariables();
        }

        function getAllCustomVariables() {
            CustomVariableService.getAllCustomVariables(vm.account.id)
                .then(function (customVariables) {
                    vm.customVariables = customVariables;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'CustomVariables_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);

        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem( pageKeywordId) {

            CustomVariableService.deleteCustomVariable(vm.account.id, pageKeywordId)
                .then(function (customVariables) {
                    vm.customVariables = customVariables;
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.customVariables) {
                return vm.customVariables.length;
            }
        }
    }
})();