(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitOrganisationsImportConfirmController', ['$uibModalInstance', 'modalConfig', VisitOrganisationsImportConfirmController]);

    function VisitOrganisationsImportConfirmController($uibModalInstance, modalConfig) {
        var vm = this;
        vm.title = modalConfig.title;
        vm.body = modalConfig.body;
        vm.positive = modalConfig.positive;
        vm.negative = modalConfig.negative;
        vm.ok = ok;

        function ok() {
            $uibModalInstance.close();
        }
    }

})();