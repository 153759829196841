(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('productFilter', productFilter);

    function productFilter() {
        return function (value) {
            if (value === 'WEBHOOKS_OPEN_API') {
                return 'Webhooks Open API';
            }
            if (value === 'ORGANISATIONS_BASIC_OPEN_API') {
                return 'Organisations Basic Open API';
            }
            if (value === 'TARGETING_OPEN_API') {
                return 'Targeting Open API';
            }
            if (value === 'SEGMENT_OPEN_API') {
                return 'Segment Open API';
            }
            if (value === 'ORGANISATIONS_OPEN_API') {
                return 'Organisations Open API';
            }

            if (value === 'VISITS_OPEN_API') {
                return 'Visits Open API';
            }
            if (value === 'ANALYTICS_OPEN_API') {
                return 'Analytics Open API';
            }
            if (value === 'CLICK_TRACKING_OPEN_API') {
                return 'Click Campaigns Open API';
            }

            return '';
        };
    }

})();