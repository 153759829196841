(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('LEOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', '$cacheFactory', LEOrganisationService]);

    function LEOrganisationService($http, $q, CONFIG, responseExceptionCatcher, $cacheFactory) {
        this.updateOrganisation = updateOrganisation;
        this.searchQuick = searchQuick;
        this.searchWithQuery = searchWithQuery;
        this.onFuzzySearchWithNACE = onFuzzySearchWithNACE;
        this.onSearchWithQueryCount = onSearchWithQueryCount;
        // no cache
        this.getOrganisation = getOrganisation;
        this.updateToIsps = updateToIsps;
        this.updateToNonIsps = updateToNonIsps;
        this.enhanceFromMarkedOrganisation = enhanceFromMarkedOrganisation;
        this.enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress = enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress;
        // when editing an organisation
        this.validateAndUpdateOrganisationAllByName = validateAndUpdateOrganisationAllByName;
        this.validateAndUpdateOrganisationAllByNamePartial = validateAndUpdateOrganisationAllByNamePartial;
        this.validateAndUpdateOrganisationAllBySegmentIndustryOnly = validateAndUpdateOrganisationAllBySegmentIndustryOnly;
        this.validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly = validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly;

        this.countAllByName = countAllByName;

        var cache = $cacheFactory('cacheOrganisationSearchResult');

        function searchQuick(queryForOrgName, countries, limit, config) {
            if( !limit || limit === undefined ){
                limit = 100;
            }
            var url  = CONFIG.API_PREFIX + '/enhance/organisations?q=' + queryForOrgName +  '&limit=' + limit;

            if( countries ){
                url = url + '&countries=' + countries;
            }
            return $http.get(url, config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function searchWithQuery(query) {

            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/search/grouprule' , query)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function onSearchWithQueryCount(query) {
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/search/grouprule/count' , query)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getOrganisation(organisationId) {
            return $http.get(CONFIG.API_PREFIX + '/enhance/organisations/' + organisationId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function onFuzzySearchWithNACE(naceDEscription) {
            return $http.get(CONFIG.API_PREFIX + '/enhance//industrycodes/nace/fuzzysearch?naceDescription=' + naceDEscription)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function countAllByName(organisationName) {
			var config = {
				hideLoadingIndicator: true,
				dontFailOnError: true
			};
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/countAllByName', organisationName, config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updateOrganisation(organisation) {
            return $http.put(CONFIG.API_PREFIX + '/enhance/organisations/' + organisation.id, organisation)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when updating '));

        }

        function updateToIsps(organisationIds, comment) {
            var orgs = {
                comment: comment,
                organisationIds: organisationIds
            };
            cache.removeAll();
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/markasisps', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function updateToNonIsps(organisationIds, comment) {
            var orgs = {
                comment: comment,
                organisationIds: organisationIds
            };

            cache.removeAll();
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/markasnoisps', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function enhanceFromMarkedOrganisation(organisationIds, comment, enhanceAllFromThisOrganisationId) {
            var orgs = {
                comment: comment,
                organisationIds: organisationIds,
                enhanceAllFromThisOrganisationId : enhanceAllFromThisOrganisationId
            };

            cache.removeAll();
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/enhanceFromMarkedOrganisation', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        // updates from master and takes over all excluding address
        // so DUNS, orgid, sic, nace etc
        function enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress(organisationIds, comment, enhanceAllFromThisOrganisationId) {
            var orgs = {
                comment: comment,
                organisationIds: organisationIds,
                enhanceAllFromThisOrganisationId : enhanceAllFromThisOrganisationId,
                enhanceUsingAllAttributesExceptAddress : true
            };

            cache.removeAll();
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/enhanceFromMarkedOrganisation', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function validateAndUpdateOrganisationAllByName(organisation) {
			return $http.put(CONFIG.API_PREFIX + '/enhance/organisations/validateAndUpdateOrganisationAllByName/' + organisation.id, organisation)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function validateAndUpdateOrganisationAllByNamePartial(organisation) {
			return $http.put(CONFIG.API_PREFIX + '/enhance/organisations/validateAndUpdateOrganisationAllByNameIndustrySocialDomain/' + organisation.id, organisation)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function validateAndUpdateOrganisationAllBySegmentIndustryOnly(organisation, segmentId) {

        	console.log('segmentId ',segmentId)
        	console.log('organisation.id',organisation.id)

			return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/validateAndUpdateOrganisationAllBySegmentIndustryOnly/' + organisation.id + '/' + segmentId, organisation)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly(organisation, segmentId) {
			return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly/' + organisation.id + '/' + segmentId, organisation)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when updating '));
        }
    }
})();