(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('CampaignBetaConverter', ['CONFIG', 'responseExceptionCatcher', CampaignConverter]);

    function CampaignConverter(CONFIG, responseExceptionCatcher) {
        this.responseExceptionCatcher = responseExceptionCatcher;
        this.emptyDaySchedule = Array.apply(null, Array(24 * 4)).map(function(){ return 0; });
        this.emptyWeekSchedule = Array.apply(null, Array(7)).map(function(){ return this.emptyDaySchedule.slice(); }.bind(this));

        this.emptyWeeklyTimes = Array.apply(null, Array(7)).map(function(){ return {'time': new Date(2000, 0, 1, 12, 0), 'active': false}; });
        this.emptyWeeklyTimesToSave = Array.apply(null, Array(7)).map(function(){ return {'h': 12, 'm': 0, 'active': false}; });
    }

    CampaignConverter.prototype.convertCampaigns = function(campaigns) {
        if (_.isArray(campaigns)) {
            return _.map(campaigns, function(campaign) {
                return this.convertCampaign(campaign);
            }.bind(this));
        } else {
            this.responseExceptionCatcher.catch('Error when converting')({
                statusText: 'Converting error',
                status: 100
            });
            return [];
        }
    };

    CampaignConverter.prototype.convertCampaign = function(campaign, accountId) {
        var result = {};
        var placesData = [];

        try {
            if (!_.isObject(campaign)) {
                throw 'Convert error';
            }
            if (campaign.placesData) {
                placesData = campaign.placesData.map(function(place) {
                    return {
                        nameOnMap: place.nameOnMap,
                        nameFromSearch: place.nameFromSearch,
                        id: place.id,
                        location: place.location? new google.maps.LatLng(place.location.lat, place.location.lng) : undefined
                    };
                });
            }
            campaign.audienceProfile = campaign.audienceProfile || {};

            result = {
                id: campaign.id || '',
                accountId: campaign.accountId || accountId,
                type: campaign.type || 'Banner Ad',

                name: campaign.name || '',
                status: campaign.status || 'draft',
                budget: campaign.budget || 0,
                currencyCode: campaign.currencyCode || 'SEK',
                destinationURL: campaign.destinationURL || '',

                timezone: campaign.timezone || moment.tz.guess(),
                start: moment(campaign.start).format('MM/DD/YY h:mm A') || null,
                endType: campaign.endType || 'never',
                end: moment(campaign.end).format('MM/DD/YY h:mm A') || null,
                endReaching: campaign.endReaching || {attribute: 'budget', money: 0, count: 0, currency: 'SEK'},
                schedule: campaign.schedule || this.emptyWeekSchedule,

                deliveryType: campaign.deliveryType || 'even',
                visitorCap: campaign.visitorCap || {status: false, impressions: 0, impressionsPer: 0, period: 'hours'},
                campaignCap: campaign.campaignCap || {status: false, impressions: 0, impressionsPer: 0, period: 'hours'},

                accountSegments: campaign.accountSegments || [],
                visitFilters: campaign.visitFilters || [],
                blacklists: campaign.blacklists || [],
                placesData: placesData || [],
                audienceProfile: {
                    visits: campaign.audienceProfile.visits || 0,
                    visitsPeriod: campaign.audienceProfile.visitsPeriod || 'hours',
                    deviceTypes: campaign.audienceProfile.deviceTypes || {'pc': false, 'tablet': false, 'mobile': false},
                    operatingSystems: campaign.audienceProfile.operatingSystems || [],
                    browsers: campaign.audienceProfile.browsers || [],
                    interests: campaign.audienceProfile.interests || []
                },

                created: moment(campaign.created).format('MM/DD/YYYY') || null,
                author: campaign.author || '',
                folder: campaign.folder || '',
                advAccount: campaign.advAccount || '',
                advContact: campaign.advContact || '',
                owner: campaign.owner || '',
                reportReceiver: campaign.reportReceiver || '',
                reportPeriod: campaign.period || 'day',
                dailyTime: campaign.dailyTime?
                    {'time': new Date(2000, 0, 1, campaign.dailyTime.h, campaign.dailyTime.m)}:
                    {'time': new Date(2000, 0, 1, 12, 0)},
                weeklyTimes: campaign.weeklyTimes? campaign.weeklyTimes.map(function(day) {
                        return {'time': new Date(2000, 0, 1, day.h, day.m) , active: day.active};
                    }): this.emptyWeeklyTimes,
                shareLink: campaign.shareLink || ''
            };
        } catch (error) {
            console.error(error);
            this.responseExceptionCatcher.catch('Error when converting')(error);
        }

        return result;
    };

    CampaignConverter.prototype.convertCampaignToSave = function(campaign) {
        var result = {};
        var placesData = [];

        try {
            if (!_.isObject(campaign)) {
                throw 'Convert error';
            }

            if (campaign.placesData) {
                placesData = campaign.placesData.map(function(place) {
                    return {
                        nameOnMap: place.nameOnMap,
                        nameFromSearch: place.nameFromSearch,
                        id: place.id,
                        location: place.location? {lat: place.location.lat(), lng: place.location.lng()} : undefined
                    };
                });
            }
            campaign.audienceProfile = campaign.audienceProfile || {};

            result = {
                id: campaign.id,
                accountId: campaign.accountId,
                type: campaign.type,

                name: campaign.name || 'New campaign',
                status: campaign.status || 'draft',
                budget: campaign.budget || 0,
                currencyCode: campaign.currencyCode || 'SEK',
                destinationURL: campaign.destinationURL || '',

                timezone: campaign.timezone || '',
                start: moment(campaign.start, 'MM/DD/YY h:mm A').valueOf() || new Date().valueOf(),
                endType: campaign.endType || 'never',
                end: moment(campaign.end, 'MM/DD/YY h:mm A').valueOf() || new Date().valueOf(),
                endReaching: campaign.endReaching || {attribute: 'budget', money: 0, count: 0, currency: 'SEK'},
                schedule: campaign.schedule || this.emptyWeekSchedule,

                deliveryType: campaign.deliveryType || 'even',
                visitorCap: campaign.visitorCap || {status: false, impressions: 0, impressionsPer: 0, period: 'hours'},
                campaignCap: campaign.campaignCap || {status: false, impressions: 0, impressionsPer: 0, period: 'hours'},

                accountSegments: campaign.accountSegments || [],
                visitFilters: campaign.visitFilters || [],
                blacklists: campaign.blacklists || [],
                placesData: placesData || [],
                audienceProfile: {
                    visits: campaign.audienceProfile.visits || 0,
                    visitsPeriod: campaign.audienceProfile.visitsPeriod || 'hours',
                    deviceTypes: campaign.audienceProfile.deviceTypes || {'pc': false, 'tablet': false, 'mobile': false},
                    operatingSystems: campaign.audienceProfile.operatingSystems || [],
                    browsers: campaign.audienceProfile.browsers || [],
                    interests: campaign.audienceProfile.interests || []
                },

                created: moment(campaign.created, 'MM/DD/YYYY').valueOf() || new Date().valueOf(),
                author: campaign.author || '',
                folder: campaign.folder || '',
                advAccount: campaign.advAccount || '',
                advContact: campaign.advContact || '',
                owner: campaign.owner || '',
                reportReceiver: campaign.reportReceiver || '',
                reportPeriod: campaign.period || 'day',
                dailyTime: campaign.dailyTime?
                    {'h': new Date().getHours(campaign.dailyTime.time), 'm': new Date(campaign.dailyTime.time).getMinutes()}:
                    {'h': 12, 'm': 0},
                weeklyTimes: campaign.weeklyTimes? campaign.weeklyTimes.map(function(day) {
                        return {'h': new Date(day.time).getHours(), 'm': new Date(day.time).getMinutes(), active: day.active};
                    }): this.emptyWeeklyTimes,
                shareLink: campaign.shareLink || ''
            };
        } catch (error) {
            console.error(error);
            this.responseExceptionCatcher.catch('Error when converting')(error);
        }

        return result;
    };

    CampaignConverter.prototype.convertCategoriesToList = function(categories) {
        var result = [];
        for (var category in categories) {
            if (categories.hasOwnProperty(category)) {
                result.push({
                    name: category,
                    type: category,
                    isTitle: true
                });
                for (var i = 0; i < categories[category].length; i++) {
                    result.push({
                        name: categories[category][i],
                        type: category
                    });
                }
            }
        }
        return result;
    };

})();