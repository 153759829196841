(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('DOBOrganisationsController', ['DOBOrganisationService', 'CommonService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger',
            DOBOrganisationsController]);
    function DOBOrganisationsController(DOBOrganisationService, CommonService, FileSaver, Blob, CONFIG,  TableSort, logger) {
        var vm = this;
        vm.dobOrganisations = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = null;
        vm.queryForCountryISO = {'countryISO':'SE','name': 'Sweden', 'countryISOLowerCase':'se'};
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });
        vm.refCountries = [];
        vm.enhancerSettings = null;
        vm.limit = 1000;

        //methods
        vm.loadMore = loadMore;
        vm.onDOBSearch = onDOBSearch;
        vm.tableSizeDOBOrganisations = tableSizeDOBOrganisations;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.getCountriesForSelectBox = getCountriesForSelectBox;
        vm.onSetCountryForSelectBox = onSetCountryForSelectBox;

        activate();

        function activate() {
            onLoad();
            getCountries();
        }

        /// End init
        function onLoad() {
            //DOBOrganisationService.findAllWithLimit( vm.limit).then( onFinished );
        }

        function onDOBSearch() {
            DOBOrganisationService.search(vm.queryFor, vm.queryForCountryISO.countryISO, vm.limit).then( onFinished );
        }

        function onFinished(orgs){
            vm.dobOrganisations = orgs;
            logger.info('Found ' + vm.dobOrganisations.length + ' Organisations');
        }


        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'dob_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSizeDOBOrganisations() {
            return vm.dobOrganisations.length;
        }

        // typeahead callback
        function getCountriesForSelectBox(filterByCountryName) {
            return _.filter(vm.refCountries, function (country) {
                if (country.name && filterByCountryName) {
                    return country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1;
                }
            });
        }

        // on select in UI
        function onSetCountryForSelectBox(countrySelected) {
            vm.queryForCountryISO = countrySelected;
        }

        function getCountries() {
            vm.refCountries = CommonService.getCountries();
        }
    }
})();