(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ProductController', ['selectedAccountId', 'productId', 'ProductService', 'CommonService', 'logger', '$q',
            '$state', ProductController]);
    function ProductController(selectedAccountId, productId, ProductService, CommonService, logger, $q, $state) {
        var vm = this;
        vm.showTokenFields = showTokenFields;
        vm.saveProduct = saveProduct;
        vm.isFormValid = isFormValid;
        //vm.showProductDescription = showProductDescription;
        //vm.selectedProductDescription = '';

        vm.productId = productId;
        vm.accountProductsAlreadySet = [];

        // initial if creating new
        vm.product = {
            id: productId,
            accountDetails: {
                id: selectedAccountId
            },
            tokens: []
        };

        //  console.log(selectedAccountId);
        vm.selectedAccountId = selectedAccountId;

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getProduct(), getAllProductsForAccount(), getAllProductTypesAvailable()]);
        }

        function getProduct() {
            if (_.isNull(vm.productId) || _.isEmpty(vm.productId)) {
                // initial
                return vm.product;
            } else {
                return ProductService.getProduct(vm.selectedAccountId, vm.productId);
            }
        }

        // display the stuff when promises are finished
        function initView(result) {
            vm.product = result[0];
            //vm.accountProductsAlreadySet = result[1];
            vm.allProductTypesAvailable = result[2];
            // disable the ones already set
            //updateSelectBox();
        }

        /// End init


        function isFormValid() {
            if (showTokenFields()) {

            }
        }

        function getAllProductsForAccount() {
            return ProductService.getAllProductsForAccount(vm.selectedAccountId);
        }


        function getAllProductTypesAvailable() {
            return ProductService.getAllProductTypesAvailable(vm.selectedAccountId);
        }


        function showTokenFields() {
            if (vm.product.id && vm.product.type !== 'TRACKING') {
                //console.log('true', vm.product)
                return true;
            }
            return false;
        }

/*        function showProductDescription() {
            if (vm.product.type) {
                if (vm.product.type === 'TRACKING') {
                    vm.selectedProductDescription = 'Leadenhancer Tracking enables you to';
                } else if (vm.product.type === 'ORGANISATIONS_OPEN_API') {
                    vm.selectedProductDescription = 'Orga api';
                }
                return true;
            }
            vm.selectedProductDescription = '';
            return false;
        }*/

        function saveProduct() {
            return $q.all([onSave()]);
        }

        function onSave() {
            return ProductService.createOrUpdateProduct(vm.selectedAccountId, vm.product)
                .then(function (data) {
                    vm.product = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Product saved');
            $state.go('admin.account', {accountId: vm.product.accountDetails.id}, {notify: true});
        }
    }
})();