(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ResellerAccountController', ['resellerId', 'ResellerAccountService', 'AccountService' ,'CommonService', 'logger', '$q', ResellerAccountController]);

    function ResellerAccountController(resellerId, ResellerAccountService, AccountService, CommonService, logger, $q) {
        var vm = this;
        vm.saveResellerAccount = saveResellerAccount;


        vm.resellerId = resellerId;
        vm.resellerAccount = null;

        vm.refSelectActive = [{id: true, name: 'Active'}, {id: false, name: 'Not Active'}];

        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(showResult);
        }

        function getInitialData() {
            return $q.all([getResellerAccount(),getAllAccountsForReseller()]);
        }

        function getResellerAccount() {
            if (_.isNull(vm.resellerId) || _.isEmpty(vm.resellerId)) {
                return vm.resellerAccount;
            } else {
                return ResellerAccountService.getResellerAccount(vm.resellerId);
            }
        }

        function getAllAccountsForReseller(){
            return ResellerAccountService.getAllAccountsForReseller(vm.resellerId);
        }


        // display the stuff when promises are finished
        function showResult(result) {
            vm.resellerAccount = result[0];
            vm.accounts = result[1];
        }
        /// End init

        function saveResellerAccount() {
           // alert('not implemented yet');
            /*return AccountService.createOrUpdateAccount(vm.resellerAccount)
                .then(function (data) {
                    vm.resellerAccount = data;
                   // showSaveSuccess();
                    return data;
                });*/
        }

/*        function showSaveSuccess() {
            logger.success('Reseller Account saved');
            $state.go('admin.resellerAccount', {resesellerAccountId: vm.resesellerAccount.id}, {notify: false});
        }
*/
    }
})();