(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('organisationTypesFilter', [ '$animate', 'filterValidation', 'filterConstants', 'CommonService', OrganisationTypesFilter]);


    function OrganisationTypesFilter($animate, filterValidation, filterConstants, CommonService) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/organisation/organisationTypesFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.ORGANISATION_TYPE;
				scope.operands = [
					scope.opers.IN,
					scope.opers.NOT_IN

				];


				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = [];
				} else {
					// converts to js types
					for (var i = 0; i < scope.filter.data.value.length; i++) {
						var type = scope.filter.data.value[i];
						if( type.id !== undefined ){
							// make sure we always use ISO and not json if already set
							type = type.id;
						}
						scope.filter.data.value[i] = CommonService.getOrganisationType(type);
					}
				}

				if(scope.filter.data.operand === undefined) {
					scope.filter.data.operand = scope.opers.IN;
				}

				scope.autoCompleteOrganisationTypes = function (query) {
					return _.filter(CommonService.getOrganisationTypesNoIndicator(), function (type) {
						if( type.name.toLowerCase().indexOf(query.toLowerCase()) !== -1){
							return type;
						}
					});
				};

				scope.tagAdded = function(tag){
					//scope.filter.data.value.push(tag.id);
				}
			}
		};
    }

})();