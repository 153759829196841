(function() {
    'use strict';

    angular
        .module('adminApp')
        .directive('anchormenu', function() {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    items: '='
                },
                transclude: true,
                templateUrl: 'partials/common/anchormenu.html',
                controller: ['$element', '$scope', '$timeout', '$window', '$location', AnchormenuController]
            };
    });

    function AnchormenuController($element, $scope, $timeout, $window, $location) {

        var $scrollableContent = $element.find('.anchormenu__content');
        var $anchorMenuList = $element.find('.anchormenu__list');
        var anchorMenuBottom;

        $scope.gotoAnchor =  gotoAnchor;

        $scrollableContent.on('scroll', onScroll);
        $scope.$on('$destroy', onDestroy);
        angular.element($window).bind('resize', resizeContentHeight);

        $timeout(function() {
            anchorMenuBottom = $anchorMenuList.offset().top + $anchorMenuList.height() + 2;
            resizeContentHeight();
        });


        function onDestroy() {
            angular.element($window).off('resize');
        }

        function resizeContentHeight() {
            $scrollableContent.css('height', $($window).height() - anchorMenuBottom);
        }

        function onScroll() {
            var scrollPos = $scrollableContent.scrollTop();
            $scope.items.map(function (menuItem) {
                var refElement = $('#' + menuItem.anchor);
                var refElementTop = refElement.offset().top + scrollPos - $scrollableContent.offset().top;

                if (refElementTop <= scrollPos && (refElementTop + refElement.height()) > scrollPos) {
                    menuItem.active = true;
                } else {
                    menuItem.active = false;
                }
            });
            $scope.$digest();
        }

        function gotoAnchor(anchor) {
            $($scrollableContent).off('scroll');

            $scope.items.map(function(menuItem) {
                menuItem.active = menuItem.anchor === anchor;
            });

            $location.hash(anchor);

            var $target = $element.find('#' + anchor);

            $scrollableContent.stop().animate({
                'scrollTop': $scrollableContent.scrollTop() + $target.offset().top - $scrollableContent.offset().top
            }, 500, 'swing', function () {
                $scrollableContent.on('scroll', onScroll);
            });
        }
    }


})();
