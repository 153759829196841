(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('CampaignBetaService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', 'CampaignBetaConverter', CampaignService]);

    function CampaignService($http, $q, CONFIG, responseExceptionCatcher, CampaignConverter) {
        var vm = this;
        vm.getCampaigns = getCampaigns;
        vm.getCampaign = getCampaign;
        vm.getSegments = getSegments;
        vm.createOrUpdateCampaign = createOrUpdateCampaign;
        vm.deleteCampaign = deleteCampaign;
        vm.deleteCampaigns = deleteCampaigns;
        vm.cloneCampaigns = cloneCampaigns;
        vm.deleteCreative = deleteCreative;
        vm.isNameAvailable = isNameAvailable;
        vm.publishCampaign = publishCampaign;
        vm.getCampaignRevenueModelsForAccount = getCampaignRevenueModelsForAccount;
        vm.createOrUpdateCampaignRevenueModel = createOrUpdateCampaignRevenueModel;
        vm.getCampaignRevenueModel = getCampaignRevenueModel;
        vm.getAdDimensions = getAdDimensions;
        vm.createPayment = createPayment;
        vm.updatePayment = updatePayment;
        vm.getInterests = getInterests;
        vm.getBrowsers = getBrowsers;
        vm.getOperatingSystems = getOperatingSystems;

        function getCampaigns(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns')
                .then(function (response) {
                    return CampaignConverter.convertCampaigns(response.data.data);
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCampaigns(accountId, campaignsIds) {
            return $http.delete('/mock/campaigns/', {
                    params: {'campaignsIds': campaignsIds}
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function cloneCampaigns(accountId, campaignIds) {
            return $http.post('/mock/campaigns', {
                    data: {'campaignsIds': campaignIds}
                })
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCampaign(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + id)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCreative(accountId, campaignId, campaignAdId) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + campaignId + '/ads/' + campaignAdId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when deleting creative'));
        }

        function isNameAvailable() {
            return $q.resolve(true);
            // return $http.get('/mock/campaigns?name=' + name).then(function(response) {
            //     return response.data;
            // });
        }

        // find segments to add to campaign
        function getSegments(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/segments')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaign(accountId, campaignId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + campaignId)
                .then(function (response) {
                    return CampaignConverter.convertCampaign(response.data.data);
                })
                .catch(function() {
                    responseExceptionCatcher.catch('Error when retrieving data');
                });
        }

        function publishCampaign(accountId, campaign) {
            if (campaign && campaign.dsp === 'Adforms') {
                return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + campaign.id + '/publish/adforms/' )
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data '));
            }
            if (campaign && campaign.dsp === 'Leadenhancer') {
                return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/'  + campaign.id + '/publish/leadenhancer/' )
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data '));
            }

        }

        function createOrUpdateCampaign(accountId, campaign) {
            var promise;
            if (!(_.isNull(campaign.id) || campaign.id === undefined || campaign.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/', CampaignConverter.convertCampaignToSave(campaign))
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/', CampaignConverter.convertCampaignToSave(campaign, accountId))
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign '));
            }
            return promise;
        }

        function createOrUpdateCampaignRevenueModel(accountId, campaignRevenueMddel) {
            var promise;
            if (!(_.isNull(campaignRevenueMddel.id) || campaignRevenueMddel.id === undefined || campaignRevenueMddel.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/', campaignRevenueMddel)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign revenue model'));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/', campaignRevenueMddel)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign revenue model'));
            }
            return promise;
        }


        function getCampaignRevenueModelsForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaignRevenueModel(accountId, revenueModelId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/' + revenueModelId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAdDimensions(){
            return $http.get(CONFIG.API_PREFIX + '/adDimensions')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function createPayment(payment) {
            return $http.post('/mock/payments', payment)
                .then(function(response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updatePayment(payment) {
            return $http.put('/mock/payments', payment)
                .then(function(response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getInterests() {
            return $http.get('/mock/interests')
                .then(function(response) {
                    return CampaignConverter.convertCategoriesToList(response.data);
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getBrowsers() {
            return $http.get('/mock/browsers')
                .then(function(response) {
                    return CampaignConverter.convertCategoriesToList(response.data);
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getOperatingSystems() {
            return $http.get('/mock/operatingSystems')
                .then(function(response) {
                    return CampaignConverter.convertCategoriesToList(response.data);
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();