(function() {
	'use strict';

	angular
		.module('adminApp')
		.controller('TokensController', [
			'account',
			'TokenService',
			'logger',
			'FileSaver',
			'Blob',
			'CONFIG',
			'TableSort',
			TokensController
		]);
	function TokensController(account, TokenService, logger, FileSaver, Blob, CONFIG, TableSort) {
		var vm = this;

		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.account = account;

		vm.tokens = [];
		vm.ok = null;
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.sort = new TableSort('adminTable', {
			by: ['name', 'active'],
			reverse: false
		});

		////////////
		//  Init
		activate();

		function activate() {
			findAccountTokens();
		}

		function findAccountTokens() {
			TokenService.findAccountTokens(vm.account.id).then(showTokens);
		}

		function showTokens(tokens) {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
			vm.tokens = tokens;
			logger.info('Loaded Tokens ');
		}
		/// End init
		///////////////

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename: 'tokens_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.tokens.length;
		}
	}
})();
