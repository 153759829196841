(function () {
    'use strict';

    //https://stijndewitt.com/2014/01/26/enums-in-javascript/
    angular
        .module('adminApp')
        .constant('RULE_FIELDS', {

            organisationsName: 10,
            organisationsAddressCity: 20,
            organisationsAddressPostalcode: 30,
            organisationsAddressRegion: 40,
            organisationsAddressCountry: 50,
            organisationsEmployees: 60,
            organisationsSales: 70,
            organisationsDUNS: 80,
            organisationsOrganisationId: 90,
			organisationsIndustryTags: 95,
            organisationsSic: 100,
            organisationsSniNace: 110,
            organisationsISP: 120,
            organisationsEnhancementScore: 130,
			organisationsNoOfNetworks: 130,

            properties: {
                10: {name: 'organisationsName', description: 'Name'},
                20: {name: 'organisationsAddressCity', description: 'City'},
                30: {name: 'organisationsAddressPostalCode', description: 'Postal code'},
                40: {name: 'organisationsAddressRegion', description: 'Region'},
                50: {name: 'organisationsAddressCountry', description: 'Country'},
                60: {name: 'organisationsEmployees', description: 'No of employees'},
                70: {name: 'organisationsSales', description: 'Sales'},
                80: {name: 'organisationsDUNS', description: 'DUNS'},
                90: {name: 'organisationsOrganisationId', description: 'Organisation id'},
                95: {name: 'organisationsIndustryTags', description: 'Industry tags'},
                100: {name: 'organisationsSic', description: 'SIC'},
                110: {name: 'organisationsSniNace', description: 'SNI or Nace'},
                120: {name: 'organisationsISP', description: 'ISP'},
                130: {name: 'organisationsEnhancementScore', description: 'Enhancement Score'},
                140: {name: 'organisationsNoOfNetworks', description: 'Network size'}
            }
        });
})();