(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitFiltersController', ['account', 'VisitFilterService', 'FileSaver', 'Blob', 'CONFIG', '$q', 'TableSort', 'logger', '$uibModal', VisitFiltersController]);

    function VisitFiltersController(account, VisitFilterService, FileSaver, Blob, CONFIG, $q, TableSort, logger, $uibModal) {
        var vm = this;

        vm.confirmDelete = confirmDelete;
        vm.tableSize = tableSize;
        vm.getVisitFilters = getVisitFilters;
        vm.doExportToExcel = doExportToExcel;
        vm.deleteItem = deleteItem;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;

        vm.visitFilters = null;
        vm.account = account;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name'],
            reverse: false
        });
        vm.targetingsLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.ok = null;



        activate();

        function activate() {
            vm.account = account;
            getInitialData()
                .then(initView);
        }

        function getInitialData() {
            return $q.all([getVisitFilters()]);
        }

        function getVisitFilters() {
            //return VisitFilterService.getVisitFiltersBasic(vm.account.id);
            return VisitFilterService.getVisitFilters(vm.account.id);
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.visitFilters = result[0];

            if( vm.visitFilters.length === 0 ){
                logger.info('No Visit Filters found, please create one!!');
            }
        }


        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Filters_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem(visitFilterId) {
            VisitFilterService.deleteItem(vm.account.id, visitFilterId).then( function(errorMessage){
                if( errorMessage){
                    // failed
                    logger.error( 'Could not delete Visit Filter ' + errorMessage);
                } else {
                    logger.info( 'Visit Filter deleted' );
                    activate();
                }
            } );
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.visitFilters) {
                return vm.visitFilters.length;
            }
        }


        function confirmDelete(itemId, name) {
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + name + ' Visit Filter?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(itemId);
            });
        }
    }
})();