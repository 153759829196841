(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitsController', ['account', 'startDate', 'endDate', 'filterId', 'limit','visitorId', 'visitCookieId', 'VisitService', 'ExcludedOrganisationService', 'CommonService', '$document', '$q', 'CONFIG', 'FileSaver', 'TableSort', '$state', 'logger', 'VisitFilterService', 'SegmentFilterService', 'EnhancedOrganisationService', 'STATE', VisitsController]);

    function VisitsController(account, startDate, endDate, filterId, limit, visitorId, visitCookieId, VisitService, ExcludedOrganisationService, CommonService,  $document, $q, CONFIG, FileSaver, TableSort, $state, logger, VisitFilterService, SegmentFilterService, EnhancedOrganisationService, STATE) {
        var vm = this;

        vm.onVisitClick = onVisitClick;
        vm.onVisitClickRequestEnhancement = onVisitClickRequestEnhancement;
        vm.onVisitClickRequestEnhancements = onVisitClickRequestEnhancements;
        vm.onVisitClickRequestEnhancementForISP = onVisitClickRequestEnhancementForISP;
        vm.onVisitExcludeClick = onVisitExcludeClick;
        vm.onVisitExcludeAllClick = onVisitExcludeAllClick;
        vm.onVisitTagAsISPsClick = onVisitTagAsISPsClick;
        vm.onVisitSendEmail = onVisitSendEmail;
        vm.doExportToExcel = doExportToExcel;
        vm.doExportToExcelTopCountries = doExportToExcelTopCountries;
        vm.doExportToExcelTopScore = doExportToExcelTopScore;
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.countAndGroupByName = countAndGroupByName;
        vm.countFirstVisits = countFirstVisits;
        vm.checkAll = checkAll;
        vm.deCheckAll = deCheckAll;
        vm.onSendEmailWithVisits = onSendEmailWithVisits;
        vm.getFilters = getFilters;
        vm.onReloadVisits = onReloadVisits;
        vm.onVisitsInTablecClickJumpToFilter = onVisitsInTablecClickJumpToFilter;
        vm.onOrganisationClick = onOrganisationClick;
        vm.onTableSort = onTableSort;
        vm.onAddVisitsToSegment = onAddVisitsToSegment;
        vm.jumpToSegment = jumpToSegment;
        vm.createVisitReportHtml = createVisitReportHtml;
        vm.validateReceivers = validateReceivers;

        // export and search disabled if not less than this
        vm.maxNoOfHitsAllowed = CONFIG.MAX_VISITS_WHEN_EXPORTING_FROM_UI;
        vm.isExportAllowed = false;
        vm.visitsGroupedByTopCountries = [];
        vm.visitsGroupedByTopIndustries = [];


        vm.emailToList = [];
        vm.refSegments = [];
        vm.selectedSegmentId = null;
        vm.account = account;
        vm.filterId = filterId;
        vm.visitorId = visitorId;
        vm.visitCookieId = visitCookieId;
        vm.visits = [];
        vm.viewLoaded = false;

        vm.visits.selected = [];
        vm.start = startDate ? moment(startDate, 'YYYY-MM-DD').toDate() : moment().subtract(7, 'days').toDate();
        vm.end = endDate ? moment(endDate, 'YYYY-MM-DD').toDate() : moment().add(1, 'days').toDate();
        vm.limit = limit;

        vm.sort = new TableSort('adminTable', {
            by: ['startDate'],
            reverse: true
        });
        vm.sortTopScore = new TableSort('visitsTableTopScore', {
            by: ['totalScore'],
            reverse: true
        });
        vm.sortTopCountry = new TableSort('visitsTableTopCountry', {
            by: ['totalCountries'],
            reverse: true
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';

        // reference data
        vm.refFilters = [];


        activate();

        function activate() {
            if( !vm.limit){
                vm.limit = 200;
            }

            //$document.scrollTopAnimated(0);
            getInitialData().then(showInitialView);
        }

        function getInitialData() {

            if (visitorId) {
                return $q.all([findVisitsByVisitorId(), getFilters(), getSegmentFilters()]);
            } else if (visitCookieId) {
				return $q.all([findVisitsByVisitCookieVisits(), getFilters(), getSegmentFilters()]);
            } else {
                return $q.all([getVisits(), getFilters(), getSegmentFilters()]);
            }
        }

        function findVisitsByVisitorId() {
            return VisitService.findVisitsByVisitorId(vm.account.id, vm.account.defaultSiteId, vm.visitorId);
        }

        function findVisitsByVisitCookieVisits() {
            return VisitService.findAllByTag(vm.account.id, vm.account.defaultSiteId, vm.visitCookieId);
        }

        function getVisits() {
            return VisitService.findVisits(vm.account.id, vm.account.defaultSiteId, format(vm.start), format(vm.end), vm.filterId, vm.limit);
        }

        function format(date) {
            return moment(date).format('YYYY-MM-DD');
        }

        // use the stuff when promises are finished
        function showInitialView(result) {
            vm.visitsResults = result[0];
            vm.refFilters = result[1];
            vm.refSegments = result[2];
            vm.viewLoaded = true;
            postReloadVisits();
        }

        function postReloadVisits() {
            vm.visits = vm.visitsResults.visits;

            if (vm.visits && (vm.visits.length < vm.maxNoOfHitsAllowed)) {
                vm.isExportAllowed = true;
            } else {
                vm.isExportAllowed = false;
            }

            // decor with country name
            vm.visits = _.each( vm.visits, function (visit) {
                var country = CommonService.getCountryFromIsoCode(visit.countryIso);
                _.extend(visit, {countryName: country.name});
            } );


            createVisitsGroupedByTopScore(vm.visits);

            createVisitsGroupedByTopCountries(vm.visits);

            createVisitsGroupedByTopIndustries(vm.visits);


            if (!vm.visits.length) {
                logger.info('No Visits found for selected time range! Try changing period using Filter settings');
            }


            $state.go('admin.visits', {
                startDate: format(vm.start),
                endDate: format(vm.end),
                filterId: vm.selectedFilterId,
                limti: vm.limit
            }, {notify: false});
        }

        function createVisitsGroupedByTopScore(visits) {
            var scoreArr = _.map(visits, function(visit){
                return visit.visitScore;
            });
            var totalScoreAllVisits = _.reduce(scoreArr, function(memo, num){ return memo + num; }, 0);

            vm.visitsGroupedByTopScore = _.chain(visits)
                // --> orgna
                .groupBy(function (visit) {
                    return visit.organisation.name;
                })
                .map(function (visit, key) {
                    var totalScore = _.reduce(visit, function (memo, val) {
                            return memo + val.visitScore;
                        }, 0
                    );
                    var noOfVisits = _.reduce(visit, function (memo) {
                            return memo + 1;
                        }, 0
                    );
                    // keep track of leids
                    var leIds = _.reduce(visit, function (seed,item) {
                        seed.push(item.organisation.id);
                        return seed;
                    }, []);


                    return {
                        name: key,
                        countryIso: visit[0].countryIso,
                        countryName: visit[0].countryName,
                        city: visit[0].city,
                        leIds: leIds,
                        totalScore: totalScore,
                        noOfVisits: noOfVisits,
                        totalScoreAllVisits: totalScoreAllVisits
                    };
                })
                .value();

            vm.visitsGroupedByTopScore = _.sortBy(vm.visitsGroupedByTopScore, function (visit) {
                return visit.totalScore;  // revert order
            });
        }

        function createVisitsGroupedByTopCountries(visits) {
            var totalVisits = visits.length;
            vm.visitsGroupedByTopCountries = _.chain(visits)
                // --> country
                .groupBy(function (visit) {
                    return visit.organisation.address.countryISO;
                })
                .map(function (visit, key) {
                    var totalScore = _.reduce(visit, function (memo, val) {
                            return memo + val.visitScore;
                        }, 0
                    );

                    var sum = _.reduce(visit, function (memo) {
                            return memo + 1;
                        }, 0
                    );
                    // keep track of leids
                    var leIds = _.reduce(visit, function (seed,item) {
                        seed.push(item.organisation.id);
                        return seed;
                    }, []);


                    return {
                        name: key,
                        countryIso: visit[0].countryIso,
                        countryName: visit[0].countryName,
                        city: visit[0].city,
                        leIds: leIds,
                        totalCountries: sum,
                        totalVisits: totalVisits,
                        totalScore: totalScore
                    };
                })
                .value();

            vm.visitsGroupedByTopCountries = _.sortBy(vm.visitsGroupedByTopCountries, function (visit) {
                return visit.totalCountries;  // revert order
            });

        }

      function createVisitsGroupedByTopIndustries(visits) {
            var totalVisits = visits.length;

            var  cleanedVisits = _.filter(visits, function (visit) {
              return visit.organisation.sicPrimaryCodeDescription !== null &&
                  visit.organisation.sicPrimaryCode !== null;
            });


            vm.visitsGroupedByTopIndustries = _.chain(cleanedVisits)
                // --> orgna
                .groupBy(function (visit) {
                    return visit.organisation.sicPrimaryCode;
                })
                .map(function (visit, key) {
                    var totalScore = _.reduce(visit, function (memo, val) {
                            return memo + val.visitScore;
                        }, 0
                    );
                    var sum = _.reduce(visit, function (memo) {
                            return memo + 1;
                        }, 0
                    );
                    var sicPrimaryCode = 'Unknown';
                    if( visit[0].organisation.sicPrimaryCode !== '' ){
                        sicPrimaryCode = visit[0].organisation.sicPrimaryCode;
                    }

                    var sicPrimaryCodeDescription = 'Unknown';
                    if( visit[0].organisation.sicPrimaryCodeDescription !== '' ){
                        sicPrimaryCodeDescription = visit[0].organisation.sicPrimaryCodeDescription;
                    }

                    // keep track of leids
                    var leIds = _.reduce(visit, function (seed,item) {
                        seed.push(item.organisation.id);
                        return seed;
                    }, []);


                    return {
                        name: key,
                        sicPrimaryCode: sicPrimaryCode,
                        sicPrimaryCodeDescription: sicPrimaryCodeDescription,
                        leIds: leIds,
                        totalIndustries: sum,
                        totalScore: totalScore,
                        totalVisits: totalVisits
                    };
                })
                .value();

            vm.visitsGroupedByTopIndustries = _.sortBy(vm.visitsGroupedByTopIndustries, function (visit) {
                return visit.totalIndustries;  // revert order
            });

        }

        function onVisitClick(visit) {
            $state.go(STATE.VISIT, {accountId: vm.account.id, visitId: visit.id});
        }

        function onVisitClickRequestEnhancement(visit) {
            EnhancedOrganisationService.createHighPrioEnhancementRequests( [visit.organisation.id]);
            logger.info('Request for Enhancement has been created');
        }

         function onVisitClickRequestEnhancements() {
             if (vm.visits.selected && vm.visits.selected.length > 0) {
                 var leIds = [];
                 _.each( vm.visits.selected, function(visit){
                     leIds.push(visit.organisation.id);
                 });
                 EnhancedOrganisationService.createHighPrioEnhancementRequests( leIds );
                 logger.info('Request for Enhancement has been created');
             }

        }

        function onVisitClickRequestEnhancementForISP(visit) {
            var user = CommonService.getUser();
            EnhancedOrganisationService.createEnhancementRequestForISP( visit, user,  account );
            logger.info('Request for Enhancement of ISP has been created and we will have a look into it');
        }

        function onVisitTagAsISPsClick() {
            if (vm.visits.selected && vm.visits.selected.length > 0) {
                var user = CommonService.getUser();
                EnhancedOrganisationService.createEnhancementRequestForISPs( vm.visits.selected, user,  account );
                logger.info('Request for Enhancement of ISP/s has been created and we will have a look at it');

            }
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function onVisitSendEmail() {

        }

        function getFilters() {
            return VisitFilterService.getVisitFiltersBasic(vm.account.id);
        }

        function onReloadVisits() {
            VisitService.findVisits(vm.account.id, vm.account.defaultSiteId, format(vm.start), format(vm.end), vm.selectedFilterId, vm.limit)
                .then(function (visits) {
                    vm.visitsResults = visits;
                    postReloadVisits();
                });
        }

        function checkAll() {
            vm.visits.selected = angular.copy(vm.visits);
        }

        function deCheckAll() {
            vm.visits.selected = null;
        }

        function onVisitExcludeAllClick() {
            if (vm.visits.selected && vm.visits.selected.length > 0) {
                ExcludedOrganisationService.updateExcludedOrganisationBatch(vm.account, vm.visits.selected)
                    .then(function () {
                        logger.info('Added Excluded Organisation, Excluding last 2 weeks and future visits from this Organisation.' );
                    });
            }
        }


        function onVisitExcludeClick(visit) {
            ExcludedOrganisationService.updateExcludedOrganisation(vm.account, visit)
                .then(function () {
                    logger.info('Added Excluded Organisation, Excluding last 2 weeks and future visits from this Organisation.');
                });

        }

        function onSendEmailWithVisits() {
            VisitService.sendEmailWithVisits(vm.account, vm.emailToList, vm.visits.selected)
                .then(function () {
                    logger.info('Mail sent! with Visits selected by you');
                });
        }

        function validateReceivers(values) {
            var valid = false;
            _.each(values, function (email) {
                if (email.indexOf('@') === -1) {
                    valid = false;

                }
                else if (email.indexOf('.') === -1) {
                    valid = false;
                }
                else {
                    valid = true;
                }
            });
            return valid;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Visits_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function doExportToExcelTopCountries() {
            var data = new Blob([angular.element('#exportable-table-topcountries').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Visits_Top_Countries_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function doExportToExcelTopScore() {
            var data = new Blob([angular.element('#exportable-table-topscore').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Visits_Top_Score_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.visits) {
                return vm.visits.length;
            }
        }

        function countAndGroupByName() {
            return vm.visitsGroupedByTopScore;
        }

        function countFirstVisits() {
            if (vm.visits) {
                var first = _.where(vm.visits, {isFirstVisit: true});
                if (first) {
                    return first.length;
                }
            }
            return 0;
        }

        function onOrganisationClick(organisationLeId) {
            $state.go(STATE.ORGANISATION, {organisationId: organisationLeId});
        }

        function onVisitsInTablecClickJumpToFilter(leIds){
            var uniqueFilterOnLeIds = _.uniq(leIds);
            $state.go(STATE.VISIT_FILTER, {startDate : format(vm.start), leIds: uniqueFilterOnLeIds });
        }

        function getSegmentFilters() {
            if (_.isNull(vm.account.id) || _.isEmpty(vm.account.id)) {
                // initial when no campaign found
                return {};
            } else {
                return SegmentFilterService.getSegments(vm.account.id);
            }
        }

        function onAddVisitsToSegment() {
            if (vm.visits.selected && vm.selectedSegmentId) {
                var selectedVisits = angular.copy(vm.visits.selected);
                var leIds = _.map(selectedVisits, 'organisation.id');
                SegmentFilterService.addLeIdsToSegmentFilter(vm.account.id, vm.selectedSegmentId, leIds).then(function () {
                    logger.info('Segment was updated !!!!');
                });
            }
        }

        function jumpToSegment() {
            $state.go('admin.segmentFilter', {accountId: vm.account.id, segmentFilterId: vm.selectedSegmentId});
        }

        function createVisitReportHtml() {
            if (!vm.visits || vm.visits.length === 0) {
                logger.info('No Visits so can not export any!!');
                return;
            }

            if (vm.selectedFilterId) {
                VisitService.createVisitReportUsingFilterIdHtml(vm.account.id, account.defaultSiteId, format(vm.start), format(vm.end), vm.selectedFilterId)
                    .then(function (response) {
                        var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                        var config = {
                            data: data,
                            filename: 'Visits_filter_' + vm.selectedFilterId + '_' + new Date().toISOString().substring(0, 10) + '.html'
                        };
                        FileSaver.saveAs(config.data, config.filename);
                    });
            } else {
                // when entering we do not have a filter...
                VisitService.createVisitsHtmlReportFromUIWithoutFilter(vm.account.id, account.defaultSiteId, format(vm.start), format(vm.end))
                    .then(function (response) {
                        var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                        var config = {
                            data: data,
                            filename: 'Visits_no_filter_' + new Date().toISOString().substring(0, 10) + '.html'
                        };
                        FileSaver.saveAs(config.data, config.filename);
                    });
            }
        }
    }
})();