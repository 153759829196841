(function() {
	'use strict';

	angular
		.module('adminApp')
		.service('TopCountriesBarChart', ['CountryService', TopCountriesBarChart]);

	function TopCountriesBarChart(CountryService) {
		return function(visits) {
			var that = this;

			this.config = {
				options: {
					chart: {
						renderTo: 'container',
						type: 'column',
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 20,
						spacingTop: 20,
						spacingLeft: 20,
						spacingRight: 20,
					},
					exporting: {
						enabled: false,
					},
					plotOptions: {
						series: {
							animation: {
								duration: 400,
							},
						},
					},
					tooltip: {
						formatter: function() {
							return '<b>' + this.point.name + '</b>: ' + this.y + ' total visits';
						},
					},
				},
				title: {
					text: '',
				},
				xAxis: {
					categories: [],
				},
				yAxis: {
					title: {
						text: '',
					},
					gridLineWidth: 0,
					minorGridLineWidth: 0,
				},
				series: [
					{
						data: [],
						size: '100%',
						showInLegend: false,
						dataLabels: false,
					},
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
				setXAxis: function(xAxis) {
					that.config.xAxis.categories = xAxis;
				},
			};

			initChart(visits);

			function initChart(visits) {
				var numberOfVisitsPerCountry = _.chain(visits)
					.groupBy(country)
					.map(visitsPerCountry)
					.map(toChartItem)
					.sortBy(numberOfVisits)
					.value()
					.slice(0, 10);
				that.config.setData(numberOfVisitsPerCountry);

				var xAxis = _.chain(visits)
					.groupBy(country)
					.map(visitsPerCountry)
					.map(toChartItem)
					.sortBy(numberOfVisits)
					.map(toXAxis)
					.value()
					.slice(0, 10);
				that.config.setXAxis(xAxis);
			}

			function country(visit) {
				return CountryService.getCountryNameByCode(visit.countryIso);
			}

			function visitsPerCountry(value, key) {
				return { country: key, visits: value.length };
			}

			function toChartItem(visit) {
				return [visit.country, visit.visits];
			}

			function numberOfVisits(visit) {
				return -visit[1];
			}

			function toXAxis(visit) {
				return visit.name;
			}
		};
	}
})();
