(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('ResellerAccountService', ['$http', 'CONFIG', 'responseExceptionCatcher', ResellerAccountService]);

    function ResellerAccountService($http, CONFIG, responseExceptionCatcher) {
        this.getAccountsBasicForReseller = getAccountsBasicForReseller;
        this.getResellerAccount = getResellerAccount;
        this.getAllResellerAccounts = getAllResellerAccounts;
        this.getAllAccountsForReseller = getAllAccountsForReseller;
        this.getResellerAccountForCurrent = getResellerAccountForCurrent;
        this.switchResellerTo = switchResellerTo;
        this.createOrUpdateResellerAccount = createOrUpdateResellerAccount;

        function getAllResellerAccounts(active) {
            var url = CONFIG.API_PREFIX + '/admin/resellerAccounts';
            if( active !== undefined &&  !active ){
                url = CONFIG.API_PREFIX + '/admin/resellerAccounts?active=false';
            }
            return $http.get(url)
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getResellerAccount(resellerId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/resellerAccounts/' + resellerId)
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAllAccountsForReseller(resellerId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/resellerAccounts/' + resellerId + '/accounts')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAccountsBasicForReseller(resellerId){
            return $http.get(CONFIG.API_PREFIX + '/admin/resellerAccounts/' + resellerId + '/accounts/basic')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // get the loggedin user resellers acount
        function getResellerAccountForCurrent() {
            return $http.get(CONFIG.API_PREFIX + '/resellerAccounts/current')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function switchResellerTo( switchToResellerID) {
            return $http.post(CONFIG.API_PREFIX + '/admin/resellerAccounts/switch/' + switchToResellerID)
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function createOrUpdateResellerAccount(resellerAccount) {
            var promise;
            if (!(_.isNull(resellerAccount.id) || resellerAccount.id === undefined || resellerAccount.id === '')) {
                promise = $http.put( CONFIG.API_PREFIX + '/admin' + '/resellerAccounts/', resellerAccount)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/admin' + '/resellerAccounts/', resellerAccount)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving '));
            }
            return promise;
        }


        function onSuccess(response) {
            return response.data.data;
        }
    }
})();