(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('UserService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', UserService]);

    function UserService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getLoggedInUser = getLoggedInUser;
        this.getUsers = getUsers;
        this.searchUsersForAccount = searchUsersForAccount;
        this.searchAllUsers = searchAllUsers;
        this.getUsersForAccount = getUsersForAccount;
        this.getUser = getUser;
        this.getRoles = getRoles;
        this.createOrUpdateUser = createOrUpdateUser;
        this.sendWelcomeEmail = sendWelcomeEmail;
        this.pushUsersToMailChimp = pushUsersToMailChimp;

        function getLoggedInUser() {
            return $http.get(CONFIG.API_PREFIX + '/admin/users/current')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // for typeahead, account is picked up in the backend
        function searchUsersForAccount(user) {
            return $http.get(CONFIG.API_PREFIX + '/users?query=' + user)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }        // for typeahead, account is picked up in the backend

		function searchAllUsers(userName) {
            return $http.get(CONFIG.API_PREFIX + '/users/all?q=' + userName)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getUsersForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/users')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getUsers(active) {
            var url = CONFIG.API_PREFIX + '/admin/users';
            if( active !== undefined &&  !active ){
                url = CONFIG.API_PREFIX + '/admin/users?active=false';
            }
            return $http.get( url )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getRoles() {
            return $http.get( CONFIG.API_PREFIX + '/admin/roles' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getUser(userId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/users/' + userId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function sendWelcomeEmail(userId){
            return $http.post(CONFIG.API_PREFIX + '/admin/users/' + userId + '/mail')
                .catch(responseExceptionCatcher.catch('Error when sending email '));
        }

       function pushUsersToMailChimp(listId){
            return $http.post(CONFIG.API_PREFIX + '/admin/users//mailchimp/' + listId + '/members' )
                .catch(responseExceptionCatcher.catch('Error when sending email '));
        }

        function createOrUpdateUser(user) {
            var promise;
            if (!(_.isNull(user.id) || user.id === undefined || user.id === '')) {
                promise = $http.put( CONFIG.API_PREFIX + '/admin' + '/users/', user)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/admin' + '/users/', user)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving '));
            }
            return promise;
        }
    }
})();