(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ResetPwdController', ['rid', 'uid', 'dt', '$q', 'CONFIG', '$scope', '$state', 'logger', 'AuthService', ResetPwdController]);

    function ResetPwdController(rid, uid, dt, $q, CONFIG, $scope, $state, logger, AuthService) {
        var vm = this;
        vm.onClickResetPassword = onClickResetPassword;
        vm.onValidateResetPasswordForm = onValidateResetPasswordForm;
        vm.isResetPasswordFormOk = false;
        vm.forgotPasswordUserName = null;
        vm.forgotPasswordInputSum = null;
        vm.rid = rid;
        vm.dt = dt;


        vm.passwordUpdate = {
            id: uid,
            rid: rid,
            dt: dt
        };

        function onValidateResetPasswordForm() {
            vm.isResetPasswordFormOk = vm.passwordUpdate.password === vm.passwordUpdate.passwordConfirm;
        }

        function onClickResetPassword() {
            if (!vm.rid || 0 === vm.rid.length) {
                logger.error('Reset link was invalid!');
                return;
            }
            if (!vm.dt || 0 === vm.dt.length) {
                logger.error('Reset link was invalid!');
                return;
            }

            AuthService.resetPassword(vm.passwordUpdate).then(function () {
                logger.info('Your password has been updated!');
                $state.go('login', {notify: false});
            });
        }
    }
})();