(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('UserController', ['account', 'selectedAccountId', 'userId', 'currentResellerAccount', 'UserService', 'AccountService', 'CommonService', 'logger', '$q',
            '$scope', '$state', UserController]);
    function UserController(account, selectedAccountId, userId, currentResellerAccount, UserService, AccountService, CommonService, logger, $q, $scope, $state) {
        var vm = this;
        vm.saveUser = saveUser;
        vm.autoCompleteRoles = autoCompleteRoles;

        vm.userId = userId;
        vm.currentResellerAccount = currentResellerAccount;
        vm.refAccounts = [];
        vm.account = account;
        // when comin from account to create user for the account
        vm.selectedAccountId = selectedAccountId;
        vm.selectedAccount = null;

        // initial if creating new
        vm.user = {
            id: userId,
            userState : 'ACTIVE',
            timeZoneId : account.timeZoneId
        };

        // reference data
        vm.refRoles = [];
        vm.refSelectActive = [{id: 'ACTIVE', name: 'Active'}, {id: 'LOCKED_OUT', name: 'Locked out'}, {id: 'NOT_ACTIVE', name: 'Not Active'}];
        vm.refTimeZones = null;


        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getUser(), getTimeZones(), getRoles(), getAllAccounts(), getAllSelectedAccount()]);
        }

        function getUser() {
            if (_.isNull(vm.userId) || _.isEmpty(vm.userId)) {
                // initial when no campaign found
                return vm.user;
            } else {
                return UserService.getUser(vm.userId);
            }
        }

        function getTimeZones() {
            return CommonService.getTimeZones();
        }

        function getRoles() {
            return UserService.getRoles();
        }

        function getAllAccounts() {
            return AccountService.getAccountsBasic();
        }

       function getAllSelectedAccount() {
           if( vm.selectedAccountId && vm.selectedAccountId !== null){
               // only fetch if set
               return AccountService.getAccountBasicByAccountId(vm.selectedAccountId);
           }
        }

        // display the stuff when promises are finished
        function initView(result) {
            vm.user = result[0];
            vm.refTimeZones = result[1];
            vm.refRoles = result[2];
            vm.refAccounts = result[3];
            vm.selectedAccount = result[4];



            if( vm.refAccounts !== null && vm.selectedAccount !== null && vm.selectedAccount !== undefined ){


                vm.refAccounts.unshift(vm.selectedAccount);
                vm.user.accountDetails = vm.refAccounts[0];
            }

        }
        /// End init

        // mulitselect callback when user enter text
        function autoCompleteRoles() {
            return vm.refRoles.filter(function (role) {
                return role.id;
            });
        }

        function saveUser() {
            return $q.all([onSaveUser()]);
        }

        function onSaveUser() {
            return UserService.createOrUpdateUser(vm.user)
                .then(function (data) {
                    vm.user = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('User saved');
            $state.go('admin.account', {accountId: vm.selectedAccountId}, {notify: true});
        }

    }
})();