(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('logicJoint', ['$timeout', LogicJoint]);

    function LogicJoint($timeout) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/logic-joint.html',
            require: 'ngModel',
            link: function (scope, elem, attrs, ngModel) {
                scope.values = ['and', 'or'];

                ngModel.$render(function () {
                    scope.activeValue = ngModel.$modelValue;
                });

                $timeout(function () {
                    scope.activeValue = ngModel.$modelValue || scope.values[0];
                });

                scope.$watch(function() {
                    return ngModel.$modelValue;
                }, function() {
                    scope.activeValue = ngModel.$modelValue;
                });

                scope.$watch('activeValue', function (newValue, oldValue) {
                    if(newValue !== oldValue) {
                        ngModel.$setViewValue(newValue);
                    }
                });

                scope.clicked = function (val) {
                    scope.activeValue = val;
                };
            }
        };
    }
})();