(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ReportsController', ['account', 'ReportService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$uibModal', ReportsController]);

    function ReportsController(account, ReportService, FileSaver, Blob, CONFIG, TableSort, logger, $uibModal) {
        var vm = this;
        //vm.deleteItem = deleteItem;

        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.runReport = runReport;
        vm.confirmDelete = confirmDelete;
        vm.clone = clone;

        vm.account = account;
        vm.reports = null;
        vm.searchFilter = '';
        vm.ok = null;
        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.loadMore = loadMore;

        activate();

        function activate() {
            getReports().then(showReports);
        }

        function getReports() {
            return ReportService.getReports(vm.account.id);
        }

        function showReports(reports) {
            vm.reports = reports;
            if (!vm.reports.length) {
                logger.info('No reports found!');
            }
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Reports_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function confirmDelete(itemId, name) {
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + name + ' Report?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(itemId);
            });
        }

        function deleteItem(reportId) {
            ReportService.deleteReport(account.id, reportId)
                .then(function (reports) {
                    vm.reports = reports;
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.reports) {
                return vm.reports.length;
            }
        }

        function runReport(reportId){
            ReportService.runReport(account.id, reportId)
                .then(function () {
                    logger.info('Report has been scheduled to run!');
                });
        }

        function clone(reportId) {
            var reportIds = [reportId];
            ReportService.clone(vm.account.id, reportIds)
                .then(function (reports) {
                    if (reports) {
                        logger.success('Report was cloned cloned with new name : ' + reports[0].name );
                        vm.clonedReports = reports;
                    }
                })
                .then( function(){
                    getReports().then(showReports);
                });
        }
    }
})();