(function () {
    'use strict';

    angular
        .module('adminApp')
        .constant('RULE_OPERATORS', {
            'equal': 'Equal',
            'beginsWith': 'Begins with',
            'contains': 'Contains',
            'between': 'Between'
        });
})();