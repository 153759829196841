(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignsBetaController', ['$scope', '$element', '$window', '$timeout', 'logger', 'account', 'CampaignBetaService', 'campaignsTableModel', CampaignsController]);

    function CampaignsController($scope, $element, $window, $timeout, logger, account, CampaignService, campaignsTableModel) {
        var vm = this;
        vm.account = account;
        vm.campaigns = null;
        vm.deleteSelected = deleteSelected;
        vm.cloneSelected = cloneSelected;
        vm.ok = null;
        vm.doExportToExcel = doExportToExcel;
        vm.selectedNodes = [];
        vm.initialized = {value: false};
        vm.panelHeights = 120;
        vm.globalSearch = '';


        function doExportToExcel() {
            $scope.$broadcast('exportDataAsExcel');
        }

        function deleteSelected() {
            var campaignsIds = _.map(vm.selectedNodes, function(node){return node.data.id;});
            CampaignService.deleteCampaigns(vm.account.id, campaignsIds)
                .then(function() {
                    $scope.$broadcast('updateTable');
                });
        }

        function cloneSelected() {
            var campaignsIds = _.map(vm.selectedNodes, function(node){return node.data.id;});
            CampaignService.cloneCampaigns(vm.account.id, campaignsIds)
                .then(function(){
                    logger.success('You have created clone(s).');
                    $scope.$broadcast('updateTable');
                });
        }

        vm.campaignsTable = {
            getFn: function() {
                return CampaignService.getCampaigns(vm.account.id)
                    .then(function (campaigns) {
                        vm.campaigns = campaigns;
                        return campaigns;
                    });
            },
            enableFilter: true,
            isExternalFilterPresent: isExternalFilterPresent,
            doesExternalFilterPass: doesExternalFilterPass,
            onRowsNumberChanged: function () {
                filterChanged();
            },
            columnDefs: campaignsTableModel.columnDefs
        };

        vm.globalSearchChanged = function () {
            campaignsTableModel.setFilterValue(vm.globalSearch);
            vm.campaignsTable.grid.api.onFilterChanged();
        };

        $scope.$on('$destroy', onDestroy);
        angular.element($window).bind('resize', resizeContentHeight);

        $timeout(function() {
            resizeContentHeight();
        });

        function filterChanged() {
            var sel = $element.find('.searchable-cell');
            if(vm.globalSearch) {
                sel.unmark();
                sel.mark(vm.globalSearch);
            } else {
                sel.unmark();
            }
        }

        function isExternalFilterPresent() {
            return vm.globalSearch.length > 0;
        }

        function doesExternalFilterPass(node) {
            var query = vm.globalSearch.toLowerCase();
            return node.data.name.toLowerCase().indexOf(query) >= 0;
        }

        function resizeContentHeight() {
            if ($(window).height() > 600) {
                $element.css('height', $(window).height() - vm.panelHeights);
            } else {
                $element.css('height', 800 - vm.panelHeights);
            }
        }

        function onDestroy() {
            angular.element($window).off('resize');
        }
    }
})();