(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('RIPEOrganisationController', ['RIPEOrganisationService', 'query', '$state','FileSaver', 'Blob', 'CONFIG', 'TableSort',
            RIPEOrganisationController]);
    function RIPEOrganisationController(RIPEOrganisationService, query, $state, FileSaver, Blob, CONFIG, TableSort) {
        var vm = this;
        vm.ripeOrganisation = null;
        vm.query = query;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        //vm.queryFor = '83.92.211.128';
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });

        //methods
        vm.loadMore = loadMore;
        vm.onRIPESEarch = onRIPESEarch;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        activate();

        function activate() {
            // brin up state if any
            //vm.queryFor = RIPEOrganisationService.query;
            if (vm.query) {
                onRIPESEarch();
            }
        }

        /// End init

        function onRIPESEarch() {
            RIPEOrganisationService.searchRIPE(vm.query).then(onFinished);
        }

        function onFinished(organisation) {
            vm.ripeOrganisation = organisation;
            $state.go('admin.enhanceripeOrganisations', {query: vm.query}, {notify: true});
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'organisations_ripe_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.ripeOrganisation.organisationRows.length;
        }
    }
})();