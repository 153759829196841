(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SitesController', ['SiteService', 'logger', '$filter', '$state',
            '$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort',
            SitesController]);
    function SitesController(SiteService, logger, $filter, $state,
                             $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
        var vm = this;
        vm.sites = [];
        vm.ok = null;
        vm.toggled = toggled;
        vm.toggleDropdown = toggleDropdown;
        vm.loadMore = loadMore;
        vm.getSites = getSites;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        vm.items = [
            'The first choice!',
            'The first choice!sss',
            'And another choice for you.',
            'but wait! A third!'
        ];

        vm.status = {
            isopen: false
        };


        ////////////
        //  Init
        activate();

        function activate() {
            getSites();
        }

        function getSites() {
            SiteService.getSites().then(showSites);
        }

        function showSites(sites) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.sites = sites;
        }

        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'sites_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.sites.length;
        }

        function toggled(open) {
        }

        function toggleDropdown($event) {
            $event.preventDefault();
            $event.stopPropagation();
            vm.status.isopen = !vm.status.isopen;
        }

    }
})();