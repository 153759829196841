(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitorVisitsController', ['account', 'visitorId' , '$document','$q', 'CONFIG', 'FileSaver', 'TableSort', '$state', 'logger', 'VisitorService', VisitorVisitsController]);

    function VisitorVisitsController(account, visitorId , $document, $q, CONFIG, FileSaver, TableSort, $state, logger, VisitorService) {
        var vm = this;

        vm.onTableSort = onTableSort;
        vm.doExportToExcel = doExportToExcel;
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;


        vm.account = account;
        vm.visitorId = visitorId;
        vm.visits = [];
        vm.visits.selected = [];
        vm.sort = new TableSort('adminTable', {
            by: ['startDate'],
            reverse: true
        });
        vm.sortTopScore = new TableSort('adminTable', {
            by: ['totalScore'],
            reverse: true
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';

        activate();

        function activate() {
            $document.scrollTopAnimated(0);
            getInitialData().then(showInitialView);
        }

        function getInitialData() {
            return $q.all([findVisitsByVisitorId()]);
        }

        function findVisitsByVisitorId() {
            return VisitorService.findVisitsByVisitorId(vm.account.id, vm.account.defaultSiteId, vm.visitorId);
        }

        // use the stuff when promises are finished
        function showInitialView(result) {
            vm.visitResults = result[0];
            postReloadVisits();
        }


        function postReloadVisits() {
            vm.visits = vm.visitResults;
            if (!vm.visits.length) {
                logger.info('No Visits found!');
            }
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        /*
        function checkAll() {
            vm.visits.selected = angular.copy(vm.visits);
        }

        function deCheckAll() {
            vm.visitors.selected = null;
        }*/

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'VisitorVisits_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.visits) {
                return vm.visits.length;
            }
        }

    }
})();