(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('SegmentFiltersTableModel', ['$filter','$state','cellRenderers',SegmentFiltersTableModel
		]);

	function SegmentFiltersTableModel($filter,$state,cellRenderers) {

		function goToState(segmentId, accountId) {
			$state.go('admin.segmentAdvancedFilter', {
				segmentFilterId: segmentId,
				accountId : accountId
			})
		}

		function nameCellRendererFunc(params) {
			params.$scope.goToState = goToState;
			return '<a ng-click="goToState(data.id, data.accountId)" class="pointer" ng-bind="data.name"></a>';
		}

		var filterValue = '';
		return {
			setFilterValue: function (value) {
				filterValue = value;
			},
			columnDefs: [
				{
					minWidth: 250,
					headerName: 'Name',
					field: 'name',
					headerCheckboxSelectionFilteredOnly: true,
					cellRenderer: nameCellRendererFunc

					/*
					'/#/accounts/' +
							params.data.accountId +
						'/segmentadvancedfilters/' +
						params.data.id
					*/

				},
				{
					headerName: 'Type',
					field: 'type',
					valueGetter: function (params) {
						return params.data.type === 'STATIC'
							? 'Static'
							: params.data.type === 'DYNAMIC'
								? 'Dynamic ': 'Dynamic';
					}
				},
				{
					minWidth: 160,
					headerName: 'No of Organisations',
					field: 'nr orgs',
					valueGetter: function (params) {
						return params.data.totalSegmentSize
							? params.data.totalSegmentSize
							: 0;
					},
					filter: 'number'
				},
				{
					headerName: 'Active',
					field: 'active',
					valueGetter: function (params) {
						return params.data.active ? 'Active' : 'Inactive';
					}
				},
				{
					minWidth: 170,
					maxWidth: 170,
					headerName: 'Used in Audiences',
					field: 'length	',
					cellRenderer: cellRenderers.PopupCellRenderer,
					cellRendererParams: {
						popupGetter: function (params) {
							return params.data.campaigns.map(function (c) {
								return c.name;
							});
						},
						filterValue: function () {
							return filterValue;
						},
						valueGetter: function (params) {
							return params.data.campaigns.length;
						},
						hrefGetter: function () {
							/*jshint scripturl:true*/
							return 'javascript:void(0)';
						}
					},
					filter: 'number'
				},
				{
					headerName: 'Created By',
					field: 'userName',
					cellClass: 'searchable-cell',
					valueGetter: function (params) {
						return params.data.userName;
					},
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				},
				{
					headerName: 'Updated',
					field: 'updated',
					valueGetter: function (params) {
						return $filter('date')(params.data.modified, 'yyyy-MM-dd');
					}
				},

				{
					hide : true,
					minWidth: 230,
					maxWidth: 240,
					headerName: 'Id',
					field: 'id',
					cellClass: 'searchable-cell',
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				}
			]
		};
	}
})();
