(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('filterAccordion', ['$rootScope', '$timeout', '$uibModal', 'CommonService', 'TableSort', function ($rootScope, $timeout, $uibModal, CommonService, TableSort) {
            return {
                restrict: 'E',
                scope: {
                    groups: '=',
                    topHeight: '='
                },
                templateUrl: 'partials/common/filteraccordion/filter-accordion.html',
                link: function (scope,element) {

                    scope.hamburgerOpenState = true;

                    $timeout(function () {
                        element.find('.filter-accordion')
                            .css('height', ($(window).height() - scope.topHeight) < 167? 167: ($(window).height() - scope.topHeight));
                        _.first(scope.groups).show = true;

                    });

                    _.forEach(scope.groups, function (g) {
                        g.show = false;
                    });

                    scope.expand = function () {
                        _.forEach(scope.groups, function (g) {
                            g.show = true;
                        });
                    };

                    scope.hide = function () {
                        _.forEach(scope.groups, function (g) {
                            g.show = false;
                        });
                    };

                    scope.avatarFn = function (el) {
                        var a = el.clone();
                        a.addClass('filter-accordion__group-item-avatar');
                        a.css('width', el.outerWidth());
                        return a;
                    };

                    scope.dragData = function (itemScope) {
                       return {
                           type: 'newFilter',
                           data: itemScope.item
                       };
                    };

                    scope.addClick = function (e, item) {
                        // e.stopPropagation();
                        // e.preventDefault();
                        $rootScope.$broadcast('filterAdded', item);
                    };

                    //used in popup table for sorting etc
					scope.searchFilter = '';
					scope.sort = new TableSort('industryCodeTable', {
						by: ['code', 'name'],
						reverse: false
					});

					scope.showHelp = function (e, item) {
						if (item !== null && item.name === 'Name') {
							showHelpDialogName();
						} else if (item !== null && item.name === 'DUNS') {
							showHelpDialogDUNS();
						} else if (item !== null && item.name === 'SIC Code') {
							showHelpDialogSICCode();
						} else if (item !== null && item.name === 'SNI or NACE Code') {
							showHelpDialogNACECode();
						} else if (item !== null && item.name === 'NAICS Code') {
							showHelpDialogNAICSCode();
						} else if (item !== null && item.name === 'GICS Code') {
							showHelpDialogGICSSCode();
						} else if (item !== null && item.name === 'Number of employees') {
							showHelpDialogNoOfEmployees();
						} else if (item !== null && item.name === 'Domain name') {
							showHelpDialogDomainName();
						} else if (item !== null && item.name === 'Organisation ID') {
							showHelpDialogOrganisationId();
						}else if (item !== null && item.name === 'Enhancement score') {
							showHelpDialogEnhancementScore();
						}else if (item !== null && item.name === 'Sales') {
							showHelpDialogSales();
						}else if (item !== null && item.name === 'Organisation types') {
							showHelpDialogOrganisationTypes();
						}else if (item !== null && item.name === 'ISP') {
							showHelpDialogISP();
						}else if (item !== null && item.name === 'Industry tags') {
							showHelpDialogIndustryTags();
						} else if (item !== null && (item.name === 'City' ||
							item.name === 'Country' ||
							item.name === 'Region' ||
							item.name === 'Postal code')) {
							showHelpDialogGeography();
						}
					};

					function showHelpDialogGeography() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-geography-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}
					function showHelpDialogIndustryTags() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-industrytags-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogISP() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-isp-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogName() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-name-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogDomainName() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-domain-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogEnhancementScore() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-enhancementscore-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogSales() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-sales-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogNoOfEmployees() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-noofemployees-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}


					function showHelpDialogOrganisationId() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-organisationid-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogDUNS() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-duns-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogOrganisationTypes() {
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-organisationtypes-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

                    function showHelpDialogSICCode() {
						CommonService.getSICCodes().then(function(codes){
							scope.industryCodes = codes;
						});
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-siccode-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

                    function showHelpDialogNACECode() {
						CommonService.getNACECodes().then(function(codes){
							scope.industryCodes = codes;
						});
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-nacecode-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

					function showHelpDialogNAICSCode() {
						CommonService.getNAICSCodes().then(function(codes){
							scope.industryCodes = codes;
						});
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-naicscode-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

                    function showHelpDialogGICSSCode() {
						CommonService.getGICSCodes().then( function(codes){
							scope.industryCodes = codes.tableRows;
						});
						console.log('opening up..')
						$uibModal.open({
							templateUrl: 'partials/common/advancedfilters/filter-help/filter-help-gicscode-modal.html',
							scope: scope,
							size: 'lg'
						}).result.then(function () {
						});
					}

                    scope.$watch('searchPattern', function (searchPattern) {
                        $timeout(function () {
                            var sel = element.find('.filter-accordion__group-item-label');
                            if(searchPattern) {
                                sel.unmark();
                                sel.mark(searchPattern);
                            } else {
                                sel.unmark();
                            }
                        });
                        if(!searchPattern) {
                            _.forEach(scope.groups, function (g) {
                                g.show = false;
                            });
                            scope.numberOfShown = countShown();
                            return;
                        }

                        scope.numberOfShown = _.reduce(scope.groups, function (acc, g) {
                            var shown = _.filter(g.items, function (i) {
                                return i.name.toLowerCase().indexOf(searchPattern.toLowerCase()) >= 0;
                            });
                            g.show = shown.length > 0;
                            return acc += shown.length;
                        }, 0);
                    });

                    function countShown() {
                        return _.reduce(scope.groups, function (acc, g) {
                            if(!g.items) { return acc; }
                            return acc+=g.items.length;
                        }, 0);
                    }

                    scope.numberOfShown = countShown();


                }
            };
        }]);
})();
