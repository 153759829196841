(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PageKeywordController', ['account' , 'pageKeywordId', '$q','$state'  , 'logger' , 'PageKeywordService' ,   PageKeywordController]);

    function PageKeywordController(account, pageKeywordId, $q, $state, logger, PageKeywordService) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.savePageKeyword = savePageKeyword;

        // this is resolved from ui-router if any
        vm.pageKeywordId = pageKeywordId;
        vm.account = account;
        vm.start = moment().subtract(30, 'days').toDate();
        vm.end = moment().toDate();

        // reference data
        vm.refFilters = [];
        vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};
        // initial if creating new
        vm.pageKeyword = {
            id: null,
            accountId: account.id,
            siteId: account.defaultSiteId

        };

        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getPageKeyword()]);
        }

        function getPageKeyword(){
            if (_.isNull(vm.pageKeywordId) || _.isEmpty(vm.pageKeywordId)) {
                // on create
                return vm.pageKeyword;
            } else {
                return PageKeywordService.getPageKeyword(vm.account.id, vm.pageKeywordId);
            }
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.pageKeyword = result[0];
        }

        /// End init
        ///////////////

        function savePageKeyword(){
            return $q.all([onSaved()]);
        }

        function onSaved() {
            return PageKeywordService.updatePageKeyword(vm.account.id, vm.pageKeyword)
                .then(function (data) {
                    //console.log('On save - the data' + JSON.stringify(data));
                    vm.pageKeyword = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Page Keyword successfully saved ');
        }


    }
})();