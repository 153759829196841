(function() {
    'use strict';

    angular
        .module('adminApp')
        .constant('VisitAdvancedFilterConstants', new VisitAdvancedFilterConstants());

    function VisitAdvancedFilterConstants() {

    	// used as broadcast event
		var eventDeletedVisitFromVisitList = 'eventDeletedVisitFromVisitList';


		// on save we get a new count on segment
		var eventVisitSizeUpdatedFromQuery = 'eventVisitSizeUpdatedFromQuery';

		// when fired from search controller we should save the filter
		var eventNewVisitAddedToVisitList = 'eventNewVisitAddedToVisitList';


		var filterTypes = function(filterOperator, filterField) {
        	var equalTypeDependingOnField = 'stringType';

        	if( filterField && filterField === 'visitsOrganisationsEmployees'){
				equalTypeDependingOnField = 'integerType';
			}
        	if( filterField && filterField === 'visitsOrganisationsNoOfNetworks'){
				equalTypeDependingOnField = 'integerType';
			}
          	if( filterField && filterField === 'visitsScore'){
				equalTypeDependingOnField = 'integerType';
			}
            if( filterField && filterField === 'visitsOrganisationsISP'){
				equalTypeDependingOnField = 'booleanType';
			}
            if( filterField && filterField === 'visitsIsFirstVisit'){
				equalTypeDependingOnField = 'booleanType';
			}
			if( filterField && filterField === 'visitsOrganisationsSic'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'visitsOrganisationsEnhancementScore'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'visitsOrganisationsSniNace'  ){
				return 'integerType';
			}
            if( filterField && filterField === 'visitsOrganisationsNaics'  ){
				return 'integerType';
			}
			if( filterField && filterField === 'visitsOrganisationsGicsSectorCode1'  ){
				return 'integerType';
			}
			// given operand what type
            var types =  {
                'Text match': 'stringType',
                'Contains': 'stringType',
                'Contains any in list': 'stringType',
                'Begins with any in list': 'stringType',
                'Does not contain': 'stringType',
                'Equal to': equalTypeDependingOnField,
                'Not equal to': (filterField && filterField === 'visitsOrganisationsEmployees')? 'integerType': 'stringType',
                'Begins with': 'stringType',
                'Does not begin with': 'stringType',
                'More than': 'integerType',
				'Less than': 'integerType',
				'Between': 'integerType',
				'In list': 'stringType',
				'Not in list': 'stringType'
            };

            return types[filterOperator];
        };

		// maps name to field
        var filterFields = {
            'Name': 'visitsOrganisationsName',
            'Organisation ID': 'visitsOrganisationsID',
            'Leadenhancer ID': 'visitsOrganisationsLEID',
            'DUNS': 'visitsOrganisationsDUNS',
            'Sales': 'visitsOrganisationSales',
            'Postal code': 'visitsOrganisationAddressPostalCode',
            'Region': 'visitsOrganisationAddressRegion',
            'Country': 'visitsOrganisationAddressCountry',
            'City': 'visitsOrganisationAddressCity',
            'ISP': 'visitsOrganisationsISP',
            'SNI or NACE Code': 'visitsOrganisationsSniNace',
            'NAICS Code': 'visitsOrganisationsNaics',
            'GICS Code': 'visitsOrganisationsGicsSectorCode1',
            'SIC Code': 'visitsOrganisationsSic',
            'Domain name': 'visitsOrganisationsDomain',
            'Industry tags': 'visitsOrganisationsIndustryTags',
            'Number of employees': 'visitsOrganisationsEmployees',
			'Enhancement score' : 'visitsOrganisationsEnhancementScore',
			'Organisation types' : 'visitsOrganisationsType',
			'Networks size' : 'visitsOrganisationsNoOfNetworks',

			'First' : 'visitsIsFirstVisit',
			'Score' : 'visitsScore',
			'Excluded Organisations' : 'visitsIsExcludedOrganisation',
			'Referer' : 'visitsReferer',
			'Page Keywords' : 'visitsPageViewKeywords'


        };

        // need to map name
        var filterOperators = {
            'Text match': 'textMatch',
            'Contains': 'contains',
            'Contains any in list': 'containsAnyIn',
            'Begins with any in list': 'beginsWithAnyIn',
            'Does not contain': 'doesNotContain',
            'Equal to': 'equal',
            'Not equal to': 'notEqual',
            'Begins with': 'beginsWith',
            'Does not begin with': 'doesNotBeginsWith',
            'More than': 'moreThan',
            'Less than': 'lessThan',
            'Between': 'between',
            'In list': 'in',
            'Not in list': 'notIn'
        };

		// These are used on the view to display the tabs
        var tabItems = [
            {
                name: 'search',
                href: 'admin.visitAdvancedFilter.search'
            },{
                name: 'visit list',
                href: 'admin.visitAdvancedFilter.list'
            },{
                name: 'settings',
                href: 'admin.visitAdvancedFilter.settings'
            }
        ];

        return {
			tabItems: tabItems,
            filterTypes: filterTypes,
			eventNewVisitAddedToVisitList : eventNewVisitAddedToVisitList,
			eventVisitSizeUpdatedFromQuery : eventVisitSizeUpdatedFromQuery,
			eventDeletedVisitFromVisitList : eventDeletedVisitFromVisitList,
            filterOperators: filterOperators,
            filterFields: filterFields
        };
    }
})();