(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('VisitCookieService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', VisitCookieService]);

	function VisitCookieService($http, $q, CONFIG, responseExceptionCatcher) {
		this.findAll = findAll;
		this.deleteSelected = deleteSelected;

		function findAll(accountId, config) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visittags', config)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function deleteSelected(visitorCookieIds, accountId) {
			var deleteThem = {
				comment: "Manually enhanced",
				visitorCookieIds: visitorCookieIds
			};

			console.log('dele', deleteThem)
			console.log('accountId', accountId)
			console.log('accountId', CONFIG.API_PREFIX + +'/accounts/' + accountId + '/visittags/delete')
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visittags/delete', deleteThem)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}
	}
})();