(function () {
    'use strict';

    angular
        .module('adminApp')

        .directive('tableComponentHeader', [function TableComponentHeader() {
            return {
                restrict: 'E',
                templateUrl: 'partials/common/table-component/table-component-header.html',
                link: function (scope, elem) {
                    scope.sortClicked = function () {
                        scope.params.progressSort();
                    };

                    scope.filterClicked = function () {
                        scope.params.showColumnMenu(elem.find('img').get(0));
                    };

                    scope.settingsClicked = function () {
                        scope.params.showColumnMenu(elem.find('.column-settings-icon').get(0));
                    };
                }
            };
        }]);


})();