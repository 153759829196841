(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PageKeywordsController', ['account', 'PageKeywordService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$uibModal', PageKeywordsController]);

    function PageKeywordsController(account, PageKeywordService, FileSaver, Blob, CONFIG, TableSort, logger, $uibModal) {
        var vm = this;
        vm.confirmDelete = confirmDelete;
        vm.getAllPageKeywords = getAllPageKeywords;

        vm.account = account;
        vm.pageKeywords = null;
        vm.ok = null;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.searchFilter = '';
        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.loadMore = loadMore;

        activate();

        function activate() {
            getAllPageKeywords();
        }

        function getAllPageKeywords() {
            PageKeywordService.getAllPageKeywords(vm.account.id)
                .then(function (pageKeywords) {
                    vm.pageKeywords = pageKeywords;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'PagesKeywords_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem( pageKeywordId) {
            PageKeywordService.deletePageKeyword(vm.account.id, pageKeywordId)
                .then(function (pageKeywords) {
                    vm.pageKeywords = pageKeywords;
                    logger.info('The Page Keyword will be reomved from any Pages in the background and can take some time to process!!!!');
                });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.pageKeywords) {
                return vm.pageKeywords.length;
            }
        }

        function confirmDelete(item){
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + item.keyWord + ' Page Keyword?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(item.id);
            });
        }

    }
})();