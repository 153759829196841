(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('leAdminFormInput', ['$compile', formInputDirective]);

    function formInputDirective($compile) {
        return {
            restrict: 'A',
            require: '^form',
            link: link($compile)
        };
    }

    function link($compile) {
        return function (scope, element, attributes, form) {
            var name = setUpDom(element[0], form);
            addMessages(form, element, name, $compile, scope);
            scope.$watch(watcherForChange(form, name), updaterOfChange(element));
        };
    }

    function setUpDom(element) {
        element.classList.add('form-group');

        var label = element.querySelector('label');
        if (label) {
            label.classList.add('control-label');
        }

        var input = element.querySelector('input, textarea, select, date-spinner', 'tags-input');
        if (input) {
            var type = input.getAttribute('type');
            if (type !== 'checkbox' && type !== 'radio') {
                input.classList.add('form-control');
            }
            return input.getAttribute('name');
        }
    }

    function addMessages(form, element, name, $compile, scope) {
        var messages = '<ng-messages for="' + form.$name + '.' + name + '.$error"' + ' class="help-block">' + '<div ng-messages-include="partials/form-messages.html"></div>' + '</ng-messages>';
        element.append($compile(messages)(scope));
    }

    function watcherForChange(form, name) {
        return function() {
            if (name && form[name]) {
                // Only trigger change if input is invalid and input has changed (dirty) or has a value.
                return form[name].$invalid && (form[name].$dirty || form[name].$viewValue);
            }
        };
    }

    function updaterOfChange(element) {
        return function(hasError) {
            if (hasError) {
                element.removeClass('has-success').addClass('has-error');
            } else {
                element.removeClass('has-error');
            }
        };
    }

})();