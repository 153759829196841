(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('DomainEnhanceService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', DomainEnhanceService]);

	function DomainEnhanceService($http, $q, CONFIG, responseExceptionCatcher) {
		this.search = search;
		this.updateLEOrganisationFromDomainEnhancedData = updateLEOrganisationFromDomainEnhancedData;
		this.queryAndStoreDomainDataFromExternalSource = queryAndStoreDomainDataFromExternalSource;
		this.clearQ = clearQ;
		this.countAll = countAll;
		this.updateToISP = updateToISP;
		this.clearStatusQueriedWithNoResults = clearStatusQueriedWithNoResults;
		this.clearStatusQueriedWithResultsNoGoodMatch = clearStatusQueriedWithResultsNoGoodMatch;
		this.updateToFailed = updateToFailed;

		function search(country, limit, status, config) {
			var request = null;
			if (country && country.countryISO) {
				request = 'countryISO=' + country.countryISO;
			}
			if (limit) {
				request = request + '&limit=' + limit;
			}
			if (status) {
				request = request + '&importStatus=' + status;
			}
			if (!request) {
				return $http.get(CONFIG.API_PREFIX + '/enhance/domain/search', config)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			} else {
				return $http.get(CONFIG.API_PREFIX + '/enhance/domain/search?' + request, config)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			}
		}

		function countAll() {
			return $http.get(CONFIG.API_PREFIX + '/enhance/domain/countAll')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function clearStatusQueriedWithNoResults() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/clearStatusQueriedWithNoResults')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function clearStatusQueriedWithResultsNoGoodMatch() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/clearStatusQueriedWithResultsNoGoodMatch')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function updateLEOrganisationFromDomainEnhancedData(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/updateLEOrganisationFromDomainEnhancedData', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function updateToFailed(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/updateToFailed', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function queryAndStoreDomainDataFromExternalSource(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/queryAndStoreDomainDataFromExternalSource', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function updateToISP(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/updateToISP', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function clearQ() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/domain/clearQ')
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}
	}
})();