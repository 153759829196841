(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('SegmentAdvancedFilterController',
			['$scope', '$q', '$state', '$location', '$timeout', 'logger', 'account', 'user', 'accountId',
				'segmentFilterId', 'SegmentAdvancedFilterService', 'OrganisationService',
				'filterModel', 'SegmentAdvancedFilterConstants', 'SegmentAdvancedFilterConverter', 'STATE', '$uibModal', 'FileSaver',
				SegmentAdvancedFilterController]);

	function SegmentAdvancedFilterController($scope, $q, $state, $location, $timeout, logger, account, user, accountId,
											 segmentFilterId, SegmentAdvancedFilterService, OrganisationService,
											 FilterModel, SegmentAdvancedFilterConstants, SegmentAdvancedFilterConverter, STATE, $uibModal, FileSaver) {

		var vm = this;
		vm.getSegment = getSegment;
		vm.confirmExportIPs = confirmExportIPs;
		vm.confirmExportOrganisations = confirmExportOrganisations;
		vm.saveSegmentQuery = saveSegmentQuery;
		// from modal window
		vm.onImportOrganisationsFromList = onImportOrganisationsFromList;
		vm.onImportOrganisationsFromList = onImportOrganisationsFromList;

		vm.menuItems = SegmentAdvancedFilterConstants.menuItems;

		vm.account = account;
		vm.user = user;
		vm.totalSegmentSize = '';
		vm.$state = $state;
		var FilterItem = FilterModel.FilterItem;
		var $titleElement;

		this.initialRuleGroupQuery = {
			limit: 10000,
			page: null,
			pageSize: null,
			scoreThreshold: 0,
			ruleGroup: {
				condition: "and",
				rules: [
					{id: "1", field: "organisationsISP", operator: "equal", type: "booleanType", value: false}
				],
				ruleGroups: []
			}
		};
		vm.initialNewSegment =  {
			id:  undefined,
			ruleGroupQuery : vm.initialRuleGroupQuery,
			accountId: vm.account.id,
			name: 'New Segment ',
			active : true,
			created:  (new Date()).valueOf(),
			modified: (new Date()).valueOf(),
			segmentSizeFromQuery: 0,
			totalSegmentSize: 0,
			manuallyAddedLEOrganisationIds: [],
			userId: vm.user.id,
			userName: vm.user.userName,
			version: '2',
			type: 'STATIC',
			global: false,
			useForMultiUpdates : false
		};

		activate();

		function activate() {
			getSegment();
		}

		function getSegment() {
			if (segmentFilterId) {
				return SegmentAdvancedFilterService.getSegment(accountId, segmentFilterId)
					.then(function (segment) {
						vm.segment = segment;
						vm.totalSegmentSize = segment.totalSegmentSize;
						vm.segmentSizeFromQuery = segment.segmentSizeFromQuery;
						vm.filters = segment.filters;
					}).then(function () {
						broadCastNoOSearchHits(vm.segment.segmentSizeFromQuery);
					})
			} else {
				//if we want emoty vm.filters = new FilterItem(null);
				vm.segment = SegmentAdvancedFilterConverter.mapFromBackend(vm.initialNewSegment);
				vm.filters = vm.segment.filters;

				logger.info('Change name and edit the variables - initial ISP variable is set to false!!');
			}
		}


		$('body').scrollTop(0);

		$scope.$on('$stateChangeSuccess', onStateChangeSucceed);
		$scope.$on('$stateChangeStart', onStateChangeStart);
		// When event is fired from search controller do
		$scope.$on(  SegmentAdvancedFilterConstants.eventNewOrganisationsAddedToSegmentList, onSegmentListAddedOrganisations);
		$scope.$on(  SegmentAdvancedFilterConstants.eventDeletedOrganisationsFromSegmentList, onDeletedOrganisationsFromSegmentList);
		$scope.$watch('vm.segment.name', onSegmentNameChanged);



		function onStateChangeSucceed(event, toState, toParams, fromState, fromParams) {
			if (fromState.name === STATE.TARGETING_SEGMENTS_ADVANCED_FILTER_SEARCH &&
				fromParams.accountId === '' && fromParams.segmentFilterId === '') {
			}
		}

		function onStateChangeStart(event, toState, toParams) {
			if (toState.name.indexOf(STATE.TARGETING_SEGMENTS_ADVANCED_FILTER + '.') === -1 &&
				toState.name !== STATE.TARGETING_SEGMENTS_ADVANCED_FILTER) {
				vm.stateToGo = {
					name: toState.name,
					params: toParams
				};
			}
		}

		function onSegmentNameChanged(newVal, oldVal) {
			if (!$titleElement || $titleElement.length === 0) {
				$titleElement = $('.top-menu__title:first');
			}
			if ($titleElement && $titleElement.children().length > 0) {
				vm.segment.name = $titleElement.text();
				$titleElement.html($titleElement.text());
			}
			if (newVal && $titleElement && $titleElement.text().length > 255) {
				logger.warning('You have exceeded the maximum number of 255 characters in this field.');
				vm.segment.name = oldVal;
			}
		}

		function onSegmentListAddedOrganisations() {
			console.log('Event fired, Segment list changed (new), saving changes...');
			saveRequest();
		}

		function onDeletedOrganisationsFromSegmentList() {
			console.log('Event fired, Segment list changed (delete), saving changes...');
			saveRequest();
		}

		function saveSegmentQuery() {
			console.log( 'seg name' , vm.segment )
			//vm.segment.name = $('.top-menu__title:first').text();
			if (vm.segment.name.trim().length === 0) {
				logger.error('Please set the name before saving.');
				return;
			}
			saveRequest();
		}

		function onImportOrganisationsFromList() {
			return SegmentAdvancedFilterService.importOrganisations(vm.account.id, vm.segment.id, vm.importOrganisationLEIds)
				.then(function (segment) {
					$state.reload();
				})
		}

		function saveRequest() {
			vm.segment.userId = user.id;
			if (vm.segment.id) {
				return SegmentAdvancedFilterService.createOrUpdateSegment(account.id, vm.segment, vm.filters.serialise())
					.then(function (response) {
						segmentSavedSuccessfully(response)
					})

			} else {
				return SegmentAdvancedFilterService.createOrUpdateSegment(account.id, vm.segment, vm.filters.serialise())
					.then(function (response) {
						$location.path('/accounts/' + account.id + '/segmentadvancedfilters/' + response.id).replace();
						segmentSavedSuccessfully(response)
					});
			}
		}

		function broadCastNoOSearchHits(searchHits) {
			// make sure this is the last thing done, so child controller gets it
			$timeout(function () {
				$scope.$broadcast(SegmentAdvancedFilterConstants.eventSegmentSizeUpdatedFromQuery, searchHits);
			}, 0);
		}

		function segmentSavedSuccessfully(segment) {
			//vm.lastSavedSegment = _.cloneDeep(vm.segment);
			vm.totalSegmentSize = segment.totalSegmentSize;
			vm.segmentSizeFromQuery = segment.segmentSizeFromQuery;
			broadCastNoOSearchHits(vm.segmentSizeFromQuery);
			logger.success('The segment has been saved successfully.');
		}


		function exportSegmentIPs() {
			SegmentAdvancedFilterService.exportSegmentIPs(vm.account.id, vm.segment.id)
				.then(function (response) {
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					var data = new Blob([response.data], {type: 'application/text;charset=utf-8'});
					var config = {
						data: data,
						filename: 'Segments_IPS_' + vm.segment.id + '_' + new Date().toISOString().substring(0, 10) + '.txt'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function exportSegmentOrganisations() {
			SegmentAdvancedFilterService.exportSegmentOrganisations(vm.account.id, vm.segment.id)
				.then(function (response) {
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					//var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
					var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
					var config = {
						data: data,
						filename: 'Segment_Organisations_' + vm.segment.id + '_' + new Date().toISOString().substring(0, 10) + '.xls'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function confirmExportIPs() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Export IPs!',
							body: 'Are you absolutely sure you want to export the selected Segment? The exported ' +
								'file can be used when uploading to a DMP (a DMP not supported by Leadenhancer)',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				exportSegmentIPs();
			});
		}

		function confirmExportOrganisations() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Export Organisations!',
							body: 'Are you absolutely sure you want to export the selected Segment? The exported ' +
								'file can be used when when asking a customer to cherry pick Organisations from the Segment',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				exportSegmentOrganisations();
			});
		}



	}
})();
