(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('UserAuditLogService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', UserAuditLogService]);

    function UserAuditLogService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAll = findAll;

        function findAll(userName, startDate) {
        	var url = '/admin/userauditlog';
        	if( userName !==  null && userName !== undefined && userName !== '' && userName !== 'undefined' && startDate ){
				url = url + '?userName=' +userName + '&startDate=' + startDate;
			} else if (startDate){
				url = url + '?startDate=' + startDate;
			}
            return $http.get(CONFIG.API_PREFIX + url)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();