(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignRevenueModelController', ['account', 'campaignRevenueModelId', 'CampaignService', 'logger', '$q', '$state', CampaignRevenueModelController]);

    function CampaignRevenueModelController(account, campaignRevenueModelId, CampaignService, logger, $q, $state) {
        var vm = this;
        vm.saveCampaign = saveCampaign;
        vm.searchFilter = '';
        vm.activate = activate;
        vm.onSaveCampaignRevenueModel = onSaveCampaignRevenueModel;


        vm.account = account;
        vm.campaignRevenueModelId = campaignRevenueModelId;
        vm.selected = undefined;
        // initial upload url if existing campaign, ie loading from list

        // initial if creating new
        vm.campaignRevenuModel = {
            id: campaignRevenueModelId,
            accountId: vm.account.id
        };

        // reference data
        vm.refRevenueModels = [{id: 'REVENUE_CPM', name: 'Revenue CPM'}, {id: 'REVENUE_CPC', name: 'Revenue CPC'},
            {id: 'EXCHANGE_SPEND_REVENUE_PERCENT', name: 'Revenue Percent'}];

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(showCampaign);
        }

        function getInitialData() {
            return $q.all([getCampaignRevenueModel()]);
        }

        function getCampaignRevenueModel() {
            if (_.isNull(vm.campaignRevenueModelId) || _.isEmpty(vm.campaignRevenueModelId)) {
                // initial when no campaign found
                return vm.campaignRevenuModel;
            } else {
                return CampaignService.getCampaignRevenueModel(vm.account.id, vm.campaignRevenueModelId);
            }
        }

        // display the stuff when promises are finished
        function showCampaign(result) {
            vm.campaignRevenuModel = result[0];
        }
        /// End init

        function saveCampaign() {
            return $q.all([onSaveCampaignRevenueModel()]);
        }

        function onSaveCampaignRevenueModel() {
            return CampaignService.createOrUpdateCampaignRevenueModel(vm.account.id, vm.campaignRevenuModel)
                .then(function (data) {
                    vm.campaignRevenuModel = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Campaign Revenue model saved');
            $state.go('admin.campaignRevenueModel', {accountId: vm.account.id, campaignRevenueModelId: vm.campaignRevenuModel.id}, {notify: false});
        }
    }
})();