(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('countryFilter', ['$q', 'CountryService', 'filterConstants', 'CountryService',  CountryFilter]);


    function CountryFilter($q, countryService, filterConstants, CountryService) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/filters/geography/countryFilter.html',
            link: function (scope) {
                scope.opers = filterConstants.operands;
                scope.name = filterConstants.filters.COUNTRY;
                scope.operands = [
                    scope.opers.IN,
                    scope.opers.NOT_IN

                ];


                if (scope.filter.data.value === undefined) {
                    scope.filter.data.value = [];
                } else {
					for (var i = 0; i < scope.filter.data.value.length; i++) {
						var countryISO = scope.filter.data.value[i];
						if( countryISO.id !== undefined ){
							// make sure we always use ISO and not json if already set
							countryISO = countryISO.id;
						}
						scope.filter.data.value[i] = CountryService.getCountryByCode(countryISO);
					}
                }

                if(scope.filter.data.operand === undefined) {
                    scope.filter.data.operand = scope.opers.IN;
                }

                scope.loadNames = function(query) {
                    return $q.resolve({
                        data: _.filter(CountryService.getAllCountries(), function (c) {
                            return c.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                        })
                    });
                };

				scope.autoCompleteCountries = function (query) {
					return _.filter(CountryService.getAllCountries(), function (country) {
						if( country.name.toLowerCase().indexOf(query.toLowerCase()) !== -1){
							return country;
						}
					});
				};

				scope.tagAdded = function(tag){
					//scope.filter.data.value.push(tag.id);
				}
            }
        };
    }

})();