(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('draggingService', ['$rootScope',
            '$document', function ($rootScope, $document) {

            this.threshold = function(e) {
                var move = this.countMove(e);
                return Math.abs(move.x) > 3 || Math.abs(move.y) > 3;
            };

            this.start = function (e, elem, avatar, data) {
                if (!e.pageX) {
                    e = e.originalEvent.touches[0];
                }
                this.startPos = {
                    x: e.pageX,
                    y: e.pageY
                };
                this.elem = elem;
                this.avatar = avatar;
                this.data = data;

                this.dragStarted = false;

                this.shiftX = this.startPos.x - this.elem.offset().left;
                this.shiftY = this.startPos.y - this.elem.offset().top;

                $document.on('mousemove.draggging', this.move.bind(this));
                $document.on('mouseup.draggging', this.stop.bind(this));
                $document.on('touchmove.draggging', this.move.bind(this));
                $document.on('touchend.draggging', this.stop.bind(this));
            };

            this.countMove = function (e) {
                if (!e.pageX) {
                    e = e.originalEvent.touches[0];
                }
                return {
                    x: e.pageX - this.startPos.x,
                    y: e.pageY - this.startPos.y
                };
            };

            this.move = function (e) {
                e.preventDefault();
                if(this.threshold(e) && !this.dragStarted) {
                    $document.find('body').append(this.avatar);
                    this.avatar.css({
                        'position': 'absolute',
                        'left': this.elem.offset().left,
                        'top': this.elem.offset().top
                    });
                    this.dragStarted = true;
                }
                if(this.dragStarted) {
                    if (!e.pageX) {
                        e = e.originalEvent.touches[0];
                    }
                    this.avatar.offset({
                        left: e.pageX - this.shiftX,
                        top: e.pageY - this.shiftY
                    });
                    this.avatar.css('visibility', 'hidden');
                    var dropElement = document.elementFromPoint(e.pageX, e.pageY);
                    this.avatar.css('visibility', 'visible');
                    if(this.data.type === 'moveFilter') {
                        $rootScope.$broadcast('dragMove', {
                            dragData: this.data,
                            target: dropElement
                        });
                    }
                }
            };

            this.stop = function (event) {
                $document.off('mousemove.draggging');
                $document.off('mouseup.draggging');
                $document.off('touchmove.draggging');
                $document.off('touchend.draggging');
                this.avatar.remove();
                if(!this.dragStarted) {
                    return true;
                }
                if (!event.pageX) {
                    event = event.originalEvent.changedTouches[0];
                }
                this.dragStarted = false;
                var dropElement = document.elementFromPoint(event.clientX, event.clientY);
                var h, py;
                if (dropElement) {
                    h = $(dropElement).height();
                    py = event.pageY - $(dropElement).offset().top;
                }

                $rootScope.$broadcast('dragStop', {
                    dragData: this.data,
                    target: dropElement,
                    posTip: {
                        height: h,
                        top: py
                    }
                });
            };

            this.setTarget = function (target) {
                if(!this.dragStarted) {return;}
                this.target = target;
            };

            this.resetTarget = function () {
                if(!this.dragStarted) {return;}
                this.target = null;
            };

        }]);
})();