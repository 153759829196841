(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('SegmentAdvancedFilterShowOrganisationsController', [
			'$element',
			'$timeout',
			'$q',
			'$scope',
			'$state',
			'$window',
			'logger',
			'account',
			'segmentFilterId',
			'SegmentAdvancedFilterService',
			'SegmentFilterSearchResultsTableModel',
			SegmentAdvancedFilterShowOrganisationsController]);

	function SegmentAdvancedFilterShowOrganisationsController($element,
															  $timeout,
															  $q,
															  $scope,
															  $state,
															  $window,
															  logger,
															  account,
															  segmentFilterId,
															  SegmentAdvancedFilterService,
															  SegmentFilterSearchResultsTableModel) {

		var vm = this;

		vm.doExportToExcel = doExportToExcel;

		vm.checkedIds = [];
		vm.checkedIdsNumber = 0;
		vm.selectedNodes = [];
		vm.account = account;
		vm.segmentFilterId = segmentFilterId;

		// filter search
		vm.globalSearch = '';


		vm.segmentFilterShowOrganisationsTable = {
			angularCompileRows: true,
			enableFilter: true,
			isExternalFilterPresent: isExternalFilterPresent,
			doesExternalFilterPass: doesExternalFilterPass,
			onRowsNumberChanged: function () {
				filterChanged();
			},
			getFn: function () {
				// fired when jumping into table pressing search
				return SegmentAdvancedFilterService
					.findOrganisationsBySegmentId(account.id,vm.segmentFilterId)
					.then(function(organisations) {
						vm.noOfOrganisations = organisations.length;
						return organisations;
					});
			}.bind(this),
			columnDefs: SegmentFilterSearchResultsTableModel.columnDefs,
			pagination: false  // all data in one go
		};


		vm.panelHeights = 120;
		var $segmentTable = $('.segments-table');

		$timeout(function () {
			resizeHeight();
		});


		vm.globalSearchChanged = function () {
			SegmentFilterSearchResultsTableModel.setFilterValue(vm.globalSearch);
			vm.segmentFilterShowOrganisationsTable.grid.api.onFilterChanged();
		};
		function isExternalFilterPresent() {
			return vm.globalSearch.length > 0;
		}
		function doesExternalFilterPass(node) {
			var query = vm.globalSearch.toLowerCase();
			return node.data.name.toLowerCase().indexOf(query) >= 0;
		}

		function filterChanged() {
			var sel = $element.find('.searchable-cell');
			if (vm.globalSearch) {
				sel.unmark();
				sel.mark(vm.globalSearch);
			} else {
				sel.unmark();
			}
		}

		$scope.$on('$destroy', onDestroy);
		angular.element($window).bind('resize', _.debounce(onResize, 250));


		function onDestroy() {
			angular.element($window).off('resize');
		}

		function onResize() {
			resizeColumns();
			resizeHeight();
		}

		function resizeColumns() {
			vm.segmentFilterShowOrganisationsTable.grid.api.sizeColumnsToFit();
		}

		function resizeHeight() {
			if ($(window).height() > 600) {
				$segmentTable.css('height', $(window).height() - vm.panelHeights);
			} else {
				$segmentTable.css('height', 800 - vm.panelHeights);
			}
		}



		function doExportToExcel() {
			var columnKeys = _.map(vm.segmentFilterShowOrganisationsTable.grid.columnApi.getAllDisplayedColumns(), 'colId').slice(0, -1);
			vm.segmentFilterShowOrganisationsTable.grid.api.exportDataAsCsv({
				columnKeys: columnKeys,
				allColumns: false
			});
		}


	}
})();