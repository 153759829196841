(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdaptiveContentService', ['$http', 'CONFIG', 'responseExceptionCatcher', AdaptiveContentService]);

    function AdaptiveContentService($http, CONFIG, responseExceptionCatcher) {
        this.getTargetings = getTargetings;
        this.getTargeting = getTargeting;
        this.createOrUpdateInstance = createOrUpdateInstance;
        this.deleteInstance = deleteInstance;
        this.getTargetingStatistics = getTargetingStatistics;
        this.searchWithQuery = searchWithQuery;

        function getTargetings(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getTargetingStatistics(accountId, targetingId, from, to) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings/' + targetingId + '/statistics?startDate=' + from + '&endDate=' + to)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getTargeting(accountId, targetingId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings/' + targetingId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function deleteInstance(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateInstance(accountId, targeting) {
            var promise;
            if (!(_.isNull(targeting.id) || targeting.id === undefined || targeting.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings/', targeting)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving targeting '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/targetings/', targeting)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving targeting '));
            }
            return promise;
        }


        function searchWithQuery(query) {
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/search/grouprule' , query)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();