(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('WidgetOrderLocalStorageService', ['$q', '$localStorage', WidgetOrderLocalStorageService]);

    function WidgetOrderLocalStorageService($q, $localStorage) {
        this.saveOrder = saveOrder;
        this.getOrder = getOrder;

        function saveOrder(widgetOrder) {
            $localStorage.widgetOrder = widgetOrder;
        }

        function getOrder() {
            return $q.when($localStorage.widgetOrder);
        }

    }
})();