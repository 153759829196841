(function() {
    'use strict';

    angular
        .module('adminApp')
        .directive('pageContent', function() {
            return {
                restrict: 'E',
                replace: true,
                transclude: {
                    'header': 'pageContentHeader',
                    'menu': '?pageContentMenu',
                    'submenu': '?pageContentSubmenu'
                },
                template:
                    ('<div class="page-page-content-inner">' +
                        '<div ng-transclude="header" class="page-content__top-menu"></div>' +
                        '<div ng-transclude="menu" class="page-content__nav" ng-if="isMenuFilled"></div>' +
                        '<div ng-transclude="submenu" class="page-content__subnav" ng-if="isSubmenuFilled"></div>' +
                        '<ng-transclude></ng-transclude>' +
                    '</div>'),

                controller: function($transclude, $scope) {
                    $scope.isMenuFilled = $transclude.isSlotFilled('menu');
                    $scope.isSubmenuFilled = $transclude.isSlotFilled('submenu');                    
                }
            };
        });
})();