(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('PageQueryService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', PageQueryService]);

    function PageQueryService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getAllPageQueries = getAllPageQueries;
        vm.getPageQuery = getPageQuery;
        vm.deletePageQuery = deletePageQuery;
        vm.updatePageQuery = updatePageQuery;
        vm.publishPageQuery = publishPageQuery;

        function getAllPageQueries(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getPageQuery(accountId, id) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deletePageQuery(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updatePageQuery(accountId, pageQuery) {
            var promise;
            if (!(_.isNull(pageQuery.id) || pageQuery.id === undefined || pageQuery.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries/', pageQuery)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page keyword'));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries/', pageQuery)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page keyword '));
            }
            return promise;
        }

        function publishPageQuery(accountId, pageQueryId) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagequeries/' + pageQueryId + '/publish')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when saving page keyword '));


        }
    }
})();