(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdaptiveStatisticsChart', ['DateUtils','moment', '$filter', AdaptiveStatisticsChart]);

    function AdaptiveStatisticsChart(DateUtils, moment, $filter) {
        var dateFilter = $filter('date');
        return function (targetingStatistics) {
            var that = this;
            this.getNumberOfItems = getNumberOfItems;
            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'area',
                        zoomType: 'x',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 20,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    /*navigator: {
                        enabled: false,
                        series: {
                            id: 'navigator'
                        },
                        height: 60,
                        xAxis: {
                            dateTimeLabelFormats: {
                                month: '%b \'%y',
                                year: '%Y'
                            }
                        }
                    },*/
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 500
                            }
                        },
                        area: {
                            fillColor: {
                                linearGradient: {
                                    x1: 0,
                                    y1: 0,
                                    x2: 0,
                                    y2: 1
                                },
                                stops: [
                                    [0, '#337ab7'],
                                    [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                                ]
                            },
                            marker: {
                                radius: 5,
                                symbol: 'circle'
                            },
                            lineWidth: 1,
                            states: {
                                hover: {
                                    lineWidth: 1
                                }
                            },
                            threshold: null
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return 'Requests on ' + dateFilter(this.x, 'yyyy-MM-dd') + ' is ' + this.y  ;
                        }
                    }

                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        month: '%b \'%y',
                        year: '%Y'
                    }
                },

                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                series: [{
                    cursor: 'pointer',
                    data: [],
                    name: 'Requests',
                    size: '100%',
                    color: Highcharts.getOptions().colors[0],
                    showInLegend: false,
                    dataLabels: false,
                    point: {
                        events: {
                            click: function () {
                               // (onPointClick || angular.noop)(this.x);
                            }
                        }
                    }
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                },
                setXAxis: function (xAxis) {
                    that.config.xAxis.categories = xAxis;
                },
                getData: function () {
                    return that.config.series[0].data;
                }
            };

            initChart(targetingStatistics);

            function initChart(targetingStatistics) {
                var statsTransformed = _.chain(targetingStatistics)
                   // .groupBy(date)
                    .map(toChartItem)
                    .value()
                    .reverse();
                that.config.setData(statsTransformed);

             /*   var xAxis = _.chain(targetingStatistics)
                    .groupBy(formattedDate)
                    .map(toXAxis)
                    .value()
                    .reverse();
                that.config.setXAxis(xAxis);*/
            }

            function toChartItem(statspoints) {
                return [statspoints.date, statspoints.dailyRequestCounter];
            }

            function getNumberOfItems() {
                return that.config.getData().length;
            }
        };
    }
})
();
