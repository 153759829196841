(function () {
	'use strict';

	angular
		.module('adminApp')
		.directive('sicCodeFilter', ['filterConstants', SicCodeFilter]);


	function SicCodeFilter(filterConstants) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/industry/sicCodeRangeFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.SIC;
				scope.operands = [
					scope.opers.BETWEEN,
					scope.opers.BEGIN_WITH

				];

				var initialValue = {
					from: 0,
					to: 100
				};

				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = initialValue;
					scope.filter.data.operand = scope.opers.BETWEEN;
				}

				scope.$watch('filter.data.operand', function (newOperandValue, oldOperandValue) {
					var operandWasChanged = newOperandValue !== oldOperandValue;
					if (operandWasChanged) {
						// when switching operand
						if (newOperandValue === scope.opers.BETWEEN) {
							scope.filter.data.value = initialValue;
						}
						if (newOperandValue === scope.opers.BEGIN_WITH) {
							scope.filter.data.value = 42;
						}
					} else {
						// when switching to settings/list we end up in here
						if (newOperandValue === scope.opers.BETWEEN) {
							// we always receive an array from backend, convert it to {to :  X, from : Y}
							var valueIsArray = scope.filter.data.value !== undefined && Array.isArray(scope.filter.data.value);
							if (valueIsArray) {
								scope.filter.data.value = {
									from: scope.filter.data.value[0],
									to: scope.filter.data.value[1]
								};
							}
						}
					}
/*
					if (newValue === scope.opers.BETWEEN) {
						if (scope.filter.data.value !== undefined) {
							scope.filter.data.value = {
								from: scope.filter.data.value[0],
								to: scope.filter.data.value[1]
							};

						} else {
							scope.filter.data.value = initialValue;
						}

						console.log('after between ', scope.filter.data.value)
					}
					if (oldValue === scope.opers.BETWEEN && newValue === scope.opers.BEGIN_WITH) {
						console.log('begins with ', scope.filter.data.value)
						if (scope.filter.data.value !== undefined) {
							scope.filter.data.value = scope.filter.data.value.from;
						}
						console.log('after begins with ', scope.filter.data.value)
					}*/
					/*if(newValue !== oldValue) {
                        if(newValue === scope.opers.BETWEEN) {
                            if(oldValue === scope.opers.MORE_THEN) {
                                scope.filter.data.value = {
                                    from: scope.filter.data.value,
                                    to: scope.maxValue
                                };
                            } else if(oldValue === scope.opers.LESS_THEN) {
                                scope.filter.data.value = {
                                    from: 0,
                                    to: scope.filter.data.value
                                };
                            } else {
                                scope.filter.data.value = {
                                    from: scope.filter.data.value,
                                    to: scope.maxValue
                                };
                            }
                        } else if(oldValue === scope.opers.BETWEEN) {
                            scope.filter.data.value = scope.filter.data.value.from;
                        }
                    }*/
				});

				/*
                scope.$watch('filter.data.value', function (newValue, oldValue) {
                    if(newValue !== oldValue) {
                        if (_.includes([scope.opers.EQUALS_TO, scope.opers.DOESNT_EQUAL_TO],
                                scope.filter.data.operand)) {
                            if (newValue < 0) {
                                scope.filter.data.value = oldValue;
                            } else if(newValue > scope.maxValue) {
                                scope.filter.data.value = scope.maxValue;
                            }
                        }
                    }

                });
                */
			}
		};
	}
})();