(function () {
    'use strict';

    angular
        .module('adminApp')
        .constant('STATE', {
            'ADMIN': 'admin',
            'LOGIN': 'login',
            'RESET_PWD': 'reset',
            'DASHBOARD': 'admin.dashboard',
            'VISITS': 'admin.visits',
            'VISIT': 'admin.visit',
            'VISIT_FILTERS': 'admin.visitFilters',
            'VISIT_FILTER': 'admin.visitFilter',
			'VISIT_ADVANCED_FILTERS': 'admin.visitAdvancedFilters',
			'VISIT_ADVANCED_FILTER': 'admin.visitAdvancedFilter',
			'VISIT_ADVANCED_FILTER_SEARCH': 'admin.visitAdvancedFilter.search',
			'VISIT_ADVANCED_FILTER_LIST': 'admin.visitAdvancedFilter.list',
			'VISIT_ADVANCED_FILTER_SETTINGS': 'admin.visitAdvancedFilter.settings',
			'VISIT_CAMPAIGNS': 'admin.visitors',
            'VISIT_CAMPAIGN': 'admin.visitor',
            'VISIT_REPORTS': 'admin.reports',
            'VISIT_REPORT': 'admin.report',
            'VISIT_REPORT_HISTORY': 'admin.reportHistory',
            'CLICK_CAMPAIGN_TRACKING_LINK': 'admin.trackingLink',
            'CLICK_CAMPAIGN': 'admin.clickCampaign',
            'CLICK_CAMPAIGNS': 'admin.clickCampaigns',
            'VISIT_PAGE': 'admin.page',
            'VISIT_PAGES': 'admin.pages',
            'VISIT_PAGE_KEYWORDS': 'admin.pageKeywords',
            'VISIT_PAGE_KEYWORD': 'admin.pageKeyword',
            'VISIT_PAGE_QUERIES': 'admin.pageQueries',
            'VISIT_PAGE_QUERY': 'admin.pageQuery',
            'VISIT_EXCLUDED_ORGANISATIONS': 'admin.excludedOrganisations',
            'VISIT_EXCLUDED_ORGANISATION': 'admin.excludedOrganisation',
            'VISIT_CUSTOM_VARIABLES': 'admin.customVariables',
            'VISIT_COOKIES': 'admin.visitCookies',

            'TARGETING_ADAPTIVE_CONTENTS': 'admin.adaptiveContents',
            'TARGETING_ADAPTIVE_CONTENT': 'admin.adaptiveContent',
            'TARGETING_ADAPTIVE_CONTENT_STATISTICS': 'admin.adaptiveContentStatistics',

			'TARGETING_AUDIENCES': 'admin.audiences',
			'TARGETING_AUDIENCE': 'admin.audience',


			'TARGETING_SEGMENTS_ADVANCED_FILTERS': 'admin.segmentAdvancedFilters',
			'TARGETING_SEGMENTS_ADVANCED_FILTERS_SHOW_ORGANISATIONS': 'admin.segmentAdvancedFiltersShowOrganisations',
			'TARGETING_SEGMENTS_ADVANCED_FILTER': 'admin.segmentAdvancedFilter',
			'TARGETING_SEGMENTS_ADVANCED_FILTER_SEARCH': 'admin.segmentAdvancedFilter.search',
			'TARGETING_SEGMENTS_ADVANCED_FILTER_LIST': 'admin.segmentAdvancedFilter.list',
			'TARGETING_SEGMENTS_ADVANCED_FILTER_SETTINGS': 'admin.segmentAdvancedFilter.settings',
			'TARGETING_SEGMENTS_ADVANCED_FILTER_STATISTICS': 'admin.segmentAdvancedFilter.statistics',

            'CONNECT_TOKENS': 'admin.tokens',
            'CONNECT_TOKEN_STATISTICS': 'admin.tokenStatistics',


            'ADMIN_LOGGING': 'admin.adminLogging',
            'ADMIN_TOKENS': 'admin.adminTokens',
            'ADMIN_USERS': 'admin.users',
            'ADMIN_USER_AUDIT_LOG': 'admin.userAuditLog',
            'ADMIN_USER_STATISTICS_LOG': 'admin.userStatisticsLog',
            'ADMIN_USER': 'admin.user',

            'ADMIN_BT_CLIENTS': 'admin.adminBTClients',
            'ADMIN_BT_ADVERTISERS': 'admin.adminBTAdvertisers',
            'ADMIN_BT_ADVERTISER_LINEITEMS': 'admin.adminBTAdvertiserLineItems',
            'ADMIN_BT_CAMPAIGNS': 'admin.adminBTCampaigns',
            'ADMIN_BT_ADS': 'admin.adminBTAds',

            'ADMIN_MC_LISTS': 'admin.adminMClists',
            'ADMIN_MC_LIST_MEMBERS': 'admin.adminMCListMembers',


            /////////////////////////
            // ENHANCE
            'ENHANCE_CLEARBIT_ORGANISATIONS': 'admin.enhanceClearBitOrganisations',
            'ENHANCE_CLEARBIT_ORGANISATION': 'admin.enhanceClearBitOrganisation',
            'ENHANCE_NBK_ORGANISATIONS': 'admin.enhancenbkOrganisations',
            'ENHANCE_DOB_ORGANISATIONS': 'admin.enhanceDOBOrganisations',
            'ENHANCE_RIPE_ORGANISATIONS': 'admin.enhanceripeOrganisations',
            'ENHANCE_LE_ORGANISATIONS': 'admin.enhanceLEOrganisations',
            'ENHANCE_LE_ORGANISATION': 'admin.enhanceLEOrganisation',
            'ENHANCE_ENHANCEMENT_QUEUE': 'admin.enhancementQueue',
            'ENHANCE_ENHANCED_ORGANISATION': 'admin.enhancedOrganisation',
            'ENHANCE_ENHANCEMENT_QUEUE_ORGANISATION_ISPS': 'admin.enhancementQueueOrganisationISPs',
            'ENHANCE_LE_ORGANISATIONS_BATCH_UPLOAD': 'admin.enhanceLEOrganisationsBatchUpload',
            'ENHANCE_ENHANCEMENT_STATS': 'admin.enhancementStatistics',
            'ENHANCE_ENHANCEMENT_STATS_BY_USER': 'admin.enhancementStatisticsBuUser',
            'ENHANCE_INDUSTRY_CODES_SIC': 'admin.enhancementIndustryCodesSIC',
            'ENHANCE_INDUSTRY_CODES_NACE': 'admin.enhancementIndustryCodesSNI',
            'ENHANCE_INDUSTRY_CODES_NACE_TO_SIC': 'admin.enhancementIndustryCodesNaceToSIC',
            'ENHANCE_INDUSTRY_CODES_NAICS': 'admin.enhancementIndustryCodesNAICS',
            'ENHANCE_INDUSTRY_CODES_GICS': 'admin.enhancementIndustryCodesGICS',
            'ENHANCE_INDUSTRY_CODES_INDUSTRY_TAGS': 'admin.enhancementIndustryTags',
            'ENHANCE_QUEUE_DOMAIN': 'admin.enhancementQueueDomain',
            'ENHANCE_QUEUE_CBIT': 'admin.enhancementQueueCBIT',
            'ENHANCE_QUEUE_NBK': 'admin.enhancementQueueNBK',


            'ORGANISATION': 'admin.organisation',
            'HOME': 'home',
            'ERROR': 'error',
            'ERROR_404': '404'


        });
})();