(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', 'Visits', VisitService]);

    function VisitService($http, $q, CONFIG, responseExceptionCatcher, Visits) {
        this.findVisits = findVisits;
        this.findVisitsByIds = findVisitsByIds;
        this.findNumberOfVisits = findNumberOfVisits;
        this.getVisit = getVisit;
        this.getVisitsUsingTransientWithVisitFilter = getVisitsUsingTransientWithVisitFilter;
        this.getNoOfVisitsUsingTransientWithVisitFilter = getNoOfVisitsUsingTransientWithVisitFilter;
        this.sendEmailWithVisits = sendEmailWithVisits;
        this.createVisitReportExcel = createVisitReportExcel;
        this.createVisitReportHtml = createVisitReportHtml;
        this.createVisitReportUsingFilterIdHtml = createVisitReportUsingFilterIdHtml;
        this.createVisitReportAndSendEmail = createVisitReportAndSendEmail;
        this.findVisitsByVisitorId = findVisitsByVisitorId;
        this.createVisitsHtmlReportFromUIWithoutFilter = createVisitsHtmlReportFromUIWithoutFilter;
        this.sendMailForAllVisitsUsingFilter = sendMailForAllVisitsUsingFilter;
        this.findAllByTag = findAllByTag;


        var visits = null;

        function findVisits(accountId, siteId, start, end, filterId, limit, config ) {
            return (visits && visits.isFor(visits, accountId, siteId, start, end, filterId, limit)) ? getVisitsFromState() : findVisitsFromBackend(accountId, siteId, start, end, filterId, limit, config);
        }

        function getVisitsFromState() {
            return $q.when(visits.list);
        }

        function findVisitsByVisitorId(accountId, siteId, visitorId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visitors/' + visitorId + '/visits', config)
                .then(function (response) {
                    var visitList = response.data.data;
                    visits = new Visits(visitList, accountId, siteId, null, null, null);
                    return visits.list;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
//return VisitService.findAllByTag(vm.account.id, vm.account.defaultSiteId, vm.visitorCookieId);

		function findAllByTag(accountId, siteId, visitorCookieId, config) {
			//return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visittags/' + visitorCookieId + '/visits', config)
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId +  '/visittags/' + visitorCookieId + '/visits', config)
				.then(function (response) {
					var visitList = response.data;
					visits = new Visits(visitList, accountId, siteId, null, null, null);
					return visits.list;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

        // if filter is chosen we switch endpoint
        function findVisitsFromBackend(accountId, siteId, start, end, filterId, limit, config ) {
            var filterPart = filterId ? 'filters/' + filterId : '';
            var limitPart = limit ? '&limit=' + limit : '';
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/' + filterPart + '?startDate=' + start + '&endDate=' + end + limitPart, config)
                .then(function (response) {
                    var visitList = response.data.data;
                    visits = new Visits(visitList, accountId, siteId, start, end, filterId, limit);
                    return visits.list;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findNumberOfVisits(accountId, siteId, start, end, filterId, config) {
            var filterPart = filterId ? 'filters/' + filterId : '';
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/'  + siteId +  '/visits/' + filterPart + '/numberOfVisits?startDate=' + start + '&endDate=' + end, config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getVisit(accountId, visitId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visits/' + visitId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function sendEmailWithVisits(accountId, emails, visits) {
            var visitIds = _.map(visits, 'id');
            var visitEmailSendJson = {
                visitIds: visitIds,
                emails: emails
            };
            var promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visits/email', visitEmailSendJson)
                .then(function () {
                    //return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when sending email '));
            return promise;
        }


        // Used when doing searches from VisitFilter page, can be used with non persitent filter
        function getVisitsUsingTransientWithVisitFilter(accountId, siteId, start, end,  visitFilterQuery) {
            var visitFilterTransformed = transformVisitFilterToJson(visitFilterQuery);
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/'  + siteId +  '/visits/filters?startDate=' + start + '&endDate=' + end, visitFilterTransformed)
                .then(function (response) {
                    return response.data.data.visits;
                })
                .catch(responseExceptionCatcher.catch('Error when searching Visits'));
        }

        // Used when filter settings is changed, can be used with non persitent filter
        function getNoOfVisitsUsingTransientWithVisitFilter(accountId, siteId, start, end,  visitFilterQuery) {
            var visitFilterTransformed = transformVisitFilterToJson(visitFilterQuery);
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/'  + siteId +  '/visits/filters/numberOfVisits?startDate=' + start + '&endDate=' + end, visitFilterTransformed)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when searching Visits'));
        }

        function createVisitReportExcel(accountId, siteId, start, end, visitFilterQuery) {
            var visitFilterTransformed = transformVisitFilterToJson(visitFilterQuery);
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/export/' + start + '/' + end + '/excel', visitFilterTransformed,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // used from fitlers view
        function createVisitReportHtml(accountId, siteId, start, end, visitFilterQuery) {
            var visitFilterTransformed = transformVisitFilterToJson(visitFilterQuery);
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/export/' + start + '/' + end + '/html', visitFilterTransformed,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createVisitReportUsingFilterIdHtml(accountId, siteId, start, end, filterId) {
            //console.log('createVisitReportHtml', JSON.stringify(visitFilterTransformed));
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/filters/' + filterId + '/export/' + start + '/' + end + '/html',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // from visits view when no filter selected
        function createVisitsHtmlReportFromUIWithoutFilter(accountId, siteId, start, end) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/export/' + start + '/' + end + '/html/default',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createVisitReportAndSendEmail(accountId, siteId, start, end, visitFilterQuery, emails, subject) {
            var visitFilterTransformed = transformVisitFilterToJson(visitFilterQuery);

            var visitEmailSendJson = {
                visitFilterQuery: visitFilterTransformed,
                emails: emails,
				subject: subject
            };


            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/export/mail/' +  start + '/' + end   ,visitEmailSendJson)
                .catch(responseExceptionCatcher.catch('Error when sending email '));
        }

        // from Visits when exporting and sending email with Visits using Filterid
        function sendMailForAllVisitsUsingFilter(accountId, siteId, start, end, emails, filterId){
            var visitEmailSendJson = {
                visitIds: null,
                emails: emails
            };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/filters/' +
                filterId + '/export/mail/' +  start + '/' + end , visitEmailSendJson )
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        // convert list from autocomplete input to correct json format
        function transformVisitFilterToJson(visitFilterQuery) {
            // Create a deep clone
            var visitFilterQueryTransformed = $.extend(true, {}, visitFilterQuery);

            if (visitFilterQueryTransformed.filterOnPageKeywords) {
                visitFilterQueryTransformed.filterOnPageKeywords = _.map(visitFilterQueryTransformed.filterOnPageKeywords,
                    function (keyWord) {
                        if( keyWord.keyWord ){
                            // if from autocomplete
                            return keyWord.keyWord;
                        } else if( keyWord ){
                            // from persisted
                            return keyWord;
                        }
                    });
            }
            return visitFilterQueryTransformed;
        }


        function findVisitsByIds(accountId, siteId, visitIds){
			if( !(_.isNull(visitIds) || visitIds  === undefined) ){
				return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visits/byIds', visitIds)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			} else {
				return $q.resolve([]);
			}
		}
    }
})();