(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('postalCodeFilter', ['$animate', 'filterConstants', 'filterValidation',  PostalCodeFilter]);


    function PostalCodeFilter($animate, filterConstants, filterValidation) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/filters/geography/cityFilter.html',
            link: function (scope) {
                scope.opers = filterConstants.operands;
                scope.city = filterConstants.filters.CITY;

                scope.operands = [
					scope.opers.EQUAL,
					scope.opers.NOT_EQUAL,
                	scope.opers.CONTAINS,
					scope.opers.DOES_NOT_CONTAINS,
					scope.opers.BEGIN_WITH,
					scope.opers.DOES_NOT_BEGIN_WITH

				];
                $animate.enabled(true);


                if(scope.filter.data.value === undefined) {
                    scope.filter.data.value = '';
                }

                if(scope.filter.data.operand === undefined) {
                    scope.filter.data.operand = scope.opers.CONTAINS;
                }

				scope.$watch('filter.data.value', function (newValue, oldValue) {
					if(newValue !== oldValue) {
						scope.filter.data.value = newValue;
					}
				});

                scope.$on('$destroy', function() {
                    $animate.enabled(true);
                });
            }
        };
    }

})();