(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitAdvancedFilterSettingsController', ['$scope', 'VisitAdvancedFilterService', 'VisitAdvancedFilterConstants',
			VisitAdvancedFilterSettingsController]);

    function VisitAdvancedFilterSettingsController($scope, VisitAdvancedFilterService, VisitAdvancedFilterConstants) {
        var vm = this;
        vm.parent = $scope.vm;

        if (vm.parent.visitFilter) {
            vm.visitFilter = vm.parent.visitFilter;
            vm.status = vm.visitFilter.status;
        }
        vm.visitFilterID = $scope.$resolve.segmentFilterId;
    }
})();
