(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('tableComponentFilter', InlineFilter);

    function InlineFilter() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/table-component/table-component-filter.html',
            link: function (scope) {
                if(!scope.maxHeight) {
                    scope.maxHeight = 250;
                }
                scope.all = true;
                var data = [];
                scope.grid.api.forEachNode(function (node) {
                	console.log('node', scope.params.column.colId);
                	if( scope.params.column.colId === 'keywords' ){
                		var keywords = scope.params.valueGetter(node);
                		keywords.forEach(function(keyword){
                			console.log(keyword);
							data.push(keyword);
						})
					} else {
						data.push(scope.params.valueGetter(node));
					}
                });
                scope.options = _(data).filter()
                    .uniqBy(function (v) {
                        return v.toString().toLowerCase();
                    }).map(function (v) {
                        return {
                            value: v
                        };
                    }).value();

                scope.filterValue = '';

                angular.forEach(scope.options, function (option) {
                    option.checked = false;
                });

				// fired when selecting All from filter popup
                scope.allClicked = function () {
                    scope.all = true;
                    angular.forEach(scope.options, function (option) {
                        option.checked = false;
                    });
                    scope.filterValue = '';
                    scope.params.filterChangedCallback();
                };
                // fired when selecting from filter popup
                scope.optionClicked = function (option) {
                    scope.all = false;
                    angular.forEach(scope.options, function (option) {
                        option.checked = false;
                    });

                    console.log('option.value',option.value)
                    scope.filterValue = option.value.toString();
                    scope.params.filterChangedCallback();
                };
            }
        };
    }
})();

