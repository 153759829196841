(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('NavigationController', ['$location', NavigationController]);

    function NavigationController($location) {
        var navigation = this;


        navigation.onMenuTitleClick = onMenuTitleClick;
        navigation.doesAddressContain = doesAddressContain;

        activate();

        function activate() {
        }

        function doesAddressContain(address) {
            var addressLength = address.length;
            var indexOfAddress = $location.url().indexOf(address);
            var fullLength = addressLength + indexOfAddress;
            return indexOfAddress !== -1 && (fullLength === $location.url().length || $location.url()[fullLength] === '/') ;
        }

        navigation.menuTitles = {
            showDiscover: false,
            showTargeting: false,
            showConnect: false,
            showEnhancing: false,
            showAdmin: false,
            showMonitor: false,
            showSwitchSite: false,
            showUserSettings: false,
            showUserAccounts: false
        };

        function onMenuTitleClick(menuTitle) {
            navigation.menuTitles[menuTitle] = !navigation.menuTitles[menuTitle];
            for (var item in navigation.menuTitles) {
                if (item !== menuTitle) {
                    navigation.menuTitles[item] = false;
                }
            }
        }

    }
})();