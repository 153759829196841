(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('ExcludedOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', ExcludedOrganisationService]);

    function ExcludedOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getAllExcludedOrganisations = getAllExcludedOrganisations;
        vm.getExcludedOrganisation = getExcludedOrganisation;
        vm.deleteExcludedOrganisation = deleteExcludedOrganisation;
        vm.deleteExcludedOrganisations = deleteExcludedOrganisations;
        vm.updateExcludedOrganisation = updateExcludedOrganisation;
        vm.updateExcludedOrganisationBatch = updateExcludedOrganisationBatch;

        function getAllExcludedOrganisations(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/excludedorganisations')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getExcludedOrganisation(accountId, id) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/excludedorganisations/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteExcludedOrganisation(accountId, id) {
            console.log(id);
            if (!(_.isNull(id) || id === undefined || id === '')) {
                var ids = [id];
                return deleteExcludedOrganisations(accountId, ids);
            }
        }

        function deleteExcludedOrganisations(accountId, ids){

            console.log('deleting ' , ids);
            if (!(_.isNull(ids) || ids === undefined || ids.length === 0)) {

                return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/excludedorganisations/' + ids)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            }
        }

        function updateExcludedOrganisation(account, visit) {

            var excludedOrganisation = {
                id: null,
                organisationId: visit.id,
                accountId: account.id,
                siteId: account.defaultSiteId,
                organisationName: visit.organisation.name
            };


            var promise;
            if (!(_.isNull(excludedOrganisation.id) || excludedOrganisation.id === undefined || excludedOrganisation.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + account.id + '/excludedorganisations/', excludedOrganisation)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving  '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + account.id + '/excludedorganisations/', excludedOrganisation)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving  '));
            }
            return promise;
        }

        function updateExcludedOrganisationBatch(account, visits) {

            var excludedOrganisations = [];
            _.each( visits, function(visit){
                var excludedOrganisation = {
                    id: null,
                    organisationId: visit.id,
                    accountId: account.id,
                    siteId: account.defaultSiteId,
                    organisationName: visit.organisation.name
                };
                excludedOrganisations.push(excludedOrganisation);

            });

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + account.id + '/excludedorganisations/batch', excludedOrganisations)
                .catch(responseExceptionCatcher.catch('Error when saving  '));
        }
    }
})();