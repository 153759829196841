(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('DomainEnhanceController', ['DomainEnhanceService', 'CommonService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$q',
			DomainEnhanceController]);

	function DomainEnhanceController(DomainEnhanceService, CommonService, FileSaver, Blob, CONFIG, TableSort, logger, $q) {
		var vm = this;
		vm.domainEnhancements = [];
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.queryFor = null;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});
		vm.selectedStatus = null;
		vm.refImportStatus = [
			{id: 'queriedWithResultsAndNameMatched', name: 'Name match (best)'},
			{id: 'queriedWithResults', name: 'No name match (worse)'},
			{id: 'queriedWithResultsNoGoodMatch', name: 'No good match (contains stopwords)'},
			{id: 'queriedWithNoResults', name: 'No results (really bad)'},
			{id: 'shouldNotBeEnhance', name: 'Not LE enhanced yet'},
			{id: 'enhanced', name: 'Enhanced'},
			{id: 'shouldNotBeEnhance', name: 'Not enhanced (failed)'}];


		//methods
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.updateLEOrganisationFromDomainEnhancedData = updateLEOrganisationFromDomainEnhancedData;
		// update row by row
		vm.updateOneLEOrganisationFromDomainEnhancedData = updateOneLEOrganisationFromDomainEnhancedData;
		vm.queryAndStoreDomainDataFromExternalSource = queryAndStoreDomainDataFromExternalSource;
		vm.search = search;
		vm.getCountriesForSelectBox = getCountriesForSelectBox;
		vm.clearQ = clearQ;
		vm.updateToISP = updateToISP;
		vm.clearStatusQueriedWithNoResults = clearStatusQueriedWithNoResults;
		vm.clearStatusQueriedWithResultsNoGoodMatch = clearStatusQueriedWithResultsNoGoodMatch;  // facebook etc
		vm.updateToFailed = updateToFailed;
		vm.limit = 100;
		vm.filterToShowOnlyEnhanced = null;
		vm.total = null;

		vm.checkAll = checkAll;
		vm.deCheckAll = deCheckAll;


		activate();

		function activate() {
			getInitialData().then(showData);
		}

		function countAll() {
			return DomainEnhanceService.countAll().then(function (data) {
				vm.total = data;
			});
		}

		function getInitialData() {
			return $q.all([getCountries(), countAll()]);
		}

		function showData(result) {
			vm.refCountries = result[0];
		}

		function getCountries() {
			return CommonService.getCountries();
		}

		function onFinished(domainEnhancements) {
			vm.domainEnhancements = domainEnhancements.result;
			vm.wasQueried = domainEnhancements.wasQueried;
			vm.wasPending = domainEnhancements.wasPending;
			vm.total = domainEnhancements.total;
			vm.totalWithResults = domainEnhancements.totalWithResults;
			vm.totalNopGoodMatch = domainEnhancements.totalNopGoodMatch;
			vm.totalEnhanced = domainEnhancements.totalEnhanced;
			vm.totalNoResults = domainEnhancements.totalNoResults;
			vm.totalWithResultsAndNameMatched = domainEnhancements.totalWithResultsAndNameMatched;
			vm.totalShouldNotBeEnhanced = domainEnhancements.totalShouldNotBeEnhanced;
			logger.info('Found ' + vm.domainEnhancements.length + ' items');
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()],
				{type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
			var config = {
				data: data,
				filename: 'domainEnhancements_sic_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort(by) {
			//jshint unused:false
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.domainEnhancements.length;
		}


		function checkAll() {
			vm.domainEnhancements.selected = angular.copy(vm.domainEnhancements);
		}

		function deCheckAll() {
			vm.domainEnhancements.selected = null;
		}

		function updateLEOrganisationFromDomainEnhancedData() {
			var ids = getSelected();
			DomainEnhanceService.updateLEOrganisationFromDomainEnhancedData(ids).then(function () {
				logger.info('Started enhancing LE ORgs from queue.... wait for it');
				getInitialData().then(showData);
			});
		}

		function updateOneLEOrganisationFromDomainEnhancedData(id) {
			var ids = [id];
			DomainEnhanceService.updateLEOrganisationFromDomainEnhancedData(ids).then(function () {
				logger.info('Enhancing... ');
				_.remove(vm.domainEnhancements, function(item) {
					return item.id === id;
				});
			});
		}

		function updateToFailed(id) {
			var ids = [id];
			DomainEnhanceService.updateToFailed(ids).then(function () {
				logger.info('Marking as failed... ');
				_.remove(vm.domainEnhancements, function(item) {
					return item.id === id;
				});
			});
		}

		function queryAndStoreDomainDataFromExternalSource() {
			var ids = getSelected();
			DomainEnhanceService.queryAndStoreDomainDataFromExternalSource(ids).then(function () {
				logger.info('Started enhancing querying for domain datas.... wait for it');
				getInitialData().then(showData);
			});
		}

		function updateToISP() {
			var ids = getSelected();
			DomainEnhanceService.updateToISP(ids).then(function (respose) {
				logger.info('Updated ' + respose.data +  ' Organisations yo ISP (match globally by name)');

			});
		}

		function clearQ() {
			DomainEnhanceService.clearQ().then(function () {
				logger.info('Clearing Q');
				countAll()
			});
		}

		function clearStatusQueriedWithNoResults() {
			DomainEnhanceService.clearStatusQueriedWithNoResults().then(function () {
				logger.info('Clearing Status for No Nmme match');
				countAll()
			});
		}

		function clearStatusQueriedWithResultsNoGoodMatch() {
			DomainEnhanceService.clearStatusQueriedWithResultsNoGoodMatch().then(function () {
				logger.info('Clearing Status for No results match');
				countAll()
			});
		}

		function search() {
			DomainEnhanceService.search(vm.country, vm.limit, vm.selectedStatus).then(onFinished);
		}

		function getSelected() {
			if (vm.domainEnhancements.selected || vm.domainEnhancements.selected.length > 0) {
				return _.map(vm.domainEnhancements.selected, 'id');
			}
			return null;
		}

		// typeahead callback
		function getCountriesForSelectBox(filterByCountryName) {
			return _.filter(vm.refCountries, function (country) {
				if (country.name && filterByCountryName) {
					return (
						country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1
					);
				}
			});
		}

		// on select in UI
		function onSetCountryForSelectBox(countrySelected) {
			vm.country = countrySelected;
		}
	}
})();