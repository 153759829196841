(function () {
	'use strict';

	angular
		.module('adminApp')
		.factory('cellRenderers', ['$interpolate', '$compile', '$rootScope', '$timeout', 'CommonService', '$state', CellRenderers]);

	function CellRenderers($interpolate, $compile, $scope, $timeout, CommonService, $state) {

		function ImgCellRenderer(params) {
			var data = {
				name: params.value.name,
				logo: params.value.logo
			};
			var imgCell = $interpolate('<span><img class="ag-grid-cell-img" src="{{logo}}" alt=""/>{{name}}</span>');
			return imgCell(data);
		}

		function OrgWithLogo(params) {
			var data = {
				name: params.data.name,
				logoUrl: params.data.address.logoUrl
			};
			var imgCell = $interpolate('<img  src="{{logoUrl}}" alt=""  class="logo-ratio-resize" onerror="this.style.display=\'none\'"  /><span class="spaceCharacter" >{{name}}</span>');
			return imgCell(data);
		}

		function CountryCellRenderer(params) {
			if( params.value === ''){
				return;
			}
			var data = {
				flag: params.value.toLowerCase(),
				country: CommonService.getCountryFromIsoCode(params.value).name
			};
			//var flag = data.flag;
			var imgCell = $interpolate('<img class="flag flag-{{flag}} "  alt=""/><span class="spaceCharacter">{{country}}</span>');
			return imgCell(data);
		}

		/**
		 * @return {string}
		 */
		function VisitKeywordsCellRenderer(params) {
			if( params.value === undefined || params.value === ''   ){
				return;
			}
			var spans = '';
			params.value.forEach(function (item, index) {
				var span = '<span class="badge">' + item +'</span>';
				spans = spans + span;
			});
			return spans;
		}

		/**
		 * @return {boolean}
		 */
		function BooleanCellRenderer(params) {
			if( params.value === undefined || params.value === ''   ){
				return;
			}
			// from getter then string
			if( params.value === true || params.value === 'Yes' ){
				return '<span class="badge badge-success">Yes</span>'
			}
			return '<span class="badge badge-info">No</span>'
		}

		/**
		 * @return {boolean}
		 */
		function DomainCellRenderer(params) {
			if( params.value === undefined || params.value === ''   ){
				return;
			}
			if( params.value !== '' ){
				return '<a target="_blank"  href="http://' + params.value + ' " >' +  params.value + '<span  class="spaceCharacter  fa fa-external-link"></span>  </a>'
			}
			return ''
		}


		function PopupCellRenderer(params) {
			var cellPopup = $compile(
				'<span> \
                    <a href="{{href}}" \
                       class="le-table__cell-popup-link" \
                       ng-class="{\'le-table__font__\': !isName}" \
                       ng-mouseenter="onMouseEnter($event)" \
                       ng-mouseleave="onMouseLeave($event)" \
                    > \
                        {{value}} \
                    </a> \
                    <div class="le-table__cell-popup" \
                         ng-if="popup.length" \
                    > \
                        <ul class="searchable-cell"> \
                            <li ng-repeat="item in popup track by $index"> \
                                 {{::item}} \
                            </li> \
                        </ul> \
                    </div> \
                </span>'.trim()
			);

			var data = $scope.$new();
			data.href = params.hrefGetter(params);
			data.value = params.valueGetter(params);
			data.popup = params.popupGetter(params);
			data.isName = params.column.colId === 'name';

			data.onMouseEnter = function (e) {
				var el = $(e.target);
				var pos = el.offset();
				var popupEl = el.parent().find('.le-table__cell-popup');
				if ((el.closest('.ag-cell').width() - 20) >= el.width() && data.isName) {
					popupEl.css('visibility', 'hidden');
					return false;
				}
				popupEl.mark(params.filterValue());

				var newPos = {};

				if (data.isName) {
					newPos.left = pos.left;
				} else {
					newPos.left = ($(window).width() - pos.left - 20) < 300 ? $(window).width() - 320 : pos.left;
				}

				if ($(window).height() - e.pageY > 100) {
					newPos.top = pos.top + 20;
				} else {
					newPos.top = pos.top - popupEl.height() - el.height() - 40;
				}
				popupEl.offset(newPos);
				var popupWidth = 0;
				if (data.isName) {
					popupWidth = (el.width() * 8 / 7 + 40);
					popupWidth = ($(window).width() - pos.left - 20) < popupWidth ? $(window).width() - pos.left - 20 : popupWidth;
				} else {
					popupWidth = 300;
				}

				popupEl.css('width', popupWidth);
				popupEl.css('visibility', 'visible');
			};

			data.onMouseLeave = function (e) {
				var popupEl = $(e.target).parent().find('.le-table__cell-popup');
				popupEl.css('visibility', 'hidden');
			};

			var $el = $(cellPopup(data));
			$el.mark(params.filterValue());

			$scope.safeApply = function (fn) {
				var phase = this.$root.$$phase;
				if (phase === '$apply' || phase === '$digest') {
					if (fn && (typeof(fn) === 'function')) {
						fn();
					}
				} else {
					this.$apply(fn);
				}
			};
			$scope.safeApply();

			return $el.get(0);
		}

		function VisitPageViewsCellRenderer(params) {
			// value is the link in cell
			var cellPopup = $compile(
				'<span> \
                    <a href="{{href}}" \
                       class="le-table__cell-popup-link" \
                       ng-class="{\'le-table__font__\': !isName}" \
                       ng-mouseenter="onMouseEnter($event)" \
                       ng-mouseleave="onMouseLeave($event)" \
                    > \
                        {{value}}  \
                    </a> \
                    <div class="le-table__cell-popup" \
                         ng-if="popup.length" \
                    > \
                        <ul class="searchable-cell"> \
                            <li ng-repeat="item in popup track by $index"> \
                                 {{::item}} \
                            </li> \
                        </ul> \
                    </div> \
                </span>'.trim()
			);

			var data = $scope.$new();
			data.href = params.hrefGetter(params);
			data.value = params.valueGetter(params); // no of pageviews
			data.popup = params.popupGetter(params);


			data.onMouseEnter = function (e) {
				var el = $(e.target);
				var pos = el.offset();
				var popupEl = el.parent().find('.le-table__cell-popup');
				popupEl.mark(params.filterValue());

				var newPos = {};
				newPos.left = ($(window).width() - pos.left - 20) < 300 ? $(window).width() - 320 : pos.left;


				if ($(window).height() - e.pageY > 100) {
					newPos.top = pos.top + 20;
				} else {
					newPos.top = pos.top - popupEl.height() - el.height() - 40;
				}
				popupEl.offset(newPos);
				var popupWidth = 500;

				popupEl.css('width', popupWidth);
				popupEl.css('visibility', 'visible');
			};

			data.onMouseLeave = function (e) {
				var popupEl = $(e.target).parent().find('.le-table__cell-popup');
				popupEl.css('visibility', 'hidden');
			};

			var $el = $(cellPopup(data));
			$el.mark(params.filterValue());

			$scope.safeApply = function (fn) {
				var phase = this.$root.$$phase;
				if (phase === '$apply' || phase === '$digest') {
					if (fn && (typeof(fn) === 'function')) {
						fn();
					}
				} else {
					this.$apply(fn);
				}
			};
			$scope.safeApply();

			return $el.get(0);
		}

		return {
			ImgCellRenderer: ImgCellRenderer,
			CountryCellRenderer: CountryCellRenderer,
			VisitKeywordsCellRenderer : VisitKeywordsCellRenderer,
			BooleanCellRenderer : BooleanCellRenderer,
			VisitPageViewsCellRenderer : VisitPageViewsCellRenderer,
			DomainCellRenderer : DomainCellRenderer,
			OrgWithLogo: OrgWithLogo,
			PopupCellRenderer: PopupCellRenderer
		};
	}


})();