(function() {
	'use strict';

	// Make sure moment is invoked at runtime. This does nothing but force the 'moment' to
	// be loaded after bootstrap. This is done so the 'moment' factory has a chance to
	// 'erase' the global reference to the moment library.
	// http://www.bennadel.com/blog/2720-creating-and-extending-a-lodash-underscore-service-in-angularjs.htm
	angular.module('adminApp').run([
		// eslint-disable-next-line no-unused-vars
		function(moment) {
			// ...
		}
	]);

	angular.module('adminApp').factory('moment', ['$window', momentFactory]);

	function momentFactory($window) {
		var moment = $window.moment;
		//delete($window.moment);
		return moment;
	}
})();
