(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('inlineFilter', InlineFilter);

    function InlineFilter() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/inline-filter.html',
            scope: {
                onChange: '&onChange'
            },
            link: function (scope, element) {
                scope.filterValue = '';
                var pw = element.parent().width();
                element.find('input').css('width', pw*0.6 - 15);
            }
        };
    }
})();
