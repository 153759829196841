(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('SegmentAdvancedFilterSegmentListController', ['$timeout', '$scope', '$q',
			'CommonService', 'OrganisationService',
			'SegmentFilterSearchResultsTableModel', 'SegmentAdvancedFilterService','logger',
			'$uibModal', 'SegmentAdvancedFilterConstants',
			SegmentAdvancedFilterSegmentListController]);

	function SegmentAdvancedFilterSegmentListController($timeout, $scope, $q,
														CommonService,
														OrganisationService,
														SegmentFilterSearchResultsTableModel,
														SegmentAdvancedFilterService,
														logger,
														$uibModal,
														SegmentAdvancedFilterConstants
	) {
		var vm = this;
		vm.removeSelectedOrganistionsFromSegmentList = removeSelectedOrganistionsFromSegmentList;
		vm.showLeidsList = showLeidsList;
		// holds organisations selected to be removed to segment list
		vm.selectedOrganisations = {list: []};

		vm.parent = $scope.vm;

		if (vm.parent.segment) {
			vm.segment = vm.parent.segment;
			$scope.segmentListTable = {
				getFn: function () {
					return OrganisationService.getOrganisations(vm.segment.manuallyAddedLEOrganisationIds);
				},
				columnDefs: SegmentFilterSearchResultsTableModel.columnDefs
			};
		}

		$timeout(function () {
			$scope.panelHeights = 171 + 36;
			$('.segmentlist')
				.css('height', $(document).height() - $scope.panelHeights);
		});

		function removeSelectedOrganistionsFromSegmentList() {
			var selectedIdsInTable = _.map(vm.selectedOrganisations.list, 'data.id');
			vm.segment.manuallyAddedLEOrganisationIds = _.difference(vm.segment.manuallyAddedLEOrganisationIds, selectedIdsInTable);
			$scope.$broadcast('updateTable');

			$scope.$emit(SegmentAdvancedFilterConstants.eventDeletedOrganisationsFromSegmentList, '');
			logger.info('Removed seleced Organisations from segment list')
		}

		function showLeidsList() {
			vm.segment.manuallyAddedLEOrganisationIdsCommaSeperated = vm.segment.manuallyAddedLEOrganisationIds.join(",");
			$uibModal.open({
				templateUrl: 'partials/targeting/segments/advanced/segmentfilter-segment-list-allleids-modal.html',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
				vm.segment.manuallyAddedLEOrganisationIdsCommaSeperated = null;
			});
		}
	}
})();