(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('OrganisationStatisticsService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', OrganisationStatisticsService]);

    function OrganisationStatisticsService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAll = findAll;
        this.findAllByDate = findAllByDate;
        this.runStatistics = runStatistics;
        this.findAllDistinctDate = findAllDistinctDate;

        function findAll() {
            return $http.get( CONFIG.API_PREFIX + '/admin/organisations/statistics' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllByDate(date) {
            return $http.get( CONFIG.API_PREFIX + '/admin/organisations/statistics?date=' + date )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllDistinctDate() {
            return $http.get( CONFIG.API_PREFIX + '/admin/organisations/statistics/organisationStatisticsDistinctDates' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function runStatistics() {
            return $http.post(CONFIG.API_PREFIX + '/admin/organisations/statistics/runStatistics')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when executing statistics '));
        }
    }
})();