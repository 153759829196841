(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('IndustriesPieChart', [IndustriesPieChart]);

    function IndustriesPieChart() {
        return function (visits) {
            var that = this;

            this.getNumberOfItems = getNumberOfItems;
            this.getItems = getItems;
            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'pie',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 0,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 400
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.point.name + '</b>: ' + this.y + ' visits';
                        }
                    }
                },
                title: {
                    text: ''
                },
                series: [{
                    data: [],
                    size: '100%',
                    showInLegend: false,
                    dataLabels: false
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                },
                getData: function () {
                    return that.config.series[0].data;
                }
            };

            initChart(visits);

            function initChart(visits) {
                var numberOfVisitsPerIndustry = _.chain(visits)
                    .groupBy(industry)
                    .map(toChartItem)
                    .sortBy(industrySize)
                    .value()
                    .reverse()
                    .slice(0, 10);

                that.config.setData(numberOfVisitsPerIndustry);
            }

            function industry(visit) {
                return visit.organisation.sicPrimaryCodeDescription ? visit.organisation.sicPrimaryCodeDescription : 'Unknown';
            }

            function toChartItem(visits, sicPrimaryCodeDescription) {
                return [sicPrimaryCodeDescription, visits.length];
            }

            function industrySize(chartItem) {
                return chartItem[1];
            }

            function getNumberOfItems() {
                return that.config.getData().length;
            }

            function getItems() {
                return that.config.getData();
            }
        };
    }
})
();
