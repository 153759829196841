(function () {
	'use strict';

	angular
		.module('adminApp')
		.directive('pagesFilter', ['$http', '$animate', 'filterValidation', 'filterConstants', 'CommonService', 'PageService', PagesFilter]);

	function filterOutDuplicatesAndNull(value) {
		if( value &&  Array.isArray( value) ){
			var filteredNonNullPageIds = value.filter(function (pageId) {
				return pageId != null;
			});
			var uniq = _.uniqBy(filteredNonNullPageIds, 'id');
			console.log('uni fil', uniq)

			return uniq;
		}

		return [];

	}

	function PagesFilter($http, $animate, filterValidation, filterConstants, CommonService, PageService) {
		return {
			restrict: 'E',
			templateUrl: 'partials/common/advancedfilters/filters/visit/pagesFilter.html',
			link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.name = filterConstants.filters.PAGES;
				scope.operands = [
					scope.opers.IN,
					scope.opers.NOT_IN
				];

				if (scope.filter.data.value === undefined) {
					scope.filter.data.value = [];
				} else {
					// converts to js types
					/*for (var i = 0; i < scope.filter.data.value.length; i++) {
						var type = scope.filter.data.value[i];
						if( type.id !== undefined ){
							// make sure we always use ISO and not json if already set
							type = type.id;
						}
						scope.filter.data.value[i] = CommonService.getOrganisationType(type);
					}*/
				}

				var aid = CommonService.getAccount().id;

				console.log('value ' , scope.filter.data.value)
				var pageIds = filterOutDuplicatesAndNull(scope.filter.data.value);
				if(Array.isArray( pageIds)   ){
					PageService.searchPages(aid, pageIds).then(function(pages){
						scope.filter.data.value = pages;
					})
				}

				if (scope.filter.data.operand === undefined) {
					scope.filter.data.operand = scope.opers.IN;
				}


				scope.autoCompletePages = function (query) {
					return $http.get('/v3/accounts/' + aid + '/pages?limit=50&name' + name ).then(function(response) {
						return response.data.data;
					});
				};

				scope.tagAdded = function (page) {
					//console.log('tg added page is ', page)
				}
			}


		};
	}

})();