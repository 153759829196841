(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TopEnhancersBarChart', [TopEnhancersBarChart]);

    function TopEnhancersBarChart() {
        return function (enhancerStatistics) {
            var that = this;

            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'column',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 20,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    exporting: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 400
                            }
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.point.name + '</b>: ' + this.y + ' total enhancements';
                        }
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                series: [{
                    data: [],
                    size: '100%',
                    showInLegend: false,
                    dataLabels: false
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                },
                setXAxis: function (xAxis) {
                    that.config.xAxis.categories = xAxis;
                }
            };

            initChart(enhancerStatistics);

            function initChart(enhancerStatistics) {
                var enhancerStatisGroupedByTopScore = _.chain(enhancerStatistics)
                    .groupBy(function (statRow) {
                        return statRow.user.userName;
                    })
                    .map(function (statRow, key) {
                        var sum = _.reduce(statRow, function (memo, val) {
                                return memo + val.noOfEnhancementsSuccessful;
                            }, 0
                        );
                        return [key,  sum];
                    })
                    .slice(0, 10)
                    .value();

                // largest first
                var enhancerStatisGroupedAndSorted = _.sortBy( enhancerStatisGroupedByTopScore, function(chartItem){
                    return -chartItem[1];
                } );

                that.config.setData(enhancerStatisGroupedAndSorted);
            }
        };
    }
})
();
