(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('IndustryTagController', ['IndustryCodesService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger',
			IndustryTagController]);
    function IndustryTagController(IndustryCodesService, FileSaver, Blob, CONFIG,  TableSort, logger) {
        var vm = this;
        vm.industryCodes = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });

        //methods
        vm.loadMore = loadMore;
        vm.onIndustryCodeSearch = onIndustryCodeSearch;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        activate();

        function activate() {
            onLoad();
        }

        /// End init
        function onLoad() {
            IndustryCodesService.findIndustryTags( ).then( onFinished );
        }

        function onIndustryCodeSearch() {
            IndustryCodesService.findSicCodes(vm.queryFor).then( onFinished );
        }

        function onFinished(industryCodes){
            vm.industryCodes = industryCodes;
            logger.info('Found ' + vm.industryCodes.length + ' codes');
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'industrycodes_sic_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.industryCodes.length;
        }


    }
})();