(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AccountController', ['accountId', 'AccountService', 'SiteService', 'UserService', 'ProductService', 'ResellerAccountService',  'CommonService', 'logger', '$q', '$state', '$rootScope', AccountController]);

    function AccountController(accountId, AccountService, SiteService, UserService, ProductService, ResellerAccountService, CommonService, logger, $q, $state, $rootScope) {
        var vm = this;
        vm.saveAccount = saveAccount;
        vm.autoCompleteProducts = autoCompleteProducts;
        vm.setUpDSP = setUpDSP;
        vm.isDSPSettingsOk = isDSPSettingsOk;
        vm.sendWelcomeEmail = sendWelcomeEmail;

        vm.accountId = accountId;
        vm.selected = undefined;
        vm.searchFilter = '';
        vm.currentReseller = null;

        vm.end = moment().add(30, 'days').toDate();
        vm.start = moment().toDate();

        // initial if creating new
        vm.account = {
            id: accountId,
            active: true,
            useWholePageUrlAsUniqueId: false,
            timeZoneId: 'Europe/Copenhagen',
            resellerId: '53341735e4b00425d89961f5',
            resellerAccount: {},
            removeVisitsOlderThanXMonths: 3,
            defaultScoreForPageView: 0,
            contract: {
                type: 'TRIAL',
                start: vm.start,
                end: vm.end,
                contactDaysBeforeEnd: 30
            }
        };

        // reference data
        vm.refSelectAllProducts = null;
        vm.sites = null;
        vm.refSelectActive = [{id: true, name: 'Active'}, {id: false, name: 'Not Active'}];
        vm.refSelectContractTypes = [{id: 'TRIAL', name: 'Trial'}, {id: 'RENEWAL_1_YEAR',name: 'Renewal'}, {id: 'RUNNING', name: 'Running'}];
        vm.refGdpr = [{id: true, name: 'Do not show Sole Trader (organisation type)'}, {id: false ,name: 'Show all (organisation type)'}];
        vm.refSelectUseWholePageUrlAsUniqueId = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];
        vm.refTimeZones = null;
        vm.refResellerAccounts = null;
        vm.datePickerOptionsStarDate = {formatYear: 'yy', startingDay: 1};
        vm.datePickerOptionsEndDate = {formatYear: 'yy', startingDay: 1};

        vm.datePickerOptionsEndDate = {
            formatYear: 'yy',
            startingDay: 1
        };

        vm.datePickerOptionsStarDate = {
            formatYear: 'yy',
            startingDay: 1
        };


        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(showResult);
        }

        function getInitialData() {
            return $q.all([getAccount(), getTimeZones(), getAllResellerAccounts(), getAllProductsForAccount(), getSitesForAccount(),
                getUsersForAccount(), getResellerAccountForCurrent()]);
        }

        function getAccount() {
            if (_.isNull(vm.accountId) || _.isEmpty(vm.accountId)) {
                // initial
                return vm.account;
            } else {
                return AccountService.getAccount(vm.accountId);
            }
        }

        function getSitesForAccount() {
            if (_.isNull(vm.accountId) || _.isEmpty(vm.accountId)) {
                return [];
            }
            return SiteService.getSitesForAccount(vm.accountId);
        }

        function getUsersForAccount() {
            if (_.isNull(vm.accountId) || _.isEmpty(vm.accountId)) {
                return [];
            }

            return UserService.getUsersForAccount(vm.accountId);
        }

        function getTimeZones() {
            return CommonService.getTimeZones();
        }

         function getResellerAccountForCurrent() {
            return ResellerAccountService.getResellerAccountForCurrent();
        }

        function getAllProductsForAccount() {
            if (_.isNull(vm.accountId) || _.isEmpty(vm.accountId)) {
                return [];
            }
            return ProductService.getAllProductsForAccount(vm.accountId);
        }

        function getAllResellerAccounts() {
            return AccountService.getAllResellerAccounts();
        }


        // display the stuff when promises are finished
        function showResult(result) {
            vm.account = result[0];
            vm.refTimeZones = result[1];
            vm.refResellerAccounts = result[2];
            vm.products = result[3];
            if( vm.products.length === 0 ){
                logger.warning('No products selected for this account!!!');
            }


            vm.sites = result[4];
            vm.users = result[5];
            vm.currentReseller = result[6];

            // use reseller info to set some defaults
            if( !vm.account.accountAddress ){
                vm.account.accountAddress = {
                    country: vm.currentReseller.accountAddress.country,
                    city: vm.currentReseller.accountAddress.city,
                    postalCode: vm.currentReseller.accountAddress.postalCode,
                    address: vm.currentReseller.accountAddress.address
                };
                logger.success('Address information used from Reseller Account - please update!!');
            }
        }

        /// End init


        // mulitselect callback when user enter text
        function autoCompleteProducts() {
            return vm.refSelectAllProducts.filter(function (product) {
                //return segment.name.toLowerCase().indexOf($query.toLowerCase()) != -1;
                return product.id;
            });
        }


        function saveAccount() {
            return $q.all([onSave()]);
        }

        function onSave() {
            return AccountService.createOrUpdateAccount(vm.account)
                .then(function (data) {
                    vm.account = data;
                    showSaveSuccess();


                    return data;
                });
        }

        // validate if new buton should show
        function isDSPSettingsOk(){
            if( vm.account && vm.account.externalBTAdvertiserId){
                // already setup
                return false;
            }
            /*jslint laxbreak: true */
            if( vm.account
                && vm.account.externalBTAdvertiserContactName
                && vm.account.externalBTAdvertiserContactEmail
                && vm.account.externalBTAdvertiserContactName !== '' && vm.account.externalBTAdvertiserContactEmail !== '') {
                    return true;
            }
            return false;
        }

        function showSaveSuccess() {
            logger.success('Account saved');
            console.log('Broadcast new or updated account');
			$rootScope.$broadcast('reselleraccounts-updated');
            $state.go('admin.account', {accountId: vm.account.id}, {notify: false});
        }


        function setUpDSP(){
            return AccountService.setUpDSP(vm.account)
                .then(function (data) {
                    vm.account = data;
                    showSetupDSPSuccess();
                    return data;
                });
        }

        function showSetupDSPSuccess() {
            logger.success('Account is now setup for Targeting');
            $state.go('admin.account', {accountId: vm.account.id}, {notify: false});
        }


        function sendWelcomeEmail(userId) {
            UserService.sendWelcomeEmail(userId).then(onSendWelcomeEmail);
        }

        function onSendWelcomeEmail() {
            logger.success('User mail sent');
        }


    }
})();