(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('TrackingLinkController', ['account', 'clickCampaignId', 'trackingLinkId', '$q', '$state', 'logger', 'TrackingLinkService', 'TokenCommonService', 'STATE' ,TrackingLinkController]);

    function TrackingLinkController(account, clickCampaignId, trackingLinkId, $q, $state, logger, TrackingLinkService, TokenCommonService, STATE) {
        var vm = this;
        // interface
        vm.activate = activate;
        vm.saveClick = saveClick;
        vm.onUpdateProtocol = onUpdateProtocol;
        vm.sourcePartForTrackingLink = null;

        // this is resolved from ui-router if any
        vm.trackingLinkId = trackingLinkId;
        vm.clickCampaignId = clickCampaignId;
        vm.account = account;
        vm.start = moment().subtract(30, 'days').toDate();
        vm.end = moment().toDate();
        vm.token = null;
        vm.trackingLinkDisplay = '';


        // reference data
        vm.refFilters = [];

        vm.refForwardParameters = [{id: 'forward', name: 'Forward All Parameters'},{id: 'doNotForward', name: 'Do not forward Parameters'}];
        /* normal,byBrowserLanguage,byBrowserDevice,byVisitorCountry,byVisitorSize;*/
        vm.refRedirectModes = [{id: 'normal', name: 'Normal Sequential Redirect '}];
        vm.refTrackingLinkProtocol = [{id: 'http', name: 'http://'}, {id: 'https', name: 'https://'}];
        vm.refRedirectHttpStatusCodes = [{id: 302, name: 'Temporary Redirect (302)'},{id: 301, name: 'Permanent Redirect (301)'}];


        // initial if creating new
        vm.trackingLink = {
            accountId: account.id,
            active: true,
            clickCampaignId: vm.clickCampaignId,
            forwardQueryParameterMode : 'forward',
            trackingBaseLink : 'openapi.leadenhancer.com/v2/clicks',
            targetUrl : 'http://',
            redirectMode: 'normal',
            redirectHttpStatusCode : 302,
            protocol : 'http'
        };
        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(showInitialView);
        }

        function getInitialData() {
            return $q.all([getTrackingLink(), getTokenForAccountAndClickProductType()]);
        }

        function getTrackingLink() {
            if (_.isNull(vm.trackingLinkId) || _.isEmpty(vm.trackingLinkId)) {
                // on create
                return vm.trackingLink;
            } else {
                return TrackingLinkService.getTrackingLink(vm.account.id, vm.clickCampaignId, vm.trackingLinkId);
            }
        }

        function onUpdateProtocol(){
            createSourcePartForTrackingLink();
        }

        function getTokenForAccountAndClickProductType(){
            return TokenCommonService.getTokenForAccountAndProduct(vm.account.id, 'CLICK_TRACKING_OPEN_API');
        }

        function showInitialView(result) {
            vm.trackingLink = result[0];
            vm.token = result[1];
            vm.trackingLink.token = vm.token.token;

            createSourcePartForTrackingLink();
        }


        /// End init
        /////////   //////

        function createSourcePartForTrackingLink(){
            if( vm.trackingLink && vm.trackingLink.id ){
                vm.sourcePartForTrackingLink = vm.trackingLink.protocol + '://'  + vm.trackingLink.trackingBaseLink + '/' + vm.trackingLink.token + '/' + vm.trackingLink.id;
            } else {
                vm.sourcePartForTrackingLink = vm.trackingLink.protocol + '://' + vm.trackingLink.trackingBaseLink + '/' + vm.trackingLink.token;
            }
        }

        function saveClick() {
            var targetHttp = vm.trackingLink.targetUrl.startsWith('http://');
            var targetHttps = vm.trackingLink.targetUrl.startsWith('https://');
            if(  !( targetHttp|| targetHttps) ){
                logger.error('Destination url needs to start with http:// or https://');
                return;
            }
            if ( vm.trackingLink.protocol === 'https' && targetHttp ){
                logger.error('Destination and Source links need to have same protocol - http or https');
                return;
            }

            if ( vm.trackingLink.protocol === 'http' && targetHttps ){
                logger.error('Destination and Source links need to have same protocol - http or https');
                return;
            }

            return TrackingLinkService.createOrUpdateClick(vm.account.id, vm.trackingLink.clickCampaignId, vm.trackingLink)
                .then(function (data) {
                    vm.trackingLink = data;
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Tracking Link successfully saved ');
            $state.go(STATE.CLICK_CAMPAIGN_TRACKING_LINK, {accountId: vm.account.id, clickCampaignId: vm.trackingLink.clickCampaignId, trackingLinkId: vm.trackingLink.id}, {notify: true});
        }


    }
})();