(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignController', ['account', 'campaignId', 'CampaignService', 'UserService', 'logger', '$q', '$state', 'FileUploader', '$window', CampaignController]);

    function CampaignController(account, campaignId, CampaignService, UserService, logger, $q, $state, FileUploader, $window) {
        var vm = this;
        vm.deleteCreative = deleteCreative;
        vm.onClick = onClick;
        // show hide parts of settings...
        vm.isCampaignGoalBudget = isCampaignGoalBudget;
        vm.isCampaignGoalPeriod = isCampaignGoalPeriod;
        vm.isCampaignGoalClicks = isCampaignGoalClicks;
        vm.isCampaignGoalImpressions = isCampaignGoalImpressions;
        vm.isDSPAdform = isDSPAdform;
        vm.autoCompleteSegments = autoCompleteSegments;
        vm.saveCampaign = saveCampaign;
        vm.getUsersForAccount = getUsersForAccount;
        vm.onPublishCampaign = onPublishCampaign;
        vm.activate = activate;
        vm.startDateChanged = startDateChanged;
        vm.noOfFilesInQueue = noOfFilesInQueue;


        vm.searchFilter = '';
        vm.account = account;
        vm.campaignId = campaignId;
        vm.selected = undefined;
        // initial upload url if existing campaign, ie loading from list
        vm.uploadUrl = '/v3/accounts/' + vm.account.id + '/campaigns/' + vm.campaignId + '/upload';
        vm.start = moment().toDate();
        vm.end = moment().add(30, 'days').toDate();
        // reference data
        vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};

        // initial if creating new
        vm.campaign = {
            id: campaignId,
            accountId: vm.account.id,
            campaignManager: null,
            advertiserContact: null,
            advertiserClient: null,
            start: vm.start,
            end: vm.end,
            status: 'NotActive',  // not active
            dsp: 'Leadenhancer',
            campaignGoal: 'Impressions',
            currencyCode: 'SEK',
            //adGroup: 0,
            //adDimension : 0,
            //period: start + ' - ' + end,
            segment: [],
            externalBTAdvertiserId : vm.account.externalBTAdvertiserId,
            externalBTAdvertiserContactName : vm.account.externalBTAdvertiserId,
            externalBTAdvertiserContactEmail : vm.account.externalBTAdvertiserContactEmail
        };

        vm.campaignAd = {
            id: campaignId,
            accountId: vm.account.id,
            url : vm.uploadUrl,
            adStatus : 'Active',
            adType : 'IMAGE_BANNER',
            //adGroup : null,
        //externalBTCampaignId;
            adDimension : null
        };

        vm.uploader = new FileUploader({
            url: vm.uploadUrl
        });

        // reference data
        vm.refCurrencies = null;
        vm.refRevenueModels = null;
        vm.refDsps = null;
        vm.refActiveStates = null;
        vm.refCategories = null;
        vm.refSegments = null;
        vm.refAdDimensions = null;
        vm.refAdGroups = null;
        vm.refAdStatus = [  {id : 'Active' , name : 'Active'}, {id : 'InActive' , name : 'In Active'}];
        vm.refAdTypes = [ {id : 'FLASH_BANNER' , name : 'Flash Banner'}, {id : 'IMAGE_BANNER' , name : 'Image Banner'}];
        vm.refCampaignGoals = [{id: 'Budget', name: 'Budget'}, {id: 'Impressions', name: 'Impressions'},
            {id: 'Period', name: 'Period'}, {id: 'Clicks', name: 'Clicks'}];

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(showCampaign);
        }

        function getInitialData() {
            return $q.all([getCampaignAdforms(), getCampaignTypes(), getCurrencies(), getDSPs(),
                getCategories(), getCampaignStates(), getAdGroups(), getSegments(),
                getAdDimensions(), getCampaignRevenueModelsForAccount()]);
        }

        function getCampaignAdforms() {
            if (_.isNull(vm.campaignId) || _.isEmpty(vm.campaignId)) {
                // initial when no campaign found
                return vm.campaign;
            } else {
                return CampaignService.getCampaignAdforms(vm.account.id, vm.campaignId);
            }
        }

        function getSegments() {
            if (_.isNull(vm.account.id) || _.isEmpty(vm.account.id)) {
                // initial when no campaign found
                return {};
            } else {
                return CampaignService.getSegments(vm.account.id);
            }
        }

        function getCampaignRevenueModelsForAccount() {
            //return CampaignService.getCampaignRevenueModelsForAccount(vm.account.id);
            return null; // todo
        }

        function getUsersForAccount(user) {
            return UserService.searchUsersForAccount(user);
        }

        function getCampaignTypes() {
            return null;
        }

        function getCurrencies() {
            return [{code: 'SEK', name: 'Swedish SEK'}, {code: 'EUR', name: 'Euro'}, {code: 'USD', name: 'US Dollars'}];
        }

        function getCampaignStates() {
            return [{id: 'NotActive', name: 'Not Active'}, {id: 'Active', name: 'Active'},
                {id: 'Paused', name: 'Paused'}, {id: 'Completed', name: 'Completed'},
                {id: 'Deleted', name: 'Deleted'}
            ];
        }

        function getDSPs() {
            //return [{id: 'Leadenhancer', name: 'Leadenhancer'}, {id: 'Adforms', name: 'Adforms'}];
            return [{id: 'Adforms', name: 'Adforms'}];
        }

        function getCategories() {
            return [{id: 'TargetedAdvertisment', name: 'Targeted Advertisement'}, {
                id: 'Retargeting',
                name: 'Retargeting'
            }];
        }

        function getAdGroups() {
            return [{id: 'Flash', name: 'Flash'}, {id: 'Banner', name: 'HTML Banner'}, {
                id: 'Image',
                name: 'Image Banner'
            },
                {id: 'MobileApp', name: 'Mobile/In app'}];
        }

        function getAdDimensions() {
            return CampaignService.getAdDimensions();
        }

        // display the stuff when promises are finished
        function showCampaign(result) {
            vm.campaign = result[0];
            vm.refCategories = result[1];
            vm.refCurrencies = result[2];
            vm.refDsps = result[3];
            vm.refCategories = result[4];
            vm.refStatuses = result[5];
            vm.refAdGroups = result[6];
            vm.refSegments = result[7];
            vm.refAdDimensions = result[8];
            vm.refRevenueModels = result[9];
        }
        /// End init


        function isDSPAdform() {
            if (vm.campaign.dsp === 'Adforms') {
                return true;
            }
            return false;
        }

        function deleteCreative(campaignAdId) {
            CampaignService.deleteCreative(vm.account.id, vm.campaignId, campaignAdId)
                .then(function (campaign) {
                    vm.campaign = campaign;
                });
        }

        function isCampaignGoalBudget() {
            return vm.campaign.campaignGoal === 0;
        }

        function isCampaignGoalPeriod() {
            return vm.campaign.campaignGoal === 2;
        }

        function isCampaignGoalClicks() {
            return vm.campaign.campaignGoal === 3;
        }

        function isCampaignGoalImpressions() {
            return vm.campaign.campaignGoal === 1;
        }


        function saveCampaign() {
            return $q.all([onSaveCampaign()]);
        }

        // update period field
        function startDateChanged() {
            if (_.isNull(vm.period) || _.isEmpty(vm.period)) {
                vm.campaign.period = moment(vm.campaign.start).format('YYYY-MM-DD') +
                ' - ' + moment(vm.campaign.end).format('YYYY-MM-DD');
            }
        }

        function onClick() {
            $window.alert(' ' + vm.account.id);
        }

        function onSaveCampaign() {
            vm.campaign.start = moment(vm.campaign.start).valueOf();
            vm.campaign.end = moment(vm.campaign.end).valueOf();

            return CampaignService.createOrUpdateCampaign(vm.account.id, vm.campaign)
                .then(function (data) {
                    vm.campaign = data;
                    showSaveSuccess();
                    return data;
                });
        }

        // will fire of backend and publish to dsp
        function onPublishCampaign() {
            return CampaignService.publishCampaign(vm.account.id, vm.campaign)
                .then(function (data) {
                    showPublishedSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Campaign saved');
            $state.go('admin.campaign', {accountId: vm.account.id, campaignId: vm.campaign.id}, {notify: false});
        }

        function showPublishedSuccess() {
            logger.success('Campaign successfully published.');
        }

        // mulitselect callback when user enter text
        function autoCompleteSegments($query) {
            return vm.refSegments.filter(function (segment) {
                return segment.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
        }

        function noOfFilesInQueue(){
            if( vm.uploader ){
                return vm.uploader.getNotUploadedItems().length;
            }
            return 0;
        }

        ////////
        // FILTERS - https://github.com/nervgh/angular-file-upload
        vm.uploader.filters.push({
            name: 'imageFilter',
            fn: function (item) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|gif|'.indexOf(type) !== -1;
            }
        });
        // CALLBACKS
        vm.uploader.onWhenAddingFileFailed = function (item, filter, options) {
            //jshint unused:false
            logger.success('onWhenAddingFileFailed');
        };
        vm.uploader.onAfterAddingFile = function (fileItem) {
            //jshint unused:false
            logger.success('Added file to upload queue');
        };
        vm.uploader.onAfterAddingAll = function (addedFileItems) {
            //jshint unused:false
            //logger.success('onAfterAddingAll');
        };
        vm.uploader.onBeforeUploadItem = function (item) {
            //jshint unused:false
        };
        vm.uploader.onProgressItem = function (fileItem, progress) {
            //jshint unused:false
        };
        vm.uploader.onProgressAll = function (progress) {
            //jshint unused:false
        };
        vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
            //jshint unused:false
        };
        vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
            //jshint unused:false
        };
        vm.uploader.onCancelItem = function (fileItem, response, status, headers) {
            //jshint unused:false
        };
        vm.uploader.onCompleteItem = function (fileItem, response, status, headers) {
            //jshint unused:false
            vm.campaign = response.data;
        };
        vm.uploader.onCompleteAll = function () {
            logger.success('Uploaded Ads.');
        };
    }
})();