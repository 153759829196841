(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('SiteService', ['$http', 'CONFIG', 'responseExceptionCatcher', SiteService]);

    function SiteService($http, CONFIG, responseExceptionCatcher) {
        this.getSite = getSite;
        this.getSites = getSites;
        this.getSiteScript = getSiteScript;
        this.getSitesForAccount = getSitesForAccount;
        this.createOrUpdateSite = createOrUpdateSite;
        this.sendSiteScriptEmail = sendSiteScriptEmail;

        function getSites() {
            return $http.get(CONFIG.API_PREFIX + '/admin/sites')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getSitesForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/sites')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getSite(accountId, siteId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/sites/' + siteId)
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getSiteScript(accountId, siteId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/sites/' + siteId + '/script')
                .then(onSuccess)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function onSuccess(response) {
            return response.data.data;
        }

        function createOrUpdateSite(accountid, site) {
            var promise;
            if (!(_.isNull(site.id) || site.id === undefined || site.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/admin/accounts/' + accountid + '/sites/', site)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/admin/accounts/' + accountid + '/sites/', site)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving '));
            }
            return promise;
        }

        function sendSiteScriptEmail(accountId, siteId, mailTo){
            console.log(mailTo);
            return $http.post(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/sites/' + siteId + '/mailscript/' + mailTo + '.kalle')
                .catch(responseExceptionCatcher.catch('Error when sending email '));
        }
    }
})();