(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SegmentFilterController', ['account', 'user', 'accountId', 'segmentFilterId', 'SegmentFilterService', 'CountryService', 'OrganisationService',  'logger', '$q', '$state', '$window', '$document', 'CONFIG', 'FileSaver', '$uibModal', '$scope', '$localStorage', 'EnhancedOrganisationService', SegmentFilterController]);

    function SegmentFilterController(account, user, accountId, segmentFilterId, SegmentFilterService, CountryService, OrganisationService, logger, $q, $state, $window, $document, CONFIG, FileSaver, $uibModal, $scope, $localStorage, EnhancedOrganisationService) {
        var vm = this;
        vm.account = account;
        vm.segmentFilterId = segmentFilterId;
        vm.accountId = accountId;
        vm.organisations = [];
        //vm.selected = undefined;
        // used in modal
        vm.selectOrganisationsForLeIdsFilter = [];
        vm.selectOrganisationsForLeIdsFilter.selected = [];
        vm.isNoFilterShowing = isNoFilterShowing;
        vm.onFormChange = onFormChange;
        vm.onFormQueryChange = onFormQueryChange;
        vm.createSegmentFileWithIPs = createSegmentFileWithIPs;
        vm.createSegmentFileWithOrganisationss = createSegmentFileWithOrganisationss;
        vm.onSearchWithSegmentFilter = onSearchWithSegmentFilter;
        vm.getNoOfOrganisationsUsingTransientWithSegmentFilter = getNoOfOrganisationsUsingTransientWithSegmentFilter;
        vm.getOrganisationsUsingTransientWithSegmentFilter = getOrganisationsUsingTransientWithSegmentFilter;
        vm.createSegmentFileWithIPsUploadToS3AndSendEmail = createSegmentFileWithIPsUploadToS3AndSendEmail;
        vm.addOrganisationsToLeadenhancerSelectedAccountFilter = addOrganisationsToLeadenhancerSelectedAccountFilter;
        vm.validateReceivers = validateReceivers;
        vm.saveSegment = saveSegment;
        vm.activate = activate;
        vm.autoCompleteCountries = autoCompleteCountries;
        //vm.findOrganisations = findOrganisations;
        vm.clearSegmentSettings = clearSegmentSettings;
        //vm.onShowFilterOrganisations = onShowFilterOrganisations;
        vm.showInformationWhenLimitReached = showInformationWhenLimitReached;
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.onOrganisationClick = onOrganisationClick;
        vm.onOpenOrganisations = onOpenOrganisations;
        vm.getOrganisationsUsingQueryField = getOrganisationsUsingQueryField;
        vm.onOrganisationClickRequestEnhancement = onOrganisationClickRequestEnhancement;


        vm.modalLeIdOrganisationsCheckAll = modalLeIdOrganisationsCheckAll;
        vm.modalLeIdOrganisationsDeCheckAll = modalLeIdOrganisationsDeCheckAll;
        vm.modalDone = modalDone;

        // check decheck
        vm.organisations.selected = [];
        vm.checkAll = checkAll;
        vm.deCheckAll = deCheckAll;
        vm.addOrganisationsToLeeadenhancerFilter = addOrganisationsToLeeadenhancerFilter;
        vm.addAndReplaceAllOrganisationsToLeadenhancerFilter = addAndReplaceAllOrganisationsToLeadenhancerFilter;

        // clears fields for segment specific fields
        vm.clearLeadenhancerIds = clearLeadenhancerIds;
        vm.clearOrganisationsIds = clearOrganisationsIds;
        vm.clearDUNSCodes = clearDUNSCodes;
        vm.clearSICCodes = clearSICCodes;
        vm.clearSNICodes = clearSNICodes;
        vm.clearOrganisationNames = clearOrganisationNames;
        vm.clearEmployeesRange = clearEmployeesRange;
        vm.clearSICRange = clearSICRange;
        vm.clearSNIRange = clearSNIRange;
        vm.clearSalesRange = clearSalesRange;
        vm.clearPostalCodes = clearPostalCodes;
        vm.clearCountries = clearCountries;
        vm.clearRegions = clearRegions;
        vm.clearCities = clearCities;

        vm.$storage = $localStorage.$default({showHelp: false});


        // query field
        vm.queryFieldSegmentFilter = {
            accountId: vm.account.id,
            userId : user.id,
            version : '2',
            isNew: true,
            organisationQueryV2 : {
                queryTextTerm : '*',
                dataSet : 'basicAndEnhanced',
                queryOperatorSniCodeIds : 'CONTAINS',
                queryOperatorSicCodeIds : 'CONTAINS',
                queryOperatorOrganisationIds : 'EQUAL',
                queryOperatorDunsIds : 'EQUAL',
                queryOperatorLeIds : 'EQUAL',
                queryOperatorPostalCodes : 'CONTAINS',
                queryOperatorRegions : 'CONTAINS',
                queryOperatorCities : 'CONTAINS',
                queryOperatorOrganisationNames : 'CONTAINS'
            }
        };
        vm.queryFieldNoOfOrganisations = [];

        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;


        vm.showFilterOrganisations = false;
        vm.searchFilter = '';
        vm.numberOfOrganisations = null;
        vm.organisations = [];
        vm.sendMailTo = [];

        // export and search disabled if not less than this
        vm.maxNoOfHitsAllowed = 5000;

        // drop downs operators, different for different fields
        var lessThan = {id: 'LESS_THAN', name: 'Less than'};
        var equal = {id: 'EQUAL', name: 'Equal'};
        var biggerThen = {id: 'BIGGER_THAN', name: 'Bigger then'};
        var contains = {id: 'CONTAINS', name: 'Contains'};
        var beginsWith = {id: 'BEGINS_WITH', name: 'Begins with'};
        var regExp = {id: 'REGEXP', name: 'Reg exp'};
        var notEqual = {id: 'NOT_EQUAL', name: 'Not equal'};
        vm.refQueryOperatorsForCountries = [equal, notEqual];
        vm.refQueryOperatorsAll = [lessThan, equal, biggerThen, contains, beginsWith, regExp, notEqual];
        vm.refQueryOperatorsForDunsIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForLeIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForOrganisationIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForOrganisationNames = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForSNIIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForSICIds = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForRegions = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForPostalCodes = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForRegions = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForCities = [equal, notEqual, contains, beginsWith, regExp];
        vm.refQueryOperatorsForPageKeyqords = [contains, notEqual];

        vm.accountSegments = null;
        vm.selectedSegmentId = null;

        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(showSegment);
        }

        function getInitialData() {
            return $q.all([getSegment(), getSegments()]);
        }

        function getSegment() {
            var config = {hideLoadingIndicator: true,dontFailOnError: true};

            if (_.isNull(vm.segmentFilterId) || _.isEmpty(vm.segmentFilterId)) {
                // initial when no campaign found
                return vm.segmentFilter;
            } else {
                return SegmentFilterService.getSegment(vm.account.id, vm.segmentFilterId, config);
            }
        }

        function getSegments() {
            SegmentFilterService.getSegments(vm.accountId)
                .then(function (segments) {
                    vm.accountSegments = segments;
                    if (!vm.accountSegments.length) {
                        logger.info('No segments found!');
                    }

                });
        }


        // display the stuff when promises are finished
        function showSegment(result) {
            vm.accountSegments = result[1];
            if (result[0]) {
                vm.segmentFilter = result[0];
                if(vm.segmentFilter.organisationQueryV2.filterOnOrganisationsIds){
                    modalReloadOrganisationsForLeIdFilter();
                }
            } else {
                vm.segmentFilter = {
                    //id: segmentFilterId,
                    accountId: vm.account.id,
                    userId : user.id,
                    version : '2',
                    isNew: true,
                    organisationQueryV2 : {
                        queryTextTerm : '*',
                        dataSet : 'basicAndEnhanced',
                        /*
                         force user to make a select so we can trigger a count
                         filterOnCountryISOHolder : {
                         keyValues : [],
                         queryOperatorCountry : "EQUAL"
                         },*/
                        queryOperatorSniCodeIds : 'CONTAINS',
                        queryOperatorSicCodeIds : 'CONTAINS',
                        queryOperatorOrganisationIds : 'EQUAL',
                        queryOperatorDunsIds : 'EQUAL',
                        queryOperatorLeIds : 'EQUAL',
                        queryOperatorPostalCodes : 'CONTAINS',
                        queryOperatorRegions : 'CONTAINS',
                        queryOperatorCities : 'CONTAINS',
                        queryOperatorOrganisationNames : 'CONTAINS'
                    }
                };
            }

            // sepcial query field segment


            getNoOfOrganisationsUsingQueryField();

            updateViewOnSegmentFilterQuery();
            if (vm.segmentFilter.id) {
                getNumberOfOrganisationsForSegmentFilter();
            } else {
                getNoOfOrganisationsUsingTransientWithSegmentFilter();
            }
        }

        // unhide if query says so
        function updateViewOnSegmentFilterQuery() {
            // vm.visitFilter.visitFilterQuery can be null if user has not entered a fitler param
            if (vm.segmentFilter.organisationQueryV2 !== null) {
                vm.showFilterISP = vm.segmentFilter.organisationQueryV2.hideIsp;
                vm.showFilterSales = vm.segmentFilter.organisationQueryV2.filterOnMinSales || vm.segmentFilter.organisationQueryV2.filterOnMaxSales;
                vm.showFilterEmployees = vm.segmentFilter.organisationQueryV2.filterOnMinNoOfEmployees || vm.segmentFilter.organisationQueryV2.filterOnMaxNoOfEmployees;
                vm.showFilterSIC = vm.segmentFilter.organisationQueryV2.filterOnMinSicCode || vm.segmentFilter.organisationQueryV2.filterOnMaxSicCode;
                vm.showFilterNace = vm.segmentFilter.organisationQueryV2.filterOnMinNaceCode || vm.segmentFilter.organisationQueryV2.filterOnMaxNaceCode;
                vm.showFilterOrganisationIds = vm.segmentFilter.organisationQueryV2.filterOnOrganisationsIds && vm.segmentFilter.organisationQueryV2.filterOnOrganisationsIds > 0;
                vm.showFilterOrganisationNames = vm.segmentFilter.organisationQueryV2.filterOnOrganisationNames && vm.segmentFilter.organisationQueryV2.filterOnOrganisationNames.length > 0;
                vm.showFilterSICIds = vm.segmentFilter.organisationQueryV2.filterOnSicCodeIds && vm.segmentFilter.organisationQueryV2.filterOnSicCodeIds.length > 0;
                vm.showFilterSNINACE = vm.segmentFilter.organisationQueryV2.filterOnSNICodeIds && vm.segmentFilter.organisationQueryV2.filterOnSNICodeIds.length > 0;
                vm.showFilterLEIds = vm.segmentFilter.organisationQueryV2.filterOnLeIds && vm.segmentFilter.organisationQueryV2.filterOnLeIds.length > 0;
                vm.showFilterDUNS = vm.segmentFilter.organisationQueryV2.filterOnDunsIds && vm.segmentFilter.organisationQueryV2.filterOnDunsIds.length > 0;
                vm.showFilterPostalCodes = vm.segmentFilter.organisationQueryV2.filterOnPostalCodes && vm.segmentFilter.organisationQueryV2.filterOnPostalCodes.length > 0;
                vm.showFilterRegions = vm.segmentFilter.organisationQueryV2.filterOnRegions && vm.segmentFilter.organisationQueryV2.filterOnRegions.length > 0;
                vm.showFilterCities = vm.segmentFilter.organisationQueryV2.filterOnCities && vm.segmentFilter.organisationQueryV2.filterOnCities.length > 0;
                vm.showFilterCountries = vm.segmentFilter.organisationQueryV2.filterOnCountryISOHolder;
            }
        }
        /// End init

        function onSearchWithSegmentFilter() {
            if( vm.numberOfOrganisations > vm.maxNoOfHitsAllowed ){
                window.alert('To many organisations, narrow down using filter - max allowed is ' + vm.maxNoOfHitsAllowed);
                return;
            }
            vm.organisations = null;
            vm.numberOfOrganisations = null;
            $document.scrollToElementAnimated(angular.element(document.getElementById('filter-result')), -225);
            getOrganisationsUsingTransientWithSegmentFilter();
        }

        function getNumberOfOrganisationsForSegmentFilter(){
            return SegmentFilterService.countOrganisations(vm.account.id, vm.segmentFilter.id)
                .then(function (numberOfOrganisations) {
                    vm.numberOfOrganisations = numberOfOrganisations;
                });
        }

        function saveSegment() {
            return $q.all([onSaveSegment()]);
        }

        function onSaveSegment() {
            vm.organisations = null;
            vm.segmentFilter.segmentSize = vm.numberOfOrganisations;
            return SegmentFilterService.createOrUpdateSegment(vm.account.id, vm.segmentFilter)
                .then(function (data) {
                    vm.segmentFilter = data;
                    showSaveSuccess();
                    //getNumberOfOrganisationsForSegmentFilter();
                    //updateViewOnSegmentFilterQuery();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Segment saved');
            $state.go('admin.segmentFilter', {accountId: vm.account.id, segmentFilterId: vm.segmentFilter.id}, {notify: false});
        }

        function autoCompleteCountries(query) {
            return _.filter(CountryService.getAllCountries(), function (country) {
                return country.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }

        // unhide if query says so
        function clearSegmentSettings() {
            // vm.segmentFilter.organisationQueryV2 can be null if user has not entered a filter param
            if (vm.segmentFilter.organisationQueryV2 !== null) {
                vm.segmentFilter.organisationQueryV2.hideIsp = true;
                vm.segmentFilter.organisationQueryV2.filterOnMinSales = null;
                vm.segmentFilter.organisationQueryV2.filterOnMaxSales = null;
                vm.segmentFilter.organisationQueryV2.filterOnMinNoOfEmployees = null;
                vm.segmentFilter.organisationQueryV2.filterOnMaxNoOfEmployees = null;
                vm.segmentFilter.organisationQueryV2.filterOnMinSicCode = null;
                vm.segmentFilter.organisationQueryV2.filterOnMaxSicCode = null;
                vm.segmentFilter.organisationQueryV2.filterOnMinNaceCode = null;
                vm.segmentFilter.organisationQueryV2.filterOnMaxNaceCode = null;
                vm.segmentFilter.organisationQueryV2.filterOnSicCodeIds = [];
                vm.segmentFilter.organisationQueryV2.filterOnOrganisationNames =  [];
                vm.segmentFilter.organisationQueryV2.filterOnOrganisationsIds =  [];
                vm.segmentFilter.organisationQueryV2.filterOnSicCodeIds =  [];
                vm.segmentFilter.organisationQueryV2.filterOnSNICodeIds =  [];
                vm.segmentFilter.organisationQueryV2.filterOnLeIds =  [];
                vm.segmentFilter.organisationQueryV2.filterOnDunsIds =  [];
                vm.segmentFilter.organisationQueryV2.filterOnPostalCodes =  [];
                vm.segmentFilter.organisationQueryV2.filterOnRegions =  [];
                vm.segmentFilter.organisationQueryV2.filterOnCities =  [];
                vm.segmentFilter.organisationQueryV2.filterOnCountryISOHolder = null;

                updateViewOnSegmentFilterQuery();

                vm.organisations = [];

                getNoOfOrganisationsUsingTransientWithSegmentFilter();
            }
        }

        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.organisations) {
                return vm.organisations.length;
            }
        }

        function showInformationWhenLimitReached(){
            if( vm.organisations && vm.organisations.length > vm.maxNoOfHitsAllowed ){
                return true;
            }
            return false;
        }

        function createSegmentFileWithIPs(){
            SegmentFilterService.createSegmentFileWithIPs(vm.account.id, vm.segmentFilter.id)
                .then( function(response){
                    //var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                    var config = {
                        data: data,
                        filename: 'Segments_IPS_' + vm.segmentFilter.id + '_' + new Date().toISOString().substring(0, 10) + '.xls'
                    };
                    FileSaver.saveAs(config.data, config.filename);
                } );
        }

        function createSegmentFileWithOrganisationss(){
            SegmentFilterService.createSegmentFileWithOrganisationss(vm.account.id, vm.segmentFilter.id)
                .then( function(response){
                    //var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    var data = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
                    var config = {
                        data: data,
                        filename: 'Segment_Organisations_' + vm.segmentFilter.id + '_' + new Date().toISOString().substring(0, 10) + '.xls'
                    };
                    FileSaver.saveAs(config.data, config.filename);
                } );
        }

        function createSegmentFileWithIPsUploadToS3AndSendEmail(){
            SegmentFilterService.createSegmentFileWithIPsUploadToS3AndSendEmail(vm.account.id, vm.segmentFilter.id, vm.sendMailTo)
                .then( function(){
                   //console.log('', response.data);
                } )
                .then(showExportSuccess);
        }

        function showExportSuccess() {
            logger.success('Report sent ' );
        }

        function validateReceivers(values) {
            var valid = false;
            _.each(values, function(email) {
                if( email.indexOf('@') === -1){
                    valid = false;
                } else {
                    valid = true;
                }
            });
            return valid;
        }

        function getNoOfOrganisationsUsingTransientWithSegmentFilter() {
            SegmentFilterService.getNoOfOrganisationsUsingTransientWithSegmentFilter(vm.account.id,
                vm.segmentFilter.organisationQueryV2)
                .then( function(noOfOrganisations){
                    vm.numberOfOrganisations = noOfOrganisations;
                } );
        }

        function getOrganisationsUsingTransientWithSegmentFilter(){
            SegmentFilterService.getOrganisationsUsingTransientWithSegmentFilter(vm.account.id,vm.segmentFilter.organisationQueryV2)
                .then( function(organisations){
                    vm.numberOfOrganisations = organisations.length;
                    vm.organisations = organisations;
                    vm.organisations.selected = null;
                } );
        }

        // update count when something changes in form
        function onFormChange(){
            vm.organisations = null;
            getNoOfOrganisationsUsingTransientWithSegmentFilter();

            modalReloadOrganisationsForLeIdFilter();
        }

        function onFormQueryChange(){
            vm.organisations = null;
            getNoOfOrganisationsUsingQueryField();
        }

        function isNoFilterShowing() {
            /*jslint laxbreak: true */
            return !vm.showFilterISP && !vm.showFilterSales && !vm.showFilterEmployees && !vm.showFilterSIC
                && !vm.showFilterNace && !vm.showFilterOrganisationIds && !vm.showFilterOrganisationNames && !vm.showFilterSICIds
                && !vm.showFilterSNINACE && !vm.showFilterLEIds && !vm.showFilterDUNS && !vm.showFilterPostalCodes
                && !vm.showFilterRegions && !vm.showFilterCountries && !vm.showFilterCities;
        }

        function checkAll() {
            vm.organisations.selected = angular.copy(vm.organisations);
        }

        function deCheckAll() {
            vm.organisations.selected = null;
        }

        function addOrganisationsToLeeadenhancerFilter() {
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                // add if not present
                var selectedOrganisationId = _.map(vm.organisations.selected, 'id');
                vm.segmentFilter.organisationQueryV2.filterOnLeIds = _.union(selectedOrganisationId, vm.segmentFilter.organisationQueryV2.filterOnLeIds);

                vm.showFilterLEIds = true;
                onFormChange();
                modalReloadOrganisationsForLeIdFilter();
            }
        }

        // will store selected on other segment
        function addOrganisationsToLeadenhancerSelectedAccountFilter() {
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                // add if not present
                var selectedOrganisationIds = _.map(vm.organisations.selected, 'id');

                SegmentFilterService.addLeIdsToSegmentFilter(vm.account.id,  vm.selectedSegmentId, selectedOrganisationIds)
                    .then(function(){
                        logger.info('Segment was updated !!!!');
                        if( vm.selectedSegmentId === vm.segmentFilterId ){
                            // we updated the same filter we are on
                            //$state.go('admin.segmentFilter', {accountId: vm.account.id, segmentFilterId: vm.segmentFilterId}, {notify: false});
                            $state.go('admin.segmentFilter', { segmentFilterId: vm.segmentFilterId}, {notify: true});
                        }
                });
            }
        }

        function addAndReplaceAllOrganisationsToLeadenhancerFilter() {
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                vm.segmentFilter.organisationQueryV2.filterOnLeIds = _.map(vm.organisations.selected, 'id');
                vm.showFilterLEIds = true;
                onFormChange();
                modalReloadOrganisationsForLeIdFilter();
            }
        }

        function clearLeadenhancerIds(){
            vm.segmentFilter.organisationQueryV2.filterOnLeIds = [];
            onFormChange();
        }

        function clearOrganisationsIds(){
            vm.segmentFilter.organisationQueryV2.filterOnOrganisationsIds = [];
            onFormChange();
        }

        function clearDUNSCodes(){
            vm.segmentFilter.organisationQueryV2.filterOnDunsIds = [];
            onFormChange();
        }

        function clearSICCodes(){
            vm.segmentFilter.organisationQueryV2.filterOnSicCodeIds = [];
            onFormChange();
        }

        function clearSNICodes(){
            vm.segmentFilter.organisationQueryV2.filterOnSNICodeIds = [];
            onFormChange();
        }

        function clearOrganisationNames(){
            vm.segmentFilter.organisationQueryV2.filterOnOrganisationNames = [];
            onFormChange();
        }

        function clearEmployeesRange(){
            vm.segmentFilter.organisationQueryV2.filterOnMinNoOfEmployees = null;
            vm.segmentFilter.organisationQueryV2.filterOnMaxNoOfEmployees = null;
            onFormChange();
        }

        function clearSICRange(){
            vm.segmentFilter.organisationQueryV2.filterOnMinSicCode = null;
            vm.segmentFilter.organisationQueryV2.filterOnMaxSicCode = null;
            onFormChange();
        }

        function clearSNIRange(){
            vm.segmentFilter.organisationQueryV2.filterOnMinNaceCode = null;
            vm.segmentFilter.organisationQueryV2.filterOnMaxNaceCode = null;
            onFormChange();
        }

        function clearSalesRange(){
            vm.segmentFilter.organisationQueryV2.filterOnMinSales = null;
            vm.segmentFilter.organisationQueryV2.filterOnMaxSales = null;
            onFormChange();
        }

        function clearPostalCodes(){
            vm.segmentFilter.organisationQueryV2.filterOnPostalCodes = [];
            onFormChange();
        }

        function clearCountries(){
            vm.segmentFilter.organisationQueryV2.filterOnCountryISOHolder = null;
            onFormChange();
        }

        function clearRegions(){
            vm.segmentFilter.organisationQueryV2.filterOnRegions = [];
            onFormChange();
        }

        function clearCities(){
            vm.segmentFilter.organisationQueryV2.filterOnCities = [];
            onFormChange();
        }

        function onOrganisationClick(organisation) {
            $state.go('admin.organisation', {organisationId: organisation.id});
        }

        function onOpenOrganisations() {
            $uibModal.open({
                templateUrl: 'partials/targeting/segments/organisations-selected-le-modal.html',
                //controller: 'ConfirmController as vm',
                scope: $scope,
                size: 'lg'
            }).result.then(function () {
            });
        }

        function modalLeIdOrganisationsCheckAll() {
            vm.selectOrganisationsForLeIdsFilter.selected = angular.copy(vm.selectOrganisationsForLeIdsFilter);
        }

        function modalLeIdOrganisationsDeCheckAll() {
            vm.selectOrganisationsForLeIdsFilter.selected = null;
        }

        function modalDone() {
            if (vm.selectOrganisationsForLeIdsFilter.selected || vm.selectOrganisationsForLeIdsFilter.selected.length > 0) {
                vm.segmentFilter.organisationQueryV2.filterOnLeIds = _.map(vm.selectOrganisationsForLeIdsFilter.selected, 'id');
                vm.showFilterLEIds = true;
                onFormChange();
                modalReloadOrganisationsForLeIdFilter();
            }
        }

        function modalReloadOrganisationsForLeIdFilter(){
            if( vm.segmentFilter.organisationQueryV2.filterOnLeIds && vm.segmentFilter.organisationQueryV2.filterOnLeIds.length >0 ) {
                getOrganisations().then(function (data) {
                    vm.selectOrganisationsForLeIdsFilter = data;
                    vm.selectOrganisationsForLeIdsFilter.selected = angular.copy(vm.selectOrganisationsForLeIdsFilter);
                });
            }
        }

        function getOrganisations(){
            if( vm.segmentFilter.organisationQueryV2.filterOnLeIds && vm.segmentFilter.organisationQueryV2.filterOnLeIds.length >0 ){
                var config = {
                    hideLoadingIndicator: true,
                    dontFailOnError: true
                };
                return OrganisationService.getOrganisations(vm.segmentFilter.organisationQueryV2.filterOnLeIds, config);
            } else {
                return [];
            }
        }

        // Search field
        function getNoOfOrganisationsUsingQueryField() {
            SegmentFilterService.getNoOfOrganisationsUsingTransientWithSegmentFilter(vm.account.id,
                vm.queryFieldSegmentFilter.organisationQueryV2)
                .then( function(noOfOrganisations){
                    vm.queryFieldNoOfOrganisations = noOfOrganisations;
                } );
        }

        function getOrganisationsUsingQueryField(){
            SegmentFilterService.getOrganisationsUsingTransientWithSegmentFilter(vm.account.id,vm.queryFieldSegmentFilter.organisationQueryV2)
                .then( function(organisations){
                    vm.queryFieldNoOfOrganisations = organisations.length;
                    vm.organisations = organisations;
                    vm.organisations.selected = null;
                    $document.scrollToElementAnimated(angular.element(document.getElementById('filter-result')), -325);
                } );
        }


        function onOrganisationClickRequestEnhancement(organisation){
            EnhancedOrganisationService.createHighPrioEnhancementRequests( organisation.id);
            logger.info('Request for Enhancement has been created');

        }
    }
})();