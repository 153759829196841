(function() {
	'use strict';

	angular
		.module('adminApp')
		.controller('DashboardController', [
			'account',
			'user',
			'startDate',
			'endDate',
			'filterId',
			'limit',
			'DashboardService',
			'VisitService',
			'VisitFilterService',
			'$q',
			'$state',
			'CONFIG',
			'VisitsMapChart',
			'VisitsPieChart',
			'VisitsAreaChart',
			'IndustriesPieChart',
			'OrganisationSizeBarChart',
			'TopScoreOrganisationBarChart',
			'UniqueVisitsFunnelChart',
			'TopCountriesBarChart',
			'TopReferrerPieChart',
			'$timeout',
			'$scope',
			'logger',
			'STATE',
			'TargetingDashboardService',
			DashboardController
		]);

	function DashboardController(
		account,
		user,
		startDate,
		endDate,
		filterId,
		limit,
		DashboardService,
		VisitService,
		VisitFilterService,
		$q,
		$state,
		CONFIG,
		VisitsMapChart,
		VisitsPieChart,
		VisitsAreaChart,
		IndustriesPieChart,
		OrganisationSizeBarChart,
		TopScoreOrganisationBarChart,
		UniqueVisitsFunnelChart,
		TopCountriesBarChart,
		TopReferrerPieChart,
		$timeout,
		$scope,
		logger,
		STATE,
		TargetingDashboardService
	) {
		var vm = this;
		// this is resolved from ui-router
		vm.account = account;
		vm.user = user;
		vm.selectedFilterId = filterId;
		vm.limit = limit;
		vm.tableVisitsMax = 5;
		vm.visitsResults = [];
		vm.visitSummary = {
			numberOfVisits: 0,
			numberOfCountries: 0,
			numberOfVisitsPerDay: 0,
			numberOfIndustries: 0,
			topIndustries: [],
			topReferrer: []
		};

		vm.targetingSummary = null;
		vm.showTargetingSummary = false;
		vm.showVisitsSummary = false;
		// method declarations
		vm.activate = activate;
		// reload can be usin a choosen filter combined with dates, or no filter (a default will be created) with dates
		vm.onReloadVisits = onReloadVisits;
		vm.visitsGroupedByTopScore = null;

		vm.start = startDate
			? moment(startDate, 'YYYY-MM-DD').toDate()
			: moment()
					.subtract(7, 'days')
					.toDate();
		vm.end = endDate
			? moment(endDate, 'YYYY-MM-DD').toDate()
			: moment()
					.add(1, 'days')
					.toDate();

		// reference data
		vm.refFilters = [];
		vm.datePickerOptions = { formatYear: 'yy', startingDay: 1 };

		// charts
		vm.visitsMapChart = null;
		vm.visitsPieChart = null;
		vm.visitsAreaChart = null;
		vm.industriesPieChart = null;
		vm.organisationSizeBarChart = null;
		vm.topScoreOrganisationBarChart = null;
		vm.uniqueVisitsFunnelChart = null;
		vm.topCountriesBarChart = null;
		vm.topReferrerPieChart = null;

		// widget order
		vm.dashboardSettings = null;
		vm.onVisitClick = onVisitClick;
		vm.refreshVisits = refreshVisits;
		vm.onWidgetOrderChange = onWidgetOrderChange;
		vm.onWidgetOrderReset = onWidgetOrderReset;
		vm.onWidgetFullscreenClick = onWidgetFullscreenClick;
		vm.format = format;




		////////////
		//  Init
		activate();

		function activate() {
			if (!vm.limit) {
				vm.limit = 200;
			}

			getInitialData().then(showInitialView);
		}

		function getInitialData() {
			return $q.all([getVisits(), getFilters(), getDashboardSettings(), getTargetingSummary()]);
		}

		function getVisits() {
			return VisitService.findVisits(
				vm.account.id,
				vm.account.defaultSiteId,
				moment(vm.start).format('YYYY-MM-DD'),
				moment(vm.end).format('YYYY-MM-DD'),
				vm.selectedFilterId,
				vm.limit
			);
		}

		function getFilters() {
			return VisitFilterService.getVisitFiltersBasic(vm.account.id);
		}

		function getDashboardSettings() {
			return DashboardService.getDashboardSettings(vm.user.id);
		}

		function getTargetingSummary() {
			return TargetingDashboardService.getTargetingSummary(vm.account.id);
		}

		// use the stuff when promises are finished
		function showInitialView(result) {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
			vm.visitsResults = result[0];
			vm.refFilters = result[1];
			vm.dashboardSettings = result[2];
			vm.targetingSummary = result[3];

			if( vm.targetingSummary.audiences.length || vm.targetingSummary.segments.length ){
				vm.showTargetingSummary = true;
			}

			if (vm.visitsResults.visits.length === 0) {
				logger.info(
					'No Visits found for selected time range! Try changing period using Filter settings'
				);
			} else {
				vm.showVisitsSummary = true;
			}

			createVisitsGroupedByTopScore(vm.visitsResults.visits);

			postReloadVisits();
		}

		/// End init
		///////////////

		function onReloadVisits() {
			VisitService.findVisits(
				vm.account.id,
				vm.account.defaultSiteId,
				format(vm.start),
				format(vm.end),
				vm.selectedFilterId,
				vm.limit
			).then(function(visits) {
				vm.visitsResults = visits;
				postReloadVisits();
			});
		}

		function format(date) {
			return moment(date).format('YYYY-MM-DD');
		}

		function postReloadVisits() {
			// Url
			$state.go(
				'admin.dashboard',
				{
					startDate: format(vm.start),
					endDate: format(vm.end),
					filterId: vm.selectedFilterId,
					limit: vm.limit,
				},
				{ notify: false }
			);

			if (vm.visitsResults.visits.length) {
				// Charts
				vm.visitsMapChart = new VisitsMapChart(vm.visitsResults.visits);
				vm.visitsPieChart = new VisitsPieChart(vm.visitsResults.visits);
				vm.visitsAreaChart = new VisitsAreaChart(vm.visitsResults.visits);
				vm.industriesPieChart = new IndustriesPieChart(vm.visitsResults.visits);
				vm.organisationSizeBarChart = new OrganisationSizeBarChart(vm.visitsResults.visits);
				vm.topScoreOrganisationBarChart = new TopScoreOrganisationBarChart(
					vm.visitsResults.visits
				);
				vm.uniqueVisitsFunnelChart = new UniqueVisitsFunnelChart(vm.visitsResults.visits);
				vm.topCountriesBarChart = new TopCountriesBarChart(vm.visitsResults.visits);
				vm.topReferrerPieChart = new TopReferrerPieChart(vm.visitsResults.visits);

				// Summary
				vm.visitSummary.numberOfVisits = vm.visitsResults.visits.length;
				vm.visitSummary.numberOfCountries = vm.visitsMapChart.getNumberOfItems();
				vm.visitSummary.numberOfVisitsPerDay = vm.visitsAreaChart.getNumberOfItems();
				vm.visitSummary.numberOfIndustries = vm.industriesPieChart.getNumberOfItems();

				vm.visitSummary.topIndustries = vm.industriesPieChart.getItems().slice(0, 3);
				vm.visitSummary.topReferrer = vm.topReferrerPieChart.getItems().slice(0, 3);
			}
		}

		function onWidgetOrderChange(widgetOrder) {
			var dashboardSettings = {
				id: null, // null is new...
				userId: vm.user.id,
				filterId: filterId,
				allWidgetSettings: widgetOrder,
			};

			vm.dashboardSettings = dashboardSettings;

			DashboardService.saveDashboardSettings(dashboardSettings.userId, dashboardSettings);
		}

		function onWidgetOrderReset() {
			onWidgetOrderChange(null);
		}

		// This is to force highchart to rerender (reflow) chart to new size
		function onWidgetFullscreenClick() {
			$timeout(function() {
				$scope.$broadcast('highchartsng.reflow');
			}, 10);
		}

		function createVisitsGroupedByTopScore(visits) {
			if (visits) {
				if (visits.length < vm.tableVisitsMax) {
					vm.tableVisitsMax = visits.length;
				}
				vm.visits = visits.slice(0, vm.tableVisitsMax);
			}
		}

		function onVisitClick(visit) {
			$state.go(STATE.VISIT, { accountId: vm.account.id, visitId: visit.id });
		}

		function updateVisits(visits) {
			vm.visitsResults = visits;
			if (vm.visitsResults.visits.length === 0) {
				logger.info(
					'No Visits found for selected time range! Try changing period using Filter settings'
				);
			}
		}

		function refreshVisits() {
			getVisits().then(updateVisits);
		}
	}
})();
