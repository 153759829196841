(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('sticky', ['$timeout', Sticky]);

    function Sticky($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $timeout(function() {
                    element.addClass('fixedsticky');
                    element.fixedsticky();
                });
            }
        };
    }
})();
