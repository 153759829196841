(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('EnhancementQueueService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', EnhancementQueueService]);

    function EnhancementQueueService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findAllPendingForHighPrio = findAllPendingForHighPrio;
        this.findPendingForAllAccountsISP = findPendingForAllAccountsISP;
        this.findAllPendingForAccount = findAllPendingForAccount;
        this.findAllPendingForCountry = findAllPendingForCountryt;
        this.findEnhancedOrFailed = findEnhancedOrFailed;
        this.updateToNonIsps = updateToNonIsps;
        this.updateToIsps = updateToIsps;
        this.getStatisticsForUser = getStatisticsForUser;
        this.getStatisticsForUserName = getStatisticsForUserName;
        this.getStatisticsForAllUsers = getStatisticsForAllUsers;
        this.countPendingForCountry = countPendingForCountry;

        function findAllPendingForCountryt(countryISO, limit) {

            var useCountry = 'SE';
            if (countryISO) {
                useCountry = countryISO;
            }
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }
            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/country/' + useCountry + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function countPendingForCountry(countryISO) {

            var useCountry = 'SE';
            if (countryISO) {
                useCountry = countryISO;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/country/' + useCountry + '/count')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllPendingForHighPrio(limit) {
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/highPrio' + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getStatisticsForUser(limit) {
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/statistics/user' + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function getStatisticsForUserName(userName, checkOrgUpdates, monthsBack) {
            return $http.get(CONFIG.API_PREFIX + '/enhance/statistics/users/' + userName + '/?checkOrgUpdates=' + checkOrgUpdates + '&monthsBack=' + monthsBack)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getStatisticsForAllUsers(limit) {
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/statistics/all' + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findPendingForAllAccountsISP() {
            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/isp')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllPendingForAccount(limit) {
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/account' + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findEnhancedOrFailed(limit) {
            var url = '';
            if (limit) {
                url = '?limit=' + limit;
            }

            return $http.get(CONFIG.API_PREFIX + '/enhance/queue/enhancedOrFailed' + url)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updateToNonIsps(organisationIds) {
            var orgs = {
                comment: 'Manually enhanced',
                organisationIds: organisationIds
            };

            return $http.post(CONFIG.API_PREFIX + '/enhance' + '/markasnoisps', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));

        }

        function updateToIsps(organisationIds) {
            var orgs = {
                comment: 'Manually enhanced',
                organisationIds: organisationIds
            };

            return $http.post(CONFIG.API_PREFIX + '/enhance' + '/markasisps', orgs)
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

    }
})();