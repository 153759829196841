(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('UserStatisticsLogController', ['currentResellerAccount',
			'UserLoginStatisticsLogService', 'UserService', 'AccountService', 'CommonService', 'logger', '$q',
			'$scope', '$state', 'CONFIG', 'TableSort', UserStatisticsLogController]);

	function UserStatisticsLogController(currentResellerAccount, UserLoginStatisticsLogService,
										 UserService, AccountService, CommonService, logger, $q, $scope, $state, CONFIG, TableSort) {
		var vm = this;
		vm.findAll = findAll;
		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.searchUsers = searchUsers;

		vm.startDate = moment().subtract(6, 'months').toDate();
		vm.datePickerOptions = { formatYear: 'yy', startingDay: 1 };
		vm.userstatisticslog = [];
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.queryFor = null;
		vm.sort = new TableSort('adminTable', {
			by: ['country', 'active'],
			reverse: false
		});
		vm.user = null;
		vm.limit = 100;


		////////////
		//  Init
		activate();

		function activate() {
			//getInitialData().then(initView);
		}


		/// End init


		function findAll() {

			if (vm.user && vm.startDate ) {
				UserLoginStatisticsLogService.findAll(vm.user.userName, moment(vm.startDate).format('YYYY-MM-DD')).then(function (data) {
					vm.userstatisticslog = data;
				})
			} else if (vm.startDate) {
				UserLoginStatisticsLogService.findAll(null, moment(vm.startDate).format('YYYY-MM-DD')).then(function (data) {
					vm.userstatisticslog = data;
				})

			}
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()], {
				type: CONFIG.EXPORT_FILE_TYPE,
				encoding: CONFIG.EXPORT_FILE_ENCODING
			});
			var config = {
				data: data,
				filename:
					'userstatisticslog_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function onTableSort() {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.userstatisticslog.length;
		}

		function searchUsers(user) {
			return UserService.searchAllUsers(user);
		}
	}
})();