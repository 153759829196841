(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('CampaignBetaController', ['$scope', '$timeout', '$q', '$state', '$location', 'logger', 'account', 'AccountService', 'CampaignBetaService',  'CampaignBetaConstants', 'accountId', 'campaignId', CampaignController]);

    function CampaignController($scope, $timeout, $q, $state, $location, logger, account, AccountService, CampaignService, CampaignConstants, accountId, campaignId) {

        var vm = this;

        vm.menuItems = CampaignConstants.menuItems;
        vm.saveCampaign = saveCampaign;
        vm.goToCampaigns = goToCampaigns;
        vm.lastSavedCamapign = {};
        vm.account = account;

        initializeData();

        $scope.$watch('vm.campaign.name', onCampaignNameChanged);
        $scope.$on('$stateChangeStart', onStateChangeStart);

        function initializeData() {
            getCampaign().then(function() {
                $timeout(function() {
                    $scope.$broadcast('campaignDataLoaded');
                });
            });
        }

        function onAccept() {
            saveCampaign(true);
        }

        function onReject() {
            $state.go(vm.stateToGo.name, vm.stateToGo.params);
        }

        function getCampaign() {
            if (campaignId) {
                return CampaignService.getCampaign(accountId, campaignId).then(function(campaign) {
                    vm.lastSavedCamapign = _.cloneDeep(campaign);
                    vm.campaign = campaign;
                });
            } else {
                return CampaignService.createOrUpdateCampaign(accountId, {}).then(function(response) {
                    $location.path('/accounts/' + account.id + '/campaigns/' + response.data.id).replace();
                });
            }
        }

        function saveCampaign(moveAfterSave) {
            vm.campaign.name = $('.top-menu__title:first').text();
            if (vm.campaign.name.trim().length === 0) {
                logger.error('Please set the name before saving.');
                return;
            }
            if (vm.campaign.name !== vm.lastSavedCamapign.name) {
                CampaignService.isNameAvailable(vm.campaign.name).then(function() {
                    saveRequest(moveAfterSave);
                }).catch(function() {
                    logger.error('This name already exists.');
                });
            } else {
                saveRequest(moveAfterSave);
            }
        }

        function saveRequest(moveAfterSave) {
            CampaignService.createOrUpdateCampaign(accountId, vm.campaign).then(function() {
                logger.success('The campaign has been saved successfully.');
                if (moveAfterSave) {
                    $state.go(vm.stateToGo.name, vm.stateToGo.params);
                }
            });
        }

        function goToCampaigns() {
            $state.go('admin.campaigns');
        }

        function onCampaignNameChanged(newVal, oldVal) {
            var $titleElement;
            if (!$titleElement || $titleElement.length === 0) {
                $titleElement = $('.top-menu__title:first');
            }
            if ($titleElement && $titleElement.children().length > 0) {
                vm.campaign.name = $titleElement.text();
                $titleElement.html($titleElement.text());
            }
            if (newVal && $titleElement && $titleElement.text().length > 255) {
                logger.warning('You have exceeded the maximum number of 255 characters in this field.');
                vm.campaign.name = oldVal;
            }
        }

        function onStateChangeStart(event, toState, toParams) {
            if (toState.name.indexOf('admin.campaign.') === -1 && toState.name !== 'admin.campaign' ) {
                vm.stateToGo = {
                    name: toState.name,
                    params: toParams
                };

                if (!_.isEqual(angular.toJson(vm.campaign), angular.toJson(vm.lastSavedCamapign))) {
                    event.preventDefault();
                }
            } else if (toState.name.indexOf('admin.campaign') === -1 ) {
                event.preventDefault();
            }
        }

    }

})();