(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('PageKeywordService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', PageKeywordService]);

    function PageKeywordService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getAllPageKeywords = getAllPageKeywords;
        vm.getPageKeyword = getPageKeyword;
        vm.deletePageKeyword = deletePageKeyword;
        vm.updatePageKeyword = updatePageKeyword;

        function getAllPageKeywords(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagekeywords')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getPageKeyword(accountId, id) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagekeywords/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deletePageKeyword(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagekeywords/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updatePageKeyword(accountId, pageKeyword) {
            var promise;
            if (!(_.isNull(pageKeyword.id) || pageKeyword.id === undefined || pageKeyword.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagekeywords/', pageKeyword)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page keyword'));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pagekeywords/', pageKeyword)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page keyword '));
            }
            return promise;
        }
    }
})();