(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('LEOrganisationsBatchUploadController', [ 'LEOrganisationService', 'EnhancerSettingsService', 'CountryService', 'logger',
            '$state', 'FileSaver', 'Blob', 'CONFIG', 'TableSort','FileUploader',
            LEOrganisationsBatchUploadController]);
    function LEOrganisationsBatchUploadController(LEOrganisationService, EnhancerSettingsService, CountryService, logger, $state, FileSaver, Blob, CONFIG, TableSort, FileUploader) {
        var vm = this;

        vm.organisations = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });
        vm.selectedCountries = null;
        vm.refCountries = [];
		vm.organisationBulkMatch = [];
        vm.enhancerSettings = null;
		vm.dryRun = true;
		// when false we set it before calling upload...
		vm.uploadUrl = '/v3/enhance/organisations/updateToIsp' ;


        vm.uploader = new FileUploader({
            url: vm.uploadUrl,
			formData : [ {} ]
        });
        vm.noOfFilesInQueue = noOfFilesInQueue;


        //methods
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        activate();

        function activate() {
        }


        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'organisations_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.organisationBulkMatch.organisationsMatchedAndUpdated.length;
        }

        function noOfFilesInQueue(){
            if( vm.uploader ){
                return vm.uploader.getNotUploadedItems().length;
            }
            return 0;
        }

        ////////
        // FILTERS - https://github.com/nervgh/angular-file-upload
        vm.uploader.filters.push({
            name: 'csvFilter',
            fn: function (item) {
                var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                return '|csv|'.indexOf(type) !== -1;
            }
        });
        // CALLBACKS
        vm.uploader.onWhenAddingFileFailed = function (item, filter, options) {
            //jshint unused:false
            console.log('onWhenAddingFileFailed')
        };
        vm.uploader.onAfterAddingFile = function (fileItem) {
            //jshint unused:false
            logger.success('Added file to upload queue, press upload to proceed' , fileItem);
        };
        vm.uploader.onAfterAddingAll = function (addedFileItems) {
            //jshint unused:false
        };
        vm.uploader.onBeforeUploadItem = function (fileItem) {
            //jshint unused:false
			fileItem.formData.push({ dryRun: vm.dryRun });
			console.log( 'Using url ' , vm.uploader )
        };
        vm.uploader.onProgressItem = function (fileItem, progress) {
            //jshint unused:false
        };
        vm.uploader.onProgressAll = function (progress) {
            //jshint unused:false
        };
        vm.uploader.onSuccessItem = function (fileItem, response, status, headers) {
            //jshint unused:false
			console.log('onSuccessItem' , response);
			vm.organisationBulkMatch = response;
			logger.info('Update complete ') ;
        };
        vm.uploader.onErrorItem = function (fileItem, response, status, headers) {
            //jshint unused:false
			logger.error('Error when handling file : ' + response) ;

        };
        vm.uploader.onCancelItem = function (fileItem, response, status, headers) {
            //jshint unused:false
        };
        vm.uploader.onCompleteItem = function (fileItem, response, status, headers) {
            //jshint unused:false
           // vm.organisationBulkMatch = response.data;
        };
        vm.uploader.onCompleteAll = function (response) {
			console.info('onCompleteAll done', response);
        };
	}
})();