(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('VisitAdvancedFilterSegmentListController', ['$timeout', '$scope', '$q',
			'CommonService', 'OrganisationService',
			'VisitFilterSearchResultsTableModel', 'VisitAdvancedFilterService', 'logger',
			'$uibModal', 'VisitAdvancedFilterConstants', 'account', 'VisitService',
			VisitAdvancedFilterSegmentListController]);

	function VisitAdvancedFilterSegmentListController($timeout, $scope, $q,
													  CommonService,
													  OrganisationService,
													  VisitFilterSearchResultsTableModel,
													  VisitAdvancedFilterService,
													  logger,
													  $uibModal,
													  VisitAdvancedFilterConstants,
													  account,
													  VisitService
	) {
		var vm = this;
		vm.account = account;
		vm.siteID = account.defaultSiteId;
		vm.removeSelectedVisitsFromVisitList = removeSelectedVisitsFromVisitList;
		vm.showLeidsList = showLeidsList;
		// holds organisations selected to be removed to segment list
		vm.selectedVisitsInTable = {list: []};

		vm.parent = $scope.vm;

		if (vm.parent.visitFilter){
			vm.visitFilter = vm.parent.visitFilter;
			$scope.visitListTable = {
				angularCompileRows: true,
				getFn: function () {
					// fired when jumping into table pressing search
					return VisitService.findVisitsByIds(vm.account.id, vm.siteID,  vm.visitFilter.manuallyAddedLEOrganisationIds)
						.then(function(visits){
							return visits;
						})
				}.bind(this),
				columnDefs: VisitFilterSearchResultsTableModel.columnDefs,
				pagination: true
			};
		}

		/*
		does not work since we nid angular compile
		if (vm.parent.visitFilter) {
			vm.visitFilter = vm.parent.visitFilter;
			console.log('vm.visitFilter.manuallyAddedLEOrganisationIds)', vm.visitFilter.manuallyAddedLEOrganisationIds)
			$scope.visitListTable = {
				getFn: function () {
					return VisitService.findVisitsByIds(vm.account.id, vm.siteID,  vm.visitFilter.manuallyAddedLEOrganisationIds)
						.then(function(visits){
							return visits;
						})
				},
				columnDefs: VisitFilterSearchResultsTableModel.columnDefs
			};
		}*/

		$timeout(function () {
			$scope.panelHeights = 171 + 36;
			$('.segmentlist')
				.css('height', $(document).height() - $scope.panelHeights);
		});

		function removeSelectedVisitsFromVisitList() {
			var selectedIdsInTable = _.map(vm.selectedVisitsInTable.list, 'data.id');
			vm.visitFilter.manuallyAddedLEOrganisationIds = _.difference(vm.visitFilter.manuallyAddedLEOrganisationIds, selectedIdsInTable);
			$scope.$broadcast('updateTable');

			$scope.$emit(VisitAdvancedFilterConstants.eventDeletedVisitFromVisitList, '');
			logger.info('Removed selected Visits from Visit list')
		}

		function showLeidsList() {
			vm.visitFilter.manuallyAddedLEOrganisationIdsCommaSeperated = vm.visitFilter.manuallyAddedLEOrganisationIds.join(",");
			$uibModal.open({
				templateUrl: 'partials/targeting/segments/advanced/segmentfilter-segment-list-allleids-modal.html',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
				vm.visitFilter.manuallyAddedLEOrganisationIdsCommaSeperated = null;
			});
		}
	}
})();