(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('dropdownSelectInput', [DropdownSelectInput]);


    function DropdownSelectInput() {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                options: '=',
                value: '=?'
            },
            templateUrl: 'partials/common/dropdown-select-input.html',
            link: function (scope) {
                scope.show = false;
                if(_.isUndefined(scope.value)) {
                    scope.value = _.first(scope.options).value;
                }
                scope.selected = _.find(scope.options, {value: scope.value}).label;
                scope.select = function (option) {
                    scope.value = option.value;
                    scope.selected = option.label;
                    scope.show = false;
                };
            }
        };
    }



})();