(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('CustomVariableService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', CustomVariableService]);

    function CustomVariableService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getAllCustomVariables = getAllCustomVariables;
        vm.deleteCustomVariable = deleteCustomVariable;

        function getAllCustomVariables(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/customvariables')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCustomVariable(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/customvariables/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();