(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('leTableSort', [TableSortDirective]);

    function TableSortDirective() {
        return {
            restrict: 'A',
            transclude: true,
            template: '<a href ng-click="onClick()">' +
            '<span ng-transclude></span>' +
            ' <i class="fa" ng-class="{\'fa-caret-up\' : order === by && !reverse,  \'fa-caret-down\' : order===by && reverse}"></i>' +
            '</a>',
            scope: {
                order: '=',
                by: '=',
                reverse: '=',
                onSortClick: '='
            },
            link: function (scope) {
                scope.onClick = function () {
                    if (scope.order === scope.by) {
                        scope.reverse = !scope.reverse;
                    } else {
                        scope.by = scope.order;
                        scope.reverse = false;
                    }

                    if (scope.onSortClick) {
                        scope.onSortClick(scope.by);
                    }
                };
            }
        };
    }

})();