(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitAdvancedFilterConverter', ['responseExceptionCatcher', 'VisitAdvancedFilterConstants', 'filterModel', VisitAdvancedFilterConverter]);

    function VisitAdvancedFilterConverter(responseExceptionCatcher, VisitAdvancedFilterConstants, filterModel) {
        this.responseExceptionCatcher = responseExceptionCatcher;
        this.filterModel = filterModel;
        this.visitfilterConstants = VisitAdvancedFilterConstants;
    }

	VisitAdvancedFilterConverter.prototype.mapFromBackend = function(visitAdvancedFilter) {
        var result = {};

        try {
            result = {
                id: visitAdvancedFilter.id || undefined,
                accountId: visitAdvancedFilter.accountId,
                name: visitAdvancedFilter.name || '',
				active : visitAdvancedFilter.active ,
                created: visitAdvancedFilter.created || (new Date()).valueOf(),
                modified: visitAdvancedFilter.modified || (new Date()).valueOf(),
				visitsSizeFromQuery: visitAdvancedFilter.visitsSizeFromQuery || 0,
				totalVisitsSize: visitAdvancedFilter.totalVisitsSize || 0,
				manuallyAddedLEOrganisationIds: visitAdvancedFilter.manuallyAddedLEOrganisationIds || [],
                siteId: visitAdvancedFilter.siteId || '',
                userId: visitAdvancedFilter.userId || undefined,
                userName: visitAdvancedFilter.userName || '',
                version: visitAdvancedFilter.version || '2',
                type: visitAdvancedFilter.type || 'STATIC',
				global: visitAdvancedFilter.global,
				useForMultiUpdates : visitAdvancedFilter.useForMultiUpdates
            };
            if( visitAdvancedFilter.ruleGroupQuery ){
				result.filters = this.filterModel.deserialize(JSON.stringify(this.convertRuleGroupFromAPI(visitAdvancedFilter.ruleGroupQuery)));
			} else {
            	// no rules
				result.filters = new this.filterModel.FilterItem(null);
			}
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }
        return result;
    };

	// when loading existing filter from backend
	VisitAdvancedFilterConverter.prototype.convertRuleGroupFromAPI = function(ruleGroupBackend) {
		var ruleGroupUI = {};
		try {
			ruleGroupUI = {
				id: this.filterModel.generateUUID(),
				jointNext: ruleGroupBackend.ruleGroup.condition,
				parent: null,
				items: []
			};
			var i = 0;
			for (i = 0; i < ruleGroupBackend.ruleGroup.rules.length; i++) {
				ruleGroupUI.items.push(this.convertRuleFromAPI(ruleGroupBackend.ruleGroup.rules[i], ruleGroupBackend.ruleGroup.condition, ruleGroupUI.id));
			}

			for (i = 0; i < ruleGroupBackend.ruleGroup.ruleGroups.length; i++) {
				var tempRuleGroup = {
					id: this.filterModel.generateUUID(),
					jointNext: ruleGroupUI.condition,
					parent: ruleGroupUI.id,
					items: []
				};
				for (var j = 0; j < ruleGroupBackend.ruleGroup.ruleGroups[i].rules.length; j++) {
					tempRuleGroup.items.push(this.convertRuleFromAPI(ruleGroupBackend.ruleGroup.ruleGroups[i].rules[j], ruleGroupBackend.ruleGroup.ruleGroups[i].condition, tempRuleGroup.id));
				}
				ruleGroupUI.items.push(tempRuleGroup);
			}
		} catch(e) {
			console.error(e);
			this.responseExceptionCatcher.catch('Error when converting Rules for Visit Filter');
		}
		return ruleGroupUI;
	};


	// convert from api
	VisitAdvancedFilterConverter.prototype.convertRuleFromAPI = function(filter, condition, parentId) {
		return {
			id: filter.id,
			jointNext: condition,
			data: {
				id: this.filterModel.generateUUID(),
				name: _.invert(this.visitfilterConstants.filterFields)[filter.field],
				included: true,
				value: filter.value,
				operand: _.invert(this.visitfilterConstants.filterOperators)[filter.operator],
				candidate: false
			},
			parent: parentId,
			items: []
		};
	};

	// used from visitfilter service
	VisitAdvancedFilterConverter.prototype.convertVisitFilterToSave = function(visitFilterUI, ruleGroupQueryUI) {
        var result = {};
        try {
            result = {
                id: visitFilterUI.id || undefined,
                accountId: visitFilterUI.accountId,
                name: visitFilterUI.name || '',
				active : visitFilterUI.active,
                created: visitFilterUI.created || (new Date()).valueOf(),
                modified: visitFilterUI.modified || (new Date()).valueOf(),
				manuallyAddedLEOrganisationIds: visitFilterUI.manuallyAddedLEOrganisationIds || [],
                siteId: visitFilterUI.siteId || '',
                userId: visitFilterUI.userId || undefined,
                userName: visitFilterUI.userName || '',
                version: visitFilterUI.version || '2',
                type: visitFilterUI.type || 'STATIC',
                global: visitFilterUI.global,
				useForMultiUpdates : visitFilterUI.useForMultiUpdates

            };
            result.ruleGroupQuery = ruleGroupQueryUI? this.convertFiltersToSave(JSON.parse(ruleGroupQueryUI)): {ruleGroup: {}};
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }

        return result;
    };

    VisitAdvancedFilterConverter.prototype.convertFiltersToSave = function(filters) {
        var result = {};
        try {
            result = {
                scoreThreshold: null,
                limit: 10000,
                page: null,
                pageSize: null,
                ruleGroup: {
                    condition: filters.jointNext,
                    rules: [],
                    ruleGroups: []
                }
            };

            for (var i = 0; i < filters.items.length; i++) {
                if (filters.items[i].items.length > 0) {
                    var tempRuleGroup = {
                        condition: filters.items[i].items[0].jointNext,
                        rules: []
                    };
                    for (var j = 0; j < filters.items[i].items.length; j++) {
                        if (!filters.items[i].items[j].data.included) {
                            continue;
                        }
                        tempRuleGroup.rules.push(this.convertOneFilterToSave(filters.items[i].items[j]));
                    }
                    result.ruleGroup.ruleGroups.push(tempRuleGroup);
                } else {
                    if (!filters.items[i].data.included) {
                        continue;
                    }
                    result.ruleGroup.rules.push(this.convertOneFilterToSave(filters.items[i]));
                }
            }
        } catch(e) {
            console.error(e);
            this.responseExceptionCatcher.catch('Error when converting');
        }
        return result;
    };

    VisitAdvancedFilterConverter.prototype.convertOneFilterToSave = function(filter) {
		var field = this.visitfilterConstants.filterFields[filter.data.name];
        var type = this.visitfilterConstants.filterTypes(filter.data.operand, field);
        var operator = this.visitfilterConstants.filterOperators[filter.data.operand];
		var value = filter.data.value;
        if( (operator === 'in' || operator === 'notIn')  && field === this.visitfilterConstants.filterFields.Country  ){
        	// convert list of objects to iso code (= id)
			value = _.map(filter.data.value, 'id');
		} else if( (operator === 'in' || operator === 'notIn')  && field === this.visitfilterConstants.filterFields["Industry tags"] ){
			value = _.map(filter.data.value, 'name');

		} else if(( type !== 'integerType'  &&  (operator === 'contains' || operator === 'beginsWith') )) {
			// when we have a list of strings we will do a OR based on array of items
			// indu codes are number so excluded
			if ( filter.data.value.indexOf(',') > -1) {
				value = filter.data.value.split(',');
			}
		}else if( (operator === 'in' || operator === 'notIn')  && field === this.visitfilterConstants.filterFields["Organisation types"] ){
        	// convert list of objects to iso code (= id)
			value = _.map(filter.data.value, 'id');
		}else if( (operator === 'in' || operator === 'notIn')  && field === this.visitfilterConstants.filterFields["Page Keywords"] ){
        	// convert list of objects to keywords
			value = _.map(filter.data.value, 'keyWord');
		} else if( field === this.visitfilterConstants.filterFields.ISP ){
        	// always boolean
			value = filter.data.value;
		} else if( field === this.visitfilterConstants.filterFields.First ){
        	// always boolean
			value = filter.data.value;
		} else {
			value = filter.data.value? filter.data.value: type === 'integerType'? 0: '';
		}

        var convertdFilter = {
            id: filter.id,
            field: field,
            type: type,
            operator: operator,
            value: value
        };
        console.log('convertdFilter', convertdFilter)
		return convertdFilter;
	};
})();