(function() {
    'use strict';

    angular
        .module('adminApp')
        .directive('paymentPopup',function(){
            return {
                restrict: 'E',
                templateUrl: 'partials/targeting/beta/campaigns/common/payment-popup.html',
                scope: {
                    visible: '=',
                    accountId: '=',
                    campaignId: '=',
                    message: '=?'
                },
                controller: ['$scope', '$element', '$timeout', 'CampaignBetaConstants', 'CampaignBetaService', 'logger', paymentPopupController],
                controllerAs: 'vm'
            };
        });

    function paymentPopupController(scope, element, timeout, CampaignConstants, CampaignService, logger) {
        var vm = this;
        vm.logger = logger;
        vm.moneyType = { type: 'number' };
        vm.close = close;
        vm.submitForm = submitForm;
        vm.campaignConstants = CampaignConstants;

        if (scope.message) {
            vm.state = 'result';
        } else {
            vm.state = 'card';
        }

        vm.payment = {
            'payment_ref': scope.accountId + Date.now(),
            'customer_ref': scope.accountId,
            amount: '',
            currency: 'SEK',
            test: 'test',   //Remove test parameter in production
            'success_url': 'http://' + location.host + '/accounts/' + scope.accountId + '/campaignsbeta/' + scope.campaignId + '/settings',
            'error_url': 'http://' + location.host + '/accounts/' + scope.accountId + '/campaignsbeta/' + scope.campaignId + '/settings'
        };

        function close() {
            scope.message = null;
            scope.visible = false;
            vm.state = 'card';
        }

        function submitForm() {
            if (vm.payment.amount <= 0) {
                vm.logger.warning('Please fill amount value to pay');
                return;
            }

            vm.payment.amount = Number(vm.payment.amount).toFixed(2);
            CampaignService.createPayment(vm.payment)
                .then(function(payment) {
                    vm.payment = payment;
                    timeout(function() {
                        element.find('form').trigger('submit');
                    });
                });
        }
    }

})();