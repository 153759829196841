(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('dropdownSelect', ['$timeout', DropdownSelect]);


    function DropdownSelect($timeout) {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                options: '=',
                value: '=?'
            },
            templateUrl: 'partials/common/advancedfilters/inputs/dropdown-select.html',
            link: function (scope, elem) {
                scope.show = false;
                //FIX: decide api
                scope.select = function (option) {
                    scope.value = option;
                    scope.show = false;
                };

                scope.hide = function() {
                    $timeout(function() {
                        if (scope.show) {
                            scope.show = false;
                            scope.$emit('dropdownClosed');
                        }
                    });
                };

                scope.toggleState = function() {
                    scope.show=!scope.show;
                    $timeout(function() {
                        if (scope.show) {
                            scope.$emit('dropdownOpened', $(elem).closest('.filter-block__dropdowns-container')[0]);
                        } else {
                            scope.$emit('dropdownClosed');
                        }
                    });
                };
            }
        };
    }



})();