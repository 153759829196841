(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('PageService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', PageService]);

    function PageService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getPages = getPages;
        vm.searchPages = searchPages;
        vm.findPages = findPages;
        vm.deletePage = deletePage;
        vm.getPage = getPage;
        vm.updatePage = updatePage;
        vm.findAndUpdate = findAndUpdate;
        vm.findAndDelete = findAndDelete;
        vm.onFilterAndApplyPages = onFilterAndApplyPages;
        vm.onDeleteUsingPageQuery = onDeleteUsingPageQuery;
        vm.onFilterPages = onFilterPages;

        function getPages(accountId, limit) {
            var useLimit = 100;
            if( limit !== undefined){
                useLimit = limit;
            }
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages?limit=' + useLimit )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

		function searchPages(accountId, ids) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/search', ids)
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function findPages(accountId,  name) {
        	if( name === '' || accountId === '' ){
        		return;
			}
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages?limit=50&name' + name )
				.then(function (response) {
					return response.data.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

        function deletePage(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getPage(accountId, id) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function updatePage(accountId, page) {
            var promise;
            if (!(_.isNull(page.id) || page.id === undefined || page.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/', page)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/', page)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving page '));
            }
            return promise;
        }

        function findAndUpdate(accountId, page) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/publish/' + page.id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data '));
        }

        function findAndDelete(accountId, page) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/publish/' + page.id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data '));
        }

        function onFilterAndApplyPages(accountId, pageFilterAndApply) {
            var promise;
            if (!(_.isNull(pageFilterAndApply) || pageFilterAndApply === undefined )) {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/filterandapply', pageFilterAndApply)
                    .then(function (response) {
                        //logger.info('Updated ' + response.data.header.totalCount + ' no of Pages');
                        return response.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when applying filter and settings on pages'));
            }
            return promise;
        }

      function onDeleteUsingPageQuery(accountId, pageFilterAndApply) {
            var promise;
            if (!(_.isNull(pageFilterAndApply) || pageFilterAndApply === undefined )) {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/filteranddelete', pageFilterAndApply)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when applying filter and settings on pages'));
            }
            return promise;
        }

        // just query for pages
        function onFilterPages(accountId, pageFilterAndApply) {
           return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/pages/filter', pageFilterAndApply)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when applying filer on pages '));
        }
    }
})();