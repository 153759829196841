(function() {
	'use strict';

	angular.module('adminApp').service('UniqueVisitsFunnelChart', [UniqueVisitsFunnelChart]);

	function UniqueVisitsFunnelChart() {
		return function(visits) {
			var that = this;

			this.config = {
				options: {
					chart: {
						type: 'funnel',
						renderTo: 'container',
						marginRight: 120,
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 20,
						spacingTop: 20,
						spacingLeft: 20,
						spacingRight: 20,
					},
					exporting: {
						enabled: false,
					},
					plotOptions: {
						series: {
							animation: {
								duration: 400,
							},
						},
					},
					tooltip: {
						formatter: function() {
							return '<b>' + this.point.name + '</b>: ' + this.y + ' visits';
						},
					},
				},
				title: {
					text: '',
				},
				series: [
					{
						data: [],
						size: '100%',
						showInLegend: false,
						dataLabels: {
							enabled: true,
							format: '<b>{point.name}</b> ({point.y:,.0f})',
							color:
								(Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
							softConnector: true,
						},
					},
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
			};

			initChart(visits);

			function initChart(visits) {
				var totalNumberOfVisits = visits.length;
				var totalUniqueVisits = uniqueVisits(visits).length;
				var totalKnownVisits = knownVisits(visits).length;

				var funnelData = [];
				funnelData.push(['Total visits', totalNumberOfVisits]);
				funnelData.push(['Known visits', totalKnownVisits]);
				funnelData.push(['Unique visits', totalUniqueVisits]);

				that.config.setData(funnelData);
			}

			function knownVisits(visits) {
				return _.filter(visits, function(visit) {
					return visit.organisation.duns;
				});
			}

			function uniqueVisits(visits) {
				return _.uniq(visits, function(visit) {
					return visit.organisation.duns;
				});
			}
		};
	}
})();
