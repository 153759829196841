(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('LEOrganisationsController', [ 'account', 'user', 'segmentId',  'query', 'LEOrganisationService', 'EnhancerSettingsService', 'SegmentAdvancedFilterService', 'CountryService', 'logger',
            '$state','$q', 'FileSaver', 'Blob', 'CONFIG', 'TableSort',
            'QueryBuilder', 'RuleGroup', 'Rule', 'RULE_FIELDS',
            LEOrganisationsController]);
    function LEOrganisationsController(account, user, segmentId, query, LEOrganisationService, EnhancerSettingsService, SegmentAdvancedFilterService, CountryService, logger, $state, $q, FileSaver, Blob, CONFIG, TableSort,
                                                   QueryBuilder, RuleGroup, Rule, RULE_FIELDS) {
        var vm = this;

        vm.account = account;
        vm.organisations = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.queryFor = query;
        vm.selectedDate = null;
        vm.sort = new TableSort('adminTable', {
            by: ['country', 'active'],
            reverse: false
        });
        vm.selectedCountries = null;
        vm.refCountries = [];
        vm.enhancerSettings = null;
        vm.selectedSegmentFilterId = null;
        vm.enhanceAllFromThisOrganisation = null;

        //methods
        vm.checkAll = checkAll;
        vm.deCheckAll = deCheckAll;
        vm.loadMore = loadMore;
        vm.autoCompleteCountries = autoCompleteCountries;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.updateToNonIsps = updateToNonIsps;
        vm.updateToIsps = updateToIsps;
        vm.saveEnhancerSettings = saveEnhancerSettings;
        vm.saveAsSegmentFilter = saveAsSegmentFilter;

        activate();


        ///////////
        // Query Builder
        vm.searchQuick = searchQuick;
        vm.onSearchWithQuery = onSearchWithQuery;
        vm.onSearchWithQueryCount = onSearchWithQueryCount;
        vm.isQueryValid = isQueryValid;
        vm.isQueryValidForSave = isQueryValidForSave;
        vm.addRuleGroup = addRuleGroup;
        vm.addRule = addRule;
        vm.changeLimitOnQuery = changeLimitOnQuery;
        vm.removeRule = removeRule;
        vm.removeRuleGroup = removeRuleGroup;
        vm.removeCondition = removeCondition;
        vm.changeConditionToAnd = changeConditionToAnd;
        vm.changeConditionToOr = changeConditionToOr;
        vm.onSearchWithSelectedSegmentFilter = onSearchWithSelectedSegmentFilter;
        vm.onClickSetMasterOrganisationToUseForUpdating = onClickSetMasterOrganisationToUseForUpdating;
        vm.enhanceFromMarkedOrganisation = enhanceFromMarkedOrganisation;
        vm.enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress = enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress;

        vm.segmen2Filters = [];
        vm.showFinalQueryForDebug = false;
        vm.limit = 1000;
        vm.queryBuilder = new QueryBuilder(vm.limit);
        vm.refOperators = [
            {name: 'Text match', operator: 'textMatch'},
            {name: 'Equal', operator: 'equal'},
            {name: 'Begins with', operator: 'beginsWith'},
            {name: 'Contains', operator: 'contains'},
            {name: 'Between', operator: 'between'}
        ];

        vm.refRuleFields = [
            RULE_FIELDS.properties[RULE_FIELDS.organisationsName],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsISP],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsEnhancementScore],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsAddressCity],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsAddressPostalcode],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsAddressRegion],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsAddressCountry],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsDUNS],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsOrganisationId],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsSic],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsSales],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsSniNace],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsEmployees],
            RULE_FIELDS.properties[RULE_FIELDS.organisationsNoOfNetworks]
        ];

        vm.refBoolOperators = [
            {name: 'And', type: 'And'},
            {name: 'Or', type: 'Or'},
            {name: 'Not', type: 'Not'}
        ];

        // query field
        vm.segmentFilter = {
            name : null,
            accountId: vm.account.id,
            userId : user.id,
            version : '1'
        };

        updateFinalQuery();
        ////////


        function activate() {
            getOrCreateEnhancerSettings();
            getInitialData().then(initView);
        }

        /// End init
        function getInitialData() {
            return $q.all([getSegment2Filters()]);
        }

        function initView(result) {
            vm.segmen2Filters = result[0];
        }

        function onFinishedSearch(orgs) {
            vm.organisations = orgs;
            //$state.go('admin.enhanceLEOrganisations', {query: vm.queryFor}, {notify: false});
            logger.info('Found ' + vm.organisations.length + ' Organisations');
        }

         function onFinishedCount(hits) {
            logger.info('Total hits ' + hits + ' Organisations matching. Of which we only fetched ' + vm.organisations.length + ' for performance reasons ');
            vm.totalHits = hits;
        }

        function onClickSetMasterOrganisationToUseForUpdating(organisation){
            vm.enhanceAllFromThisOrganisation = organisation;
            logger.info('Selected ' + organisation.name + ' ' + organisation.id + ' as master for updating!!!');

        }

        function checkAll() {
            vm.organisations.selected = angular.copy(vm.organisations);
        }

        function deCheckAll() {
            vm.organisations.selected = null;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'organisations_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort() {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.organisations.length;
        }

        function autoCompleteCountries(query) {
            return _.filter(CountryService.getAllCountries(), function (country) {
                return country.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
            });
        }

        function updateToIsps() {
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                var ids = _.map(vm.organisations.selected, 'id');
                LEOrganisationService.updateToIsps(ids).then(updateFinished);
            }
        }

        function updateToNonIsps() {
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                var ids = _.map(vm.organisations.selected, 'id');
                LEOrganisationService.updateToNonIsps(ids).then(updateFinished);
            }
        }

        function enhanceFromMarkedOrganisation() {
            if(vm.enhanceAllFromThisOrganisation === null){
                logger.info('Master Organisation needs to be selected!!');
                return;
            }
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                var ids = _.map(vm.organisations.selected, 'id');
                LEOrganisationService.enhanceFromMarkedOrganisation(ids, '', vm.enhanceAllFromThisOrganisation.id).then(function(){
                    logger.info('Updated all Organisations from master Organisation ');
                });
                vm.enhanceAllFromThisOrganisationId = null;

            }
        }

        function enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress() {
            if(vm.enhanceAllFromThisOrganisation === null){
                logger.info('Master Organisation needs to be selected!!');
                return;
            }
            if (vm.organisations.selected || vm.organisations.selected.length > 0) {
                var ids = _.map(vm.organisations.selected, 'id');
                LEOrganisationService.enhanceFromMarkedOrganisationAndTakeOverAllAttributesExcludingAddress(ids, '', vm.enhanceAllFromThisOrganisation.id).then(function(){
                    logger.info('Updated all Organisations from master Organisation ');
                });
                vm.enhanceAllFromThisOrganisationId = null;

            }
        }

        function updateFinished() {
            logger.info('Organisation updated to ISP');
            //onSearch();
        }

        function getOrCreateEnhancerSettings(){
            vm.enhancerSettings = EnhancerSettingsService.getOrCreateEnhancerSettings();
        }

        function getSegment2Filters(){
            return SegmentAdvancedFilterService.getSegments(vm.account.id);
        }

        function saveEnhancerSettings() {
            EnhancerSettingsService.saveEnhancerSettings(vm.enhancerSettings);
        }


        //////
        //////
        //////
        //////
        //////
        ////// Query advanced

        function onSearchWithQuery() {
            LEOrganisationService.searchWithQuery(vm.finalQuery).then(onFinishedSearch).then(onSearchWithQueryCount);
        }

        // name, ip or leid
        function searchQuick() {
            if (!vm.queryFor) {
                return;
            }
            LEOrganisationService.searchQuick(vm.queryFor, null, vm.enhancerSettings.limit).then(onFinishedSearch);
        }

         function onSearchWithQueryCount() {
            LEOrganisationService.onSearchWithQueryCount(vm.finalQuery).then(onFinishedCount);
        }

        function addRuleGroup(currentRuleGroup) {
            vm.queryBuilder.addRuleGroup( currentRuleGroup);
            updateFinalQuery();
        }

        function addRule(currentRuleGroup) {
            vm.queryBuilder.addRule( currentRuleGroup);
            updateFinalQuery();
        }

        function removeRule(currentRuleGroup, currentRule) {
            vm.queryBuilder.removeRule( currentRuleGroup, currentRule);
            updateFinalQuery();
        }

        function removeRuleGroup(currentRuleGroup) {
            vm.queryBuilder.removeRuleGroup( currentRuleGroup);
            updateFinalQuery();
        }

        function changeLimitOnQuery() {
            vm.queryBuilder.changeLimitOnQuery( vm.limit);
            updateFinalQuery();
        }

        function changeConditionToAnd(currentRuleGroup) {
            vm.queryBuilder.changeConditionToAnd(currentRuleGroup);
            updateFinalQuery();
        }

        function changeConditionToOr(currentRuleGroup) {
            vm.queryBuilder.changeConditionToOr(currentRuleGroup);
            updateFinalQuery();
        }

        function isQueryValid() {
            return vm.queryBuilder.isQueryValid();
        }

        function isQueryValidForSave(){
            return vm.queryBuilder.isQueryValid() && vm.segmentFilter.name !== null;
        }

        function removeCondition() {
            if( vm.conditions.length === 1 ){
                logger.info('Query needs to hold at least one condition');
                return;
            }
            var index = vm.conditions.length - 1;
            vm.conditions.splice(index);
            var lastItemOperator = vm.boolOperators.length - 1;
            vm.boolOperators.splice(lastItemOperator);

            //
            vm.query.removeFilter(index);

            updateFinalQuery();
        }

        function updateFinalQuery() {
            vm.finalQuery = vm.queryBuilder.toJSON();
        }

        function saveAsSegmentFilter() {
            vm.segmentFilter.ruleGroupQuery = vm.finalQuery;
            Segment2FilterService.createOrUpdateSegment(vm.account.id, vm.segmentFilter).then(function(){
                logger.success('Segment Filter saved');
                reloadSegmentRules();
            });
        }

        function reloadSegmentRules(){
            Segment2FilterService.getSegments(vm.account.id)().then( function(response){
                vm.segmen2Filters = response;
            } );
        }

        function onSearchWithSelectedSegmentFilter(){
            Segment2FilterService.findOrganisations( vm.account.id, vm.selectedSegmentFilterId, vm.limit ).then(onFinishedSearch);
            Segment2FilterService.getSegment(vm.account.id, vm.selectedSegmentFilterId).then(function(response){
                vm.segmentFilter = response;
            });
        }
    }
})();

