(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('DOBOrganisationController', ['cbid', 'ClearbitOrganisationService', 'CommonService', 'logger', '$q', DOBOrganisationController]);
    function DOBOrganisationController(cbid, ClearbitOrganisationService, CommonService, logger, $q) {
        var vm = this;

        vm.cbid = cbid;
        vm.cbiOrganisation = null;


        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getCBITOrganisation()]);
        }

        function getCBITOrganisation() {
            return ClearbitOrganisationService.getCBITOrganisation(vm.cbid);
        }

        function initView(result) {
            vm.cbiOrganisation = result[0];
        }
    }
})();