(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('tableComponentSettings', [TableComponentSettings]);

    function TableComponentSettings() {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/table-component/table-component-settings.html'
        };
    }
})();

