(function () {
    'use strict';

    angular
        .module('adminApp')
        .animation('.slide-toggle', ['$animateCss', slideToggleAnimation]);

    function slideToggleAnimation($animateCss) {
        return {
            addClass: function (element, className, doneFn) {
                if (className === 'ng-hide') {
                    var animator = $animateCss(element, {
                        to: {'min-height': '0px', opacity: 0}
                    });
                    if (animator) {
                        return animator.start().finally(function () {
                            element[0].style['min-height'] = '';
                            doneFn();
                        });
                    }
                }
                doneFn();
            },
            removeClass: function (element, className, doneFn) {
                if (className === 'ng-hide') {
                    var height = element[0].offsetHeight;
                    var animator = $animateCss(element, {
                        from: {'min-height': '0px', opacity: 0},
                        to: {'min-height': height + 'px', opacity: 1}
                    });
                    if (animator) {
                        return animator.start().finally(doneFn);
                    }
                }
                doneFn();
            }
        };
    }

})();