(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitsAreaChart', ['DateUtils', 'moment', VisitsAreaChart]);

    function VisitsAreaChart(DateUtils, moment) {
        return function (visits) {
            var that = this;

            this.getNumberOfItems = getNumberOfItems;
            this.config = {
                options: {
                    chart: {
                        renderTo: 'container',
                        type: 'area',
                        backgroundColor: 'rgba(255, 255, 255, 0.01)',
                        spacingBottom: 20,
                        spacingTop: 20,
                        spacingLeft: 20,
                        spacingRight: 20
                    },
                    exporting: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            animation: {
                                duration: 400
                            }
                        },
                        area: {
                            fillOpacity: 0.5
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return 'Visits on ' + this.x + ': <b>' + this.y + '</b>';
                        }
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    gridLineWidth: 0,
                    minorGridLineWidth: 0
                },
                series: [{
                    data: []
                }],
                setData: function (data) {
                    that.config.series[0].data = data;
                },
                setXAxis: function (xAxis) {
                    that.config.xAxis.categories = xAxis;
                },
                getData: function () {
                    return that.config.series[0].data;
                }
            };

            initChart(visits);

            function initChart(visits) {
                var visitsPerDay = _.chain(visits)
                    .groupBy(date)
                    .map(toChartItem)
                    .value()
                    .reverse();

                that.config.setData(visitsPerDay);

                var xAxis = _.chain(visits)
                    .groupBy(formattedDate)
                    .map(toXAxis)
                    .value()
                    .reverse();
                that.config.setXAxis(xAxis);
            }

            function date(visit) {
                return DateUtils.normalizeEpoch(visit.visitStartDate);
            }

            function toChartItem(visits, knownOrUnknown) {
                return [knownOrUnknown, visits.length];
            }

            function formattedDate(visit) {
                return moment(DateUtils.normalizeEpoch(visit.visitStartDate)).format('YYYY-MM-DD');
            }

            function toXAxis(numberOfVisits, formattedDate) {
                return formattedDate;
            }

            function getNumberOfItems() {
                return that.config.getData().length;
            }
        };
    }
})();
