(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitAdvancedFilterService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', 'logger', 'VisitAdvancedFilterConverter', VisitAdvancedFilterService]);

    function VisitAdvancedFilterService($http, $q, CONFIG, responseExceptionCatcher, logger, VisitAdvancedFilterConverter) {
        var vm = this;
		vm.searchWithRuleGroup = searchWithRuleGroup;
		vm.getVisitFilter = getVisitFilter;
		vm.getVisitFilters = getVisitFilters;
		vm.findAllForAccountAndUseForMultiUpdates = findAllForAccountAndUseForMultiUpdates;
		vm.cloneVisitAdvancedFilters = cloneVisitAdvancedFilters;
		vm.deleteVisitFilters = deleteVisitFilters;
		vm.createOrUpdateVisitFilter = createOrUpdateVisitFilter;
		vm.renameVisitAdvancedFilterName = renameVisitAdvancedFilterName;
		vm.deleteAdvancedFilter = deleteAdvancedFilter;
		vm.isNameAvailable = isNameAvailable;
		vm.inActivate = inActivate;
        vm.exportVisitFilterOrganisations = exportVisitFilterOrganisations;
        vm.importOrganisations = importOrganisations;
        vm.createVisitFilterFileWithOrganisationss = createVisitFilterFileWithOrganisationss;
        vm.addLeIdsToVisitFilter = addLeIdsToVisitFilter;
        vm.convertOldToNew = convertOldToNew;
        vm.enhanceDomain = enhanceDomain;
        vm.enhanceDomainEnhancementCBITQueue = enhanceDomainEnhancementCBITQueue;
        vm.clone = clone;



        function getVisitFilter(accountId, visitFilterId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + visitFilterId, config)
                .then(transformFromJson)
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getVisitFilters(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function findAllForAccountAndUseForMultiUpdates(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/multiUpdates')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteVisitFilters(accountId, visitAdvancedFilterIds) {
			var deleteThese = {
				leIds: visitAdvancedFilterIds
			};
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/delete', deleteThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function cloneVisitAdvancedFilters(accountId, visitFilterIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/clone', visitFilterIds)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function convertOldToNew(accountId) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/convert')
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateVisitFilter(accountId, visitFilter, filters) {
            var promise;
            var visitFilterToSave = transformVisitFilterToJson(visitFilter);
			visitFilterToSave.accountId = accountId;
			visitFilterToSave = VisitAdvancedFilterConverter.convertVisitFilterToSave(visitFilterToSave, filters);

			console.log('createOrUpdateVisitFilter', visitFilterToSave)

            if (!(_.isNull(visitFilterToSave.id) || visitFilterToSave.id === undefined || visitFilterToSave.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/', visitFilterToSave)
                    .then(transformFromJson)
					.then(function(visitFilter) {
						return visitFilter;
					})
                    .catch(responseExceptionCatcher.catch('Error when saving Visit Filter '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/', visitFilterToSave)
                    .then(transformFromJson)
                    .then(function(visitFilter) {
                        return visitFilter;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving Visit Fiter '));
            }
            return promise;
        }


        function  renameVisitAdvancedFilterName(accountId, visitAdvancedFilterId, visitAdvancedFilterName ){
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + visitAdvancedFilterId + '/rename', visitAdvancedFilterName)
                .then(function () {
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }

        function deleteAdvancedFilter(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + id)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

       function enhanceDomain(accountId, visitFilterIds) {
		   var useThese = {
			   leIds: visitFilterIds
		   };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/createRequestForDomainEnhancment', useThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


       function enhanceDomainEnhancementCBITQueue(accountId, visitFilterIds) {
		   var useThese = {
			   leIds: visitFilterIds
		   };

            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/createRequestForDomainEnhancementCBIT', useThese)
                .then(function (response) {
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function isNameAvailable() {
            return $q.resolve(true);
            // return $http.get('/mock/visitsAdvanced?name=' + name).then(function(response) {
            //     return response.data;
            // });
        }

        function inActivate(accountId, visitFilterIds) {
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/inactivate', visitFilterIds)
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function clone(accountId, visitFilterIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/clone' , visitFilterIds)
                .then(function (response) {
                    if( response.data.errors ){
                        logger.error(response.data.errors[0].description);
                        return null;
                    }
                    return response.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function searchWithRuleGroup(accountId, rules) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/search/rules', rules)
                .then(function(response) {
                    return _.filter(response.data.visits, function(visit) {
                    	//console.log('', visit)
                        return visit;
                    });
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function transformFromJson(response) {
            return VisitAdvancedFilterConverter.mapFromBackend(response.data);
        }



        function transformVisitFilterToJson(visitFilterToSave) {
            // Create a deep clone
            return $.extend(true, {}, visitFilterToSave);
        }


       function exportVisitFilterOrganisations(accountId, visitFilterId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + visitFilterId + '/exportOrganisations',
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function importOrganisations(accountId, visitFilterId, leidsCommaSeperated) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + visitFilterId + '/importOrganisations', leidsCommaSeperated)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createVisitFilterFileWithOrganisationss(accountId, visitFilterId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/organisations/filters/' + visitFilterId + '/export/organisations' ,
                { responseType: 'arraybuffer' })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // used from visits controller
        function addLeIdsToVisitFilter(accountId, visitFilterId, leIds){
            var ids = { leIds : leIds };
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitsAdvanced/' + visitFilterId +  '/add', ids)
                .catch(responseExceptionCatcher.catch('Error when saving Visit Filter '));
        }


    }
})();