(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('PagesController', ['account', 'pageQueryId', 'PageService', 'PageKeywordService', 'PageQueryService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$uibModal', PagesController]);

    function PagesController(account, pageQueryId, PageService, PageKeywordService, PageQueryService, FileSaver, Blob, CONFIG, TableSort, logger, $uibModal) {
        var vm = this;
        vm.deleteItem = deleteItem;
        vm.onFilterPages = onFilterPages;
        vm.onFilterAndApplyPages = onFilterAndApplyPages;
        vm.onPageQuerySave = onPageQuerySave;
        vm.onDeleteUsingPageQuery = onDeleteUsingPageQuery;
        vm.onPageQueryFilterOnChange = onPageQueryFilterOnChange;
        vm.confirmDeletePage = confirmDeletePage;

        vm.tableSize = tableSize;
        vm.getPages = getPages;
        vm.autoCompletePageKeywords = autoCompletePageKeywords;
        vm.doExportToExcel = doExportToExcel;
        vm.isFilterFormValidForSave = isFilterFormValidForSave;
        vm.isFilterFormValid = isFilterFormValid;
        vm.isApplyFormValid = isApplyFormValid;
        vm.onPageQuerySelected = onPageQuerySelected;
        vm.deletePageQuery = deletePageQuery;
        vm.loadMore = loadMore;



        vm.searchAndApplyResult = [];
        vm.datePickerOptionsStartDate = {formatYear: 'yy', startingDay: 1};
        vm.datePickerOptionsStartDate = {
            formatYear: 'yy',
            startingDay: 1
        };
        vm.pageQueryFilterOn = 'pageName';
        vm.pageQueryId = pageQueryId;
        vm.account = account;
        vm.pages = null;
        vm.pageQueries = null;
        vm.ok = null;
        vm.searchFilter = '';
        vm.onTableSort = onTableSort;
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        // used for filter using pagequery
        vm.pageCreatedFrom = moment().subtract(1, 'years').toDate();
        vm.pageCreatedTo = moment().add(1, 'days').toDate();
        vm.selectedPageQueryId = null;

        // holds the filter and the apply settings
        vm.refPageKeywords = null;
        vm.limit = 500;
        vm.pageFilterAndApply = {
            accountId: account.id,
            siteId: account.defaultSiteId,
            reindexVisitsFrom : moment().subtract(1, 'months').toDate(),
            pageFilter: {
                pageName: null,
                pageUrl: null,
                pageKeywords: []
            },
            pageApply: {
                applyPageKeywords: [],
                appendKeywords: false,
                applyPageValue: null,
                applyPageName: null
            }
        };


        activate();

        function activate() {
            getAllPageKeywords();
            getAllPageQueries();
            getPageQuery();

        }

        function getPages() {
            PageService.getPages(vm.account.id, vm.limit)
                .then(function (pages) {
                    vm.pages = pages;
                    limitReached();

                });
        }

        function getPageQuery() {
            if (vm.pageQueryId) {
                PageQueryService.getPageQuery(vm.account.id, vm.pageQueryId)
                    .then(function (pageQuery) {
                        vm.pageFilterAndApply = pageQuery;
                        if( vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageName  ){
                            vm.pageQueryFilterOn = 'pageName';
                        } else if( vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageUrl  ){
                            vm.pageQueryFilterOn = 'pageUrl';
                        } else if( vm.pageFilterAndApply && vm.pageFilterAndApply.pageFilter.pageKeywords  ){
                            vm.pageQueryFilterOn = 'pageKeywords';
                        } else {
                            vm.pageQueryFilterOn = 'pageName';
                        }
                    })
                    .then( onFilterPages );
            } else {
                getPages();
            }
        }

        function getAllPageQueries() {
            PageQueryService.getAllPageQueries(vm.account.id)
                .then(function (pageQueries) {
                    vm.pageQueries = pageQueries;
                });
        }

        function getAllPageKeywords() {
            PageKeywordService.getAllPageKeywords(vm.account.id)
                .then(function (pageKeywords) {
                    vm.refPageKeywords = pageKeywords;
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'Pages_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteItem(accountId, pageId) {
            PageService.deleteCampaign(accountId, pageId)
                .then(function (pages) {
                    vm.pages = pages;
                });
        }

        function isFilterFormValidForSave(){
            var filterNameSet = vm.pageFilterAndApply.name !== undefined && !_.isNull(vm.pageFilterAndApply.name) && !_.isEmpty(vm.pageFilterAndApply.name);
            if( !filterNameSet ){
                return false;
            }
            return isFilterFormValid();
        }

        function isFilterFormValid() {
            if (vm.pageFilterAndApply.pageFilter.pageName !== undefined && !_.isNull(vm.pageFilterAndApply.pageFilter.pageName) && !_.isEmpty(vm.pageFilterAndApply.pageFilter.pageName)) {
                return true;

            }
            if (vm.pageFilterAndApply.pageFilter.pageUrl !== undefined && !_.isNull(vm.pageFilterAndApply.pageFilter.pageUrl) && !_.isEmpty(vm.pageFilterAndApply.pageFilter.pageUrl)) {
                return true;

            }
            if (vm.pageFilterAndApply.pageFilter.pageKeywords !== undefined &&
                vm.pageFilterAndApply.pageFilter.pageKeywords.length > 0) {
                return true;
            }
            return false;
        }

        function isApplyFormValid() {
            if (!isFilterFormValid()) {
                return false;
            }

            if (vm.pages && vm.pages.length === 0) {
                return false;
            }

            if (vm.pageFilterAndApply.pageApply.pageName !== undefined && !_.isNull(vm.pageFilterAndApply.pageApply.pageName) && !_.isEmpty(vm.pageFilterAndApply.pageApply.pageName)) {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageKeywords !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageKeywords.length > 0) {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageValue !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageValue !== null &&
                vm.pageFilterAndApply.pageApply.applyPageValue !== '') {
                return true;
            }

            if (vm.pageFilterAndApply.pageApply.applyPageName !== undefined &&
                vm.pageFilterAndApply.pageApply.applyPageName !== null &&
                vm.pageFilterAndApply.pageApply.applyPageName !== '') {
                return true;
            }


            return false;
        }

        function onFilterPages() {
            PageService.onFilterPages(vm.account.id, vm.pageFilterAndApply)
                .then(function (pages) {
                    vm.pages = pages;
                    if( vm.pages && vm.pages.length >0){
                        vm.searchAndApplyResult = vm.pages;
                        logger.info('Found ' + vm.pages.length + ' of Pages matching Filter criteria!!');
                    } else {
                        logger.error('No Pages found, try altering Filter criterias for query!!');
                    }

                });
        }

        function onFilterAndApplyPages() {
            logger.info('Applying settings on matched pages and updating Visits from ' + moment(vm.pageFilterAndApply.reindexVisitsFrom).format('YYYY-MM-DD'));
            PageService.onFilterAndApplyPages(vm.account.id, vm.pageFilterAndApply)
                .then(function (result) {
                    vm.pages = result.data;
                    vm.searchAndApplyResult = vm.pages;
                    if( vm.pages && vm.pages.length >0){
                        logger.info('Applied settings on ' + result.header.totalCount + ' of Pages matching Filter criteria!!');
                    } else {
                        logger.error('No Pages changed, try altering Filter criterias for query!!');
                    }
                });
        }


        function autoCompletePageKeywords($query) {
            return _.filter(vm.refPageKeywords, function (item) {
                return item.keyWord.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.pages) {
                return vm.pages.length;
            }
        }

        function limitReached() {
            if (vm.pages.length === vm.limit) {
                logger.info('Number of Pages has been limited to ' + vm.limit + ' for performance');
            }
        }

        function onPageQuerySave() {
            PageQueryService.updatePageQuery(vm.account.id, vm.pageFilterAndApply)
                .then(function (pageFilterAndApply) {
                    vm.pageFilterAndApply = pageFilterAndApply;
                    logger.success('Page Query saved!!!');
                });
        }

        function onDeleteUsingPageQuery() {
            PageService.onDeleteUsingPageQuery(vm.account.id, vm.pageFilterAndApply)
                .then(function () {
                    logger.success('Deleted Pages!!!');
                    vm.pages = [];
                    vm.searchAndApplyResult = [];
                });
        }

       function onPageQueryFilterOnChange() {
           vm.pageFilterAndApply.pageFilter.pageName = null;
           vm.pageFilterAndApply.pageFilter.pageUrl = null;
           vm.pageFilterAndApply.pageFilter.pageKeywords = [];
        }

        function onPageQuerySelected() {
            // todo
            if (!vm.selectedPageQueryId) {
                return;
            }
            PageQueryService.getPageQuery(vm.account.id, vm.selectedPageQueryId)
                .then(function (pageQuery) {
                    vm.pageFilterAndApply = pageQuery;
                });
        }

        function deletePageQuery(pageQueryId) {
            PageQueryService.deletePageQuery(vm.account.id, pageQueryId)
                .then(function (pageQueries) {
                    vm.pageQueries = pageQueries;
                    logger.info('Page Query was deleted!!');
                });
        }

        function confirmDeletePage(itemId, name) {
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + name + ' Page?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deletePage(itemId);
            });
        }


        function deletePage(pageId){
            PageService.deletePage(vm.account.id, pageId) .then(function () {
                logger.info('Page was deleted!!');

                _.remove(vm.pages, function(page) {
                    return page.id === pageId;
                });

            });


        }

    }
})();