(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('OrganisationController', ['account' , 'organisationId', '$q','$state'  , 'logger' , 'OrganisationService' ,   OrganisationController]);

    function OrganisationController(account, organisationId, $q, $state, logger, OrganisationService) {
        var vm = this;
        // interface
        vm.activate = activate;

        // this is resolved from ui-router if any
        vm.organisationId = organisationId;
        vm.account = account;


        ////////////
        //  Init
        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getOrganisation()]);
        }

        function getOrganisation(){
            return OrganisationService.getOrganisation(vm.organisationId);
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.organisation = result[0];
        }

        /// End init
        ///////////////

    }
})();