(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('EnhancerSettingsService', ['CONFIG', '$localStorage', 'logger', '$state' , EnhancerSettingsService]);

    function EnhancerSettingsService(CONFIG, $localStorage, logger, $state) {
        this.getOrCreateEnhancerSettings = getOrCreateEnhancerSettings;
        this.saveEnhancerSettings = saveEnhancerSettings;

        var ttlForEnhancerCacheInDays = 7;

        function getOrCreateEnhancerSettings(  ){
            if ($localStorage.enhancerSettings && notExpired($localStorage.enhancerSettings) ) {
                return $localStorage.enhancerSettings;
            } else {
                $localStorage.enhancerSettings = {
                    ttl: null,
                    limit: CONFIG.ENHANCER_DEFAULT_QUEUE_LIMIT,
                    country: {'countryISO': 'SE', 'name': 'Sweden', 'countryISOLowerCase': 'se'}
                };
                return $localStorage.enhancerSettings;
            }
        }

        function saveEnhancerSettings(enhancerSettings) {
            enhancerSettings.ttl = moment(new Date()).add(ttlForEnhancerCacheInDays, 'day');
            $localStorage.enhancerSettings = enhancerSettings;
            logger.info('Saved settings ');
            $state.reload();
        }

        function notExpired(enhancerSettings) {
            if(!enhancerSettings.ttl){
                return true;
            }
            return moment(enhancerSettings.ttl).isAfter(moment(new Date()));
        }
    }
})();