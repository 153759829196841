(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('ExcludedOrganisationsController', ['account', 'ExcludedOrganisationService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'logger', '$uibModal',ExcludedOrganisationsController]);

    function ExcludedOrganisationsController(account, ExcludedOrganisationService, FileSaver, Blob, CONFIG,  TableSort,logger, $uibModal) {
        var vm = this;
        // interfaces
        vm.confirmDelete = confirmDelete;
        vm.deleteAll = deleteAll;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;
        vm.loadMore = loadMore;
        vm.getAllExcludedOrganisations = getAllExcludedOrganisations;
        vm.checkAll = checkAll;
        vm.deCheckAll = deCheckAll;

        vm.account = account;
        vm.excludedOrganisations = null;
        vm.ok = null;

        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'status'],
            reverse: false
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;

        activate();

        function activate() {
            getAllExcludedOrganisations();
        }

        function getAllExcludedOrganisations() {
            ExcludedOrganisationService.getAllExcludedOrganisations(vm.account.id)
                .then(function (excludedOrganisations) {
                    vm.excludedOrganisations = excludedOrganisations;
                    vm.excludedOrganisations.selected = null;
                    if (!vm.excludedOrganisations.length) {
                        logger.info('No data found!');
                    }
                });
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'ExcludedOrganisations_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }


        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function deleteAll() {
            if (vm.excludedOrganisations.selected || vm.excludedOrganisations.selected.length > 0) {
                var ids = _.map(vm.excludedOrganisations.selected, 'id');
                ExcludedOrganisationService.deleteExcludedOrganisations(vm.account.id, ids)
                    .then(function (excludedOrganisations) {
                        vm.excludedOrganisations = excludedOrganisations;
                    });
            }
        }

        function deleteItem(excludedOrganisationId) {
            ExcludedOrganisationService.deleteExcludedOrganisation(vm.account.id, excludedOrganisationId)
                .then(function (excludedOrganisations) {
                    vm.excludedOrganisations = excludedOrganisations;
                });
            logger.info( 'Selected Excluded Organisations are being removed and processed in the background' );
        }


        function confirmDelete(item){
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + item.organisationName + ' Exluded Organisation will be removed and tracking on this Organisation will start again',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(item.id);
            });
        }

        function checkAll() {
            vm.excludedOrganisations.selected = angular.copy(vm.excludedOrganisations);
        }

        function deCheckAll() {
            vm.excludedOrganisations.selected = null;
        }

        function onTableSort(by) {
            //jshint unused:false
            //vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.excludedOrganisations) {
                return vm.excludedOrganisations.length;
            }
        }
    }
})();