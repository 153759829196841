(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('DashboardService', ['$http', 'responseExceptionCatcher', 'CONFIG', DashboardService]);

    function DashboardService($http, responseExceptionCatcher, CONFIG) {
        this.saveDashboardSettings = saveDashboardSettings;
        this.getDashboardSettings = getDashboardSettings;


        // endpoint to get settings : http://localhost:9999/v3/users/4fc7d19de4b0fa9d04617561/dashboard
        function getDashboardSettings(userId) {
            return $http.get(CONFIG.API_PREFIX + '/users/' + userId + '/dashboard')
                .then(function (response) {
                    var dashboardSettings = response.data.data;
                    if (dashboardSettings) {
                        dashboardSettings.allWidgetSettings = JSON.parse(dashboardSettings.allWidgetSettings);
                    }
                    return dashboardSettings;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        // "/users/{userId}/dashboard";
        function saveDashboardSettings(userId, dashboardSettings) {
            dashboardSettings.allWidgetSettings = JSON.stringify(dashboardSettings.allWidgetSettings);

            var promise;
            if (!(_.isNull(dashboardSettings.id) || dashboardSettings.id === undefined || dashboardSettings.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/users/' + userId + '/dashboard', dashboardSettings)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving dashboard settings '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/users/' + userId + '/dashboard', dashboardSettings)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when dashboard settings'));
            }
            return promise;
        }

    }
})();