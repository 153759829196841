(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('UserPasswordResetController', ['account' , 'user', '$q','$state'  , 'logger' , 'UserSettingsService' ,   UserPasswordResetController]);

    function UserPasswordResetController(account, user, $q, $state, logger, UserSettingsService) {
        var vm = this;

        // interface
        vm.isPasswordValid = isPasswordValid;
        vm.onUserPasswordReset = onUserPasswordReset;

        // this is resolved from ui-router if any
        vm.user = user;
        vm.account = account;
        vm.userPasswordUpdate = {
            id : user.id,
            password : null,
            passwordConfirm : null
        };


        ////////////
        //  Init


        /// End init
        ///////////////

        function isPasswordValid(){

             if(_.isNull(vm.userPasswordUpdate.password) || vm.userPasswordUpdate.password === undefined || vm.userPasswordUpdate.password === ''){
                return false;
             }

            return vm.userPasswordUpdate.password === vm.userPasswordUpdate.passwordConfirm;
        }

        function onUserPasswordReset() {
            return UserSettingsService.updateUserPassword(vm.account.id, vm.userPasswordUpdate)
                .then(function (data) {
                    showSaveSuccess();
                    return data;
                });
        }

        function showSaveSuccess() {
            logger.success('Password successfully changed ');
        }


    }
})();