(function () {
    'use strict';

    angular
        .module('adminApp')
        .factory('logger', ['$log', 'toastr', logger]);

    function logger($log, toastr) {
        var service = {
            showToasts: true,

            error: error,
            info: info,
            success: success,
            warning: warning,

            // straight to console; bypass toastr
            log: $log.log
        };

        return service;

        function error(message, data, title) {
            toastr.error(message, title, {
                timeOut : 15000,
                closeButton: true,
                closeHtml: '<button>x</button>'
            });
            $log.error('Error: ' + message, data);
        }

        function info(message, data, title) {
            toastr.info(message, title);
            $log.info('Info: ' + message, data);
        }

        function success(message, data, title) {
            toastr.success(message, title);
            $log.info('Success: ' + message, data);
        }

        function warning(message, data, title) {
            toastr.warning(message, title, {
                timeOut : 10000,
                closeButton: true,
                closeHtml: '<button>x</button>'
            });
            $log.warn('Warning: ' + message, data);
        }
    }
}());
