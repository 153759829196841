(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('UsersController', ['UserService', 'AdminMailChimpService','logger', 'FileSaver', 'Blob', 'CONFIG', 'TableSort',
            UsersController]);
    function UsersController(UserService, AdminMailChimpService, logger, FileSaver, Blob, CONFIG,  TableSort) {
        var vm = this;
        vm.users = [];
        vm.ok = null;
        vm.loadMore = loadMore;
        vm.getUsers = getUsers;
        vm.tableSize = tableSize;
        vm.sendWelcomeEmail = sendWelcomeEmail;
        vm.doExportToExcel = doExportToExcel;
        vm.pushUsersToMailChimp = pushUsersToMailChimp;
        vm.onTableSort = onTableSort;
        vm.onSToggleActive = onSToggleActive;

        vm.showOnlyActive = true;
        vm.mcLists = [];
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';

        vm.sort = new TableSort('adminTable', {
            by: ['userName', 'active'],
            reverse: false
        });

        activate();

        function activate() {
            getUsers();
            getMailChimpLists();
        }

        function getUsers() {
            UserService.getUsers(vm.showOnlyActive).then(showUsers);
        }

        function onSToggleActive() {
            vm.showOnlyActive = !vm.showOnlyActive;
            UserService.getUsers(vm.showOnlyActive).then(showUsers);
        }


        function getMailChimpLists() {
            AdminMailChimpService.getLists().then(mcListsLoaded);
        }

        function showUsers(users) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.users = users;
            if (!vm.users.length) {
                logger.info('No Users found.');
            }
        }

        function mcListsLoaded(mcLists) {
            vm.mcLists = mcLists;
            if (!vm.mcLists.length) {
                logger.info('No MailChimp lists found.');
            }
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'users_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.users.length;
        }

        function sendWelcomeEmail(userId) {
            UserService.sendWelcomeEmail(userId).then(onSendWelcomeEmail);
        }

        function onSendWelcomeEmail() {
            logger.success('User mail sent');
        }

        function pushUsersToMailChimp() {
            if( !vm.mcListSelected ){
                logger.wanning('You need to select a MailChimp List to synchronize with!!!');
            }
            UserService.pushUsersToMailChimp(vm.mcListSelected).then( function(){
                logger.success('Pushed active users to MailChimp');
            });
        }
    }
})();