(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('VisitFiltersTableModel', ['$filter','$state','cellRenderers',VisitFiltersTableModel
		]);

	function VisitFiltersTableModel($filter,$state,cellRenderers) {

		function goToState(visitFilterId, accountId) {
			$state.go('admin.visitAdvancedFilter', {
				visitFilterId: visitFilterId,
				accountId : accountId
			})
		}

		function nameCellRendererFunc(params) {
			params.$scope.goToState = goToState;
			return '<a ng-click="goToState(data.id, data.accountId)" class="pointer" ng-bind="data.name"></a>';
		}

		var filterValue = '';
		return {
			setFilterValue: function (value) {
				filterValue = value;
			},
			columnDefs: [
				{
					minWidth: 250,
					headerName: 'Name',
					field: 'name',
					headerCheckboxSelectionFilteredOnly: true,
					cellRenderer: nameCellRendererFunc

					/*
					'/#/accounts/' +
							params.data.accountId +
						'/segmentadvancedfilters/' +
						params.data.id
					*/

				},
				{
					headerName: 'Type',
					field: 'type',
					valueGetter: function (params) {
						return params.data.type === 'STATIC'
							? 'Static'
							: params.data.type === 'DYNAMIC'
								? 'Dynamic ': 'Dynamic';
					}
				},

				{
					minWidth: 160,
					headerName: 'No of Visits',
					field: 'nr orgs',
					valueGetter: function (params) {
						return params.data.totalVisitsSize
							? params.data.totalVisitsSize
							: 0;
					},
					filter: 'number'
				},
				{
					headerName: 'Global',
					field: 'global',
					valueGetter: function (params) {
						return params.data.global
							? 'Global'
							: 'Account';
					}
				},
				{
					headerName: 'Active',
					field: 'active',
					valueGetter: function (params) {
						return params.data.active ? 'Active' : 'Inactive';
					}
				},
				{
					headerName: 'Created By',
					field: 'userName',
					cellClass: 'searchable-cell',
					valueGetter: function (params) {
						return params.data.userName;
					},
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				},
				{
					headerName: 'Updated',
					field: 'updated',
					valueGetter: function (params) {
						return $filter('date')(params.data.modified, 'yyyy-MM-dd');
					}
				},

				{
					hide : true,
					minWidth: 230,
					maxWidth: 240,
					headerName: 'Id',
					field: 'id',
					cellClass: 'searchable-cell',
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				},

				{
					hide : true,
					minWidth: 230,
					maxWidth: 240,
					headerName: 'Comment',
					field: 'comment',
					cellClass: 'searchable-cell',
					cellRenderer: function (value) {
						return $('<span>' + value.value + '</span>').mark(filterValue)[0].innerHTML;
					}
				}
			]
		};
	}
})();
