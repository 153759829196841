(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('ReportService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', ReportService]);

    function ReportService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getReports = getReports;
        vm.getReport = getReport;
        vm.getReportHistory = getReportHistory;
        vm.createOrUpdateReport = createOrUpdateReport;
        vm.deleteReport = deleteReport;
        vm.runReport = runReport;
        vm.clone = clone;

        function getReports(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteReport(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getReport(accountId, id) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getReportHistory(accountId, reportId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/' + reportId + '/history')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function runReport(accountId, reportId) {
            return $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/' + reportId + '/run')
                .then(function () {
                    //
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data ' ));
        }

        function createOrUpdateReport(accountId, report) {
            var promise;
            if (!(_.isNull(report.id) || report.id === undefined || report.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/', report)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving report '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/', report)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving report '));
            }
            return promise;
        }


        function clone(accountId, reportIds) {
            return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/reports/clone' , reportIds)
                .then(function (response) {
                    // clones
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();