(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('VisitAdvancedFiltersController', ['$element', '$timeout',
			'$q', '$scope', '$state', '$window', 'logger', 'account', 'VisitAdvancedFilterService', 'VisitFiltersTableModel', '$uibModal',
			VisitAdvancedFiltersController]);

	function VisitAdvancedFiltersController($element,
											  $timeout,
											  $q,
											  $scope,
											  $state,
											  $window,
											  logger,
											  account,
											VisitAdvancedFilterService,
											VisitFiltersTableModel,
											  $uibModal) {

		var vm = this;

		vm.doExportToExcel = doExportToExcel;
		vm.confirmDelete = confirmDelete;
		vm.confirmEnhanceDomain = confirmEnhanceDomain;
		vm.confirmEnhanceDomainEnhancementCBIT = confirmEnhanceDomainEnhancementCBIT;
		vm.segmentsAdvancedCreateRequestForNBKEnhancement = segmentsAdvancedCreateRequestForNBKEnhancement;
		vm.confirmClone = confirmClone;
		vm.help = help;
		vm.convertOldToNew = convertOldToNew;

		vm.checkedIds = [];
		vm.checkedIdsNumber = 0;
		vm.selectedNodes = [];
		vm.account = account;

		vm.segmentsTable = {
			defaultColDef: {
				maxWidth: 400,
				minWidth: 40
			},
			columnDefs: VisitFiltersTableModel.columnDefs,
			enableFilter: true,
			animateRows : true,
			angularCompileRows: true,
			isExternalFilterPresent: isExternalFilterPresent,
			doesExternalFilterPass: doesExternalFilterPass,
			onRowsNumberChanged: function (rowsNumber) {
				vm.numberOfRows = rowsNumber;
				filterChanged();
			},
			onPostInit: function (rowsNumber) {
				vm.numberOfRows = rowsNumber;
			},
			getFn: function () {
				return VisitAdvancedFilterService.getVisitFilters(account.id);
			},
			onDisplayedColumnsChanged: function () {
				resizeColumns();
			}
		};

		vm.panelHeights = 120;
		var $segmentTable = $('.segments-table');

		$timeout(function () {
			resizeHeight();
		});

		vm.globalSearch = '';

		vm.globalSearchChanged = function () {
			VisitFiltersTableModel.setFilterValue(vm.globalSearch);
			vm.segmentsTable.grid.api.onFilterChanged();
		};

		$scope.$on('$destroy', onDestroy);
		angular.element($window).bind('resize', _.debounce(onResize, 250));

		function confirmDelete() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to delete the selected Segment(s)? Any of the Segments' +
							' part of a Camppaign can not be deleted.',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				deleteSelected();
			});
		}

		function confirmClone() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you absolutely sure you want to clone the selected Segment(s)?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				cloneSelected();
			});
		}

		function confirmEnhanceDomain() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for domain enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainSelected();
			});
		}

		function confirmEnhanceDomainEnhancementCBIT() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for domain enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainCBITSelected();
			});
		}

		function segmentsAdvancedCreateRequestForNBKEnhancement() {
			$uibModal.open({
				templateUrl: 'partials/common/confirm-warning.html',
				controller: 'ConfirmController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Slow down!',
							body: 'Are you sure you want to add Segment to queue for NBK enhancement?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function () {
				enhanceDomainNBKSelected();
			});
		}

		function onDestroy() {
			angular.element($window).off('resize');
		}

		function onResize() {
			resizeColumns();
			resizeHeight();
		}

		function resizeColumns() {
			vm.segmentsTable.grid.api.sizeColumnsToFit();
		}

		function resizeHeight() {
			if ($(window).height() > 600) {
				$segmentTable.css('height', $(window).height() - vm.panelHeights);
			} else {
				$segmentTable.css('height', 800 - vm.panelHeights);
			}
		}

		function isExternalFilterPresent() {
			return vm.globalSearch.length > 0;
		}

		function doesExternalFilterPass(node) {
			var query = vm.globalSearch.toLowerCase();
			return node.data.name.toLowerCase().indexOf(query) >= 0 ||
				node.data.userName.toLowerCase().indexOf(query) >= 0 ||
				//_.map(node.data.campaigns, 'name').join(', ').toLowerCase().indexOf(query) >= 0 ||
				node.data.id.toLowerCase().indexOf(query) >= 0;
		}

		function filterChanged() {
			var sel = $element.find('.searchable-cell');
			if (vm.globalSearch) {
				sel.unmark();
				sel.mark(vm.globalSearch);
			} else {
				sel.unmark();
			}
		}

		function deleteSelected() {
			var visitFilterIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			VisitAdvancedFilterService.deleteVisitFilters(account.id, visitFilterIds)
				.then(function () {
					$scope.$broadcast('updateTable');
				});
		}

		function enhanceDomainSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			VisitAdvancedFilterService.enhanceDomain(account.id, segmentsIds)
				.then(function (response) {
					if( response === 0 ){
						logger.error('Nothing was added. ISPS and already enhanced domains are not added to Q ' );
					} else {
						logger.success('Segment added to domain Q - items created ' + response);
					}

				});
		}

		function enhanceDomainCBITSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			VisitAdvancedFilterService.enhanceDomainEnhancementCBITQueue(account.id, segmentsIds)
				.then(function (response) {
					if(response === 0){
						logger.error('Nothinh added - ISPs and already enhanced are filtered out');
					} else {
						logger.success('Segment added to enhancment Q - items created ' + response);
					}
				});
		}

		function enhanceDomainNBKSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			VisitAdvancedFilterService.segmentsAdvancedCreateRequestForNBKEnhancement(account.id, segmentsIds)
				.then(function (response) {
					if(response === 0){
						logger.error('Nothinh added - ISPs and already enhanced are filtered out');
					} else {
						logger.success('Segment added to enhancment NBK Q - items created ' + response);
					}
				});
		}

		function cloneSelected() {
			var segmentsIds = _.map(vm.selectedNodes, function (node) {
				return node.data.id;
			});
			VisitAdvancedFilterService.cloneSegments(account.id, segmentsIds)
				.then(function (result) {
					logger.success('You have created clone(s).');
					$scope.$broadcast('updateTable', result);
				});
		}

		function doExportToExcel() {
			var columnKeys = _.map(vm.segmentsTable.grid.columnApi.getAllDisplayedColumns(), 'colId').slice(0, -1);
			vm.segmentsTable.grid.api.exportDataAsCsv({
				columnKeys: columnKeys,
				allColumns: false
			});
		}

		function help() {
			logger.info('Help will be here');
		}


		function convertOldToNew() {
			return VisitAdvancedFilterService.convertOldToNew(vm.account.id)
				.then(function (data) {
					logger.info('Converted all old Segment to new ones')
				});
		}


	}
})();