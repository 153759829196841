(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('AudienceController', ['account', 'user', 'audienceId', 'AudienceService', 'UserService', 'CommonService',
			'logger', '$q', '$state', 'FileUploader', '$window', '$scope', 'STATE', '$uibModal', AudienceController]);

	function AudienceController(account, user, audienceId, AudienceService, UserService, CommonService,
								logger, $q, $state, FileUploader, $window, $scope, STATE, $uibModal) {
		var vm = this;
		vm.autoCompleteSegments = autoCompleteSegments;
		vm.autoCompleteAgencies = autoCompleteAgencies;
		vm.autoCompleteAgencyCountries = autoCompleteAgencyCountries;
		vm.saveAudience = saveAudience;
		vm.saveAudienceRights = saveAudienceRights;
		vm.activate = activate;
		vm.confirmPublishAudienceModalWindow = confirmPublishAudienceModalWindow;
		vm.confirmPublishAudience = confirmPublishAudience;
		vm.confirmCreateNewCategory = confirmCreateNewCategory;
		vm.tableSize = tableSize;
		vm.cpmReadonly = true;
		vm.totalSegmentSize = null;
		vm.searchFilter = '';


		vm.isAudienceReadyForPublish = false;
		vm.publishedSegmentFileName = 'd';
		vm.searchFilter = '';
		vm.account = account;
		vm.user = user;
		vm.audienceId = audienceId;
		vm.selected = undefined;
		vm.start = moment().toDate();
		vm.end = moment().add(30, 'days').toDate();
		// reference data
		vm.datePickerOptions = {formatYear: 'yy', startingDay: 1};

		// reference data
		vm.refSegments = null;
		vm.refAgencies = [];
		vm.refAgenciesInitialAll = [];  // always all
		//vm.selectedAgencies = [];
		vm.refStatus = [{id: 'ACTIVE', name: 'Active'}, {id: 'NOT_ACTIVE', name: 'Not active'}];
		// this can be changed in Adforms
		vm.refCategories = [{id: 'ACTIVE', name: 'B2B Generice Segments'}];
		vm.refDataTypes = [{id: 'FirstParty', name: '1st Party'},
			{id: 'SecondParty', name: '2nd Party'},
			{id: 'ThirdParty', name: '3rd Party'}
		];
		vm.audienceLog = [];
		// initial if creating new
		vm.audience = {
			id: audienceId,
			accountId: vm.account.id,
			createdBy: vm.user,
			audienceRightsId: null,
			status: 'ACTIVE',
			cpmPrice: account.externalAdformsDefaultCPM,
			ttl: 99,
			frequency: 1,
			segments: [],
			dataType : 'SecondParty'
		};

		vm.audienceRights = {
			id: null,
			accountId: vm.account.id,
			audienceId: vm.audience.id !== '' ? vm.audience.id : null,
			countryRights: [],
			selectedAgencies : []
		};


		////////////
		//  Init


		$scope.$watchCollection('vm.audienceRights.countryRights', onAudienceRightsChanged);

		$scope.$watchCollection('vm.audience.segments', onAudienceSegmentsChanged);

		activate();

		function activate() {
			vm.cpmReadonly = cpmReadonly();
			getInitialData().then(showAudience);
		}

		function getInitialData() {
			return $q.all([getAudience(), getSegments(), getAudienceCategories(), findAllAgencies(),
				findAllAgencyCountries(), getAudienceLog()]);
		}

		function getAudience() {
			if (_.isNull(vm.audienceId) || _.isEmpty(vm.audienceId)) {
				// initial when no audience found
				return vm.audience;
			} else {
				return AudienceService.getAudience(vm.account.id, vm.audienceId);
			}
		}

		function getAudienceLog() {
			if (_.isNull(vm.audience.id) || _.isEmpty(vm.audience.id)) {
				// initial
				return vm.audienceLog;
			} else {
				return AudienceService.getAudienceLog(vm.account.id, vm.audience.id).then(function(logs){
					vm.audienceLog = logs;
					return vm.audienceLog;
				})
			}
		}

		function getAudienceRights() {
			if (_.isNull(vm.audience.id) || _.isEmpty(vm.audience.id) || _.isNull(vm.audience.audienceRightsId) || vm.audience.audienceRightsId === undefined) {
				// initial when no audience rights
				return vm.audienceRights;
			} else {
				return AudienceService.getAudienceRights(vm.account.id, vm.audience.id, vm.audience.audienceRightsId).then(function (data) {
					vm.audienceRights = data;
					audienceReadyForPublish();
				})
			}
		}

		function getSegments() {
			if (_.isNull(vm.account.id) || _.isEmpty(vm.account.id)) {
				return {};
			} else {
				return AudienceService.getSegments(vm.account.id);
			}
		}

		function findAllAgencies() {
			return AudienceService.findAllAgencies(vm.account.id);
		}

		function findAllAgencyCountries() {
			return AudienceService.findAllAgencyCountries(vm.account.id);
		}

		function getAudienceCategories() {
			return AudienceService.getAudienceCategories(vm.account.id);
		}

		function showAudience(result) {
			vm.audience = result[0];
			vm.refSegments = result[1];
			vm.refCategories = result[2];


			if( !vm.audience.category  ){
				// to make first category selected on new audinece
				vm.audience.category = vm.refCategories[0];
			}


			vm.refAgencies = result[3];
			vm.refAgencyCountries = result[4];

			vm.refAgenciesInitialAll = result[3];

			vm.audienceLog  = result[5];


			getAudienceRights();
		}

		/// End init

		function saveAudienceRights() {
			return AudienceService.saveAudienceRights(vm.audience.id, vm.audienceRights)
				.then(function (data) {
					logger.success('Audience rights updated');
					vm.audienceRights = data;
					return data;
				});
		}

		function saveAudience() {
			vm.audience.audienceRightsId = vm.audienceRights.id;
			return AudienceService.createOrUpdateAudience(vm.account.id, vm.audience)
				.then(function (data) {
					vm.audience = data;
					vm.audienceRights.audienceId = data.id;
					showSaveSuccess();
					getAudienceLog();
					return data;
				}).then(function (rights){
					// if audience save the also save rights if any
					saveAudienceRights();
				}).then(function (){
					audienceReadyForPublish();
				})
		}

		// will fire of backend and publish to dmp
		function publishAudience() {
			return AudienceService.publishAudience(vm.account.id, vm.audience)
				.then(function (data) {
					logger.success('Audience successfully published.');
					vm.audience = data;
				}).then(function (){
					getAudienceLog();
				});
		}

		function publishAudienceSegment() {
			return AudienceService.publishAudienceSegment(vm.account.id, vm.audience)
				.then(function (data) {
					logger.success('Audience Segment successfully published.');
					vm.audience = data;
					parseOutFileNameForSegment();
					//return data;
				}).then(function (){
					getAudienceLog();
				});
		}

		function showSaveSuccess() {
			logger.success('Audience saved ');
			$state.go(STATE.TARGETING_AUDIENCE, {
				accountId: vm.account.id,
				audienceId: vm.audience.id
			}, {notify: false});
		}

		// multiselect callback when user enter text
		function autoCompleteSegments($query) {
			return vm.refSegments.filter(function (segment) {
				return segment.name.toLowerCase().indexOf($query.toLowerCase()) !== -1;
			});
		}

		function autoCompleteAgencies($query) {
			return vm.refAgencies.filter(function (agency) {
				return agency.afAgencyName.toLowerCase().indexOf($query.toLowerCase()) !== -1;
			});
		}

		function autoCompleteAgencyCountries($query) {
			return vm.refAgencyCountries.filter(function (country) {
				return country.afCountryName.toLowerCase().indexOf($query.toLowerCase()) !== -1;
			});
		}

		// update total coount on all selected segments
		function onAudienceSegmentsChanged() {
			if (vm.audience && vm.audience.segments) {
				vm.totalSegmentSize = _.sumBy(vm.audience.segments, function (segment) {
					return segment.totalSegmentSize;
				});
			}
			//vm.audineceDirty = true;
			audienceReadyForPublish();
		}

		function onAudienceRightsChanged() {
			// Get current selected countries
			var selectedCountrRightsIsoCodes = [];
			if (vm.audienceRights.countryRights) {
				_.forEach(vm.audienceRights.countryRights, function (countryRight) {
						selectedCountrRightsIsoCodes.push(countryRight.iso2Code);
					}
				)
			}

			if (selectedCountrRightsIsoCodes && selectedCountrRightsIsoCodes.length > 0) {
				var agenciesForCountriesSelected = _.filter(vm.refAgenciesInitialAll, function (agency) {
					return _.includes(selectedCountrRightsIsoCodes, agency.iso2Code);

				});
				vm.refAgencies = angular.copy(agenciesForCountriesSelected);
			} else {
				vm.refAgencies = angular.copy(vm.refAgenciesInitialAll);
			}
			// invalidate publish if needed
			vm.audineceDirty = true;
			audienceReadyForPublish();
		}


		function audienceReadyForPublish() {
			var audienceRightsOk = vm.audienceRights !== undefined && vm.audienceRights.countryRights !== undefined
			&&  vm.audienceRights.countryRights.length > 0 ;
			vm.isAudienceReadyForPublish = vm.audience.status !== undefined && vm.audience.id !== ''
				&& vm.audience.segments !== undefined && vm.audience.segments.length > 0 &&
				audienceRightsOk ;
		}



		function confirmPublishAudience() {
			$uibModal.open({
				templateUrl: 'partials/targeting/audiences/confirm-publish-audinece-modal.html',
				//controller: 'ConfirmController as vm',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
			});
		}

		function parseOutFileNameForSegment(){
			if( vm.audience.bucketKey ){
				vm.publishedSegmentFileName = vm.audience.bucketKey.split('/')[3];
			}
		}

		function confirmCreateNewCategory() {
			$uibModal.open({
				animation: true,
				templateUrl: 'partials/targeting/audiences/confirm-new-category.html',
				controller: 'ConfirmNewCategoryController as vm',
				size: 'md',
				resolve: {
					modalConfig: function () {
						return {
							title: 'New Category',
							body: 'Are you sure want to create a new Audience Category?',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function (categoryName) {
				AudienceService.createCategory(vm.account.id, categoryName)
					.then(function (category) {
						logger.success('Audience Category created ');
						//vm.audience = data;
						//return data;

						getAudienceCategories().then(function (categories) {
							vm.refCategories = categories;
						});


					})
			});
		}


		function confirmPublishAudienceModalWindow() {
			$uibModal.open({
				animation: true,
				templateUrl: 'partials/targeting/audiences/confirm-publish-audience-modal.html',
				controller: 'ConfirmNewCategoryController as vm',
				size: 'lg',
				resolve: {
					modalConfig: function () {
						return {
							title: 'Publish Audience',
							body: 'Are you sure you want to publish the Audience? By publishing you will create (or ' +
								'update) the Audience in the DMP, including:',
							positive: 'Yes',
							negative: 'No'
						};
					}
				}
			}).result.then(function (categoryName) {
				// save any pending stuff first
				saveAudience().then(function(data){{
					publishAudience();
				}})
			});
		}

		function cpmReadonly(){
			var  isadminOrPowerUser = CommonService.userIsAdminOrPowerUSer();
			return !isadminOrPowerUser;
		}

		function tableSize() {
			if (vm.audienceLog) {
				return vm.audienceLog.length;
			}
		}
	}
})();