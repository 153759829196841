(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('QueueNBKEnhanceService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', QueueNBKEnhanceService]);

	function QueueNBKEnhanceService($http, $q, CONFIG, responseExceptionCatcher) {
		this.search = search;
		this.updateLEOrganisationsFromNBKEnhancedData = updateLEOrganisationsFromNBKEnhancedData;
		this.queryNBKAndStoreData = queryNBKAndStoreData;
		this.clearQ = clearQ;
		this.countAll = countAll;
		this.updateToISP = updateToISP;
		this.clearStatusQueriedWithNoResults = clearStatusQueriedWithNoResults;
		this.clearStatusQueriedWithResultsNoGoodMatch = clearStatusQueriedWithResultsNoGoodMatch;
		this.updateToFailed = updateToFailed;
		this.exportListForManualEnhancement = exportListForManualEnhancement;

		function search(country, limit, status, config) {
			var request = null;
			if (country && country.countryISO) {
				request = 'countryISO=' + country.countryISO;
			}
			if (limit) {
				request = request + '&limit=' + limit;
			}
			if (status) {
				request = request + '&importStatus=' + status;
			}
			if (!request) {
				return $http.get(CONFIG.API_PREFIX + '/enhance/nbk/queue/search', config)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			} else {
				return $http.get(CONFIG.API_PREFIX + '/enhance/nbk/queue/search?' + request, config)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when retrieving data'));
			}
		}

		function countAll() {
			return $http.get(CONFIG.API_PREFIX + '/enhance/nbk/queue/countAll')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function clearStatusQueriedWithNoResults() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/clearStatusQueriedWithNoResults')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}


		function clearStatusQueriedWithResultsNoGoodMatch() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/clearStatusQueriedWithResultsNoGoodMatch')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function updateLEOrganisationsFromNBKEnhancedData(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/updateLEOrganisationsFromNBKEnhancedData', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function updateToFailed(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/updateToFailed', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function queryNBKAndStoreData(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/queryNBKAndStoreData', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function updateToISP(selectedIds, config) {
			var orgs = {
				ids: selectedIds
			};

			return $http.post(CONFIG.API_PREFIX + '/enhance/v/updateToISP', orgs)
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function clearQ() {
			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/clearQ')
				.catch(responseExceptionCatcher.catch('Error when updating '));
		}

		function exportListForManualEnhancement(ids) {
			var orgs = {
				ids: ids
			};
			return $http.post(CONFIG.API_PREFIX + '/enhance/nbk/queue/exportListForManualEnhancement', orgs)
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));

		}

	}
})();