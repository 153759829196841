(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('VisitorService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher',  VisitorService]);

    function VisitorService($http, $q, CONFIG, responseExceptionCatcher) {
        this.findVisitors = findVisitors;
        this.findNumberOfVisitors = findNumberOfVisitors;
        this.getVisitor = getVisitor;
        this.findVisitsByVisitorId = findVisitsByVisitorId;

        function findVisitors(accountId, siteId, start, end, filterId, config) {
            //return (visits && visits.isFor(accountId, siteId, start, end, filterId)) ? getVisitsFromState() : findVisitsFromBackend(accountId, siteId, start, end, filterId, config);
            return findVisitsFromBackend(accountId, siteId, start, end, filterId, config);
        }

        function findVisitsFromBackend(accountId, siteId, start, end, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visitors/' + '?startDate=' + start + '&endDate=' + end, config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findNumberOfVisitors(accountId, siteId, start, end, filterId, config) {
            var filterPart = filterId ? 'filters/' + filterId : '';
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/'  + siteId +  '/visitors/' + filterPart + '/numberOfVisits?startDate=' + start + '&endDate=' + end, config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getVisitor(accountId, visitorId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/visitors/' + visitorId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findVisitsByVisitorId(accountId, siteId, visitorId, config) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/sites/' + siteId + '/visitors/' + visitorId + '/visits', config)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }




    }
})();