(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('AccountSelectorController', ['CommonService', 'logger', '$q', '$state', '$stateParams', '$location',
			'$scope', '$window', '$uibModal', 'AuthService', AccountSelectorController]);

	function AccountSelectorController(CommonService, logger, $q, $state, $stateParams, $location, $scope, $window, $uibModal, AuthService) {
		var vm = this;
		vm.switchAccount = switchAccount;
		vm.switchSite = switchSite;
		vm.logout = logout;
		vm.onOpenSettings = onOpenSettings;

		vm.resellerAccounts = null;
		vm.userAccounts = [];
		vm.currentAccount = null;
		vm.currentSite = 'dd';
		vm.currentResellerAccount = null;
		vm.currentUser = null;
		vm.ok = null;
		vm.accountSites = [];

		activate();


		function activate() {
			getInitialData().then(showResult);

			listenToRefreshEvent();
		}

		function getInitialData() {
			return $q.all([getUserAccounts(), getAccount(), getUser(), getActiveAccountsForCurrentReseller(),
				getResellerAccountForCurrentUser(), getAccountSites(), getCurrentSite()]);
		}

		function getUserAccounts() {
			return CommonService.getUserAccounts();
		}

		function getAccount() {
			return CommonService.getAccount();
		}

		function getCurrentSite() {
			return CommonService.getCurrentSite();
		}

		function getUser() {
			return CommonService.getUser();
		}

		function getActiveAccountsForCurrentReseller() {
			return CommonService.getActiveAccountsForCurrentReseller();
		}

		function getResellerAccountForCurrentUser() {
			return CommonService.getResellerAccountForCurrentUser();
		}

		function getAccountSites() {
			return CommonService.getAccountSites();
		}

		// display the stuff when promises are finished
		function showResult(result) {
			vm.userAccounts = result[0];
			vm.currentAccount = result[1];
			vm.currentUser = result[2];
			vm.resellerAccounts = result[3];
			vm.currentResellerAccount = result[4];
			vm.accountSites = result[5];
			vm.currentSite = result[6];
		}

		function listenToRefreshEvent() {
			//$scope.$on('reselleraccounts-updated', function(event, args) {
			$scope.$on('reselleraccounts-updated', function () {
				console.log('got event for updating account list');
				CommonService.getActiveAccountsForCurrentResellerFromBackend()
					.then(function (data) {
						vm.resellerAccounts = data;
					});
				CommonService.getResellerAccountForCurrentUser()
					.then(function (data) {
						vm.currentResellerAccount = data;
					});
			});
		}

		function switchAccount(accountId) {
			return CommonService.switchAccount(accountId)
				.then(function () {
					// re-fetch account details...
					vm.currentAccount = CommonService.getAccount();
					showAccountSwitchedSuccess();
					$state.reload();
				});
		}

		function showAccountSwitchedSuccess() {
			logger.success('Account changed.');
		}

		function switchSite(accountId, siteId) {
			return CommonService.switchSite(accountId, siteId)
				.then(function () {
					showSiteSwitchedSuccess();
					$state.reload();
				}).then(function () {
					$window.location.reload();
					// does not work
					// $state.reload();
					//$state.go('.', null, { reload: true });
					//$state.go($state.current, {}, {reload: true});
					//$state.transitionTo($state.current, angular.copy($stateParams), { reload: true, inherit: true, notify: true });
				});
		}

		function showSiteSwitchedSuccess() {
			logger.success('Site changed.');
		}

		function logout() {
			AuthService.logout();
		}

		function onOpenSettings() {
			$uibModal.open({
				templateUrl: 'partials/app-settings-modal.html',
				controller: 'AccountSelectorController',
				controllerAs: 'vm',
				size: 'lg'
			}).result.then(function () {
			});
		}

	}
})();