(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('EnhancementOrganisationsISPService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher',  EnhancementOrganisationsISPService]);

    function EnhancementOrganisationsISPService($http, $q, CONFIG, responseExceptionCatcher) {

        this.getOrganisationISPs = getOrganisationISPs;
        this.organisationsISPsUpdateLEOrgananisations = organisationsISPsUpdateLEOrgananisations;
        this.extractISPsFromLEOrganisationsAsync = extractISPsFromLEOrganisationsAsync;
        this.organisationsISPsUpdateLEOrgananisationsPartial = organisationsISPsUpdateLEOrgananisationsPartial;
        this.markAsISP = markAsISP;
        this.deleteItem = deleteItem;


        function getOrganisationISPs() {
            return $http.get(CONFIG.API_PREFIX + '/enhance/organisations/isps' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function organisationsISPsUpdateLEOrgananisations() {
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/isps/updateledb')
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function extractISPsFromLEOrganisationsAsync() {
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/isps/extractfromle')
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function organisationsISPsUpdateLEOrgananisationsPartial() {
            return $http.post(CONFIG.API_PREFIX + '/enhance/organisations/isps/updateledb/partial')
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function markAsISP(enhancementorganisationsISP) {
            return $http.put(CONFIG.API_PREFIX + '/enhance/organisations/isps',enhancementorganisationsISP )
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }

        function deleteItem(enhancementorganisationsISPId) {
            return $http.delete(CONFIG.API_PREFIX + '/enhance/organisations/isps/' + enhancementorganisationsISPId )
                .catch(responseExceptionCatcher.catch('Error when updating '));
        }
    }
})();