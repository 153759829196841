(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('RIPEOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', RIPEOrganisationService]);

    function RIPEOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        this.searchRIPE = searchRIPE;

        function searchRIPE(queryForIP, config) {
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/ripe?q=' + queryForIP, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

    }
})();