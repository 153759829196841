(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('AdminDatabaseController', ['AdminDatabaseService', 'FileSaver', 'Blob', 'CONFIG', 'TableSort', 'CommonService', 'AccountService', '$q', 'logger', AdminDatabaseController]);

	function AdminDatabaseController(AdminDatabaseService, FileSaver, Blob, CONFIG, TableSort, CommonService, AccountService, $q, logger) {
		var vm = this;
		vm.accounts = [];
		vm.ok = null;
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.sort = new TableSort('adminTable', {
			by: ['name', 'active'],
			reverse: false
		});
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.organisationsIndexCountry = null;
		vm.organisationsExportCountry = null;
		vm.indexStatuses = [];
		vm.cbitDomain = null;
		vm.datePickerOptionsStartDate = {formatYear: 'yy', startingDay: 1};
		vm.datePickerOptionsStartDate = {
			formatYear: 'yy',
			startingDay: 1
		};
		vm.selectedResellerIdForReindex = null;


		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTableSort = onTableSort;
		vm.getCountriesForSelectBox = getCountriesForSelectBox;
		vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
		vm.reIndexOrganisations = reIndexOrganisations;
		vm.reIndexVisits = reIndexVisits;
		vm.oneTimeUpdateAccountIdAndUrlCacheKey = oneTimeUpdateAccountIdAndUrlCacheKey;
		vm.updateEnhancementScoreOnAllOrganisations = updateEnhancementScoreOnAllOrganisations;
		vm.organisationsenhancementscore = organisationsenhancementscore;
		vm.removePagesForInactiveAccounts = removePagesForInactiveAccounts;
		vm.exportOrganisations = exportOrganisations;
		vm.exportAllOrganisationsForDomainMatching = exportAllOrganisationsForDomainMatching;
		vm.exportAllOrganisations = exportAllOrganisations;
		vm.onSetCountryForSelectBoxWhenExporting = onSetCountryForSelectBoxWhenExporting;
		vm.pollReIndexStatus = pollReIndexStatus;
		vm.deleteAllReindexStatus = deleteAllReindexStatus;
		vm.exportAllISPOrganisations = exportAllISPOrganisations;
		vm.removeForAllInactiveAccount = removeForAllInactiveAccount;
		vm.moveVisitsForwardForDevelopment = moveVisitsForwardForDevelopment;
		vm.cleanupNetworksForDevelpment = cleanupNetworksForDevelpment;
		vm.obfuscateOrganisationsFTEAsync = obfuscateOrganisationsFTEAsync;
		vm.deleteIndexAndRecreateOrganisations = deleteIndexAndRecreateOrganisations;
		vm.deleteIndexAndRecreateVisits = deleteIndexAndRecreateVisits;
		vm.enhanceFromCBITUsingDomain = enhanceFromCBITUsingDomain;
		vm.enhanceFromCBITUsingCountry = enhanceFromCBITUsingCountry;
		vm.onSetCountryForSelectBoxForNBKMatch = onSetCountryForSelectBoxForNBKMatch;
		vm.enhanceFromNBKUsingCountry = enhanceFromNBKUsingCountry;
		vm.onAccountForSelectBox = onAccountForSelectBox;
		vm.getAccountForSelectBox = getAccountForSelectBox;
		vm.updateAllOrganisationsFromAlreadyEnhancedDataWhereNameMatches = updateAllOrganisationsFromAlreadyEnhancedDataWhereNameMatches;
		vm.updateMaxIP = updateMaxIP;
		vm.createDomainAttribute = createDomainAttribute;

		vm.getAllResellerAccounts = getAllResellerAccounts;
		vm.reIndexVisitsForReseller = reIndexVisitsForReseller;
		vm.lowerCaseallSicNames = lowerCaseallSicNames;
		vm.readAllIndustryCodesAndCreateTags = readAllIndustryCodesAndCreateTags;
		vm.createDIndustryTags = createDIndustryTags;
		vm.updateSwedishOrganisationTypesBasedOnOrganisationNumber = updateSwedishOrganisationTypesBasedOnOrganisationNumber;
		vm.updateNorwayOrganisationTypesBasedOnOrganisationNumber = updateNorwayOrganisationTypesBasedOnOrganisationNumber;


		////////////
		//  Init
		activate();

		function activate() {
			getInitialData().then(showData);
		}

		function getInitialData() {
			return $q.all([getCountries(), countNumberOf(), getAccounts()]);
		}


		function showData(result) {
			vm.refCountries = result[0];
			vm.noOfFirstVisits = result[1];
			vm.accounts = result[2];
			vm.refResellerAccounts = result[3];

			pollReIndexStatus();

			getStatistics();
		}

		/// End init
		///////////////

		function getCountries() {
			return CommonService.getCountries();
		}

		function getAccounts() {
			return AccountService.getAccounts();
		}


		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()],
				{type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
			var config = {
				data: data,
				filename: 'accounts_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		function onTableSort(by) {
			//jshint unused:false
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.accounts.length;
		}


		function reIndexOrganisations() {
			if (vm.organisationsIndexCountry && vm.organisationsIndexCountry.countryISO) {
				AdminDatabaseService.reindexOrganisations(vm.organisationsIndexCountry.countryISO);
				logger.info('Reindexing of Organisations started for ' + vm.organisationsIndexCountry.countryISO);
			} else {
				AdminDatabaseService.reindexOrganisations();
				logger.info('Reindexing of ALL Organisations started!!');

			}
		}

		function reIndexVisits() {
			if (vm.visitsReindexStartDate && vm.selectedAccount) {
				var fromDate = moment(vm.visitsReindexStartDate).format('YYYY-MM-DD');
				logger.info('Reindexing of Visits for ' + vm.selectedAccount.name + ' starting today back to date ' + fromDate);
				AdminDatabaseService.reindexVisits(fromDate, vm.selectedAccount);
			} else if (vm.visitsReindexStartDate) {
				logger.info('Reindexing of Visits started, starting today back to date ' + moment(vm.visitsReindexStartDate).format('YYYY-MM-DD'));
				AdminDatabaseService.reindexVisits(moment(vm.visitsReindexStartDate).format('YYYY-MM-DD'), null);
			} else if (vm.selectedAccount) {
				logger.info('Reindexing of ALL Visits for ' + vm.selectedAccount.name);
				AdminDatabaseService.reindexVisits(null, vm.selectedAccount);

			} else {
				logger.info('Reindexing ALL Visits for ALL accounts');
				AdminDatabaseService.reindexVisits(null, null);

			}
		}


		function oneTimeUpdateAccountIdAndUrlCacheKey() {
			AdminDatabaseService.oneTimeUpdateAccountIdAndUrlCacheKey();
			logger.info('Updating Pages with accountid and cachekeys!!');
		}


		function removePagesForInactiveAccounts() {
			AdminDatabaseService.removePagesForInactiveAccounts();
			logger.info('Deleting Pages for inactive Accounts!!');
		}

		function updateEnhancementScoreOnAllOrganisations() {

			AdminDatabaseService.updateEnhancementScoreOnAllOrganisations();
			logger.info('Updating enhancementscore on all organisations!!');
		}

		function organisationsenhancementscore() {
			if (!vm.organisationsExportCountry || !vm.organisationsExportCountry.countryISO) {
				logger.error('Select country before ');
				return;
			}
			AdminDatabaseService.organisationsenhancementscore(vm.organisationsExportCountry.countryISO).then(function (enhScore) {
				logger.info('Enhancement score for country ' + vm.organisationsExportCountry.countryISO + '  was ' + enhScore);
			});
		}

		// typeahead callback
		function getCountriesForSelectBox(filterByCountryName) {
			return _.filter(vm.refCountries, function (country) {
				if (country.name && filterByCountryName) {
					return country.name.toLowerCase().indexOf(filterByCountryName.toLowerCase()) > -1;
				}
			});
		}

		// on select in UI for index
		function onSetCountryForSelectBox(countrySelected) {
			vm.organisationsIndexCountry = countrySelected;
		}

		// on select in UI for orgs export
		function onSetCountryForSelectBoxWhenExporting(countrySelected) {
			vm.organisationsExportCountry = countrySelected;
		}

		// on select in UI for nbk match
		function onSetCountryForSelectBoxForNBKMatch(countrySelected) {
			vm.organisationsNBKMatchCountry = countrySelected;
		}

		function exportOrganisations() {
			if (!vm.organisationsExportCountry || !vm.organisationsExportCountry.countryISO) {
				logger.error('Select country before exporting');
				return;
			}

			AdminDatabaseService.exportOrganisations(vm.organisationsExportCountry.countryISO)
				.then(function (response) {
					var data = new Blob([response.data], {type: 'application/csv'});
					var config = {
						data: data,
						filename: 'Organisations_' + vm.organisationsExportCountry.countryISO + '_' + new Date().toISOString().substring(0, 10) + '.csv'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function exportAllOrganisationsForDomainMatching() {
			if (!vm.organisationsExportCountry || !vm.organisationsExportCountry.countryISO) {
				logger.error('Select country before exporting');
				return;
			}

			AdminDatabaseService.exportAllOrganisationsForDomainMatching(vm.organisationsExportCountry.countryISO)
				.then(function (response) {
					var data = new Blob([response.data], {type: 'application/csv'});
					var config = {
						data: data,
						filename: 'Organisations_aggregatedbydomain_' + vm.organisationsExportCountry.countryISO + '_' + new Date().toISOString().substring(0, 10) + '.csv'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function exportAllISPOrganisations() {
			AdminDatabaseService.exportAllISPOrganisations()
				.then(function (response) {
					var data = new Blob([response.data], {type: 'application/csv'});
					var config = {
						data: data,
						filename: 'ISPOrganisationsAllCountries__' + new Date().toISOString().substring(0, 10) + '.csv'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function exportAllOrganisations() {
			if (!vm.organisationsExportCountry || !vm.organisationsExportCountry.countryISO) {
				logger.error('Select country before exporting');
				return;
			}
			AdminDatabaseService.exportAllOrganisations(vm.organisationsExportCountry.countryISO)
				.then(function (response) {
					var data = new Blob([response.data], {type: 'application/csv'});
					var config = {
						data: data,
						filename: 'Organisations_aggregated_' + vm.organisationsExportCountry.countryISO + '_' + new Date().toISOString().substring(0, 10) + '.csv'
					};
					FileSaver.saveAs(config.data, config.filename);
				});
		}

		function pollReIndexStatus() {
			AdminDatabaseService.pollReindexStatus().then(function (data) {
				vm.indexStatuses = data;
				return data;
			});
		}

		function getStatistics() {
			AdminDatabaseService.getStatistics().then(function (data) {
				vm.statistics = data;
				return data;
			});
		}

		function deleteAllReindexStatus() {
			AdminDatabaseService.deleteAllReindexStatus().then(pollReIndexStatus);
		}

		function removeForAllInactiveAccount() {
			logger.info('Starting delete of first visits');
			AdminDatabaseService.removeForAllInactiveAccount().then(function () {
				logger.info('Delete of first visits finished');
			});
		}

		function countNumberOf() {
			return AdminDatabaseService.countNumberOf();
		}

		function moveVisitsForwardForDevelopment() {
			return AdminDatabaseService.moveVisitsForwardForDevelopment();
		}

		function cleanupNetworksForDevelpment() {
			return AdminDatabaseService.cleanupNetworksForDevelpment();
		}

		function obfuscateOrganisationsFTEAsync() {
			return AdminDatabaseService.obfuscateOrganisationsFTEAsync();
		}

		function deleteIndexAndRecreateOrganisations() {
			return AdminDatabaseService.deleteIndexAndRecreateOrganisations().then(function () {
				logger.info('Index recreated');
			});
		}

		function deleteIndexAndRecreateVisits() {
			return AdminDatabaseService.deleteIndexAndRecreateVisits().then(function () {
				logger.info('Index recreated');
			});
		}

		function enhanceFromCBITUsingDomain() {
			if (!vm.cbitDomain) {
				logger.error('You need to provide a domain!!');
				return;
			}
			logger.info('Updating from CBIT using domain ' + vm.cbitDomain);
			return AdminDatabaseService.enhanceFromCBITUsingDomain(vm.cbitDomain).then(function () {
				logger.info('Index recreated');
			});
		}

		function enhanceFromCBITUsingCountry() {
			logger.info('Updating from CBIT using country ');
			return AdminDatabaseService.enhanceFromCBITUsingDomain().then(function () {
				logger.info('Index recreated');
			});
		}

		function updateAllOrganisationsFromAlreadyEnhancedDataWhereNameMatches() {
			logger.info('Updating common data for all by matching on name');
			return AdminDatabaseService.updateAllOrganisationsFromAlreadyEnhancedDataWhereNameMatches().then(function () {
				logger.info('Finished updating');
			});
		}

		function updateMaxIP() {
			logger.info('Updating max ip on organisations');
			return AdminDatabaseService.updateMaxIP().then(function () {
				logger.info('Finished updating');
			});
		}

		function createDomainAttribute() {
			logger.info('Updating max ip on organisations');
			return AdminDatabaseService.createDomainAttribute().then(function () {
				logger.info('Finished updating');
			});
		}

		function onAccountForSelectBox(account) {
			vm.selectedAccount = account;
		}

		function getAccountForSelectBox(filterBy) {
			return _.filter(vm.accounts, function (account) {
				if (account.name && filterBy) {
					return account.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				}
			});
		}

		function enhanceFromNBKUsingCountry() {
			if (!vm.organisationsNBKMatchCountry || !vm.organisationsNBKMatchCountry.countryISO) {
				logger.error('Select country before exporting');
				return;
			}

			logger.info('Updating from NBK using country ' + vm.organisationsNBKMatchCountry.countryISO);
			return AdminDatabaseService.enhanceFromNBKUsingCountry(vm.organisationsNBKMatchCountry.countryISO).then(function () {
				logger.info('Index recreated');
			});
		}

		function getAllResellerAccounts() {
			return AccountService.getAllResellerAccounts();
		}

		function reIndexVisitsForReseller() {
			if (!vm.selectedResellerIdForReindex) {
				logger.error('Select Reseller before exporting');
				return;
			}

			logger.info('Reindexing all Accounts for Reseller ' + vm.selectedResellerIdForReindex);

			return AdminDatabaseService.reIndexVisitsForReseller(vm.selectedResellerIdForReindex);
		}

		function lowerCaseallSicNames() {
			return AdminDatabaseService.lowerCaseallSicNames();
		}

		function readAllIndustryCodesAndCreateTags() {
			return AdminDatabaseService.readAllIndustryCodesAndCreateTags();
		}

		function createDIndustryTags() {
			return AdminDatabaseService.createDIndustryTags();
		}

		function updateSwedishOrganisationTypesBasedOnOrganisationNumber() {
			return AdminDatabaseService.updateSwedishOrganisationTypesBasedOnOrganisationNumber();
		}

		function updateNorwayOrganisationTypesBasedOnOrganisationNumber() {
			return AdminDatabaseService.updateNorwayOrganisationTypesBasedOnOrganisationNumber();
		}

	}
})();