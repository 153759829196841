(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('Rule', [ Rule]);

    function Rule( ) {
        return function (id, field, operator, type) {
            var that = this;
            that.id = id;
            that.field = field;
            that.operator = operator;
            that.type = type;
            that.value = null; // single value
            that.valueFrom = null; // between
            that.valueTo = null; // between
            this.toJSON = function toJSON() {
                if( that.operator !== 'between'){
                    return {
                        id: that.id,
                        field: that.field,
                        operator: that.operator,
                        type: that.type,
                        value: that.value
                    };
                } else {
                    var values = [];
                    that.type = 'integerType';
                    if( that.valueFrom ){
                        values.push(that.valueFrom);
                    }
                    if( that.valueTo ){
                        values.push(that.valueTo);
                    }

                    return {
                        id: that.id,
                        field: that.field,
                        operator: that.operator,
                        type: that.type,
                        value: values
                    };
                }
            };
        };
    }
})
();
