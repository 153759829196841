var Highcharts = require('highcharts/highstock');

(function() {
	'use strict';

	angular.module('adminApp').service('VisitMapChart', [VisitMapChart]);

	function VisitMapChart() {
		return function(visit) {
			var that = this;
			this.visit = visit;
			this.config = {
				options: {
					chart: {
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 0,
						spacingTop: 0,
						spacingLeft: 0,
						spacingRight: 20
					},
					tooltip: {
						backgroundColor: '#FCFFC5',
						borderColor: 'black',
						borderRadius: 10,
						borderWidth: 1,
						formatter: function() {
							return '<b>' + this.point.value + '</b>';
						}
					},
					exporting: {
						enabled: false
					},
					legend: {
						enabled: false
					},
					colorAxis: {
						min: 0
					},
					mapNavigation: {
						enabled: false,
						enableDoubleClickZoomTo: false
					},
					plotOptions: {
						map: {
							allAreas: false  // zooms in
						},
						series: {
							animation: {
								duration: 400
							}
						}
					}
				},
				chartType: 'map',
				title: {
					text:  this.visit.organisation.name + ', ' + this.visit.organisation.address.city + ' (' +   this.visit.organisation.address.countryISO.toUpperCase() + ')'
				},

				series: [
					{
						data: [],
						nullColor: 'white',
						mapData: Highcharts.maps['custom/world'],
						joinBy: 'hc-key',
						name: 'A visit in ',
						states: {
							hover: {
								color: '#ffb752'
							}
						}
					}
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
				getData: function() {
					return that.config.series[0].data;
				}
			};

			initChart(visit);

			function initChart(visit) {
				var visitMapPoint = [{
					'hc-key': visit.countryIso,
					value: visit.organisation.name + ' in ' + visit.organisation.address.city
				}];
				that.config.setData(visitMapPoint);
			}
		};
	}
})();
