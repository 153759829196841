(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('ProductService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', ProductService]);

    function ProductService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getAllProductsForAccount = getAllProductsForAccount;
        this.getAllProducts = getAllProducts;
        this.getAllProductTypesAvailable = getAllProductTypesAvailable;
        this.getProduct = getProduct;
        this.createOrUpdateProduct = createOrUpdateProduct;

        function getAllProductsForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/products')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

         function getAllProductTypesAvailable(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/products/types/available')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAllProducts() {
            return $http.get( CONFIG.API_PREFIX + '/admin/products' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getProduct(accountId, productId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/accounts/' + accountId + '/products/' + productId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateProduct(accountid, product) {
            var promise;
            if (!(_.isNull(product.id) || product.id === undefined || product.id === '')) {
                promise = $http.put( CONFIG.API_PREFIX + '/admin/accounts/' + accountid + '/products/', product)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when updating '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/admin/accounts/' + accountid  + '/products/', product)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving '));
            }
            return promise;
        }
    }
})();