(function() {
	'use strict';

	angular.module('adminApp').service('TopReferrerPieChart', [TopReferrerPieChart]);

	function TopReferrerPieChart() {
		return function(visits) {
			var that = this;

			this.getItems = getItems;
			this.config = {
				options: {
					chart: {
						renderTo: 'container',
						type: 'pie',
						backgroundColor: 'rgba(255, 255, 255, 0.01)',
						spacingBottom: 20,
						spacingTop: 0,
						spacingLeft: 20,
						spacingRight: 20,
					},
					exporting: {
						enabled: false,
					},
					plotOptions: {
						series: {
							animation: {
								duration: 400,
							},
						},
					},
					tooltip: {
						formatter: function() {
							return '<b>' + this.point.name + '</b>: ' + this.y + ' referrals';
						},
					},
				},
				title: {
					text: '',
				},
				series: [
					{
						data: [],
						size: '100%',
						showInLegend: false,
						dataLabels: false,
					},
				],
				setData: function(data) {
					that.config.series[0].data = data;
				},
				getData: function() {
					return that.config.series[0].data;
				},
			};

			initChart(visits);

			function initChart(visits) {
				var numberOfVisitsPerReferrer = _.chain(visits)
					.groupBy(referrer)
					.map(toChartItem)
					.sortBy(mostReferrals)
					.value()
					.reverse()
					.slice(0, 10);

				that.config.setData(numberOfVisitsPerReferrer);
			}

			function referrer(visit) {
				var domain = getDomain(visit.referer);
				return domain ? domain : 'Unknown';
			}

			function getDomain(url) {
				if (!url) {
					return null;
				}
				var hostName = getHostName(url);
				var domain = hostName;

				if (hostName !== null) {
					var parts = hostName.split('.').reverse();

					if (parts !== null && parts.length > 1) {
						domain = parts[1] + '.' + parts[0];

						if (hostName.toLowerCase().indexOf('.co.uk') !== -1 && parts.length > 2) {
							domain = parts[2] + '.' + domain;
						}
					}
				}

				return domain;
			}

			function getHostName(url) {
				var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
				if (
					match !== null &&
					match.length > 2 &&
					typeof match[2] === 'string' &&
					match[2].length > 0
				) {
					return match[2];
				} else {
					return null;
				}
			}

			function toChartItem(visits, referrer) {
				return [referrer, visits.length];
			}

			function mostReferrals(chartItem) {
				return chartItem[1];
			}

			function getItems() {
				return that.config.getData();
			}
		};
	}
})();
