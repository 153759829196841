(function () {
    'use strict';

    // getTotalTrafficGroupedPerAccount
    angular
        .module('adminApp')
        .controller('TotalTrafficGroupedPerAccountController', ['TrackingStatisticsService', 'logger', '$filter', '$state',
            '$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort', TotalTrafficGroupedPerAccountController]);
    function TotalTrafficGroupedPerAccountController(TrackingStatisticsService, logger, $filter, $state,
                                        $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
        var vm = this;

        vm.loadMore = loadMore;
        vm.tableSize = tableSize;
        vm.doExportToExcel = doExportToExcel;
        vm.onTableSort = onTableSort;

        vm.trafficAccountTrackingStatistics = [];
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name', 'active'],
            reverse: false
        });

        ////////////
        //  Init
        activate();

        function activate() {
            getTotalTrafficGroupedPerAccount();
        }

        function getTotalTrafficGroupedPerAccount() {
            TrackingStatisticsService.getTotalTrafficGroupedPerAccount().then(showData);
        }

        function showData(trafficAccountTrackingStatistics) {
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
            vm.trafficAccountTrackingStatistics = trafficAccountTrackingStatistics;

            // group per reseller


        }
        /// End init
        ///////////////

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()],
                {type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
            var config = {
                data: data,
                filename: 'TrafficAccountTrackingStatistics_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            return vm.trafficAccountTrackingStatistics.length;
        }

    }
})();