(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SiteController', ['selectedAccountId', 'siteId', 'SiteService', 'CommonService', 'logger', '$q',
            '$state', SiteController]);
    function SiteController(selectedAccountId, siteId, SiteService, CommonService, logger, $q, $state) {
        var vm = this;
        vm.saveSite = saveSite;
        vm.isFormValid = isFormValid;
        vm.sendSiteScriptEmail = sendSiteScriptEmail;
        vm.validateReceivers = validateReceivers;

        vm.sendMailScriptTo = '';

        vm.siteId = siteId;
        vm.siteScript = '';

        // initial if creating new
        vm.site = {
            id: siteId,
            active : true,
            account: {
                id: selectedAccountId
            }
        };

        vm.selectedAccountId = selectedAccountId;




        ////////////
        //  Init

        activate();

        function activate() {
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getSite(), getSiteScript()]);
        }

        function getSite() {
            if (_.isNull(vm.siteId) || _.isEmpty(vm.siteId)) {
                // initial
                return vm.site;
            } else {
                return SiteService.getSite(vm.selectedAccountId, vm.siteId);
            }
        }

        function getSiteScript() {
            if (_.isNull(vm.siteId) || _.isEmpty(vm.siteId)) {
                // initial
                return '';
            } else {
                return SiteService.getSiteScript(vm.selectedAccountId, vm.siteId);
            }
        }

        // display the stuff when promises are finished
        function initView(result) {
            vm.site =  result[0];
            vm.siteScript =  result[1];

        }
        /// End init


        function isFormValid() {
            return true;
        }


        function saveSite() {
            return $q.all([onSave()]);
        }

        function onSave() {
            return SiteService.createOrUpdateSite(vm.selectedAccountId, vm.site)
                .then(function (data) {
                    vm.site = data;
                    showSaveSuccess();
                    return data;
                });
        }


        function validateReceivers(values) {
            var valid = false;
            _.each(values, function(email) {
                if( email.indexOf('@') === -1){
                    valid = false;
                } else {
                    valid = true;
                }
            });
            return valid;
        }

        function showSaveSuccess() {
            logger.success('Site saved');
            $state.go('admin.account', {accountId: vm.selectedAccountId}, {notify: true});

            //$state.go('admin.site', {selectedAccountId : vm.site.account.id, siteId: vm.site.id}, {notify: false});
            // get script after save
           /* SiteService.getSiteScript(vm.selectedAccountId, vm.site.id).then(function (data){
                vm.siteScript = data;
            });*/

        }

        function sendSiteScriptEmail(mailTo) {
            return $q.all([onSendSiteScriptEmail(mailTo)]);
        }

        function onSendSiteScriptEmail() {
            return SiteService.sendSiteScriptEmail(vm.site.account.id,vm.site.id, vm.sendMailScriptTo)
                .then(function (data) {
                    showMailSuccess();
                    return data;
                });
        }

        function showMailSuccess() {
            logger.success('Mail with Site script sent');
        }
    }
})();