(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('organisationIdFilter', ['filterValidation', 'filterConstants', '$animate', OrganisationIdFilter]);


    function OrganisationIdFilter(filterValidation, filterConstants, $animate) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/filters/organisation/organisationIdFilter.html',
            link: function (scope) {
				scope.opers = filterConstants.operands;
				scope.operands = [
					scope.opers.EQUAL,
					scope.opers.NOT_EQUAL,
					scope.opers.CONTAINS,
					scope.opers.DOES_NOT_CONTAINS,
					scope.opers.BEGIN_WITH,
					scope.opers.DOES_NOT_BEGIN_WITH
				];

                if(scope.filter.data.value === undefined) {
                    scope.filter.data.value = '';
                }

                if(scope.filter.data.operand === undefined) {
                    scope.filter.data.operand = scope.opers.EQUAL;
                }



                scope.$on('$destroy', function() {
                    $animate.enabled(true);
                });
            }
        };
    }

})();