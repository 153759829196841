(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('AdaptiveContentsController', ['account', 'AdaptiveContentService', 'FileSaver', 'Blob', 'CONFIG', '$q', 'TableSort', '$uibModal', AdaptiveContentsController]);

    function AdaptiveContentsController(account, AdaptiveContentService, FileSaver, Blob, CONFIG, $q, TableSort, $uibModal) {
        var vm = this;
        vm.targetings = null;
        vm.account = account;
        vm.ok = null;
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';
        vm.sort = new TableSort('adminTable', {
            by: ['name'],
            reverse: false
        });
        vm.targetingsLimit = CONFIG.INFINITE_SCROLL_STEP;

        vm.confirmDelete = confirmDelete;
        vm.tableSize = tableSize;
        vm.getTargetings = getTargetings;
        vm.doExportToExcel = doExportToExcel;
        vm.loadMore = loadMore;

        activate();

        function activate() {
            vm.account = account;
			console.log('99999')
            getInitialData().then(initView);
        }

        function getInitialData() {
            return $q.all([getTargetings()]);
        }

        function getTargetings() {
            return AdaptiveContentService.getTargetings(vm.account.id);
        }

        // use the stuff when promises are finished
        function initView(result) {
            vm.targetings = result[0];
            //$state.go('targetingstatistics', {accountId: vm.accountId} , {notify: false})
        }


        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'AdaptiveContent_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        // infinite scroll
        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.targetings) {
                return vm.targetings.length;
            }
        }


        function confirmDelete(targeting) {
            $uibModal.open({
                templateUrl: 'partials/common/confirm-warning.html',
                controller: 'ConfirmController as vm',
                size: 'md',
                resolve: {
                    modalConfig: function () {
                        return {
                            title: 'Slow down!',
                            body: 'Are you absolutely sure you want to delete the selected ' + targeting.name + ' Adaptive Content?',
                            positive: 'Yes',
                            negative: 'No'
                        };
                    }
                }
            }).result.then(function () {
                deleteItem(targeting.id);
            });
        }

        function deleteItem(id) {
            AdaptiveContentService.deleteInstance(vm.account.id, id)
                .then(function (targetings) {
                    vm.targetings = targetings;
                });
        }
    }
})();