(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('AnalyticsController', ['AnalyticsService', 'logger', '$filter', '$state',
			'$translate', 'FileSaver', 'Blob', 'CONFIG', '$scope', '$confirm', '$stateParams', 'TableSort',
			AnalyticsController]);

	function AnalyticsController(AnalyticsService, logger, $filter, $state,
								 $translate, FileSaver, Blob, CONFIG, $scope, $confirm, $stateParams, TableSort) {
		var vm = this;

		vm.loadMore = loadMore;
		vm.tableSize = tableSize;
		vm.doExportToExcel = doExportToExcel;
		vm.onTabldeSort = onTableSort;
		vm.loadAnalyticsData = loadAnalyticsData;


		vm.limit = 10;

		vm.selectedQuery = 1;
		vm.selectedDateRange = 'week';


		vm.refAnalyticQueries = [
			{id: 1, name: ' Total Visits grouped by Resellers & Accounts'},
			{id: 2, name: ' Total Visits grouped by Accounts'},
			{id: 3, name: ' Total Visits grouped by Account & Organisations (non ISP)'},
			{id: 4, name: ' Total Visits grouped by Date '}
		];

		vm.visitsGroupedByResellerAndAccount = [];
		vm.ok = null;
		vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		vm.searchFilter = '';
		vm.sort = new TableSort('adminTable', {
			by: ['name', 'active'],
			reverse: false
		});

		////////////
		//  Init
		activate();

		function activate() {
			//getVisitsGroupedByResellerAndAccount();
		}

		function getVisitsGroupedByResellerAndAccount() {
			AnalyticsService.getVisitsGroupedByResellerAndAccount().then(showData);
		}

		function loadAnalyticsData() {

			console.log('selectedDateRange', vm.selectedDateRange)
			console.log('selectedQuery', vm.selectedQuery)

			switch (vm.selectedQuery) {
				case 1:
					switch (vm.selectedDateRange) {
						case 'all':
							AnalyticsService.getTotalsGroupedByResellerAndAccountAll().then(showData);
							break;
						case 'ytd':
							AnalyticsService.getTotalsGroupedByResellerAndAccountYTD().then(showData);
							break;
						case 'month':
							AnalyticsService.getTotalsGroupedByResellerAndAccountStartOfMonth().then(showData);
							break;
						case 'week':
							AnalyticsService.getTotalsGroupedByResellerAndAccountStartOfWeek().then(showData);
							break;
					}

					break;
				case 2:
					switch (vm.selectedDateRange) {
						case 'all':
							AnalyticsService.getTotalsGroupedByAccountAll().then(showData);
							break;
						case 'ytd':
							AnalyticsService.getTotalsGroupedByAccountYTD().then(showData);
							break;
						case 'month':
							AnalyticsService.getTotalsGroupedByAccountStartOfMonth().then(showData);
							break;
						case 'week':
							AnalyticsService.getTotalsGroupedByAccountStartOfWeek().then(showData);
							break;
					}
					break;

				case 3:
					switch (vm.selectedDateRange) {
						case 'all':
							AnalyticsService.getTotalsGroupedByAccountAndOrganisationAll().then(showDataGroupedByAccountAndOrganisation);
							break;
						case 'ytd':
							AnalyticsService.getTotalsGroupedByAccountAndOrganisationYTD().then(showDataGroupedByAccountAndOrganisation);
							break;
						case 'month':
							AnalyticsService.getTotalsGroupedByAccountAndOrganisationStartOfMonth().then(showDataGroupedByAccountAndOrganisation);
							break;
						case 'week':
							AnalyticsService.getTotalsGroupedByAccountAndOrganisationStartOfWeek().then(showDataGroupedByAccountAndOrganisation);
							break;
					}
					break;

				case 4:
					switch (vm.selectedDateRange) {
						case 'all':
							AnalyticsService.getTotalsGroupedByDateAll().then(showDataGroupedByDate);
							break;
						case 'ytd':
							AnalyticsService.getTotalsGroupedByDateYTD().then(showDataGroupedByDate);
							break;
						case 'month':
							AnalyticsService.getTotalsGroupedByDateStartOfMonth().then(showDataGroupedByDate);
							break;
						case 'week':
							AnalyticsService.getTotalsGroupedByDateStartOfWeek().then(showDataGroupedByDate);
							break;
					}
					break;

				default:
				// code block
			}

		}


		function showData(data) {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
			vm.visitsGroupedByResellerAndAccount = data;
		}

		function showDataGroupedByAccountAndOrganisation(data) {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
			vm.visitsGroupedByAccountAndOrganisation = data;
		}

		function showDataGroupedByDate(data) {
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
			vm.visitsGroupedByDate = data;
		}

		/// End init
		///////////////

		// infinite scroll
		function loadMore() {
			vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
		}

		function doExportToExcel() {
			var data = new Blob([angular.element('#exportable-table').html()],
				{type: CONFIG.EXPORT_FILE_TYPE, encoding: CONFIG.EXPORT_FILE_ENCODING});
			var config = {
				data: data,
				filename: 'reselleraccounts_' + new Date().toISOString().substring(0, 10) + '.xls'
			};
			FileSaver.saveAs(config.data, config.filename);
		}

		function onTableSort(by) {
			//jshint unused:false
			vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
		}

		function tableSize() {
			return vm.visitsGroupedByResellerAndAccount.length;
		}
	}
})();