(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('numberDecimals', ['$filter', numberDecimalsFilter]);

    function numberDecimalsFilter($filter) {
        var numberFilter = $filter('number');
        return function (value, limit) {
            var maxLimit = limit || 10;
            return value % 1 !== 0 ? numberFilter(parseFloat(value), maxLimit).replace(/[.,]?[0]+$/, '') : numberFilter(parseFloat(value));
        };
    }

})();