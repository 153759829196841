(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('pageState', pageState);

    function pageState() {
        return function (value) {
            if (value === 'ACTIVE') {
                return 'Active';
            }
            if (value === 'DELETED') {
                return 'Deleted';
            }
            if (value === 'IN_ACTIVE') {
                return 'Not Active';
            }

            return '';
        };
    }

})();