(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('CampaignService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', CampaignService]);

    function CampaignService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getCampaigns = getCampaigns;
        vm.getCampaignAdforms = getCampaignAdforms;
        vm.getSegments = getSegments;
        vm.createOrUpdateCampaign = createOrUpdateCampaign;
        vm.deleteCampaign = deleteCampaign;
        vm.deleteCreative = deleteCreative;
        vm.publishCampaign = publishCampaign;
        vm.getCampaignRevenueModelsForAccount = getCampaignRevenueModelsForAccount;
        vm.createOrUpdateCampaignRevenueModel = createOrUpdateCampaignRevenueModel;
        vm.getCampaignRevenueModel = getCampaignRevenueModel;
        vm.getAdDimensions = getAdDimensions;

        function getCampaigns(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCampaign(accountId, id) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + id)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function deleteCreative(accountId, campaignId, campaignAdId) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/' + campaignId + '/ads/' + campaignAdId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when deleting creative'));
        }

        // find segments to add to campaign
        function getSegments(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/segments')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaignAdforms(accountId, campaignId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/adf/' + campaignId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function publishCampaign(accountId, campaign) {
            if (campaign && campaign.dsp === 'Adforms') {
                return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/adf/' + campaign.id + '/publish/adforms' )
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data '));
            }
            /*if (campaign && campaign.dsp === 'Leadenhancer') {
                return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/adf/'  + campaign.id + '/publish/leadenhancer/' )
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data '));
            }*/

        }

        function createOrUpdateCampaign(accountId, campaign) {
            var promise;
            if (!(_.isNull(campaign.id) || campaign.id === undefined || campaign.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/adf', campaign)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/adf', campaign)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign '));
            }
            return promise;
        }

        function createOrUpdateCampaignRevenueModel(accountId, campaignRevenueMddel) {
            var promise;
            if (!(_.isNull(campaignRevenueMddel.id) || campaignRevenueMddel.id === undefined || campaignRevenueMddel.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/', campaignRevenueMddel)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign revenue model'));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/', campaignRevenueMddel)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving campaign revenue model'));
            }
            return promise;
        }


        function getCampaignRevenueModelsForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaignRevenueModel(accountId, revenueModelId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/campaigns/revenuemodels/' + revenueModelId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAdDimensions(){
            return $http.get(CONFIG.API_PREFIX + '/adDimensions')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));

        }
    }
})();