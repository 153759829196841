(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('autocompleteInput', ['$timeout', AutocompleteInput]);

    function AutocompleteInput($timeout) {
        return {
            restrict: 'E',
            templateUrl: 'partials/common/advancedfilters/inputs/autocomplete-input.html',
            require: 'ngModel',
            scope: {
                options: '=',
                placeholder: '='
            },
            link: function (scope, elem, attrs, ngModel) {
                var select = $(elem).find('select');
                ngModel.$render = function () {
                    scope.value = ngModel.$modelValue;
                    $(select).trigger('chosen:updated');
                };

                scope.$watch('value', function (newVal, oldVal) {
                    if(newVal !== oldVal) {
                        ngModel.$setViewValue(newVal);
                    }
                });

                $timeout(function () {
                    scope.value = ngModel.$modelValue;
                    $(select).chosen({
                        'allow_single_deselect': true,
                        'width': '100%',
                        'inherit_select_classes': true,
                        'max_shown_results': 5
                    });
                });
            }
        };
    }


})();