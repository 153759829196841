// http://stackoverflow.com/questions/28677638/angularjs-1-3-ng-change-like-functionality-for-the-entire-form
(function () {
    'use strict';

    angular
        .module('adminApp')
        .directive('leFormOnChange', ['$parse',FormOnChangeDirective]);

    function FormOnChangeDirective($parse) {
        return {
            require: 'form',
            link: function (scope, element, attrs) {
                var callBackMethod = $parse(attrs.leFormOnChange);
                element.on('change', function () {
                    callBackMethod(scope);
                });
            }
        };
    }
})();

