(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('DOBOrganisationService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', DOBOrganisationService]);

    function DOBOrganisationService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getOrganisation = getOrganisation;
        this.search = search;
        this.findAllWithLimit = findAllWithLimit;
        this.addClearBitToLEORganisation = addClearBitToLEORganisation;

        function getOrganisation(cbid, config) {

            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/clearbit/' + cbid, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function search(name, countryIso, limit, config) {
            var queryUrl = null;
            if( name ){
                queryUrl = 'q=' + name;
            }
            if( countryIso ){
                queryUrl = queryUrl + '&countryIso=' + countryIso + '&limit=' + limit;
            }
            if(!queryUrl){
                return;
            }
            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/dob?' + queryUrl, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function findAllWithLimit(limit, config) {

            return $http.get( CONFIG.API_PREFIX + '/enhance/organisations/dob?limit=' + limit, config )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function addClearBitToLEORganisation(selectedNbkOrganisation){
            var promise;
            if (!(_.isNull(selectedNbkOrganisation.duns))) {
                promise = $http.put(CONFIG.API_PREFIX + '/enhance/organisations/dob/takeover', selectedNbkOrganisation)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when retrieving data'));
            }
            return promise;

        }
    }
})();