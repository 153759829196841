class NoDataController {
	constructor($attrs) {
		'ngInject';

		this.isGood = 'isGood' in $attrs;
	}
}

const template = require('./no-data.component.html');

const NoDataComponent = {
	template,
	controller: NoDataController,
	bindings: { message: '<' },
};

module.exports = NoDataComponent;
