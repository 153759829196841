(function () {
	'use strict';

	angular
		.module('adminApp')
		.factory('VisitFilterSearchResultsTableModel', ['CommonService', 'AuthService' , '$state', 'cellRenderers', VisitFilterSearchResultsTableModel]);

	function VisitFilterSearchResultsTableModel(CommonService, AuthService, $state, CellRenderers) {

		function jumpToVisitCellRenderer() {
			return '<a ui-sref="admin.visit(  {accountId : data.accountId, visitId : data.id } )" target="_blank" class="pointer" ng-bind="data.organisation.name"></a>';
		}

		var columnDefs = [
			{
				minWidth: 188,
				headerName: 'Name',
				field: 'name',
				valueGetter: function (params) {
					return params.data.organisation.name;
				},
				editable : true,
				cellRenderer: jumpToVisitCellRenderer
			},{
				minWidth: 110,
				headerName: 'First visit',
				field: 'isFirstVisit',
				valueGetter: function (params) {
					if( params.data.isFirstVisit ){
						return 'Yes';
					}
					return 'No';
				},
				editable : false,
				cellRenderer: CellRenderers.BooleanCellRenderer

			},

			{
				hide: false,
				minWidth: 160,
				field: 'keywords',
				headerName: 'Keywords',
				valueGetter: function (params) {
					return params.data.keywords;
				},
				editable : true,
				cellRenderer: CellRenderers.VisitKeywordsCellRenderer
			},
			{
				hide: false,
				minWidth: 160,
				field: 'pageViews',
				headerName: 'Page Views',
				valueGetter: function (params) {
					return params.data.pageViews.length;
				},
				editable : false,
				cellRenderer: CellRenderers.VisitPageViewsCellRenderer,
				cellRendererParams: {
					popupGetter: function (params) {
						return params.data.pageViews.map(function (c) {
							//console.log('',c)
							if( c.pageJson && c.pageJson.name ){
								return c.pageJson.name ;//+ ' ' + c.start;  // what will be shown in pupup
							}
							return '';
						});
					},
					filterValue: function () {
						return '';
					},
					valueGetter: function (params) {
						return  params.data.pageViews.length ;
					},
					hrefGetter: function () {
						/*jshint scripturl:true*/
						return 'javascript:void(0)';
					}
				}
			},
			{
				minWidth: 110,
				headerName: 'Score',
				valueGetter: function (params) {
					return params.data.visitScore;
				},
				editable: true
			},
			{
				minWidth: 100,
				headerName: 'Country',
				valueGetter: function (params) {
					if (params.data.organisation.address && params.data.organisation.address.countryISO) {
						return params.data.organisation.address.countryISO.toUpperCase();
					}
					return '';
				},
				cellRenderer: CellRenderers.CountryCellRenderer

			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'Domain',
				field: 'www',
				valueGetter: function (params) {
					if (params.data.domain) {
						return params.data.domain ;
					}
					if (params.data.organisation.address && params.data.organisation.address.www) {
						return params.data.organisation.address.www;
					}
					return '';

				},
				cellRenderer: CellRenderers.DomainCellRenderer
			},
			{
				hide: true,
				minWidth: 130,
				headerName: 'Region',
				valueGetter: function (params) {
					if (params.data.organisation.address && params.data.organisation.address.region) {
						return params.data.organisation.address.region;
					}
					return '';
				},
				editable : true
			},
			{
				minWidth: 138,
				headerName: 'City',
				valueGetter: function (params) {
					if (params.data.organisation.address && params.data.organisation.address.city) {
						return params.data.organisation.address.city;
					}
					return '';
				},
				editable : true
			},
			{
				hide: true,
				minWidth: 80,
				headerName: 'Postal code',
				valueGetter: function (params) {
					if (params.data.organisation.address && params.data.organisation.address.postalCode) {
						return params.data.organisation.address.postalCode;
					}
					return '';
				},
				editable : true
			},
			{
				hide: true,
				minWidth: 138,
				headerName: 'Address',
				valueGetter: function (params) {
					if (params.data.organisation.address && params.data.organisation.address.address) {
						return params.data.organisation.address.address;
					}
					return '';
				}
			},
			{
				minWidth: 138,
				headerName: 'Org Nr',
				valueGetter: function (params) {
					return params.data.organisation.organisationId;
				},
				editable: true
			},

			{
				hide: true,
				minWidth: 138,
				headerName: 'DUNS',
				valueGetter: function (params) {
					return params.data.organisation.duns;
				},
				editable: true
			},
			{
				minWidth: 188,
				headerName: 'Industry Tags ',
				field: 'industryTags',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.industryTags !== null ){
						result = params.data.organisation.industryTags;
					}
					return result;
				}
			},
			{
				minWidth: 138,
				headerName: 'Type',
				valueGetter: function (params) {
					if (params.data.organisation.organisationType === 'nonProfit') {
						return 'Non profit';
					}
					if (params.data.organisation.organisationType === 'soletrader') {
						return 'Solo trader';
					}
					if (params.data.organisation.organisationType === 'limitedPartnerShip') {
						return 'Limited partnership';
					}
					if (params.data.organisation.organisationType === 'limitedCompany') {
						return 'Limited company';
					}
					if (params.data.organisation.organisationType === 'economicAssociation') {
						return 'Economic association';
					}
					if (params.data.organisation.organisationType === 'municipalCorporation') {
						return 'Municipal corporation';
					}
					if (params.data.organisation.organisationType === 'nonProfit') {
						return 'Non profit';
					}
					if (params.data.organisation.organisationType === 'notActive') {
						return 'Not active';
					}


					return params.data.organisation.organisationType;
				},
				editable: true
			},
			{
				minWidth: 200,
				headerName: 'SIC',
				field: 'sicPrimaryCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sicPrimaryCode !== null ){
						result = params.data.organisation.sicPrimaryCode + ' - ' +  params.data.organisation.sicPrimaryCodeDescription;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'SIC 2',
				field: 'sicCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sicCode2 !== null ){
						result = params.data.organisation.sicCode2 + ' - ' +  params.data.organisation.sicText2;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'SIC 3',
				field: 'sicCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sicCode3 !== null ){
						result = params.data.organisation.sicCode3 + ' - ' +  params.data.organisation.sicText3;
					}

					return result.toLocaleLowerCase();
				}
			},
			{
				hide: false,
				minWidth: 200,
				headerName: 'NACE',
				field: 'sniNaceCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sniNaceCode !== null ){
						result = params.data.organisation.sniNaceCode + ' - ' +  params.data.organisation.sniNaceCodeDescription;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				hide: true,
				minWidth: 200,
				headerName: 'NACE 2',
				field: 'sniNaceCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sniNaceCode2 !== null ){
						result = params.data.organisation.sniNaceCode2 + ' - ' +  params.data.organisation.sniNaceCodeText2;
					}
					return result.toLocaleLowerCase();
				}


			},{
				hide: true,
				minWidth: 200,
				headerName: 'NACE 3',
				field: 'sniNaceCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.sniNaceCode3 !== null ){
						result = params.data.organisation.sniNaceCode3 + ' - ' +  params.data.organisation.sniNaceCodeText3;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: false,
				minWidth: 200,
				headerName: 'NAICS',
				field: 'naicsCode',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.naicsCode !== null ){
						result = params.data.organisation.naicsCode + ' - ' +  params.data.organisation.naicsCodeText;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'NAICS 2',
				field: 'naicsCode2',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.naicsCode2 !== null ){
						result = params.data.organisation.naicsCode2 + ' - ' +  params.data.organisation.naicsText2;
					}
					return result.toLocaleLowerCase();
				}
			},{
				hide: true,
				minWidth: 200,
				headerName: 'NAICS 3',
				field: 'naicsCode3',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.naicsCode3 !== null ){
						result = params.data.organisation.naicsCode3 + ' - ' +  params.data.organisation.naicsText3;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				hide: false,
				minWidth: 200,
				headerName: 'GICS Sector',
				field: 'gicsSectorCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.gicsSectorCode1 !== null ){
						result = params.data.organisation.gicsSectorCode1 + ' - ' +  params.data.organisation.gicsSectorTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Industry Group',
				field: 'gicsIndustryGroupCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.gicsIndustryGroupCode1 !== null ){
						result = params.data.organisation.gicsIndustryGroupCode1 + ' - ' +  params.data.organisation.gicsIndustryGroupTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Industry',
				field: 'gicsIndustryCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.gicsIndustryCode1 !== null ){
						result = params.data.organisation.gicsIndustryCode1 + ' - ' +  params.data.organisation.gicsIndustryTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},
			{
				hide: true,
				minWidth: 200,
				headerName: 'GICS Sub Industry',
				field: 'gicsIndustryCode1',
				editable: true,
				valueGetter: function (params) {
					var result = '';
					if( params.data.organisation.gicsSubIndustryCode1 !== null ){
						result = params.data.organisation.gicsSubIndustryCode1 + ' - ' +  params.data.organisation.gicsSubIndustryTxt1;
					}
					return result.toLocaleLowerCase();
				}
			},

			{
				minWidth: 138,
				headerName: 'No Employees',
				valueGetter: function (params) {
					return params.data.organisation.noOfEmployees;
				},
				editable : true
			},
			{
				hide: true,
				minWidth: 80,
				headerName: 'Sales Euro',
				valueGetter: function (params) {
					return params.data.organisation.sales;
				},
				editable : true
			},
			{
				hide: false,
				minWidth: 150,
				headerName: 'Referer',
				valueGetter: function (params) {
					return params.data.referer;
				},
				editable : true
			},
			{
				minWidth: 140,
				headerName: 'ISP',
				field: 'isIsp',
				valueGetter: function (params) {
					if( params.data.isIsp ){
						return 'Yes';
					}
					return 'No';
				},
				cellRenderer: CellRenderers.BooleanCellRenderer
			},
			{
				minWidth: 138,
				headerName: 'Networks',
				field: 'noOfNetworks',
				valueGetter: function (params) {
					return params.data.organisation.noOfNetworks;
				}
			},
			{
				minWidth: 138,
				headerName: 'Enh Score',
				field: 'enhancementScore',
				valueGetter: function (params) {
					return params.data.organisation.enhancementScore;
				}
			},
			{
				hide: false,
				minWidth: 138,
				headerName: 'LEid',
				field: 'id',
				editable : true
			}
		];

		return {
			columnDefs: columnDefs
		};
	}
})();