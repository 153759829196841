(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('TrackingLinkService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', TrackingLinkService]);

    function TrackingLinkService($http, $q, CONFIG, responseExceptionCatcher) {
        var vm = this;
        vm.getClicks = getClicks;
        vm.getTrackingLink = getTrackingLink;
        vm.createOrUpdateClick = createOrUpdateClick;
        vm.deleteTrackingLink = deleteTrackingLink;
        vm.countNumberOfClicksForAccount = countNumberOfClicksForAccount;
        vm.countNumberOfTotalClickHitsForAccount = countNumberOfTotalClickHitsForAccount;

        function getClicks(accountId, clickCampaignId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/clickcampaigns/' + clickCampaignId + '/trackinglinks')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getTrackingLink(accountId, clickCampaignId, clickId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/clickcampaigns/' + clickCampaignId + '/trackinglinks/' + clickId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function createOrUpdateClick(accountId, clickCampaignId, trackingLink) {
            var promise;
            if (!(_.isNull(trackingLink.id) || trackingLink.id === undefined || trackingLink.id === '')) {
                promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/clickcampaigns/' + clickCampaignId + '/trackinglinks', trackingLink)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving click '));
            } else {
                promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/clickcampaigns/' + clickCampaignId + '/trackinglinks', trackingLink)
                    .then(function (response) {
                        return response.data.data;
                    })
                    .catch(responseExceptionCatcher.catch('Error when saving click '));
            }
            return promise;
        }

        function deleteTrackingLink(accountId, clickCampaignId, trackingLinkId) {
            return $http.delete(CONFIG.API_PREFIX + '/accounts/' + accountId + '/clickcampaigns/' + clickCampaignId + '/trackinglinks/' + trackingLinkId)
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function countNumberOfClicksForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId  + '/trackinglinks/count' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function countNumberOfTotalClickHitsForAccount(accountId) {
            return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId  + '/trackinglinks/count' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();