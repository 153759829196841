(function () {
    'use strict';

    angular
        .module('adminApp')
        .filter('yesNo', yesNoFilter);

    function yesNoFilter() {
        return function (value) {
            return value ? 'Yes' : ' No';
        };
    }

})();