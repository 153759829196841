(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('VisitorsController', ['account', 'startDate', 'endDate', '$document','$q', 'CONFIG', 'FileSaver', 'TableSort', '$state', 'logger', '$localStorage', 'VisitorService', 'STATE', VisitorsController]);

    function VisitorsController(account, startDate, endDate, $document, $q, CONFIG, FileSaver, TableSort, $state, logger, $localStorage, VisitorService, STATE) {
        var vm = this;

        vm.onVisitorClick = onVisitorClick;
        vm.onTableSort = onTableSort;
        vm.doExportToExcel = doExportToExcel;
        vm.loadMore = loadMore;
        vm.tableSize = tableSize;


        vm.account = account;
        vm.visitors = [];
        vm.visitors.selected = [];
        vm.start = startDate ? moment(startDate, 'YYYY-MM-DD').toDate() : moment().subtract(30, 'days').toDate();
        vm.end = endDate ? moment(endDate, 'YYYY-MM-DD').toDate() : moment().toDate();
        vm.sort = new TableSort('adminTable', {
            by: ['startDate'],
            reverse: true
        });
        vm.sortTopScore = new TableSort('adminTable', {
            by: ['totalScore'],
            reverse: true
        });
        vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        vm.searchFilter = '';


        vm.$storage = $localStorage.$default({showHelp: false});

        activate();

        function activate() {
            $document.scrollTopAnimated(0);

            getInitialData()
                .then(showInitialView);

        }

        function getInitialData() {
            return $q.all([getVisitors()]);
        }

        function getVisitors() {
            return VisitorService.findVisitors(vm.account.id, vm.account.defaultSiteId, format(vm.start), format(vm.end));
        }

        function format(date) {
            return moment(date).format('YYYY-MM-DD');
        }

        // use the stuff when promises are finished
        function showInitialView(result) {
            vm.visitorResults = result[0];
            postReloadVisits();
        }


        function postReloadVisits() {
            vm.visitors = vm.visitorResults;

/*            vm.visitsGroupedByTopScore = _.chain(vm.visits)
                // --> orgna
                .groupBy(function (visit) {
                    return visit.organisation.name;
                })
                .map(function (visit, key) {
                    var sum = _.reduce(visit, function (memo, val) {
                            return memo + val.visitScore;
                        }, 0
                    );
                    return {name: key, countryIso: visit[0].countryIso, city: visit[0].city, leId: visit[0].organisation.leId, totalScore: sum};
                })
                .value();

            vm.visitsGroupedByTopScore = _.sortBy(vm.visitsGroupedByTopScore, function (visit) {
                return -visit.totalScore;  // revert order
            });
            */


            if (!vm.visitors.length) {
                logger.info('No visitors found!');
            }
        }

        function onVisitorClick(visitor) {
            $state.go(STATE.VISITS, {accountId: vm.account.id, visitorId: visitor.id});
        }

        function onTableSort(by) {
            //jshint unused:false
            vm.scrollLimit = CONFIG.INFINITE_SCROLL_STEP;
        }

        /*
        function checkAll() {
            vm.visitors.selected = angular.copy(vm.visits);
        }

        function deCheckAll() {
            vm.visitors.selected = null;
        }*/

        function doExportToExcel() {
            var data = new Blob([angular.element('#exportable-table').html()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            var config = {
                data: data,
                filename: 'VisitCampaigns_' + new Date().toISOString().substring(0, 10) + '.xls'
            };
            FileSaver.saveAs(config.data, config.filename);
        }

        function loadMore() {
            vm.scrollLimit += CONFIG.INFINITE_SCROLL_STEP;
        }

        function tableSize() {
            if (vm.visitors) {
                return vm.visitors.length;
            }
        }
    }
})();