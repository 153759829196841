(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('QueryBuilder', ['RuleGroup','Rule','RULE_FIELDS',QueryBuilder]);

    function QueryBuilder(RuleGroup, Rule, RULE_FIELDS) {
        return function (limit) {
            var that = this;
            //this.BOOL_FILTER_TYPES = BOOL_FILTER_TYPES;
            this.limit = limit;
            this.ruleGroups = [];

            this.ruleGroups.push( new RuleGroup('and',true));

            this.addRuleGroup = function addRuleGroup(currentRuleGroup) {
                var newGroup = new RuleGroup('and');
                currentRuleGroup.ruleGroups.push(newGroup);
            };

            this.addRule = function addRule(currentRuleGroup) {
                var newRule = new Rule( new Date() , RULE_FIELDS.properties[ RULE_FIELDS.organisationsName].name, 'textMatch', 'stringType');
                currentRuleGroup.rules.push(newRule);
            };

            this.addNewRule = function addNewRule(ruleGroupIndex, newRule) {
                var ruleGroup = that.ruleGroups[ruleGroupIndex];
                ruleGroup.addRule(newRule);
            };

            this.addNewRuleToRootGroup = function addNewRuleToRootGroup(newRule) {
                var ruleGroup = that.ruleGroups[that.ruleGroups.length-1];
                ruleGroup.addRule(newRule);
            };

            this.removeRule = function removeRule(currentRuleGroup, currentRule) {
                currentRuleGroup.removeRule( currentRule );
            };

            this.removeRuleGroup = function removeRuleGroup(currentRuleGroup) {
                currentRuleGroup.ruleGroups = [];
            };

            this.getRuleGroups = function getRuleGroups() {
                return that.ruleGroups;
            };

            this.getRules = function getRules(ruleGroupIndex) {
                return that.ruleGroups[ruleGroupIndex].getRules() ;
            };

            this.getRuleGroup = function getRuleGroup(index) {
                return that.ruleGroups[index];
            };

            this.changeConditionToAnd = function changeConditionToAnd(currentRuleGroup) {
                currentRuleGroup.condition = 'and';
            };

            this.changeConditionToOr = function changeConditionToOr(currentRuleGroup) {
                currentRuleGroup.condition = 'or';
            };

            this.changeLimitOnQuery = function changeLimitOnQuery(limit) {
                that.limit = limit;
            };

            this.isQueryValid = function isQueryValid() {
                var root = this.ruleGroups[0];
                return !!root.isValid();
            };

            this.toJSON = function toJSON() {
                var root = this.ruleGroups[0];
                return {
                    limit: this.limit,
                    ruleGroup: root

                };
            };
        };
    }
})
();
