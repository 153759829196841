(function () {
	'use strict';

	angular
		.module('adminApp')
		.controller('LEOrganisationController', ['organisationId',  'account', 'LEOrganisationService', 'CommonService', 'logger', '$q', 'SegmentAdvancedFilterService',
			'NBKOrganisationService', '$uibModal', '$scope',
			LEOrganisationController]);

	function LEOrganisationController(organisationId, account, LEOrganisationService, CommonService, logger, $q, SegmentAdvancedFilterService, NBKOrganisationService, $uibModal, $scope) {
		var vm = this;
		vm.saveOrganisation = saveOrganisation;
		vm.onSaveOrganisation = onSaveOrganisation;
		vm.getNACECodesForSelectBoxFuzzySearch = getNACECodesForSelectBoxFuzzySearch;
		vm.getNAICSodesForSelectBoxFuzzySearch = getNAICSodesForSelectBoxFuzzySearch;
		vm.getSICCodesForSelectBoxFuzzySearch = getSICCodesForSelectBoxFuzzySearch;
		vm.getCountriesForSelectBox = getCountriesForSelectBox;
		vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
		vm.onSetCountryForSelectBox = onSetCountryForSelectBox;
		vm.getSICCodesForSelectBox = getSICCodesForSelectBox;
		vm.onSetSICCodesForSelectBox = onSetSICCodesForSelectBox;
		vm.getSNICodesForSelectBox = getSNICodesForSelectBox;
		vm.getGICSSubIndustryCodesForSelectBox = getGICSSubIndustryCodesForSelectBox;
		vm.onSetGICSSubIndustryCodesForSelectBox = onSetGICSSubIndustryCodesForSelectBox;
		vm.tableSize = tableSize;
		vm.onFuzzySearchWithNACE = onFuzzySearchWithNACE;

		//
		vm.onNBKSearchByDUNS = onNBKSearchByDUNS;
		vm.onNBKSearchByOrgid = onNBKSearchByOrgid;
		vm.onNBKSearchByName = onNBKSearchByName;
		vm.onNBKModalSearch = onNBKModalSearch;
		vm.takeOverNBKORganisation = takeOverNBKORganisation;
		vm.queryNBKForOrgName = null;
		//vm.queryNBKForCountryISO = null;
		vm.queryNBKForOrgId = null;
		vm.queryNBKForDUNS = null;
		vm.showNBKBuyButton = true;

		vm.onSetSICCodesForSelectBox = onSetSICCodesForSelectBox;
		vm.onSetSIC2CodesForSelectBox = onSetSIC2CodesForSelectBox;
		vm.onSetSIC3CodesForSelectBox = onSetSIC3CodesForSelectBox;
		vm.onSetSNICodesForSelectBox = onSetSNICodesForSelectBox;
		vm.onSetSNI2CodesForSelectBox = onSetSNI2CodesForSelectBox;
		vm.onSetSNI3CodesForSelectBox = onSetSNI3CodesForSelectBox;
		vm.onSetNAICSCodesForSelectBox = onSetNAICSCodesForSelectBox;
		vm.onSetNAICS2CodesForSelectBox = onSetNAICS2CodesForSelectBox;
		vm.onSetNAICS3CodesForSelectBox = onSetNAICS3CodesForSelectBox;

		// when editing an organisation
		vm.validateAndUpdateOrganisationAllByName = validateAndUpdateOrganisationAllByName;
		vm.validateAndUpdateOrganisationAllByNamePartial = validateAndUpdateOrganisationAllByNamePartial;
		vm.validateAndUpdateOrganisationAllBySegmentIndustryOnly = validateAndUpdateOrganisationAllBySegmentIndustryOnly;
		vm.validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly = validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly
		// use for updating types;
		vm.countAllByName = countAllByName;


		vm.selectedSegmentIdForMultiUpdate = null;
		vm.account = account;
		vm.organisationId = organisationId;
		vm.organisation = null;
		vm.selectedCountry = null;
		// ref
		vm.refOrganisationTypes = [
			{id : 'limitedCompany' , name : 'Limited company'},
			{id : 'soletrader' , name : 'Solo Trader'},
			{id : 'limitedPartnerShip' , name : 'Limited partnership'},
			{id : 'economicAssociation' , name : 'Economic Association'},
			{id : 'municipalCorporation' , name : 'Municipal corporation'},
			{id : 'nonProfit' , name : 'Non profit'},
			{id : 'notActive' , name : 'Not Active'}
		];

		vm.refCountries = [];
		vm.refSNICodes = [];
		vm.refSICCodes = [];
		vm.refNAICSCodes = [];
		vm.refGICSSubIndustryCodes = [];  // hiearchical
		vm.refGICSTableRows = null;
		vm.allMathingByName = null;
		vm.refSegmentFilters = []; // for admin multi update


		////////////
		//  Init
		activate();

		function activate() {
			getInitialData().then(initView);
		}

		function getInitialData() {
			return $q.all([getOrganisation(), getCountries(), getSNICodes(), getSICCodes(), getNAICSCodes(), getGICSCodes(), findAllForAccountAndUseForMultiUpdates()]);
		}

		function getOrganisation() {
			return LEOrganisationService.getOrganisation(vm.organisationId);
		}

		function countAllByName(name) {
			return LEOrganisationService.countAllByName(name).then(function(result){
					console.log('countAllByName' , result);

					vm.allMathingByName = result;
			});
		}

		function getGICSCodes() {
			return CommonService.getGICSCodes();
		}

		function findAllForAccountAndUseForMultiUpdates() {

			if( vm.account && vm.account.id){
				return SegmentAdvancedFilterService.findAllForAccountAndUseForMultiUpdates(vm.account.id);
			}

		}

		// display the stuff when promises are finished
		function initView(result) {
			vm.organisation = result[0];
			console.log('', vm.organisation)
			vm.refCountries = result[1];
			vm.refSNICodes = result[2];
			vm.refSICCodes = result[3];
			vm.refNAICSCodes = result[4];
			vm.refGICSCodes = result[5];
			vm.refSegmentFilters = result[6];
			vm.refGICSSubIndustryCodes = vm.refGICSCodes.gicsSubIndustryCode;
			vm.refGICSTableRows = vm.refGICSCodes.tableRows;
			onInitSetCountryFromOrganisation(vm.organisation.address.countryISO);
			countAllByName(vm.organisation.name);
		}

		/// End init

		function getCountries() {
			return CommonService.getCountries();
		}

		function getSNICodes() {
			return CommonService.getNACECodes();
		}

		function getSICCodes() {
			return CommonService.getSICCodes();
		}

		function getNAICSCodes() {
			return CommonService.getNAICSCodes();
		}

		// initialize on activate
		function onInitSetCountryFromOrganisation(countryIso) {
			if (countryIso) {
				// select uses afull json object
				vm.selectedCountryIso = _.find(vm.refCountries, function (country) {
					if (countryIso === country.countryISOLowerCase) {
						return country;
					}
				});
			}
		}

		function saveOrganisation() {
			return $q.all([onSaveOrganisation()]);
		}

		function onSaveOrganisation() {
			LEOrganisationService.updateOrganisation(vm.organisation)
				.then(function (data) {
					vm.organisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function validateAndUpdateOrganisationAllByName() {
			LEOrganisationService.validateAndUpdateOrganisationAllByName(vm.organisation)
				.then(function (data) {
					vm.organisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function validateAndUpdateOrganisationAllByNamePartial() {
			LEOrganisationService.validateAndUpdateOrganisationAllByNamePartial(vm.organisation)
				.then(function (data) {
					vm.organisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function validateAndUpdateOrganisationAllBySegmentIndustryOnly() {
			LEOrganisationService.validateAndUpdateOrganisationAllBySegmentIndustryOnly(vm.organisation,
				vm.selectedSegmentIdForMultiUpdate.id)
				.then(function (data) {
					vm.organisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly() {
			LEOrganisationService.validateAndUpdateOrganisationIndustryTypeBySegmentIndustryOnly(vm.organisation,
				vm.selectedSegmentIdForMultiUpdate.id)
				.then(function (data) {
					vm.organisation = data;
					showSaveSuccess();
					return data;
				});
		}

		function showSaveSuccess() {
			logger.success('Organisation updated !!');
		}


		// typeahead callback
		function getSNICodesForSelectBox(filterBy) {
			var ttt = _.filter(vm.refSNICodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.name && filterBy && isString) {
					return code.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
			console.log('nf',ttt)
			return ttt;
		}

		// fuzzy search
		function getNACECodesForSelectBoxFuzzySearch(searchBy) {
			return CommonService.naceCodesSearch(searchBy);
		}

		function getNAICSodesForSelectBoxFuzzySearch(searchBy) {
			return CommonService.naicsCodesSearch(searchBy);
		}

		function getSICCodesForSelectBoxFuzzySearch(searchBy) {
			return CommonService.sicCodesSearch(searchBy);
		}

		function onSetSNICodesForSelectBox(item) {
			vm.organisation.sniNaceCode = item.code;
			vm.organisation.sniNaceCodeDescription = item.name;
		}

		function onSetSNI2CodesForSelectBox(item) {
			vm.organisation.sniNaceCode2 = item.code;
			vm.organisation.sniNaceCodeText2 = item.name;
		}

		function onSetSNI3CodesForSelectBox(item) {
			vm.organisation.sniNaceCode3 = item.code;
			vm.organisation.sniNaceCodeText3 = item.name;
		}

		function onSetNAICSCodesForSelectBox(item) {
			vm.organisation.naicsCode = item.code;
			vm.organisation.naicsCodeText = item.description;
		}

		function onSetNAICS2CodesForSelectBox(item) {
			vm.organisation.naicsCode2 = item.code;
			vm.organisation.naicsText2 = item.description;
		}

		function onSetNAICS3CodesForSelectBox(item) {
			vm.organisation.naicsCode3 = item.code;
			vm.organisation.naicsText3 = item.description;
		}


		// typeahead callback
		function getSICCodesForSelectBox(filterBy) {
			return _.filter(vm.refSICCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.name && filterBy && isString) {
					return code.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}

		function onSetSICCodesForSelectBox(item) {
			vm.organisation.sicPrimaryCode = item.code;
			vm.organisation.sicPrimaryCodeDescription = item.name;
		}

		function onSetSIC2CodesForSelectBox(item) {
			vm.organisation.sicCode2 = item.code;
			vm.organisation.sicText2 = item.name;
		}

		function onSetSIC3CodesForSelectBox(item) {
			vm.organisation.sicCode3 = item.code;
			vm.organisation.sicText3 = item.name;
		}

		// typeahead callback
		function getCountriesForSelectBox(filterBy) {
			return _.filter(vm.refCountries, function (country) {
				if (country.name && filterBy) {
					return country.name.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				}
			});
		}

		function onSetCountryForSelectBox(item) {
			vm.organisation.address.countryISO = item.countryISO;
			vm.organisation.countryDescription = item.name;
		}

		function onSetNACEForMultiSearch(){
			vm.organisation.sniNaceCode = item.code;
			vm.organisation.sniNaceCodeDescription = item.name;
		}

		// typeahead callback
		function getNAICSCodesForSelectBox(filterBy) {
			return _.filter(vm.refNAICSCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.description && filterBy && isString) {
					return code.description.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}

		// typeahead callback
		function getGICSSubIndustryCodesForSelectBox(filterBy) {
			return _.filter(vm.refGICSSubIndustryCodes, function (code) {
				var isString = isNaN(filterBy);
				if (code.description && filterBy && isString) {
					return code.description.toLowerCase().indexOf(filterBy.toLowerCase()) > -1;
				} else if (code.code && filterBy && !isString) {
					return code.code === filterBy;
				}
			});
		}

		function onSetGICSSubIndustryCodesForSelectBox(item) {
			vm.organisation.gicsSubIndustryCode1 = item.code;
			vm.organisation.gicsSubIndustryTxt1 = item.description;
			var industryGroupCode = _.find(vm.refGICSTableRows, function (row) {
				return row.gicsSubIndustryGroupCode.code === item.code;
			});

			vm.organisation.gicsSectorCode1 = industryGroupCode.gicsSectorCode.code;
			vm.organisation.gicsSectorTxt1 = industryGroupCode.gicsSectorCode.description;
			vm.organisation.gicsIndustryGroupCode1 = industryGroupCode.gicsIndustryGroupCode.code;
			vm.organisation.gicsIndustryGroupTxt1 = industryGroupCode.gicsIndustryGroupCode.description;
			vm.organisation.gicsIndustryCode1 = industryGroupCode.gicsIndustryCode.code;
			vm.organisation.gicsIndustryTxt1 = industryGroupCode.gicsIndustryCode.description;
			vm.organisation.gicsSubIndustryCode1 = industryGroupCode.gicsSubIndustryGroupCode.code;
			vm.organisation.gicsSubIndustryTxt1 = industryGroupCode.gicsSubIndustryGroupCode.description;
		}

		function onNBKModalSearch() {
			NBKOrganisationService.searchNBK(vm.queryNBKForOrgName, vm.queryNBKForCountryISO, vm.queryNBKForOrgId, vm.queryNBKForDUNS).then( onFinishedNBK );
		}

		function onFinishedNBK(orgs) {
			vm.nbkOrganisations = orgs;
			logger.info('Found ' + vm.nbkOrganisations.length + ' Organisations');
		}

		function nullOutNBKQueryFields(){
			vm.queryNBKForOrgName = null;
			//vm.queryNBKForCountryISO = null;
			vm.queryNBKForOrgId = null;
			vm.queryNBKForDUNS = null;
		}

		function onNBKSearchByDUNS() {
			nullOutNBKQueryFields();
			vm.queryNBKForDUNS = vm.organisation.duns;
			vm.queryNBKForCountryISO = vm.organisation.address.countryISO;
			NBKOrganisationService.searchNBK(null, vm.queryNBKForCountryISO, null, vm.queryNBKForDUNS).then( onFinishedNBK );
			$uibModal.open({
				templateUrl: 'partials/enhance/leadenhancer/nbk-quicksearch-modal.html',
				//controller: 'ConfirmController as vm',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
			});
		}

		function onFuzzySearchWithNACE(selecteNaceCode) {
			if( _.isNull(selecteNaceCode) ){
				return;
			}

			LEOrganisationService.onFuzzySearchWithNACE(selecteNaceCode.name).then( function(response){
				// clear all fields
				vm.organisation.naicsCode = null;
				vm.organisation.naicsCodeText = null;
				vm.organisation.naicsCode2 = null;
				vm.organisation.naicsText2 = null;
				vm.organisation.naicsCode3 = null;
				vm.organisation.naicsText3 = null;
				vm.organisation.sicPrimaryCode = null;
				vm.organisation.sicPrimaryCodeDescription = null;
				vm.organisation.sicCode2 = null;
				vm.organisation.sicText2 = null;
				vm.organisation.sicCode3 = null;
				vm.organisation.sicText3 = null;
				vm.organisation.sniNaceCode = null;
				vm.organisation.sniNaceCodeDescription = null;
				vm.organisation.sniNaceCode2 = null;
				vm.organisation.sniNaceCodeText2 = null;
				vm.organisation.sniNaceCode3 = null;
				vm.organisation.sniNaceCodeText3 = null;



				if (!_.isNull(response.naicsCodes) && response.naicsCodes.length > 0) {
					vm.organisation.naicsCode = response.naicsCodes[0].code;
					vm.organisation.naicsCodeText = response.naicsCodes[0].description;
					if (!_.isEmpty(response.naicsCodes[1])) {
						vm.organisation.naicsCode2 = response.naicsCodes[1].code;
						vm.organisation.naicsText2 = response.naicsCodes[1].description;
					}
					if (!_.isEmpty(response.naicsCodes[2])) {
						vm.organisation.naicsCode3 = response.naicsCodes[2].code;
						vm.organisation.naicsText3 = response.naicsCodes[2].description;
					}
				}

				if (!_.isNull(response.sicCodes && response.sicCodes.length > 0)) {
					vm.organisation.sicPrimaryCode = response.sicCodes[0].code;
					vm.organisation.sicPrimaryCodeDescription = response.sicCodes[0].name;
					if (!_.isEmpty(response.sicCodes[1])) {
						vm.organisation.sicCode2 = response.sicCodes[1].code;
						vm.organisation.sicText2 = response.sicCodes[1].name;
					}
					if (!_.isEmpty(response.sicCodes[2])  ) {
						vm.organisation.sicCode3 = response.sicCodes[2].code;
						vm.organisation.sicText3 = response.sicCodes[2].name;
					}
				}

				if (!_.isNull(response.naceCodes) && response.naceCodes.length > 0) {
					vm.organisation.sniNaceCode = response.naceCodes[0].code;
					vm.organisation.sniNaceCodeDescription = response.naceCodes[0].name;
					if (!_.isEmpty(response.naceCodes[2])  ) {
						vm.organisation.sniNaceCode2 = response.naceCodes[1].code;
						vm.organisation.sniNaceCodeText2 = response.naceCodes[1].name;
					}
					if (!_.isEmpty(response.naceCodes[2])  ) {
						vm.organisation.sniNaceCode3 = response.naceCodes[2].code;
						vm.organisation.sniNaceCodeText3 = response.naceCodes[2].name;
					}
				}
				logger.info('Found and updated industry fields!!');
			} );
		}

		function onNBKSearchByOrgid() {
			nullOutNBKQueryFields();
			vm.queryNBKForOrgId = vm.organisation.organisationId;
			vm.queryNBKForCountryISO = vm.organisation.address.countryISO;
			NBKOrganisationService.searchNBK(null, vm.queryNBKForCountryISO, vm.queryNBKForOrgId, null).then( onFinishedNBK );
			$uibModal.open({
				templateUrl: 'partials/enhance/leadenhancer/nbk-quicksearch-modal.html',
				//controller: 'ConfirmController as vm',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
			});
		}

		function onNBKSearchByName() {
			nullOutNBKQueryFields();
			vm.queryNBKForOrgName = vm.organisation.name;
			vm.queryNBKForCountryISO = vm.organisation.address.countryISO;
			NBKOrganisationService.searchNBK(vm.queryNBKForOrgName, vm.queryNBKForCountryISO, null, null).then( onFinishedNBK );
			$uibModal.open({
				templateUrl: 'partials/enhance/leadenhancer/nbk-quicksearch-modal.html',
				//controller: 'ConfirmController as vm',
				scope: $scope,
				size: 'lg'
			}).result.then(function () {
			});
		}


		function takeOverNBKORganisation(selectedNbkOrganisation) {
			if (_.isNull(selectedNbkOrganisation.dunsNumber)) {
				logger.error('DUNS must be set!!!');
				return;
			}
			console.log('selectedNbkOrganisation', selectedNbkOrganisation)
			return NBKOrganisationService.takeOverNBKORganisation(selectedNbkOrganisation).then(takeOverFromNBK);
		}

		function takeOverFromNBK(useMe) {
			if (useMe) {
				vm.organisation.organisationType = _.isNull(useMe.leorganisationType) ? null : useMe.leorganisationType;
				vm.organisation.name = _.isNull(useMe.companyName) ? null : useMe.companyName;
				vm.organisation.organisationId = _.isNull(useMe.countryRegistrationNumber) ? null : useMe.countryRegistrationNumber;
				vm.organisation.duns = _.isNull(useMe.dunsNumber) ? null : useMe.dunsNumber;
				vm.organisation.sicPrimaryCode = useMe.sicPrimaryCode;
				vm.organisation.sicPrimaryCodeDescription = useMe.sicPrimaryDescription;
				vm.organisation.naicsCode = useMe.naicsCode;
				vm.organisation.naicsCodeText = useMe.naicsCodeText;

				if( !_.isNull(useMe.naceCode2) ){
					vm.organisation.sniNaceCode2 = useMe.naceCode2;
					vm.organisation.sniNaceCodeText2 = useMe.naceCodeText2;
				}

				if( !_.isNull(useMe.naceCode3) ){
					vm.organisation.sniNaceCode3 = useMe.naceCode3;
					vm.organisation.sniNaceCodeText3 = useMe.naceCodeText3;
				}

				vm.organisation.sniNaceCode = useMe.primarySniNaceCode;
				vm.organisation.sniNaceCodeDescription = useMe.primarySniNaceCodeText;
				vm.organisation.ceo = useMe.positionCEO;
				vm.organisation.cmo = useMe.positionCMO;
				vm.organisation.cso = _.isNull(useMe.positionCSO) ? null : useMe.positionCSO;
				vm.organisation.resourceManager = useMe.positionHR;
				vm.organisation.noOfEmployees = useMe.noOfEmployees;
				vm.organisation.financialYearEnd = useMe.financialYearEnd;
				vm.organisation.legalStatus = useMe.legalStatus;
				vm.organisation.sales = _.isNull(useMe.salesEuro) ? 0 : useMe.salesEuro;
				vm.organisation.currency = useMe.currency;
				vm.organisation.source = useMe.source;
				//  vm.organisation.is	Isp = useMe.isIsp;
				vm.organisation.active = true;
				vm.organisation.address.countryISO = _.isNull(useMe.countryIsoCode) ? vm.organisation.address.countryISO : useMe.countryIsoCode;
				vm.organisation.address.phone = _.isNull(useMe.telephoneNumber) ? vm.organisation.address.phone : useMe.telephoneNumber;
				vm.organisation.address.email = _.isNull(useMe.email) ? vm.organisation.address.email : useMe.email;
				vm.organisation.domain = _.isNull(useMe.homePage) ? vm.organisation.domain: useMe.homePage;
				vm.organisation.address.address = _.isNull(useMe.visitingAdress) ? vm.organisation.address.address : useMe.visitingAdress;
				vm.organisation.address.city = _.isNull(useMe.visitingAdressTownName) ? vm.organisation.address.city : useMe.visitingAdressTownName;
				vm.organisation.address.postalCode = _.isNull(useMe.postalCode) ? vm.organisation.address.postalCode : useMe.postalCode;

				//onInitSetCountryFromEnhancedOrganisation(vm.enhancedOrganisation.countryIso);

				onInitSetSICSelectFromEnhancedOrganisation(vm.organisation.sicPrimary);
			}

			logger.info('Took over data!!');
		}

		function onInitSetSICSelectFromEnhancedOrganisation(sicCode) {
			// select uses afull json object
			vm.selectedSICCode = _.find(vm.refSICCodes, function (code) {
				if (sicCode === code.code) {
					console.log('sicCode' , sicCode)
					return code;
				}
			});
		}

		function tableSize() {
			return vm.nbkOrganisations.length;
		}

	}
})();