(function () {
    'use strict';

    angular
        .module('adminApp')
        .controller('SegmentOrganisationsImportConfirmController', ['$uibModalInstance', 'modalConfig', SegmentOrganisationsImportConfirmController]);

    function SegmentOrganisationsImportConfirmController($uibModalInstance, modalConfig) {
        var vm = this;
        vm.title = modalConfig.title;
        vm.body = modalConfig.body;
        vm.positive = modalConfig.positive;
        vm.negative = modalConfig.negative;
        vm.ok = ok;

        function ok() {
            $uibModalInstance.close();
        }
    }

})();