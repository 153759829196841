(function () {
    'use strict';

    angular
        .module('adminApp')
        .service('AdminBTService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AdminBTService]);

    function AdminBTService($http, $q, CONFIG, responseExceptionCatcher) {
        this.getClients = getClients;
        this.getClient = getClient;
        this.getAdvertisers = getAdvertisers;
        this.getAdvertiser = getAdvertiser;
        this.getAllAdvertisers = getAllAdvertisers;
        this.getAdvertiserLineItems = getAdvertiserLineItems;
        this.getCampaigns = getCampaigns;
        this.getCampaign = getCampaign;
        this.getCampaignAds = getCampaignAds;
        this.getAllAds = getAllAds;

        function getClients() {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getClient(clientId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/'+ clientId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAdvertisers(clientId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId +  '/advertisers' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAllAdvertisers() {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/advertisers' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAdvertiser(clientId, advertiserId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId +  '/advertisers/' +advertiserId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function getAdvertiserLineItems(clientId, advertiserId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId +  '/advertisers/' + advertiserId + '/lineitems' )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }


        function getCampaigns(clientId, advertiserId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId + '/advertisers/' + advertiserId + '/campaigns')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaign(clientId, advertiserId, campaignId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId +  '/advertisers/' +advertiserId + '/campaigns/' + campaignId )
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getCampaignAds(clientId, advertiserId, campaignId) {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/clients/' + clientId + '/advertisers/' + advertiserId + '/campaigns/' + campaignId + '/ads')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }

        function getAllAds() {
            return $http.get( CONFIG.API_PREFIX + '/admin/bt/ads')
                .then(function (response) {
                    return response.data.data;
                })
                .catch(responseExceptionCatcher.catch('Error when retrieving data'));
        }
    }
})();