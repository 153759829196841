(function () {
	'use strict';

	angular
		.module('adminApp')
		.service('AudienceService', ['$http', '$q', 'CONFIG', 'responseExceptionCatcher', AudienceService]);

	function AudienceService($http, $q, CONFIG, responseExceptionCatcher) {
		var vm = this;
		vm.getAudiences = getAudiences;
		vm.getAudienceLog = getAudienceLog;
		vm.getAudienceCategories = getAudienceCategories;
		vm.getAudience = getAudience;
		vm.getAudienceRights = getAudienceRights;
		vm.getSegments = getSegments;
		vm.createOrUpdateAudience = createOrUpdateAudience;
		vm.createCategory = createCategory;
		vm.deleteAudiences = deleteAudiences;
		vm.cloneAudiences = cloneAudiences;
		vm.publishAudience = publishAudience;
		vm.publishAudienceSegment = publishAudienceSegment;
		vm.findAllAgencies = findAllAgencies;
		vm.findAllAgencyCountries = findAllAgencyCountries;
		vm.saveAudienceRights = saveAudienceRights;

		//var agencies = {};
		var agencyCountries = [];

		function getAudiences(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getAudienceLog(accountId, audienceId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/logs/' + audienceId)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getAudienceRights(accountId, audienceId, audienceRightsId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/' + audienceId + '/rights/' + audienceRightsId)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function findAllAgencies(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/agencies')
				.then(function (response) {
					var agenciesDecorated = decoarateAllAgenciesWithFlag(response.data);
					return getCopy(agenciesDecorated);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function findAllAgencyCountries(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/agencies/countries')
				.then(function (response) {
					var agencyCountriesDecorated = decoarateAllAgencyCountriesWithFlag(response.data);
					return getCopy(agencyCountriesDecorated);
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function decoarateAllAgenciesWithFlag(agencies) {
			return _.each(agencies, function (agency) {
				if (agency.iso2Code !== null) {
					agency.flagClass = 'flag-' + agency.iso2Code.toLowerCase();
				}
				return agency;
			});
		}

		function decoarateAllAgencyCountriesWithFlag(agencyCountries) {
			return _.each(agencyCountries, function (country) {
				if (country.iso2Code !== null) {
					country.flagClass = 'flag-' + country.iso2Code.toLowerCase();
				}
				return country;
			});
		}

		function getCopy(list) {
			return angular.copy(list);
		}


		function getAudienceCategories(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/categories')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function deleteAudiences(accountId, audiencesIds) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/delete', audiencesIds)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function cloneAudiences(accountId, audiencesIds) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/clone', audiencesIds)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getSegments(accountId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/segments')
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data'));
		}

		function getAudience(accountId, audienceId) {
			return $http.get(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/' + audienceId)
				.then(function (response) {
					var audience = response.data;
					return response.data;
				})
				.catch(function () {
					responseExceptionCatcher.catch('Error when retrieving data');
				});
		}

		function publishAudience(accountId, audience) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/publish/' + audience.id)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data '));
		}

		function publishAudienceSegment(accountId, audience) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/publish/segment/' + audience.id)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when retrieving data '));
		}

		function createOrUpdateAudience(accountId, audience) {
			var promise;
			if (!(_.isNull(audience.id) || audience.id === undefined || audience.id === '')) {
				promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences', audience)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving audience '));
			} else {
				promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences', audience)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving audience '));
			}
			return promise;
		}

		function saveAudienceRights(audienceId, audienceRights) {
			var promise;
			if (!(_.isNull(audienceRights.id) || audienceRights.id === undefined || audienceRights.id === '')) {
				promise = $http.put(CONFIG.API_PREFIX + '/accounts/' + audienceRights.accountId + '/audiences/' + audienceId + '/rights', audienceRights)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving audience rights'));
			} else {
				promise = $http.post(CONFIG.API_PREFIX + '/accounts/' + audienceRights.accountId + '/audiences/' + audienceId + '/rights', audienceRights)
					.then(function (response) {
						return response.data;
					})
					.catch(responseExceptionCatcher.catch('Error when saving audience rights'));
			}
			return promise;
		}

		function createCategory(accountId, categoryName) {
			return $http.post(CONFIG.API_PREFIX + '/accounts/' + accountId + '/audiences/category', categoryName)
				.then(function (response) {
					return response.data;
				})
				.catch(responseExceptionCatcher.catch('Error when saving audience '));
		}
	}
})();